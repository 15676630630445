import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    flexDirection: 'column',
  },
}));

export default function Pending() {
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={true} invisible={true}>
        <CircularProgress />
      </Backdrop>
    </div>
  );
}
