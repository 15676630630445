import { ofType } from 'redux-observable';
import {
  map,
  mergeMap,
  debounceTime,
  startWith,
  catchError,
} from 'rxjs/operators';
import {
  ADD_CART,
  DROP_CART,
  ADD_TO_CART,
  DROP_FROM_CART,
  QUANTITY_INCREMENT,
  QUANTITY_DECREMENT,
  FETCH_CART_COUPONS,
  fetchCartSuccess,
  lockCartItem,
  unlockCartItem,
  syncCartItem,
  cartLoadingStart,
  cartSyncLoadingStart,
  fetchCoupons,
  fetchCouponsSuccess,
} from '../actions/cart';
import {
  getUserCart,
  postUserAddCart,
  postUserDropCart,
  postViewCart,
} from '../services/authentication';
import {
  FETCH_USER_CART,
  FETCH_SYNC_USER_CART,
  FETCH_VIEW_CART,
} from '../actions/user';
import { Modal } from '../actions/modal';
import { postCoupons } from '../services/billingPage';
import { from, empty, of } from 'rxjs';
import i18n from '../i18n';

export const fetchSyncUserCartEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_SYNC_USER_CART),
    mergeMap((action) =>
      from(
        getUserCart(
          { token: state$.value.user.token.accessToken },
          action.payload
        )
      ).pipe(
        mergeMap((result) => {
          if (state$.value.cart.addedProducts.length === 0) {
            return of(
              fetchCartSuccess(result),
              cartSyncLoadingStart(false),
              fetchCoupons(action.payload)
            );
          }
          var data = {};
          state$.value.cart.addedProducts.forEach((element) => {
            if (
              result.data.some(function (item, index, array) {
                return element.id === item.id;
              })
            ) {
              var i = result.data.findIndex(function (item, index, array) {
                return element.id === item.id;
              });
              data[element.id] = element.quantity - result.data[i].quantity;
            } else {
              data[element.id] = element.quantity;
            }
          });
          return from(
            postUserAddCart({ data: data }, state$.value.user.token.accessToken)
          ).pipe(
            mergeMap(() =>
              from(
                getUserCart(
                  { token: state$.value.user.token.accessToken },
                  action.payload
                )
              ).pipe(
                mergeMap((result) =>
                  of(
                    fetchCartSuccess(result),
                    cartSyncLoadingStart(false),
                    fetchCoupons(action.payload)
                  )
                )
              )
            )
          );
        })
      )
    )
  );

export const fetchUserCartEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_USER_CART),
    mergeMap((action) =>
      from(
        getUserCart(
          { token: state$.value.user.token.accessToken },
          action.payload
        )
      ).pipe(
        mergeMap((result) =>
          of(fetchCartSuccess(result), fetchCoupons(action.payload))
        ),
        catchError(() => of(Modal('', true))),
        startWith(cartLoadingStart())
      )
    )
  );

export const fetchViewCartEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_VIEW_CART),
    mergeMap((action) => {
      var products = {};
      state$.value.cart.addedProducts.forEach((item) => {
        products[item.id] = item.quantity;
      });
      if (state$.value.cart.addedProducts.length === 0) {
        return empty();
      }
      return from(postViewCart({ data: products }, action.payload)).pipe(
        mergeMap((result) =>
          of(fetchCartSuccess(result), fetchCoupons(action.payload))
        ),
        startWith(cartLoadingStart())
      );
    })
  );

export const addToCartEpic = (action$, state$) =>
  action$.pipe(
    ofType(ADD_CART),
    mergeMap((action) => {
      if (
        !state$.value.cart.addedProducts.some(function (item, index, array) {
          return item.id === action.payload.id;
        })
      ) {
        if (state$.value.user.token === null) {
          return of(
            { type: ADD_TO_CART, payload: action.payload },
            fetchCoupons(i18n.language)
          );
        }
        return from(
          postUserAddCart(
            { data: { [action.payload.id]: action.payload.quantity } },
            state$.value.user.token.accessToken
          )
        ).pipe(
          mergeMap((result) =>
            of(
              { type: ADD_TO_CART, payload: action.payload },
              fetchCoupons(i18n.language)
            )
          )
        );
      } else {
        return empty(); // or jump a modal
      }
    })
  );

export const dropFromCartEpic = (action$, state$) =>
  action$.pipe(
    ofType(DROP_CART),
    mergeMap((action) => {
      if (state$.value.user.token === null) {
        return of(
          { type: DROP_FROM_CART, payload: action.payload },
          fetchCoupons(i18n.language)
        );
      }
      return from(
        postUserDropCart(
          { data: { data: [action.payload.id], query: 'n' } },
          state$.value.user.token.accessToken
        )
      ).pipe(
        mergeMap((result) =>
          of(
            { type: DROP_FROM_CART, payload: action.payload },
            fetchCoupons(i18n.language)
          )
        )
      );
    })
  );

export const itemQuantityUpdateEpic = (action$, state$) =>
  action$.pipe(
    ofType(QUANTITY_INCREMENT, QUANTITY_DECREMENT),
    debounceTime(1000),
    mergeMap((action) => {
      var data = {};
      var dataIndex = [];
      state$.value.cart.addedProducts.forEach((item) => {
        if (item.quantity !== item.oldQuantity) {
          data[item.id] = item.quantity - item.oldQuantity;
          dataIndex.push(item.id);
        }
      });
      if (state$.value.user.token === null) {
        return of(syncCartItem(), fetchCoupons(i18n.language));
      }
      return from(
        postUserAddCart({ data: data }, state$.value.user.token.accessToken)
      ).pipe(
        mergeMap(() =>
          of(
            syncCartItem(),
            fetchCoupons(i18n.language),
            unlockCartItem(dataIndex)
          )
        ),
        startWith(lockCartItem(dataIndex))
      );
    })
  );

export const itemQuantityUpdateLoadingEpic = (action$, state$) =>
  action$.pipe(
    ofType(QUANTITY_INCREMENT, QUANTITY_DECREMENT),
    map(() => cartLoadingStart())
  );

export const fetchCouponsEpic = (action$, state$) =>
  action$.pipe(
    ofType(FETCH_CART_COUPONS),
    mergeMap((action) => {
      var sets = {};
      // var dataIndex = [];
      state$.value.cart.addedProducts.forEach((s) => {
        if (sets[s.providerId] === undefined) {
          sets[s.providerId] = [];
          sets[s.providerId].push({
            id: s.id,
            quantity: s.quantity,
          });
        } else {
          sets[s.providerId].push({
            id: s.id,
            quantity: s.quantity,
          });
        }

        // if (s.quantity !== s.oldQuantity) {
        //   dataIndex.push(s.id);
        // }
      });

      return from(postCoupons(sets, action.payload)).pipe(
        mergeMap((result) =>
          of(
            fetchCouponsSuccess(result)
            // unlockCartItem(dataIndex),
          )
        )
      );
    })
  );
