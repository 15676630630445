import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;

  ${({ dialog }) =>
    dialog &&
    `
    display: flex;
    flex-direction: column;

    padding: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const Divide = styled.hr`
  border: 1px solid #ffffff;
  background: #898989;
  border-style: inset;
  // border-width: 1px;
`;

const Divider = (props) => {
  const { dialog } = props;
  return (
    <Wrapper dialog={dialog}>
      <div>
        <Divide />
      </div>
    </Wrapper>
  );
};

export default Divider;
