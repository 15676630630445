import { combineEpics } from 'redux-observable';
import { oauthEpic, logoutEpic } from './user';
import {
  fetchUserCartEpic,
  fetchSyncUserCartEpic,
  addToCartEpic,
  dropFromCartEpic,
  itemQuantityUpdateEpic,
  fetchViewCartEpic,
  fetchCouponsEpic,
  itemQuantityUpdateLoadingEpic,
} from './cart';

export const rootEpic = combineEpics(
  oauthEpic,
  logoutEpic,
  fetchUserCartEpic,
  fetchSyncUserCartEpic,
  addToCartEpic,
  dropFromCartEpic,
  itemQuantityUpdateEpic,
  fetchViewCartEpic,
  fetchCouponsEpic,
  itemQuantityUpdateLoadingEpic
);
