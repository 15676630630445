import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 48px 0px 16px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const ContainerGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FirstContainer = styled.div`
  position: relative;
`;

const SecondContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ThirdContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const UpperContainer = styled.div`
  position: relative;
`;

const LowerContainer = styled.div`
  position: relative;
`;

const ImageFirst = styled.img`
  border-radius: 20px;
  height: 392px;
  width: 381px;
`;

const ImageSecond = styled.img`
  border-radius: 20px;
  height: 181px;
  width: 419px;
`;

const ImageThird = styled.img`
  border-radius: 20px;
  height: 181px;
  width: 419px;
`;

const ImageTitleOneRow = styled.div`
  font-size: 20px;
  position: absolute;
  bottom: 21px;
  left: 24px;
  color: #ffffff;
`;

const ImageTitleTwoRowUpper = styled.div`
  font-size: 20px;
  position: absolute;
  bottom: 21px;
  left: 24px;
  color: #ffffff;
`;

const ImageTitleTwoRowLower = styled.div`
  font-size: 20px;
  position: absolute;
  bottom: 21px;
  left: 24px;
  color: #ffffff;
`;

const SoftwareImage = (props) => {
  const { data } = props;
  const { hash } = useLocation();
  return (
    <Wrapper>
      <ContainerGrid>
        <FirstContainer>
          <Link
            onClick={() => {
              const id = data[0] === undefined ? '' : data[0].id;
              if (hash.replace('#', '') === id) {
                setTimeout(() => {
                  const element = document.getElementById(
                    data[0] === undefined ? '' : data[0].id
                  );
                  if (element) {
                    window.scrollTo({
                      top:
                        element.getBoundingClientRect().top -
                        80 +
                        window.pageYOffset,
                      behavior: 'smooth',
                    });
                  }
                }, 100);
              }
            }}
            to={`#${data[0] === undefined ? '' : data[0].id}`}
          >
            <ImageTitleOneRow>
              {data[0] === undefined ? '' : data[0].title}
            </ImageTitleOneRow>
            <ImageFirst
              src={data[0] === undefined ? '' : data[0].image}
              alt=""
            />
          </Link>
        </FirstContainer>
        <SecondContainer>
          <UpperContainer>
            <Link
              onClick={() => {
                const id = data[1] === undefined ? '' : data[1].id;
                if (hash.replace('#', '') === id) {
                  setTimeout(() => {
                    const element = document.getElementById(
                      data[1] === undefined ? '' : data[1].id
                    );
                    if (element) {
                      window.scrollTo({
                        top:
                          element.getBoundingClientRect().top -
                          80 +
                          window.pageYOffset,
                        behavior: 'smooth',
                      });
                    }
                  }, 100);
                }
              }}
              to={`#${data[1] === undefined ? '' : data[1].id}`}
            >
              <ImageTitleTwoRowUpper>
                {data[1] === undefined ? '' : data[1].title}
              </ImageTitleTwoRowUpper>
              <ImageSecond
                src={data[1] === undefined ? '' : data[1].image}
                alt=""
              />
            </Link>
          </UpperContainer>
          <LowerContainer>
            <Link
              onClick={() => {
                const id = data[2] === undefined ? '' : data[2].id;
                if (hash.replace('#', '') === id) {
                  setTimeout(() => {
                    const element = document.getElementById(
                      data[2] === undefined ? '' : data[2].id
                    );
                    if (element) {
                      window.scrollTo({
                        top:
                          element.getBoundingClientRect().top -
                          80 +
                          window.pageYOffset,
                        behavior: 'smooth',
                      });
                    }
                  }, 100);
                }
              }}
              to={`#${data[2] === undefined ? '' : data[2].id}`}
            >
              <ImageTitleTwoRowLower>
                {data[2] === undefined ? '' : data[2].title}
              </ImageTitleTwoRowLower>
              <ImageThird
                src={data[2] === undefined ? '' : data[2].image}
                alt=""
              />
            </Link>
          </LowerContainer>
        </SecondContainer>
        <ThirdContainer>
          <UpperContainer>
            <Link
              onClick={() => {
                const id = data[3] === undefined ? '' : data[3].id;
                if (hash.replace('#', '') === id) {
                  setTimeout(() => {
                    const element = document.getElementById(
                      data[3] === undefined ? '' : data[3].id
                    );
                    if (element) {
                      window.scrollTo({
                        top:
                          element.getBoundingClientRect().top -
                          80 +
                          window.pageYOffset,
                        behavior: 'smooth',
                      });
                    }
                  }, 100);
                }
              }}
              to={`#${data[3] === undefined ? '' : data[3].id}`}
            >
              <ImageTitleTwoRowUpper>
                {data[3] === undefined ? '' : data[3].title}
              </ImageTitleTwoRowUpper>
              <ImageSecond
                src={data[3] === undefined ? '' : data[3].image}
                alt=""
              />
            </Link>
          </UpperContainer>
          <LowerContainer>
            <Link
              onClick={() => {
                const id = data[4] === undefined ? '' : data[4].id;
                if (hash.replace('#', '') === id) {
                  setTimeout(() => {
                    const element = document.getElementById(
                      data[4] === undefined ? '' : data[4].id
                    );
                    if (element) {
                      window.scrollTo({
                        top:
                          element.getBoundingClientRect().top -
                          80 +
                          window.pageYOffset,
                        behavior: 'smooth',
                      });
                    }
                  }, 100);
                }
              }}
              to={`#${data[4] === undefined ? '' : data[4].id}`}
            >
              <ImageTitleTwoRowLower>
                {data[4] === undefined ? '' : data[4].title}
              </ImageTitleTwoRowLower>
              <ImageThird
                src={data[4] === undefined ? '' : data[4].image}
                alt=""
              />
            </Link>
          </LowerContainer>
        </ThirdContainer>
      </ContainerGrid>
    </Wrapper>
  );
};

export default SoftwareImage;
