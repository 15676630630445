import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../index';
import { useTranslation } from 'react-i18next';
import { getUserWishList } from '../../services/authentication';
import { Button, IconButton, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// import { addToCart } from '../../actions/cart';
import { notify } from '../../actions/notification';
import { Modal } from '../../actions/modal';
import { postUserDropWishList } from '../../services/authentication';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px 16px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

const CustomTabs = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d1d1d1;
  justify-content: space-between;
`;

const CustomTab = styled.div`
  height: 50px;
  padding: 8px;
  position: relative;
`;

const TabTitle = styled.div`
  color: #d1d1d1;
  font-size: 18px;
  padding: 2px 16px;
  ${({ active }) =>
    active &&
    `
    background: #0cbaef;
    color: #ffffff;
    border-radius: 20px;
    padding: 2px 16px;
  `}
`;

const TitleLink = styled(Link)`
  text-decoration: none;
`;

const WishListWrapper = styled.div`
  padding: 32px 282px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  // align-items: center;
  padding: 8px 0 16px;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2%;
`;

const ImageLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #3e3a39;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: scale-down;
`;

const ImageDefaultContainer = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d1d1d1;
`;

const ImageDefault = styled.p`
  color: #3e3a39;
`;

const ProductContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d1d1d1;
  padding: 16px 0 16px;
  padding-left: 2%;
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
`;

const NameContainer = styled.div`
  font-size: 21px;
`;

const NameLink = styled(Link)`
  text-decoration: none;
  color: #3e3a39;
`;

const CategoryContainer = styled.div`
  font-size: 15px;
  margin: 4px 0;
`;

const CategoryLink = styled(Link)`
  text-decoration: none;
  color: #9fa0a0;
`;

const DescrtptionContainer = styled.div`
  font-size: 15px;
  color: #9fa0a0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行數
  -webkit-box-orient: vertical;
`;

const DescrtptionLink = styled(Link)`
  text-decoration: none;
  color: #9fa0a0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

// const AddButton = styled(Button)`
//   && {
//     padding: 0;
//     min-width: 72px;
//     min-height: 20px;
//     font-size: 13px;
//     border-radius: 100px;
//     background: #ef980c;
//     color: #ffffff;
//     &:hover {
//       color: #3e3a39;
//     }
//   }
// `;

const EmptyWishListContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 360px;
  background: #f9f9f9;
  flex-direction: column;
  margin-top: 72px;
`;

const EmptyWishListTitleContainer = styled.div`
  text-align: center;
  font-size: 39px;
  color: #707070;
  font-weight: bold;
`;

const EmptyWishListDescriptionContainer = styled.div`
  text-align: center;
  font-size: 29px;
  color: #707070;
`;

const EmptyWishListButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SoftwareButton = styled(Button)`
  && {
    font-size: 14px;
    padding: 4px 20px;
    background: #0cbaef;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #0cbaef;
    width: 106px;
    margin-right: 16px;
    &:hover {
      color: #0cbaef;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const HardwareButton = styled(Button)`
  && {
    font-size: 14px;
    padding: 4px 20px;
    background: #ef980c;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #ef980c;
    width: 106px;
    margin-left: 16px;
    &:hover {
      color: #ef980c;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  margin: calc(50vh - 45px) auto 0;
`;

const WishList = () => {
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [data, setData] = useState({
    wishlist: [],
  });
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(0);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');

  useEffect(() => {
    setLoading(true);
    var fetchData = async () => {
      try {
        const result = await getUserWishList(
          {
            params: { lng: i18n.language },
          },
          user.token.accessToken
        );
        setData(result.data);
        setLoading(false);
      } catch (e) {
        setData({
          wishlist: [],
        });
        setLoading(false);
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };
    if (user.token === null) {
      if (ticket === null) {
        dispatch(Modal('', true));
      } else {
        var reLogin = setInterval(() => {
          if (store.getState().user.token) {
            fetchData();
            clearInterval(reLogin);
          }
        }, 100);
      }
    } else {
      fetchData();
    }
  }, [i18n, i18n.language, user, dispatch, refresh, ticket]);

  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <CustomTabs>
            <CustomTab>
              <TitleLink className="customTab" to="/users/profile">
                <TabTitle>{t('user.tab.title.profile')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/order">
                <TabTitle>{t('user.tab.title.order')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/wishList">
                <TabTitle active={true}>
                  {t('user.tab.title.wishList')}
                </TabTitle>
              </TitleLink>
              <div className="triangle active" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/deviceList">
                <TabTitle>{t('user.tab.title.device')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/appList">
                <TabTitle>{t('user.tab.title.app')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
          </CustomTabs>
          {data.wishlist.length === 0 ? (
            loading ? (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            ) : (
              <EmptyWishListContainer>
                <EmptyWishListTitleContainer>
                  {t('wishList.label.emptyTitle')}
                </EmptyWishListTitleContainer>
                <EmptyWishListDescriptionContainer>
                  {t('wishList.label.emptyDescription')}
                </EmptyWishListDescriptionContainer>
                <EmptyWishListButtonContainer>
                  <SoftwareButton
                    onClick={() => {
                      history.push('/products/software');
                    }}
                  >
                    {t('wishList.button.software')}
                  </SoftwareButton>
                  <HardwareButton
                    onClick={() => {
                      history.push('/products/hardware');
                    }}
                  >
                    {t('wishList.button.hardware')}
                  </HardwareButton>
                </EmptyWishListButtonContainer>
              </EmptyWishListContainer>
            )
          ) : loading ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : (
            <WishListWrapper>
              {data.wishlist.map((item, index) => (
                <ItemContainer key={index}>
                  <ImageContainer>
                    <ImageLink
                      to={`/products/${item.product.toLowerCase()}s/${
                        item.id
                      }/${item.versionIndex}`}
                    >
                      {item.image ? (
                        <Image src={item.image} alt={item.name} />
                      ) : (
                        <ImageDefaultContainer>
                          <ImageDefault>KNEO</ImageDefault>
                        </ImageDefaultContainer>
                      )}
                    </ImageLink>
                  </ImageContainer>
                  <ProductContainer>
                    <InfoContainer>
                      <NameContainer>
                        <NameLink
                          to={`/products/${item.product.toLowerCase()}s/${
                            item.id
                          }/${item.versionIndex}`}
                        >
                          {item.name}
                        </NameLink>
                      </NameContainer>
                      <CategoryContainer>
                        <CategoryLink
                          to={`/products/${item.product.toLowerCase()}s/${
                            item.id
                          }/${item.versionIndex}`}
                        >
                          {`${item.product}, Category: ${item.category}`}
                        </CategoryLink>
                      </CategoryContainer>
                      <DescrtptionContainer>
                        <DescrtptionLink
                          to={`/products/${item.product.toLowerCase()}s/${
                            item.id
                          }/${item.versionIndex}`}
                        >
                          {item.description}
                        </DescrtptionLink>
                      </DescrtptionContainer>
                    </InfoContainer>
                    <ButtonContainer>
                      {/* <AddButton
                        variant="contained"
                        disableElevation
                        onClick={() => {
                          dispatch(
                            addToCart(
                              item.id,
                              item.image,
                              item.name,
                              item.description,
                              item.product,
                              item.category,
                              item.price,
                              1
                            )
                          );
                        }}
                      >
                        {t('wishList.button.add')}
                      </AddButton> */}
                      <IconButton
                        aria-label="close"
                        onClick={() => {
                          const apiFetch = async () => {
                            try {
                              const result = await postUserDropWishList(
                                {
                                  data: {
                                    data: [item.id],
                                    query: 'n',
                                  },
                                },
                                user.token.accessToken
                              );
                              if (result.status === 'ok') {
                                setRefresh(refresh + 1);
                              }
                            } catch (e) {
                              if (e && e.response) {
                                switch (e.response.status) {
                                  case 400:
                                    dispatch(
                                      notify(
                                        'error',
                                        i18n.t('message.400'),
                                        true
                                      )
                                    );
                                    break;
                                  case 401:
                                    dispatch(Modal('', true));
                                    break;
                                  case 500:
                                    dispatch(
                                      notify(
                                        'error',
                                        i18n.t('message.500'),
                                        true
                                      )
                                    );
                                    break;
                                  default:
                                    dispatch(
                                      notify(
                                        'error',
                                        i18n.t('message.error'),
                                        true
                                      )
                                    );
                                }
                              }
                            }
                          };
                          apiFetch();
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </ButtonContainer>
                  </ProductContainer>
                </ItemContainer>
              ))}
            </WishListWrapper>
          )}
        </ContentWrapper>
      </Wrapper>
    </Fragment>
  );
};

export default WishList;
