import { useState, useEffect } from 'react';
import isObject from 'lodash/isObject';
import { useFormikContext } from 'formik';

const getFirstErrorKey = (object, keys = []) => {
  const firstErrorKey = Object.keys(object)[0];
  if (isObject(object[firstErrorKey])) {
    return getFirstErrorKey(object[firstErrorKey], [...keys, firstErrorKey]);
  }
  return [...keys, firstErrorKey].join('.');
};

const FormikOnError = ({ children }) => {
  const formik = useFormikContext();
  const firstErrorKey = getFirstErrorKey(formik.errors);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!formik.isValid && formik.submitCount > count) {
      if (window.document.getElementById(firstErrorKey)) {
        window.scrollTo({
          top:
            window.document
              .getElementById(firstErrorKey)
              .getBoundingClientRect().top +
            window.pageYOffset -
            120,
          behavior: 'smooth',
        });
        setCount(count + 1);
      }
    }
  }, [formik.submitCount, formik.isValid, firstErrorKey, count]);
  return children;
};

export default FormikOnError;
