import { NOTIFICATION } from '../actions/notification';

export const notification = (
  state = { message: '', type: 'success', open: false },
  action
) => {
  switch (action.type) {
    case NOTIFICATION:
      return {
        message: action.payload.message,
        type: action.payload.type,
        open: action.payload.open,
      };

    default:
      return state;
  }
};
