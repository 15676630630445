import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { useDispatch } from 'react-redux';
import { logoutSuccess } from '../../actions/user';
import { useLocation } from 'react-router-dom';

const styles = (theme) => ({
  root: {
    // margin: 0,
    padding: theme.spacing(1.5),
    marginLeft: 4,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const PopDialog = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  return (
    <Dialog
      open={props.open}
      // onClose={props.close}
      aria-labelledby="responsive-dialog-title"
      maxWidth={'xs'}
      fullWidth={true}
    >
      <DialogTitle
        id="customized-dialog-title"
        // onClose={props.close}
      >
        {t('dialog.title.kneo')}
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {t('dialog.description.noOperation')}
        </Typography>
        <Typography gutterBottom>
          {t('dialog.description.loginAgain')}
        </Typography>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={props.close} color="primary">
          Cancel
          </Button> */}
        <Button
          onClick={() => {
            dispatch(logoutSuccess());
            window.location =
              process.env.REACT_APP_KNERON_URL +
              '/login/?redirect=' +
              window.location.origin +
              location.pathname;
          }}
          color="primary"
          autoFocus
        >
          {t('dialog.button.proceed')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopDialog;
