import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getLegalPage } from '../services/landingPage';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px;
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
`;

const TitleContainer = styled.h2`
  font-size: 28px;
  color: #023859;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 16px;
`;

const ForewordContainer = styled.div`
  font-size: 20px;
  color: #707070;
  white-space: pre-line;
  line-height: 35px;
  margin: 16px 0;
`;

const ContentContainer = styled.div`
  width: 895px;
  background: #f0fcff;
  color: #707070;
  white-space: pre-line;
  margin-top: 16px;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 35px;
  padding: 36px 84px 42px;
  margin-left: auto;
  margin-right: auto;
`;

const ContactContainer = styled.div`
  font-size: 20px;
  color: #707070;
  white-space: pre-line;
  text-align: center;
  line-height: 35px;
  margin: 16px 0;
`;

const NoticeContainer = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #023859;
  white-space: pre-line;
  text-align: center;
  line-height: 40px;
  margin: 16px 0;
`;

const Legal = () => {
  const { i18n } = useTranslation();
  const [data, setData] = useState({
    title: '',
    foreword: '',
    content: '',
    contact: '',
    notice: '',
  });
  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getLegalPage({
          params: { lng: i18n.language },
        });
        setData(result.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [i18n, i18n.language]);

  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <TitleContainer>{data.title}</TitleContainer>
          <ForewordContainer>{data.foreword}</ForewordContainer>
          <ContentContainer>{data.content}</ContentContainer>
          <ContactContainer>{data.contact}</ContactContainer>
          <NoticeContainer>{data.notice}</NoticeContainer>
        </ContentWrapper>
      </Wrapper>
    </Fragment>
  );
};

export default Legal;
