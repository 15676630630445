import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import NextArrow from '../../resources/images/next-arrow.png';
import PrevArrow from '../../resources/images/prev-arrow.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 40px 0px 8px;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;

  ${({ dialog }) =>
    dialog &&
    `
    display: flex;
    flex-direction: column;

    padding: 40px 0px 8px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const ImageLink = styled(Link)`
  text-decoration: none;
  color: #3e3a39;
`;

const AddContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddTitleContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #d1d1d1;
`;

const AddTitle = styled.div`
  padding: 0 30px;
  font-size: 16px;
  font-weight: bold;
  line-height: 32px;
  color: #ffffff;
  background: #0cbaef;
  border-top-left-radius: 10px 10px;
  border-top-right-radius: 10px 10px;
`;

const AddItemContainer = styled.div`
  // height: 261px;
  padding: 46px 0 40px;
`;

const AddItems = styled.div`
  // height: 160px;
  width: 267px;
`;

const AddItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: space-between;
  ${({ index }) =>
    index % 2 === 1 &&
    `
    background: #fafafa;
  `}
  ${({ index }) =>
    index % 2 === 0 &&
    `
    background: #f5f5f5;
  `}
`;

const AddItemInfoContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 27px;
`;

const AddItemInfoLeftContainer = styled.div`
  width: 130px;
  height: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
`;

const AddItemleftImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
`;

const AddItemleftImage = styled.img`
  width: 74px;
  height: 74px;
  object-fit: scale-down;
`;

const AddItemImageDefaultContainer = styled.div`
  width: 74px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d1d1d1;
`;

const AddItemImageDefault = styled.p`
  color: #3e3a39;
`;

const AddItemInfoRightContainer = styled.div`
  width: 136px;
  // height: 78px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
`;

const AddItemInfoNameContainer = styled.div`
  font-size: 17px;
  line-heigth: 19px;
  font-weight: bold;
  color: #3e3a39;
  margin-right: 16px;
`;

const AddItemInfoNameLink = styled(Link)`
  text-decoration: none;
  color: #3e3a39;
`;

const AddItemInfoCategoryContainer = styled.div`
  font-size: 12px;
  margin: 8px 16px 6px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;
`;

const AddItemInfoCategoryLink = styled(Link)`
  text-decoration: none;
  color: #9fa0a0;
`;

const AddItemInfoDescriptionContainer = styled.div`
  font-size: 12px;
  color: #9fa0a0;
  margin: 0 16px 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行數
  -webkit-box-orient: vertical;
`;

const AddItemInfoDescriptionLink = styled(Link)`
  text-decoration: none;
  color: #9fa0a0;
`;

const AddItemPriceContainer = styled.div`
  // width: 120px;
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;
  color: #ff0000;
  margin-top: 16px;
  margin-bottom: 22px;
`;

const Arrow = styled.img`
  color: #898989;
  && {
    width: 16px;
    height: 32px;
  }
  &:hover {
    color: #999999;
    background: #f8f9fa;
    border-radius: 100px;
  }
`;

const Coupon = (props) => {
  const { t } = useTranslation();
  const { data, dialog } = props;

  const slickSettings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <Arrow src={NextArrow} alt="" />,
    prevArrow: <Arrow src={PrevArrow} alt="" />,
  };

  const coupons = [];
  if (data) {
    data.forEach((item) => {
      coupons.push(item);
    });
    if (data.length < 4) {
      for (let i = 0; i < 4 - data.length; i++) {
        coupons.push({
          name: '',
        });
      }
    }
  }

  return (
    data && (
      <Wrapper dialog={dialog}>
        <AddContainer>
          <AddTitleContainer>
            <AddTitle>{t('product.hardware.titles.addTitle')}</AddTitle>
          </AddTitleContainer>
          <AddItemContainer>
            <Slider {...slickSettings}>
              {coupons.map((s, index) =>
                s.name !== '' ? (
                  <AddItems key={index}>
                    <AddItem index={index}>
                      <AddItemInfoContainer>
                        <AddItemInfoLeftContainer>
                          <AddItemleftImageContainer>
                            <ImageLink
                              to={`/products/${s.type.toLowerCase()}s/${s.id}/${
                                s.versionIndex
                              }`}
                            >
                              {s.image ? (
                                <AddItemleftImage src={s.image} alt={s.name} />
                              ) : (
                                <AddItemImageDefaultContainer>
                                  <AddItemImageDefault>
                                    KNEO
                                  </AddItemImageDefault>
                                </AddItemImageDefaultContainer>
                              )}
                            </ImageLink>
                          </AddItemleftImageContainer>
                        </AddItemInfoLeftContainer>
                        <AddItemInfoRightContainer>
                          <AddItemInfoNameContainer>
                            <AddItemInfoNameLink
                              to={`/products/${s.type.toLowerCase()}s/${s.id}/${
                                s.versionIndex
                              }`}
                            >
                              {s.name}
                            </AddItemInfoNameLink>
                          </AddItemInfoNameContainer>
                          <AddItemInfoCategoryContainer>
                            <AddItemInfoCategoryLink
                              to={`/products/${s.type.toLowerCase()}s/${s.id}/${
                                s.versionIndex
                              }`}
                            >
                              {s.category}
                            </AddItemInfoCategoryLink>
                          </AddItemInfoCategoryContainer>
                          <AddItemInfoDescriptionContainer>
                            <AddItemInfoDescriptionLink
                              to={`/products/${s.type.toLowerCase()}s/${s.id}/${
                                s.versionIndex
                              }`}
                            >
                              {s.description}
                            </AddItemInfoDescriptionLink>
                          </AddItemInfoDescriptionContainer>
                          <AddItemPriceContainer>
                            {`NT $ ${s.sale_price}`}
                          </AddItemPriceContainer>
                        </AddItemInfoRightContainer>
                      </AddItemInfoContainer>
                    </AddItem>
                  </AddItems>
                ) : (
                  <div key={index}></div>
                )
              )}
            </Slider>
          </AddItemContainer>
        </AddContainer>
      </Wrapper>
    )
  );
};

export default Coupon;
