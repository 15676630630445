import { combineReducers } from 'redux';
import { user } from './user';
import { notification } from './notification';
import { cart } from './cart';
import { modal } from './modal';
import { download } from './download';

export const rootReducer = combineReducers({
  user,
  notification,
  cart,
  modal,
  download,
});
