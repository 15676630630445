import React from 'react';
import { Dialog } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player/lazy';

const CustomDialog = withStyles({
  paper: {
    overflowY: 'hidden',
  },
})(Dialog);

const VideoDialog = (props) => {
  return (
    <CustomDialog
      open={props.open.play}
      onClose={props.close}
      aria-labelledby="responsive-dialog-title"
      maxWidth={'md'}
    >
      <ReactPlayer
        url={props.open.src}
        height={450}
        width={800}
        playing={props.open}
        controls={true}
      />
    </CustomDialog>
  );
};

export default VideoDialog;
