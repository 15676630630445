import React from 'react';
import { Snackbar } from '@material-ui/core';
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  alert: {
    width: '100%',
    fontSize: '1rem',
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Microsoft JhengHei"',
    ].join(','),
  },
});

function MAlert(props) {
  const classes = useStyles();
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      className={classes.alert}
      {...props}
    />
  );
}

export default function Alert(props) {
  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        open={props.open}
        autoHideDuration={2000}
        onClose={props.close}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MAlert onClose={props.close} severity={props.type}>
          {props.message}
        </MAlert>
      </Snackbar>
    </ThemeProvider>
  );
}
