import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  IconButton,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { getSubOrders } from '../../services/billingPage';
import { Modal } from '../../actions/modal';

const Wrapper = styled.div`
  width: 1069px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px;
  width: 1069px;
  margin-left: auto;
  margin-right: auto;
`;

const OrderContentContainer = styled.div`
  margin: 0 0 8px;
  width: 940px;
  margin-left: auto;
  margin-right: auto;
`;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0;
  ${({ active }) =>
    active !== 0 &&
    `
  border-top: 1px dashed #707070;
  padding-top: 32px;
`}
`;

const OrderTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OrderTitleLabel = styled.div`
  margin-left: 32px;
  font-size: 18px;
  color: #000000;
`;

const OrderTitleValue = styled.div`
  margin-left: 16px;
  font-size: 22px;
  color: #9fa0a0;
  // line-height: 1.5;
`;

const OrderListContainer = styled.div`
  border: 1px solid #d1d1d1;
  border-radius: 20px;
  margin-top: 16px;
`;

const OrderListTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 64px;
  background: #ebebeb;
  border-top-left-radius: 19px 19px;
  border-top-right-radius: 19px 19px;
  align-items: center;
  padding: 0 32px;
`;

const OrderListTitleItem = styled.div`
  font-size: 18px;
  color: #000000;
  width: 49%;
`;

const OrderListQuantity = styled.div`
  font-size: 18px;
  color: #000000;
  width: 17%;
  text-align: center;
`;

const OrderListQA = styled.div`
  font-size: 18px;
  color: #000000;
  width: 17%;
  text-align: center;
`;

const OrderListDelivery = styled.div`
  font-size: 18px;
  color: #000000;
  width: 17%;
  text-align: center;
`;

const OrderListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  ${({ active }) =>
    active !== 0 &&
    `
    &:after {
    content: '';
    position: absolute;
    right: 32px;
    left: 32px;
    top: 0;
    height: 0.5px;
    background: #d1d1d1;
    `}
  }
`;

const OrderItemInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 49%;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
`;

const ImageLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #3e3a39;
`;

const Image = styled.img`
  width: 64px;
  height: 64px;
  object-fit: scale-down;
`;

const ImageDefaultContainer = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d1d1d1;
`;

const ImageDefault = styled.p`
  color: #3e3a39;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const NameContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const NameLink = styled(Link)`
  text-decoration: none;
  color: #3e3a39;
`;

const CategoryContainer = styled.div`
  font-size: 13px;
  margin: 4px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;
  width: 100%;
`;

const CategoryLink = styled(Link)`
  text-decoration: none;
  color: #9fa0a0;
`;

const DescrtptionContainer = styled.div`
  font-size: 13px;
  color: #9fa0a0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;
`;

const DescrtptionLink = styled(Link)`
  text-decoration: none;
  color: #9fa0a0;
`;

const OrderItemQuantityContainer = styled.div`
  width: 17%;
  text-align: center;
  color: #9fa0a0;
  font-size: 24px;
`;

const OrderItemLogisticContainer = styled.div`
  width: 17%;
  display: flex;
  justify-content: center;
`;

const OrderItemLogistic = styled.div`
  width: 120px;
  text-align: center;
  color: #9fa0a0;
  font-size: 24px;
  ${({ count }) =>
    count > 7 &&
    `
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1; //行數
    -webkit-box-orient: vertical;
    &:hover {
      text-overflow: clip;
      overflow: hidden;
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 10; //行數
      -webkit-box-orient: vertical;
  }
  `}
`;

const OrderItemDeliveryContainer = styled.div`
  width: 17%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OrderItemDelivery = styled.div`
  font-size: 16px;
  color: #9fa0a0;
  padding: 0 12px 2px;
  line-height: 26px;
  border: 1px solid transparent;
`;

const OrderItemDeliveryComplete = styled.div`
  font-size: 13px;
  color: #ffffff;
  border-radius: 20px;
  padding: 0 12px;
  line-height: 26px;
  border: 1px solid transparent;
  background: #9fa0a0;
`;

const OrderItemDeliveryFareEstimation = styled.div`
  font-size: 16px;
  color: #9fa0a0;
  padding: 0 12px 4px;
  line-height: 26px;
  border: 1px solid transparent;
`;

const OrderItemDeliveryFareConfirmation = styled.div`
  font-size: 13px;
  color: #ffffff;
  border-radius: 20px;
  padding: 0 12px;
  line-height: 26px;
  border: 1px solid transparent;
  background: #ef980c;
`;

const OrderItemDeliveryReturn = styled.div`
  font-size: 13px;
  color: #ffffff;
  border-radius: 20px;
  padding: 0 12px;
  line-height: 26px;
  border: 1px solid transparent;
  background: #0cbaef;
  text-align: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const OrderRefundDialog = (props) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState({
    suborder: [],
  });
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getSubOrders({
          params: { lng: i18n.language, id: props.orderId },
          headers: {
            Authorization: `Bearer ${user.token.accessToken}`,
          },
        });
        setData(result.data);

        setLoading(false);
      } catch (e) {
        setData({
          suborder: [],
        });
        setLoading(false);
        if (e && e.response) {
          switch (e.response.status) {
            case 401:
              dispatch(Modal('', true));
              break;
            default:
              console.log(e);
          }
        }
      }
    };
    if (props.open) {
      if (user.token === null && ticket === null) {
        dispatch(Modal('', true));
      } else {
        fetchData();
      }
    }
  }, [i18n, i18n.language, props, ticket, user, dispatch]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(1100));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="responsive-dialog-title"
      maxWidth="lg"
      onExit={() => {
        setData({
          suborder: [],
        });
        setLoading(true);
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={props.close} />
      <Wrapper>
        <ContentWrapper>
          {loading ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : (
            <OrderContentContainer>
              {data.suborder.map((item, index) => (
                <OrderContainer key={index} active={index}>
                  <OrderTitleContainer>
                    <OrderTitleLabel>
                      {t('order.label.orderNum')}
                    </OrderTitleLabel>
                    <OrderTitleValue>{item.id}</OrderTitleValue>
                    <OrderTitleLabel>
                      {t('order.label.orderDate')}
                    </OrderTitleLabel>
                    <OrderTitleValue>{item.date}</OrderTitleValue>
                  </OrderTitleContainer>
                  <OrderListContainer>
                    <OrderListTitleContainer>
                      <OrderListTitleItem>
                        {t('order.label.orderItem')}
                      </OrderListTitleItem>
                      <OrderListQuantity>
                        {t('order.label.returnQuantity')}
                      </OrderListQuantity>
                      <OrderListQA>{t('order.label.logistic')}</OrderListQA>
                      <OrderListDelivery>
                        {t('order.label.returnStatus')}
                      </OrderListDelivery>
                    </OrderListTitleContainer>
                    <OrderListItemContainer>
                      {item.items &&
                        item.items.map((k, v) => (
                          <OrderItemContainer key={v} active={v}>
                            <OrderItemInfoContainer>
                              <ImageContainer>
                                <ImageLink
                                  to={`/products/${k.product.toLowerCase()}s/${
                                    k.id
                                  }/${k.versionIndex}`}
                                >
                                  {k.image ? (
                                    <Image src={k.image} alt={k.name} />
                                  ) : (
                                    <ImageDefaultContainer>
                                      <ImageDefault>KNEO</ImageDefault>
                                    </ImageDefaultContainer>
                                  )}
                                </ImageLink>
                              </ImageContainer>
                              <InfoContainer>
                                <NameContainer>
                                  <NameLink
                                    to={`/products/${k.product.toLowerCase()}s/${
                                      k.id
                                    }/${k.versionIndex}`}
                                  >
                                    {k.name}
                                  </NameLink>
                                </NameContainer>
                                <CategoryContainer>
                                  <CategoryLink
                                    to={`/products/${k.product.toLowerCase()}s/${
                                      k.id
                                    }/${k.versionIndex}`}
                                  >
                                    {`${k.product}, Category: ${k.category}`}
                                  </CategoryLink>
                                </CategoryContainer>
                                <DescrtptionContainer>
                                  <DescrtptionLink
                                    to={`/products/${k.product.toLowerCase()}s/${
                                      k.id
                                    }/${k.versionIndex}`}
                                  >
                                    {k.description}
                                  </DescrtptionLink>
                                </DescrtptionContainer>
                              </InfoContainer>
                            </OrderItemInfoContainer>
                            <OrderItemQuantityContainer>
                              {k.quantity}
                            </OrderItemQuantityContainer>
                            <OrderItemLogisticContainer>
                              <OrderItemLogistic count={k.logistic.length}>
                                {k.logistic}
                              </OrderItemLogistic>
                            </OrderItemLogisticContainer>
                            <OrderItemDeliveryContainer>
                              {k.delivery === -4 && (
                                <OrderItemDeliveryReturn>
                                  {t('order.button.completeReturn')}
                                </OrderItemDeliveryReturn>
                              )}
                              {k.delivery === -3 && (
                                <OrderItemDeliveryReturn>
                                  {t('order.button.completeRefund')}
                                </OrderItemDeliveryReturn>
                              )}
                              {k.delivery === -2 && (
                                <OrderItemDeliveryReturn>
                                  {t('order.button.completeCancel')}
                                </OrderItemDeliveryReturn>
                              )}
                              {k.delivery === -1 && (
                                <OrderItemDeliveryReturn>
                                  {t('order.button.completeCancelReturn')}
                                </OrderItemDeliveryReturn>
                              )}
                              {k.delivery === 0 && (
                                <OrderItemDeliveryComplete>
                                  {t('order.button.finish')}
                                </OrderItemDeliveryComplete>
                              )}
                              {k.delivery === 1 && (
                                <OrderItemDeliveryReturn>
                                  {t('order.button.refund')}
                                </OrderItemDeliveryReturn>
                              )}
                              {k.delivery === 2 && (
                                <OrderItemDeliveryReturn>
                                  {t('order.button.return')}
                                </OrderItemDeliveryReturn>
                              )}
                              {k.delivery === 3 && (
                                <OrderItemDelivery>
                                  {t('order.button.arrived')}
                                </OrderItemDelivery>
                              )}
                              {k.delivery === 4 && (
                                <OrderItemDelivery>
                                  {t('order.button.delivering')}
                                </OrderItemDelivery>
                              )}
                              {k.delivery === 5 && (
                                <OrderItemDelivery>
                                  {t('order.button.preparing')}
                                </OrderItemDelivery>
                              )}
                              {k.delivery === 6 && (
                                <OrderItemDelivery>
                                  {t('order.button.cancelOrder')}
                                </OrderItemDelivery>
                              )}
                              {k.delivery === 7 && (
                                <OrderItemDelivery>
                                  {t('order.button.verifying')}
                                </OrderItemDelivery>
                              )}
                              {k.delivery === 8 && (
                                <OrderItemDeliveryFareConfirmation>
                                  {t('order.button.fareConfirmation')}
                                </OrderItemDeliveryFareConfirmation>
                              )}
                              {k.delivery === 9 && (
                                <OrderItemDeliveryFareEstimation>
                                  {t('order.button.fareEstimation')}
                                </OrderItemDeliveryFareEstimation>
                              )}
                              {k.delivery === 10 && (
                                <OrderItemDelivery>
                                  {t('order.button.licensed')}
                                </OrderItemDelivery>
                              )}
                            </OrderItemDeliveryContainer>
                          </OrderItemContainer>
                        ))}
                    </OrderListItemContainer>
                  </OrderListContainer>
                </OrderContainer>
              ))}
            </OrderContentContainer>
          )}
        </ContentWrapper>
      </Wrapper>
    </Dialog>
  );
};

export default OrderRefundDialog;
