export const ADD_TO_DOWNLOAD_QUEUE = 'ADD_TO_DOWNLOAD_QUEUE';
export const DROP_FROM_DOWNLOAD_QUEUE = 'DROP_FROM_DOWNLOAD_QUEUE';
export const UPDATE_DOWNLOAD_PROGRESS = 'UPDATE_DOWNLOAD_PROGRESS';

export const addToDownloadQueue = (Id, progress) => ({
  type: ADD_TO_DOWNLOAD_QUEUE,
  payload: {
    Id,
    progress,
  },
});

export const dropFromDownloadQueue = (Id) => ({
  type: DROP_FROM_DOWNLOAD_QUEUE,
  payload: {
    Id,
  },
});

export const updateDownloadProgress = (Id, progress) => ({
  type: UPDATE_DOWNLOAD_PROGRESS,
  payload: {
    Id,
    progress,
  },
});
