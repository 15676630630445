import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory, Link, useLocation } from 'react-router-dom';
import {
  AppBar,
  Badge,
  Toolbar,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  IconButton,
  InputBase,
  CircularProgress,
} from '@material-ui/core';
import { StylesProvider, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import headerKneronLogo from '../resources/images/kneron-logo.png';
import headerKneoLogo from '../resources/images/kneo-logo.png';
import headerMesh from '../resources/images/header-mesh.png';
import headerSearch from '../resources/images/header-search.png';
import headerCart from '../resources/images/header-cart.png';
import headerUser from '../resources/images/header-user.png';
import headerLngEN from '../resources/images/header-lng-en.png';
import headerLngCN from '../resources/images/header-lng-cn.png';
import headerLngTW from '../resources/images/header-lng-tw.png';
import Alert from './Alerts/JumpAlert';
import { logoutUser, oauthUser, logoutSuccess } from '../actions/user';
import { notify } from '../actions/notification';
import { Modal } from '../actions/modal';
import PopDialog from './Alerts/PopDialog';

const HeaderToolbar = styled(Toolbar)`
  min-height: 70px;
  background: linear-gradient(
    0deg,
    rgba(1, 24, 38, 1) 0%,
    rgba(1, 39, 62, 1) 50%,
    rgba(2, 56, 89, 1) 100%
  );
  padding-right: 0px;
  padding-left: 0px;
`;

const HeaderContentContainer = styled.div`
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const HeaderKneoLogo = styled.div`
  // flex-grow: 1;
  margin-left: 48px;
  margin-right: 1.5%;
  padding-right: 1.5%;
  position: relative;
  z-index: 10;
  &:after {
    content: '';
    position: absolute;
    bottom: 15%;
    left: 100%;
    height: 70%;
    width: 1px;
    background: #545353;
  }
`;

const HeaderKneronLogo = styled.div`
  z-index: 10;
  margin-right: 24px;
`;

const HeaderKneronImg = styled.img`
  height: 42px;
  align-items: center;
  display: flex;
`;

const HeaderKneoImg = styled.img`
  align-items: center;
  display: flex;
  background: linear-gradient(
    0deg,
    rgba(1, 24, 38, 1) 0%,
    rgba(1, 39, 62, 1) 50%,
    rgba(2, 56, 89, 1) 100%
  );
`;

const HeaderToolContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 24px;
  flex: auto;
  justify-content: flex-end;
`;

const HeaderSearchIconButton = styled(IconButton)`
  color: #ffffff;
  z-index: 10;
  padding: 4px;
  margin: 4px 0 4px 4px;
  &:focus {
    outline: none;
  }
`;

const HeaderCartIconButton = styled(IconButton)`
  color: #ffffff;
  z-index: 10;
  padding: 4px;
  margin: 4px 4px 4px 0;
  &:focus {
    outline: none;
  }
`;

const HeaderIconButton = styled(IconButton)`
  color: #ffffff;
  z-index: 10;
  padding: 4px;
  margin: 4px;
  &:focus {
    outline: none;
  }
`;

const UserInfoContainer = styled.div`
  margin: 4px;
  padding-left: 2px;
  z-index: 10;
  color: #ffffff;
  font-size: 18px;
  line-height: 18px;
  white-space: nowrap;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;
  align-items: center;
`;

const UserInfoGreeting = styled.span`
  margin-right: 6px;
`;

const SearchContainer = styled.div`
  border-radius: 20px;
  background: #304f58;
  display: flex;
  width: 0;
  transition: width 0.8s, margin-right 0.8s;
  margin-right: 8px;
  margin-left: 0;
  z-index: 10;
  align-items: center;
  ${({ grow }) =>
    grow &&
    `
    width: 75%;
    margin-right: 16px;
  `}
`;

const SearchIconButton = styled(IconButton)`
  && {
    padding: 0;
    margin-right: 8px;
  }
`;

const HeaderIconImg = styled.img`
  width: 26px;
`;

const HeaderMesh = styled.div`
  align-items: center;
  // display: flex;
  background-image: url(${headerMesh});
  height: 70px;
  width: 543px;
  margin-left: auto;
  margin-right: 0;
  position: absolute;
  left: ${({ left }) => left}px;
  z-index: 1;
`;

const HeaderLink = styled(Link)`
  width: 70px;
  text-align: center;
  color: #ffffff;
  padding: 0 10px;
  white-space: nowrap;
  border-right: 1px solid #ffffff;
  display: table;
  z-index: 10;
  text-decoration: none;
  &:hover {
    color: gray;
    cursor: pointer;
  }
`;

const HeaderLinkEnd = styled(Link)`
  width: 70px;
  text-align: center;
  color: #ffffff;
  padding: 0 10px;
  white-space: nowrap;
  display: table;
  z-index: 10;
  text-decoration: none;
  &:hover {
    color: gray;
    cursor: pointer;
  }
`;

const HeaderMenuItem = styled(MenuItem)`
  color: #4d4d4d;
`;

const HeaderPaper = styled(Paper)`
  border: 1px solid #4d4d4d;
`;

const ProgressContainer = styled.div`
  padding: 4px;
  margin: 4px;
  display: flex;
  align-items: center;
  z-index: 10;
`;

const StyledCircularProgress = styled(CircularProgress)`
  && {
    color: #ffffff;
  }
`;

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    height: 'auto',
  },
  inputInput: {
    color: '#ffffff',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: 24,
    width: '100%',
    height: 'auto',
  },
}));

const Header = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation();
  const [lngOpen, setLngOpen] = useState(false);
  const anchorLngRef = useRef(null);
  const [userOpen, setUserOpen] = useState(false);
  const anchorUserRef = useRef(null);
  const classes = useStyles();

  const handleLngToggle = () => {
    setLngOpen((prevOpen) => !prevOpen);
  };

  const handleLngClose = (event) => {
    if (anchorLngRef.current && anchorLngRef.current.contains(event.target)) {
      return;
    }

    setLngOpen(false);
  };

  function handleLngListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setLngOpen(false);
    }
  }

  // return focus to the button when we transitioned from !lngOpen -> lngOpen
  const prevLngOpen = useRef(lngOpen);
  useEffect(() => {
    if (prevLngOpen.current === true && prevLngOpen === false) {
      anchorLngRef.current.focus();
    }

    prevLngOpen.current = lngOpen;
  }, [lngOpen]);
  var lngIcon = '';
  if (i18n.language === 'en-US') {
    lngIcon = headerLngEN;
  } else if (i18n.language === 'zh-CN') {
    lngIcon = headerLngCN;
  } else if (i18n.language === 'zh-TW') {
    lngIcon = headerLngTW;
  }

  const handleUserToggle = () => {
    setUserOpen((prevUserOpen) => !prevUserOpen);
  };

  const handleUserClose = (event) => {
    if (anchorUserRef.current && anchorUserRef.current.contains(event.target)) {
      return;
    }

    setUserOpen(false);
  };

  function handleUserListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setUserOpen(false);
    }
  }

  // return focus to the button when we transitioned from !lngOpen -> lngOpen
  const prevUserOpen = useRef(userOpen);
  useEffect(() => {
    if (prevUserOpen.current === true && prevUserOpen === false) {
      anchorUserRef.current.focus();
    }

    prevUserOpen.current = userOpen;
  }, [userOpen]);

  const user = useSelector((state) => state.user);
  // console.log('current user:', user);
  const notification = useSelector((state) => state.notification);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(notify(notification.type, '', false));
  };

  const [meshLeft, setMeshLeft] = useState(
    (window.innerWidth - 1280) / 2 + 1046
  );
  useEffect(() => {
    window.addEventListener('resize', () => {
      setMeshLeft((window.innerWidth - 1280) / 2 + 1046);
    });
    return () => {
      window.removeEventListener('resize', () => {
        setMeshLeft((window.innerWidth - 1280) / 2 + 1046);
      });
    };
  }, [meshLeft]);

  // oauth user
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');
  useEffect(() => {
    if (
      !user.status &&
      ticket !== null &&
      user.token === null &&
      !user.loading
    ) {
      dispatch(oauthUser(ticket));
    }
  }, [user, dispatch, ticket]);

  const [searchOpen, setSearchOpen] = useState(false);
  const handleSearchToggle = () => {
    setSearchOpen(true);
  };

  const cart = useSelector((state) => state.cart);

  const [aboutLink, setAboutLink] = useState(true);
  const [softwareLink, setSoftwareLink] = useState(true);
  const [hardwareLink, setHardwareLink] = useState(true);
  const [searchIcon, setSearchIcon] = useState(true);
  if (searchOpen) {
    setTimeout(() => {
      setAboutLink(false);
    }, 0);
    setTimeout(() => {
      setSoftwareLink(false);
    }, 100);
    setTimeout(() => {
      setHardwareLink(false);
    }, 200);
    setTimeout(() => {
      setSearchIcon(false);
    }, 300);
  }

  const modal = useSelector((state) => state.modal);

  return (
    <StylesProvider injectFirst>
      <AppBar position="fixed" color="default">
        <Alert
          open={notification.open}
          close={handleAlertClose}
          message={notification.message}
          type={notification.type}
        />
        <PopDialog
          open={modal.open}
          close={() => {
            dispatch(Modal('', false));
          }}
        />
        <HeaderToolbar>
          <HeaderContentContainer>
            <HeaderKneoLogo>
              <Link to="/">
                <HeaderKneoImg src={headerKneoLogo} alt="" />
              </Link>
            </HeaderKneoLogo>
            <HeaderKneronLogo>
              <a href="http://www.kneron.com/">
                <HeaderKneronImg src={headerKneronLogo} alt="" />
              </a>
            </HeaderKneronLogo>
            {/* {user.info === null ?
            <HeaderLink
              underline='none'
              onClick={() => {
                setSignInOpen(true);
              }}
            >
              {t('header.login')}
            </HeaderLink>
            :
            <HeaderLink
              underline='none'
            >
              {t('header.hi')}{user.info.email.split('@')[0]}
            </HeaderLink>
          } */}
            <HeaderToolContainer>
              {aboutLink && (
                <HeaderLink to="/about">{t('header.about')}</HeaderLink>
              )}
              {softwareLink && (
                <HeaderLink to="/products/software">
                  {t('header.software')}
                </HeaderLink>
              )}
              {hardwareLink && (
                <HeaderLinkEnd to="/products/hardware">
                  {t('header.hardware')}
                </HeaderLinkEnd>
              )}
              {searchIcon ? (
                <HeaderSearchIconButton
                  aria-label="search"
                  onClick={() => {
                    handleSearchToggle();
                    document.getElementById('search-input').focus();
                  }}
                >
                  <HeaderIconImg src={headerSearch} alt="" />
                </HeaderSearchIconButton>
              ) : (
                <></>
              )}
              <SearchContainer grow={searchOpen}>
                <InputBase
                  id="search-input"
                  placeholder="Search"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      event.preventDefault();
                      const mySearch = document.getElementById('search-input').value;
                      if (mySearch === "") return;
                      history.push(`/products/search/${mySearch}`);
                    }
                  }}
                />
                {!searchIcon && (
                  <SearchIconButton
                    aria-label="search-product"
                    onClick={() => {
                      const mySearch = document.getElementById('search-input').value;
                      if (mySearch === "") return;
                      history.push(`/products/search/${mySearch}`);
                    }}
                  >
                    <HeaderIconImg src={headerSearch} alt="" />
                  </SearchIconButton>
                )}
                {!searchIcon && (
                  <SearchIconButton
                    aria-label="close"
                    onClick={() => {
                      setSearchOpen(false);
                      setTimeout(() => {
                        setSearchIcon(true);
                      }, 400);
                      setTimeout(() => {
                        setHardwareLink(true);
                      }, 500);
                      setTimeout(() => {
                        setSoftwareLink(true);
                      }, 600);
                      setTimeout(() => {
                        setAboutLink(true);
                      }, 700);
                      document.getElementById('search-input').value = "";
                    }}
                  >
                    <CloseIcon style={{ color: "#bbbbbb" }}/>
                  </SearchIconButton>
                )}
              </SearchContainer>
              {/* <HeaderIconButton
                aria-label="search"
                onClick={() => {
                  handleSearchToggle()
                }}
              >
                <HeaderIconImg src={headerSearch} alt="" />
              </HeaderIconButton> */}
              <HeaderCartIconButton
                aria-label="cart"
                onClick={() => {
                  history.push('/checkout/cart');
                }}
              >
                <Badge
                  badgeContent={cart.addedProducts.length}
                  color="secondary"
                >
                  <HeaderIconImg src={headerCart} alt="" />
                </Badge>
              </HeaderCartIconButton>
              <HeaderIconButton
                ref={anchorLngRef}
                aria-controls={lngOpen ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleLngToggle}
              >
                <HeaderIconImg src={lngIcon} alt="" />
              </HeaderIconButton>
              {user.loading ? (
                <ProgressContainer>
                  <StyledCircularProgress size={26} />
                </ProgressContainer>
              ) : (
                <HeaderIconButton
                  aria-label="user"
                  ref={anchorUserRef}
                  onClick={() => {
                    if (user.info === null || user.token === null) {
                      window.location =
                        process.env.REACT_APP_KNERON_URL +
                        '/login/?redirect=' +
                        window.location.origin +
                        location.pathname;
                    } else {
                      handleUserToggle();
                    }
                  }}
                >
                  <HeaderIconImg src={headerUser} alt="" />
                </HeaderIconButton>
              )}
              {user.info && (
                <UserInfoContainer>
                  <UserInfoGreeting>{`${t('header.hi')}`}</UserInfoGreeting>
                  {`${user.info.username}`}
                </UserInfoContainer>
              )}
            </HeaderToolContainer>
          </HeaderContentContainer>
          <Popper
            open={lngOpen}
            anchorEl={anchorLngRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 10 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <HeaderPaper>
                  <ClickAwayListener onClickAway={handleLngClose}>
                    <MenuList
                      autoFocusItem={lngOpen}
                      id="menu-list-grow"
                      onKeyDown={handleLngListKeyDown}
                    >
                      <HeaderMenuItem
                        onClick={() => {
                          i18n.changeLanguage('en-US');
                          setLngOpen(false);
                        }}
                      >
                        English
                      </HeaderMenuItem>
                      <HeaderMenuItem
                        onClick={() => {
                          i18n.changeLanguage('zh-TW');
                          setLngOpen(false);
                        }}
                      >
                        繁體中文
                      </HeaderMenuItem>
                      <HeaderMenuItem
                        onClick={() => {
                          i18n.changeLanguage('zh-CN');
                          setLngOpen(false);
                        }}
                      >
                        简体中文
                      </HeaderMenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </HeaderPaper>
              </Grow>
            )}
          </Popper>
          <Popper
            open={userOpen}
            anchorEl={anchorUserRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 10 }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <HeaderPaper>
                  <ClickAwayListener onClickAway={handleUserClose}>
                    <MenuList
                      autoFocusItem={userOpen}
                      id="menu-list-grow"
                      onKeyDown={handleUserListKeyDown}
                    >
                      <HeaderMenuItem
                        onClick={() => {
                          history.push('/users/profile');
                          setUserOpen(false);
                        }}
                      >
                        {t('header.user.profile')}
                      </HeaderMenuItem>
                      <HeaderMenuItem
                        onClick={() => {
                          history.push('/users/order');
                          setUserOpen(false);
                        }}
                      >
                        {t('header.user.order')}
                      </HeaderMenuItem>
                      <HeaderMenuItem
                        onClick={() => {
                          history.push('/users/wishList');
                          setUserOpen(false);
                        }}
                      >
                        {t('header.user.wishList')}
                      </HeaderMenuItem>
                      <HeaderMenuItem
                        onClick={() => {
                          history.push('/users/deviceList');
                          setUserOpen(false);
                        }}
                      >
                        {t('header.user.device')}
                      </HeaderMenuItem>
                      <HeaderMenuItem
                        onClick={() => {
                          history.push('/users/appList');
                          setUserOpen(false);
                        }}
                      >
                        {t('header.user.app')}
                      </HeaderMenuItem>
                      <HeaderMenuItem
                        onClick={() => {
                          if (user.token === null) {
                            dispatch(logoutSuccess());
                          } else {
                            dispatch(logoutUser(user.token.accessToken));
                          }
                          setUserOpen(false);
                          history.push('/');
                          // setTimeout(() => {
                          window.location =
                            process.env.REACT_APP_KNERON_URL +
                            '/logout/?redirect=' +
                            window.location.origin;
                          // }, 100);
                        }}
                      >
                        {t('header.user.logout')}
                      </HeaderMenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </HeaderPaper>
              </Grow>
            )}
          </Popper>
          <HeaderMesh left={meshLeft} />
        </HeaderToolbar>
      </AppBar>
    </StylesProvider>
  );
};

export default Header;
