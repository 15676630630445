import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  IconButton,
  Grid,
  InputBase,
  CircularProgress,
} from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { postUserCartRecommend } from '../services/authentication';
import { getCurrency } from '../services/billingPage';
import {
  dropFromCart,
  quantityIncrement,
  quantityDecrement,
  updateCouponItem,
} from '../actions/cart';
import { fetchUserCart, fetchViewCart } from '../actions/user';
import { notify } from '../actions/notification';
import Slider from 'react-slick';
import NextArrow from '../resources/images/next-arrow.png';
import PrevArrow from '../resources/images/prev-arrow.png';
import { addToCart } from '../actions/cart';

const Wrapper = styled.div`
  display: block;
  width: 100%;

  margin: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 70px;
  ${({ data }) =>
    data.length === 0 &&
    `
      margin-top: 0;
    `}
  padding: 72px 0 0 0;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const ProviderItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 80px;
`;

const ProviderItemTitleContainer = styled.div`
  margin-left: 194px;
  margin-right: 92px;
  height: 32px;
  border-bottom: 1px solid #d1d1d1;
  display: flex;
`;

const ProviderItemTitle = styled.div`
  padding: 0 30px;
  font-size: 19px;
  font-weight: bold;
  line-height: 32px;
  color: #ffffff;
  background: #d1d1d1;
  border-top-left-radius: 10px 10px;
  border-top-right-radius: 10px 10px;
`;

const CartItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0 16px;
  margin-right: 92px;
`;

const ImageLink = styled(Link)`
  text-decoration: none;
  color: #3e3a39;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 194px;
  flex-direction: column;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: scale-down;
`;

const ImageDefaultContainer = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d1d1d1;
`;

const ImageDefault = styled.p`
  color: #3e3a39;
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d1d1d1;
  padding: 8px 0 24px;
  width: 994px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 33%;
  margin-right: 32px;
`;

const NameContainer = styled.div`
  font-size: 21px;
  font-weight: bold;
  margin-right: 8px;
`;

const NameLink = styled(Link)`
  text-decoration: none;
  color: #3e3a39;
`;

const NameTypeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TypeContainer = styled.div`
  text-transform: capitalize;
  font-size: 10px;
  font-weight: bold;
  border-radius: 15px;
  padding: 0 8px;
  ${({ type }) =>
    type === 'software' &&
    `
    color: #0cbaef;
    border: 1px solid #0cbaef;
  `}
  ${({ type }) =>
    type === 'hardware' &&
    `
    color: #ef980c;
    border: 1px solid #ef980c;
  `}
`;

const CategoryContainer = styled.div`
  font-size: 15px;
  margin: 4px 0;
`;

const CategoryLink = styled(Link)`
  text-decoration: none;
  color: #9fa0a0;
`;

const DescriptionContainer = styled.div`
  font-size: 15px;
  color: #9fa0a0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行數
  -webkit-box-orient: vertical;
`;

const DescriptionLink = styled(Link)`
  text-decoration: none;
  color: #9fa0a0;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 70%;
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-left: 32px;
  margin-right: 57px;
`;

const CustomButtonGroup = styled.div`
  display: inline-flex;
  height: 32px;
`;

const CustomInputBase = styled(InputBase)`
  && {
    color: #666666;
    border: 1px solid #cacaca;
    min-width: 28px;
    width: 38px;
    border-left: none;
    border-right: none;
    padding: 0 4px;
    text-align: center;
  }
`;

const CustomButton = styled(Button)`
  && {
    min-width: 27px;
    border-radius: 0px;
    border: 1px solid #cacaca;
    color: #cacaca;
    z-index: 10;
    // ${({ disabled }) =>
    disabled &&
    `
    //   border: 1px solid red;
    // `}
  }
`;

const UnitLeftContainer = styled.div`
  display: flex;
  align-items: center;
  ${({ language }) =>
    language === 'zh-TW' &&
    `
    margin-left: 214px;
  `}
`;

const UnitRightContainer = styled.div`
  width: 290px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ language }) =>
    language === 'zh-TW' &&
    `
    justify-content: flex-end;
    width: 48px;
  `}
`;

const PriceContainer = styled.div`
  font-size: 20px;
  line-height: 20px;
  margin-left: 44px;
  width: 100px;
  color: #3e3a39;
  white-space: nowrap;
`;

const PriceLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 44px;
  width: 100px;
`;

const RefPriceContainer = styled.div`
  width: 192px;
  font-size: 14px;
  font-weight: bold;
  color: #707070;
`;

const RefPriceLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
`;

const DropItemIcon = styled(CloseIcon)`
  && {
    color: #cacaca;
  }
`;

const CheckoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 120px;
  margin-left: 702px;
  ${({ language }) =>
    language === 'zh-TW' &&
    `
    margin-left: 916px;
  `}
`;

const CheckoutUnitRightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 319px;
  ${({ language }) =>
    language === 'zh-TW' &&
    `
    justify-content: flex-end;
    width: 106px;
  `}
`;

const CheckoutRefPriceContainer = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #707070;
`;

const CheckoutPriceContainer = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #3e3a39;
  white-space: nowrap;
`;

const CheckoutPriceLoadingContainer = styled.div`
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
`;

const CheckoutRefPriceLoadingContainer = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
`;

const CheckoutButton = styled(Button)`
  && {
    font-size: 14px;
    padding: 4px 16px;
    background: #0cbaef;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #0cbaef;
    width: 106px;
    &:hover {
      color: #0cbaef;
    }
  }
`;

const AddContainer = styled.div`
  margin-left: 194px;
  margin-right: 92px;
  display: flex;
  padding-top: 8px;
  padding-bottom: 24px;
  border-bottom: 1px solid #d1d1d1;
  margin-bottom: 16px;
`;

const AddTitleContainer = styled.div`
  display: flex;
  width: 40px;
  height: 138px;
`;

const AddTitle = styled.div`
  letter-spacing: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 40px;
  color: #ffffff;
  background: #0cbaef;
  height: 100%;
  width: 100%;
  text-align: center;
  writing-mode: vertical-rl;
  text-orientation: upright;
`;

const AddItemContainer = styled.div`
  // height: 261px;
  padding: 0 24px;
  width: 100%;
`;

const AddItems = styled.div`
  // height: 187px;
  width: 167px;
`;

const AddItem = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: space-between;
  ${({ index }) =>
    index % 2 === 1 &&
    `
    background: #fafafa;
  `}
  ${({ index }) =>
    index % 2 === 0 &&
    `
    background: #f5f5f5;
  `}
`;

const AddItemInfoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12px;
  height: 61px;
`;

const AddItemInfoLeftContainer = styled.div`
  width: 118px;
  height: 100%;
  display: flex;
  justify-content: center;
  // align-items: center;
`;

const AddItemleftImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
`;

const AddItemleftImage = styled.img`
  width: 46px;
  height: 46px;
  object-fit: scale-down;
`;

const AddItemImageDefaultContainer = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d1d1d1;
`;

const AddItemImageDefault = styled.p`
  color: #3e3a39;
`;

const AddItemInfoRightContainer = styled.div`
  width: 120px;
  // height: 78px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
`;

const AddItemInfoNameContainer = styled.div`
  font-size: 13px;
  line-heigth: 13px;
  font-weight: bold;
  color: #3e3a39;
  margin-right: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行數
  -webkit-box-orient: vertical;
`;

const AddItemInfoNameLink = styled(Link)`
  text-decoration: none;
  color: #3e3a39;
`;

const AddItemInfoCategoryContainer = styled.div`
  font-size: 6px;
  margin: 6px 8px 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;
`;

const AddItemInfoCategoryLink = styled(Link)`
  text-decoration: none;
  color: #9fa0a0;
`;

// const AddItemInfoDescriptionContainer = styled.div`
//   font-size: 6px;
//   margin-right: 8px;
//   color: #9fa0a0;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: normal;
//   display: -webkit-box;
//   -webkit-line-clamp: 1; //行數
//   -webkit-box-orient: vertical;
// `;

// const AddItemInfoDescriptionLink = styled(Link)`
//   text-decoration: none;
//   color: #9fa0a0;
// `;

const AddItemPriceContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 15px;
`;

const AddItemPriceLeftContainer = styled.div`
  width: 80px;
  display: flex;
  justify-content: center;
`;

const AddItemPriceLeft = styled.div`
  font-size: 10px;
  line-height: 10px;
  color: #939393;
`;

const AddItemPriceRightContainer = styled.div`
  width: 81px;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  color: #ff0000;
`;

const AddItemButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const AddItemButtonsLeftContainer = styled.div`
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AddItemCustomButtonGroup = styled.div`
  display: inline-flex;
  height: 18px;
`;

const AddItemCustomButton = styled(Button)`
  && {
    min-width: 21px;
    border-radius: 0px;
    border: 1px solid #cacaca;
    color: #cacaca;
    z-index: 10;
    font-size: 8px;
    padding: 6px;
    // ${({ disabled }) =>
    disabled &&
    `
    //   border: 1px solid red;
    // `}
  }
`;

const AddItemCustomInputBase = styled(InputBase)`
  && {
    color: #666666;
    border: 1px solid #cacaca;
    min-width: 22px;
    width: 22px;
    border-left: none;
    border-right: none;
    padding: 0 4px;
    text-align: center;
    font-size: 12px;
  }
`;

const AddItemButtonsRightContainer = styled.div`
  width: 81px;
  display: flex;
  align-items: flex-start;
`;

const AddItemButtonsAddButton = styled(Button)`
  && {
    font-size: 7px;
    line-height: 16px;
    padding: 0 7px;
    background: #0cbaef;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #0cbaef;
    width: 62px;
    &:hover {
      color: #0cbaef;
    }
    ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
    `}
    ${({ price }) =>
    price === 0 &&
    `
      background: #ef980c;
      border: 1px solid #ef980c;
      &:hover {
        color: #ef980c;
      }
    `}
  }
`;

const Arrow = styled.img`
  color: #898989;
  && {
    width: 16px;
    height: 32px;
  }
  &:hover {
    color: #999999;
    background: #f8f9fa;
    border-radius: 100px;
  }
`;

const SlickContainer = styled.div`
  margin: 8px 0 32px;
`;

const RecommendWrapper = styled.div`
  display: block;
  flex-direction: column;

  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: #ebebeb;
  bottom: 0;
`;

const Recommend = styled.div`
  display: flex;
  flex-direction: column;

  width: 1140px;
  margin-left: auto;
  margin-right: auto;
`;

const RecommendTitleContainer = styled.div`
  margin-top: 32px;
`;

const RecommendTitle = styled.h2`
  color: #3e3a39;
  font-size: 23px;
`;

const EmptyCartContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 360px;
  background: #f9f9f9;
  flex-direction: column;
  @media (max-width: 1440px) {
    margin-top: 75px;
  }
`;

const EmptyCartTitleContainer = styled.div`
  text-align: center;
  font-size: 39px;
  color: #707070;
  font-weight: bold;
`;

const EmptyCartDescriptionContainer = styled.div`
  text-align: center;
  font-size: 29px;
  color: #707070;
`;

const EmptyCartButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SoftwareButton = styled(Button)`
  && {
    font-size: 14px;
    padding: 4px 20px;
    background: #0cbaef;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #0cbaef;
    width: 106px;
    margin-right: 16px;
    &:hover {
      color: #0cbaef;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const HardwareButton = styled(Button)`
  && {
    font-size: 14px;
    padding: 4px 20px;
    background: #ef980c;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #ef980c;
    width: 106px;
    margin-left: 16px;
    &:hover {
      color: #ef980c;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const ItemGrid = styled(Grid)`
  && {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ItemImgGrid = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ItemImg = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-radius: 15px;
  // height: 120px;
`;

const ItemLink = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
`;

const TextLink = styled(Link)`
  text-decoration: none;
`;

const DividerGrid = styled(Grid)`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ItemSubTitle = styled.div`
  font-size: 17px;
  color: #3e3a39;
  margin-bottom: 4px;
`;

const ItemSubDescription = styled.div`
  font-size: 14px;
  color: #9fa0a0;
  margin-top: 4px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;
`;

const ItemBuyButton = styled(Button)`
  && {
    padding: 0;
    min-width: 48px;
    border-radius: 100px;
    background: #0cbaef;
    color: #ffffff;
    margin-bottom: 2px;
    font-size: 11px;
    line-height: 16px;
    &:hover {
      color: #3e3a39;
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  margin: calc(50vh + 10px) auto 0;
`;

const SlickButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Cart = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);

  const [data, setData] = useState({
    recommend: [],
  });
  const [loading, setLoading] = useState(true);
  const [currency, setCurrency] = useState({});

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');

  useEffect(() => {
    const apiFetch = async () => {
      let products = [];

      cart.addedProducts.forEach((e) => {
        products.push(e.id);
      });

      try {
        const result = await postUserCartRecommend({
          data: {
            platforms: [],
            products: products,
            lng: i18n.language,
          },
        });
        if (result.status === 'ok') {
          setData(result.data);
        }
      } catch (e) {
        if (e && e.response) {
          switch (e.response.status) {
          }
        }
      }
    };

    var exchange;
    if (i18n.language === 'en-US') {
      exchange = 'USD';
    } else if (i18n.language === 'zh-CN') {
      exchange = 'RMB';
    }
    var fetchData = async () => {
      try {
        const result = await getCurrency({
          currency: 'TWD',
          exchangeTo: exchange,
        });
        setCurrency(result.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    if (exchange !== '' && i18n.language !== 'zh-TW') {
      fetchData();
    }

    if (user.token !== null && ticket === null) {
      dispatch(fetchUserCart(i18n.language));
    } else {
      if (!ticket) {
        dispatch(fetchViewCart(i18n.language));
      }
    }

    if (cart.addedProducts.length > 0) {
      apiFetch();
    }
  }, [
    i18n,
    user,
    dispatch,
    i18n.language,
    ticket,
    loading,
  ]);

  var dollar;
  if (i18n.language === 'zh-TW') {
    dollar = 'NT';
  } else if (i18n.language === 'zh-CN') {
    dollar = 'RMB';
  } else {
    dollar = 'US';
  }

  var providerCategory = [];
  cart.addedProducts.forEach((item) => {
    if (
      !providerCategory.some(
        (element) => element.category === item.providerId + '-' + item.product
      )
    ) {
      if (item.product === 'software') {
        providerCategory.push({
          id: item.providerId,
          name: item.provider,
          category: item.providerId + '-' + item.product,
          product: item.product,
          sort: item.providerId + '-a',
        });
      } else if (item.product === 'hardware') {
        providerCategory.push({
          id: item.providerId,
          name: item.provider,
          category: item.providerId + '-' + item.product,
          product: item.product,
          sort: item.providerId + '-b',
        });
      }
    }
  });
  providerCategory.sort((a, b) => (a.sort > b.sort ? 1 : -1));

  var providerCheckout = {};
  providerCategory.forEach((e) => {
    var f = false;
    if (cart.coupon) {
      Object.keys(cart.coupon).forEach((i) => {
        if (
          cart.coupon[i].providerId + '-' + cart.coupon[i].type ===
          e.category
        ) {
          if (
            cart.addedProducts[
            cart.addedProducts.findIndex(function (item, index, array) {
              return item.id === i;
            })
            ] &&
            cart.addedProducts[
              cart.addedProducts.findIndex(function (item, index, array) {
                return item.id === i;
              })
            ].quantity *
            cart.coupon[i].count !==
            cart.discount[i].total
          ) {
            f = true;
          }
        }
      });
    }
    providerCheckout[e.category] = f;
  });

  const [total, setTotal] = useState({});
  useEffect(() => {
    var t = {};
    cart.addedProducts.forEach((e) => {
      if (t[e.providerId + '-' + e.product] === undefined) {
        t[e.providerId + '-' + e.product] = e.refPrice * e.quantity;
      } else {
        t[e.providerId + '-' + e.product] += e.refPrice * e.quantity;
      }
    });

    setTotal(t);
  }, [cart.addedProducts, cart.coupon]);

  const slickSettings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <Arrow src={NextArrow} alt="" />,
    prevArrow: <Arrow src={PrevArrow} alt="" />,
  };

  const slickSettingsRecommend = {
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <Arrow src={NextArrow} alt="" />,
    prevArrow: <Arrow src={PrevArrow} alt="" />,
  };

  return (
    <Fragment>
      <StylesProvider injectFirst>
        <Wrapper>
          <ContentWrapper data={cart.addedProducts}>
            {cart.syncLoading ? (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            ) : cart.addedProducts.length !== 0 ? (
              providerCategory.map((provider, index) => (
                <ProviderItemContainer key={index}>
                  <ProviderItemTitleContainer>
                    <ProviderItemTitle>{provider.name}</ProviderItemTitle>
                  </ProviderItemTitleContainer>
                  {cart.addedProducts.map(
                    (item, i) =>
                      item.providerId === provider.id &&
                      item.product === provider.product && (
                        <CartItemContainer key={i}>
                          <ItemContainer>
                            <ImageContainer>
                              <ImageLink
                                to={`/products/${item.product.toLowerCase()}s/${item.id
                                  }/${item.versionIndex}`}
                              >
                                {item.image ? (
                                  <Image src={item.image} alt={item.name} />
                                ) : (
                                  <ImageDefaultContainer>
                                    <ImageDefault>KNEO</ImageDefault>
                                  </ImageDefaultContainer>
                                )}
                              </ImageLink>
                              {item.review && (
                                <div>{`${item.versionIndex} (${item.version})`}</div>
                              )}
                            </ImageContainer>
                            <ProductContainer>
                              <InfoContainer>
                                <NameTypeContainer>
                                  <NameContainer>
                                    <NameLink
                                      to={`/products/${item.product.toLowerCase()}s/${item.id
                                        }/${item.versionIndex}`}
                                    >
                                      {item.name}
                                    </NameLink>
                                  </NameContainer>
                                  <TypeContainer type={item.product}>
                                    {item.product}
                                  </TypeContainer>
                                </NameTypeContainer>
                                <CategoryContainer>
                                  <CategoryLink
                                    to={`/products/${item.product.toLowerCase()}s/${item.id
                                      }/${item.versionIndex}`}
                                  >
                                    {item.category}
                                  </CategoryLink>
                                </CategoryContainer>
                                <DescriptionContainer>
                                  <DescriptionLink
                                    to={`/products/${item.product.toLowerCase()}s/${item.id
                                      }/${item.versionIndex}`}
                                  >
                                    {item.description}
                                  </DescriptionLink>
                                </DescriptionContainer>
                              </InfoContainer>
                              <ButtonContainer>
                                <QuantityContainer>
                                  <UnitLeftContainer language={i18n.language}>
                                    <CustomButtonGroup
                                      size="small"
                                      color="primary"
                                      aria-label="outlined primary button group"
                                    >
                                      <CustomButton
                                        disabled={
                                          cart.disabledStateList[i] ===
                                            undefined
                                            ? false
                                            : cart.disabledStateList[i] ||
                                            item.quantity <= 1
                                        }
                                        onClick={() => {
                                          dispatch(quantityDecrement(item.id));
                                        }}
                                      >
                                        -
                                      </CustomButton>
                                      <CustomInputBase
                                        inputProps={{
                                          style: {
                                            textAlign: 'center',
                                            padding: '6px 0',
                                          },
                                        }}
                                        value={item.quantity}
                                      />
                                      <CustomButton
                                        disabled={
                                          cart.disabledStateList[i] ===
                                            undefined
                                            ? false
                                            : cart.disabledStateList[i]
                                        }
                                        onClick={() => {
                                          dispatch(quantityIncrement(item.id));
                                        }}
                                      >
                                        +
                                      </CustomButton>
                                    </CustomButtonGroup>
                                    {!cart.loading ? (
                                      <PriceContainer>
                                        {`NT $ ${Math.round(
                                          (item.refPrice * item.quantity +
                                            Number.EPSILON) *
                                          100
                                        ) / 100
                                          }`}
                                      </PriceContainer>
                                    ) : (
                                      <PriceLoadingContainer>
                                        <CircularProgress size={20} />
                                      </PriceLoadingContainer>
                                    )}
                                  </UnitLeftContainer>
                                  <UnitRightContainer language={i18n.language}>
                                    {i18n.language !== 'zh-TW' &&
                                      (!cart.loading && !loading ? (
                                        <RefPriceContainer>
                                          {`${t(
                                            'cart.title.approx'
                                          )} ${dollar} $ ${Math.round(
                                            ((item.quantity * item.refPrice) /
                                              currency.cash_selling +
                                              Number.EPSILON) *
                                            100
                                          ) / 100
                                            }`}
                                        </RefPriceContainer>
                                      ) : (
                                        <RefPriceLoadingContainer>
                                          <CircularProgress size={14} />
                                        </RefPriceLoadingContainer>
                                      ))}
                                    <IconButton
                                      aria-label="close"
                                      onClick={() => {
                                        dispatch(dropFromCart(item.id));
                                      }}
                                    >
                                      <DropItemIcon />
                                    </IconButton>
                                  </UnitRightContainer>
                                </QuantityContainer>
                              </ButtonContainer>
                            </ProductContainer>
                          </ItemContainer>
                          {cart.coupon &&
                            cart.coupon[item.id] &&
                            cart.coupon[item.id].providerId === provider.id &&
                            cart.coupon[item.id].type === provider.product && (
                              <AddContainer>
                                <AddTitleContainer>
                                  <AddTitle>
                                    {t('cart.title.addTitle')}
                                  </AddTitle>
                                </AddTitleContainer>
                                <AddItemContainer>
                                  <Slider {...slickSettings}>
                                    {cart.coupon &&
                                      cart.coupon[item.id] &&
                                      cart.coupon[item.id].items.map((s, c) =>
                                        s.name !== '' ? (
                                          <AddItems key={c}>
                                            <AddItem index={c}>
                                              <AddItemInfoContainer>
                                                <AddItemInfoLeftContainer>
                                                  <AddItemleftImageContainer>
                                                    <ImageLink
                                                      to={`/products/${s.type.toLowerCase()}s/${s.id
                                                        }/${s.versionIndex}`}
                                                    >
                                                      {s.image ? (
                                                        <AddItemleftImage
                                                          src={s.image}
                                                          alt={s.name}
                                                        />
                                                      ) : (
                                                        <AddItemImageDefaultContainer>
                                                          <AddItemImageDefault>
                                                            KNEO
                                                          </AddItemImageDefault>
                                                        </AddItemImageDefaultContainer>
                                                      )}
                                                    </ImageLink>
                                                  </AddItemleftImageContainer>
                                                </AddItemInfoLeftContainer>
                                                <AddItemInfoRightContainer>
                                                  <AddItemInfoNameContainer>
                                                    <AddItemInfoNameLink
                                                      to={`/products/${s.type.toLowerCase()}s/${s.id
                                                        }/${s.versionIndex}`}
                                                    >
                                                      {s.name}
                                                    </AddItemInfoNameLink>
                                                  </AddItemInfoNameContainer>
                                                  <AddItemInfoCategoryContainer>
                                                    <AddItemInfoCategoryLink
                                                      to={`/products/${s.type.toLowerCase()}s/${s.id
                                                        }/${s.versionIndex}`}
                                                    >
                                                      {s.category}
                                                    </AddItemInfoCategoryLink>
                                                  </AddItemInfoCategoryContainer>
                                                  {/* <AddItemInfoDescriptionContainer>
                                                    <AddItemInfoDescriptionLink
                                                      to={`/products/${s.type.toLowerCase()}s/${s.id
                                                        }/${s.versionIndex}`}
                                                    >
                                                      {s.description}
                                                    </AddItemInfoDescriptionLink>
                                                  </AddItemInfoDescriptionContainer> */}
                                                </AddItemInfoRightContainer>
                                              </AddItemInfoContainer>
                                              <AddItemPriceContainer>
                                                <AddItemPriceLeftContainer>
                                                  <AddItemPriceLeft>{`NT $ ${s.ref_price}`}</AddItemPriceLeft>
                                                </AddItemPriceLeftContainer>
                                                <AddItemPriceRightContainer>
                                                  {`NT $ ${cart.coupon[item.id].price
                                                    }`}
                                                </AddItemPriceRightContainer>
                                              </AddItemPriceContainer>
                                              <AddItemButtonsContainer>
                                                <AddItemButtonsLeftContainer>
                                                  <AddItemCustomButtonGroup
                                                    size="small"
                                                    color="primary"
                                                    aria-label="outlined primary button group"
                                                  >
                                                    <AddItemCustomButton
                                                      disabled={
                                                        cart.discount[item.id][
                                                          s.id
                                                        ] > 0
                                                          ? false
                                                          : true
                                                      }
                                                      onClick={() => {
                                                        dispatch(
                                                          updateCouponItem(
                                                            item.id,
                                                            s.id,
                                                            -1
                                                          )
                                                        );
                                                      }}
                                                    >
                                                      -
                                                    </AddItemCustomButton>
                                                    <AddItemCustomInputBase
                                                      inputProps={{
                                                        style: {
                                                          textAlign: 'center',
                                                          padding: '6px 0',
                                                        },
                                                      }}
                                                      value={
                                                        cart.discount[item.id][
                                                        s.id
                                                        ]
                                                      }
                                                    />
                                                    <AddItemCustomButton
                                                      disabled={
                                                        cart.discount[item.id][
                                                          s.id
                                                        ] >=
                                                          cart.addedProducts[
                                                            cart.addedProducts.findIndex(
                                                              function (
                                                                f,
                                                                index,
                                                                array
                                                              ) {
                                                                return (
                                                                  f.id === item.id
                                                                );
                                                              }
                                                            )
                                                          ].quantity
                                                          ? true
                                                          : false
                                                      }
                                                      onClick={() => {
                                                        dispatch(
                                                          updateCouponItem(
                                                            item.id,
                                                            s.id,
                                                            1
                                                          )
                                                        );
                                                      }}
                                                    >
                                                      +
                                                    </AddItemCustomButton>
                                                  </AddItemCustomButtonGroup>
                                                </AddItemButtonsLeftContainer>
                                                <AddItemButtonsRightContainer>
                                                  <AddItemButtonsAddButton
                                                    price={s.sale_price}
                                                    disabled={
                                                      cart.discount[item.id][
                                                        s.id
                                                      ] >=
                                                        cart.addedProducts[
                                                          cart.addedProducts.findIndex(
                                                            function (
                                                              f,
                                                              index,
                                                              array
                                                            ) {
                                                              return (
                                                                f.id === item.id
                                                              );
                                                            }
                                                          )
                                                        ].quantity
                                                        ? true
                                                        : false
                                                    }
                                                    onClick={() => {
                                                      dispatch(
                                                        updateCouponItem(
                                                          item.id,
                                                          s.id,
                                                          1
                                                        )
                                                      );
                                                    }}
                                                  >
                                                    {s.sale_price === 0
                                                      ? t('cart.button.free')
                                                      : t('cart.button.add')}
                                                  </AddItemButtonsAddButton>
                                                </AddItemButtonsRightContainer>
                                              </AddItemButtonsContainer>
                                            </AddItem>
                                          </AddItems>
                                        ) : (
                                          <div key={c}></div>
                                        )
                                      )}
                                  </Slider>
                                </AddItemContainer>
                              </AddContainer>
                            )}
                        </CartItemContainer>
                      )
                  )}
                  <CheckoutContainer language={i18n.language}>
                    {!cart.loading ? (
                      <CheckoutPriceContainer>
                        {`NT $ ${Math.round(
                          (total[provider.category] + Number.EPSILON) * 100
                        ) / 100
                          }`}
                      </CheckoutPriceContainer>
                    ) : (
                      <CheckoutPriceLoadingContainer>
                        <CircularProgress size={20} />
                      </CheckoutPriceLoadingContainer>
                    )}
                    <CheckoutUnitRightContainer language={i18n.language}>
                      {i18n.language !== 'zh-TW' &&
                        (!cart.loading && !loading ? (
                          <CheckoutRefPriceContainer>
                            {`${t('cart.title.approx')} ${dollar} $ ${Math.round(
                              (total[provider.category] /
                                currency.cash_selling +
                                Number.EPSILON) *
                              100
                            ) / 100
                              }`}
                          </CheckoutRefPriceContainer>
                        ) : (
                          <CheckoutRefPriceLoadingContainer>
                            <CircularProgress size={14} />
                          </CheckoutRefPriceLoadingContainer>
                        ))}
                      <CheckoutButton
                        // disabled={
                        //   providerCheckout[provider.category]
                        // }
                        onClick={() => {
                          if (user.token === null) {
                            window.location =
                              process.env.REACT_APP_KNERON_URL +
                              '/login/?redirect=' +
                              window.location.origin +
                              location.pathname;
                          } else {
                            if (providerCheckout[provider.category]) {
                              dispatch(
                                notify('info', t('cart.message.discount'), true)
                              );
                            } else {
                              history.push(
                                `/checkout/bill/${provider.id}/${provider.product}`
                              );
                            }
                          }
                        }}
                      >
                        {t('cart.button.payment')}
                      </CheckoutButton>
                    </CheckoutUnitRightContainer>
                  </CheckoutContainer>
                  {/* {cart.addedProducts.map(
                    (coupon, d) =>
                      
                  )} */}
                </ProviderItemContainer>
              ))
            ) : (
              <EmptyCartContainer>
                <EmptyCartTitleContainer>
                  {t('cart.title.emptyTitle')}
                </EmptyCartTitleContainer>
                <EmptyCartDescriptionContainer>
                  {t('cart.title.emptyDescription')}
                </EmptyCartDescriptionContainer>
                <EmptyCartButtonContainer>
                  <SoftwareButton
                    onClick={() => {
                      history.push('/products/software');
                    }}
                  >
                    {t('cart.button.software')}
                  </SoftwareButton>
                  <HardwareButton
                    onClick={() => {
                      history.push('/products/hardware');
                    }}
                  >
                    {t('cart.button.hardware')}
                  </HardwareButton>
                </EmptyCartButtonContainer>
              </EmptyCartContainer>
            )}
          </ContentWrapper>
        </Wrapper>
        <RecommendWrapper>
          <Recommend>
            <RecommendTitleContainer>
              <RecommendTitle>{t('cart.title.recommend')}</RecommendTitle>
            </RecommendTitleContainer>
            <SlickContainer>
              <Slider {...slickSettingsRecommend} >
                {
                  data.recommend.map((item, index) => (
                    <div key={index}>
                      <ItemGrid key={index} container spacing={2}>
                        {item.category === 'HW' && (
                          <ItemImgGrid item xs={5}>
                            <ItemLink
                              to={`/products/hardwares/${item.id}/${item.versionIndex}`}
                            >
                              <ItemImg src={item.image} alt="" />
                            </ItemLink>
                          </ItemImgGrid>
                        )}
                        {item.category !== 'HW' && (
                          <ItemImgGrid item xs={5}>
                            <ItemLink
                              to={`/products/softwares/${item.id}/${item.versionIndex}`}
                            >
                              <ItemImg src={item.image} alt="" />
                            </ItemLink>
                          </ItemImgGrid>
                        )}
                        <DividerGrid item xs={7}>
                          <Grid>
                            {item.category === 'HW' && (
                              <TextLink
                                to={`/products/hardwares/${item.id}/${item.versionIndex}`}
                              >
                                <ItemSubTitle>{item.name}</ItemSubTitle>
                              </TextLink>
                            )}
                            {item.category !== 'HW' && (
                              <TextLink
                                to={`/products/softwares/${item.id}/${item.versionIndex}`}
                              >
                                <ItemSubTitle>{item.name}</ItemSubTitle>
                              </TextLink>
                            )}
                            {item.category === 'HW' && (
                              <TextLink
                                to={`/products/hardwares/${item.id}/${item.versionIndex}`}
                              >
                                <ItemSubDescription>
                                  {item.description}
                                </ItemSubDescription>
                              </TextLink>
                            )}
                            {item.category !== 'HW' && (
                              <TextLink
                                to={`/products/softwares/${item.id}/${item.versionIndex}`}
                              >
                                <ItemSubDescription>
                                  {item.description}
                                </ItemSubDescription>
                              </TextLink>
                            )}
                            <SlickButtonContainer>
                              <ItemBuyButton
                                variant="contained"
                                disableElevation
                                onClick={() => {
                                  dispatch(
                                    addToCart(
                                      item.id,
                                      item.image,
                                      item.name,
                                      item.description,
                                      item.product,
                                      item.category,
                                      item.provider,
                                      item.providerId,
                                      item.version,
                                      item.versionIndex,
                                      item.price,
                                      1
                                    )
                                  );
                                  history.push('/checkout/cart');
                                }}
                              >
                                {t('product.softwareCategory.button.buy')}
                              </ItemBuyButton>
                            </SlickButtonContainer>
                          </Grid>
                        </DividerGrid>
                      </ItemGrid>
                    </div>
                  ))
                }
              </Slider>
            </SlickContainer>
          </Recommend>
        </RecommendWrapper>
      </StylesProvider>
    </Fragment >
  );
};

export default Cart;
