import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { getProductHardware } from '../../services/productPage';
import { Modal } from '../../actions/modal';
import { notify } from '../../actions/notification';
import Information from './Information';
import Divider from '../Divider';
import Preview from './Preview';
import Specification from './Specification';
import Rating from './Rating';
import HardwareInfo from './HardwareInfo';
import Coupon from './Coupon';
import Recommend from './Recommend';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  margin: calc(50vh + 10px) auto 0;
`;

const Hardwares = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id, versionIndex } = useParams();
  const user = useSelector((state) => state.user);
  const [data, setData] = useState({
    info: {},
    preview: [],
    spec: {},
    rating: { comment: [] },
    hardwareInfo: {},
    recommend: [],
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    var fetchData = async () => {
      try {
        var token = '';
        if (user.token) {
          token = user.token.accessToken;
        }
        const result = await getProductHardware(id, versionIndex, {
          params: { lng: i18n.language },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(result.data);
        setLoading(false);
      } catch (e) {
        setData({
          info: {},
          preview: [],
          spec: {},
          rating: { comment: [] },
          hardwareInfo: {},
          recommend: [],
        });
        setLoading(false);
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };
    fetchData();
  }, [id, versionIndex, i18n, i18n.language, user.token, dispatch]);
  return (
    <Fragment>
      {loading ? (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      ) : (
        <>
          <Wrapper>
            <Information data={data.info} coupon={data.coupon} />
            {/* <Divider /> */}
            <Coupon data={data.coupon} />
            <Divider />
            <Preview data={data.preview} />
            <Divider />
            <Specification data={data.spec} />
            <Divider />
            <Rating data={data.rating} />
            <Divider />
            <HardwareInfo data={data.hardwareInfo} />
          </Wrapper>
          <Recommend data={data.recommend} />
        </>
      )}
    </Fragment>
  );
};

export default Hardwares;
