import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Dialog, IconButton, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme, withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { getPrivacyPage } from '../services/landingPage';

const Wrapper = styled.div`
  width: 1280px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px;
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
`;

const TitleContainer = styled.h2`
  font-size: 28px;
  color: #023859;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 28px;
`;

const ForewordContainer = styled.div`
  font-size: 20px;
  color: #707070;
  white-space: pre-line;
  line-height: 35px;
  margin: 16px 0;
`;

const ContentContainer = styled.div`
  width: 895px;
  background: #f8f7f7;
  color: #707070;
  white-space: pre-line;
  margin-top: 32px;
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 35px;
  padding: 54px 84px 88px;
  margin-left: auto;
  margin-right: auto;
`;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const PrivacyDialog = (props) => {
  const { i18n } = useTranslation();
  const [data, setData] = useState({
    title: '',
    foreword: '',
    content: '',
  });
  useEffect(() => {
    if (props.open) {
      var fetchData = async () => {
        try {
          const result = await getPrivacyPage({
            params: { lng: i18n.language },
          });
          setData(result.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, [i18n, i18n.language, props]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(1440));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xl"
    >
      <DialogTitle id="customized-dialog-title" onClose={props.close} />
      <Wrapper>
        <ContentWrapper>
          <TitleContainer>{data.title}</TitleContainer>
          <ForewordContainer>{data.foreword}</ForewordContainer>
          <ContentContainer>{data.content}</ContentContainer>
        </ContentWrapper>
      </Wrapper>
    </Dialog>
  );
};

export default PrivacyDialog;
