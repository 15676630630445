import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import HardwareItems from './HardwareItems';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 4px 0px 32px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const SeriesHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SeriesHeader = styled.h2`
  color: #595757;
  font-size: 26px;
  white-space: nowrap;
  padding-left: 8px;
  margin: 0px;
  padding-right: 96px;
`;

const HeaderDivider = styled.hr`
  border: 1px solid #ffffff;
  background: #898989;
  border-style: inset;
`;

const DividerContainer = styled.div`
  width: 100%;
  margin: auto;
`;

const HardwareRecommendation = (props) => {
  const { t } = useTranslation();
  const { data } = props;

  if (data.length % 4 !== 0) {
    var s = 4 - (data.length % 4);
    for (let j = 0; j < s; j++) {
      data.push({});
    }
  }
  var items = [];
  var block = [];
  data.forEach((i, index) => {
    if ((index + 1) % 4 !== 0) {
      items.push(i);
    } else {
      items.push(i);
      block.push(items);
      items = [];
    }
  });
  return (
    <Wrapper>
      <SeriesHeaderContainer>
        <SeriesHeader>
          {t('product.hardwareCategory.title.recommendation')}
        </SeriesHeader>
        <DividerContainer>
          <HeaderDivider />
        </DividerContainer>
      </SeriesHeaderContainer>
      {block.map((e, i) => (
        <HardwareItems key={i} data={e} />
      ))}
    </Wrapper>
  );
};

export default HardwareRecommendation;
