import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Button,
  Dialog,
  Fade,
  // IconButton,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
// import CloseIcon from '@material-ui/icons/Close';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useTheme, withStyles } from '@material-ui/core/styles';
// import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { getOrderDetail } from '../services/billingPage';
import { Modal } from '../actions/modal';

const Wrapper = styled.div`
  width: 940px;
`;

const ContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  height: 55px;
  background: #0cbaef;
`;

const ContentTitleLeft = styled.div`
  background: #0cbaef;
  width: 48%;
  color: #ffffff;
  font-size: 24px;
  line-height: 55px;
  text-align: center;
`;

const ContentTitleRight = styled.div`
  background: #ef980c;
  width: 52%;
  border-top-left-radius: 20px 20px;
  color: #ffffff;
  font-size: 24px;
  line-height: 55px;
  text-align: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 940px;
  margin-left: auto;
  margin-right: auto;
`;

const OrderContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`;

const OrderLeftContainer = styled.div`
  display: flex;
  width: 48%;
  // height: 368px;
  background: #f8f8f8;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const OrderRightContainer = styled.div`
  display: flex;
  width: 52%;
  // height: 368px;
  background: #efefef;
`;

const OrderCode = styled.div`
  margin: 54px 40px 54px 54px;
`;

const OrderCodeUntil = styled.div`
  font-size: 21px;
  line-height: 28px;
  color: #ff0000;
`;

const OrderCodeExpire = styled.span`
  font-size: 21px;
  font-weight: bold;
  line-height: 28px;
  color: #ff0000;
`;

const OrderCodePrint = styled.div`
  font-size: 21px;
  line-height: 28px;
  color: #939393;
  padding-top: 11px;
`;

const OrderCodeStore = styled.div`
  font-size: 21px;
  line-height: 28px;
  color: #939393;
  padding-top: 11px;
`;

const OrderCodeCode = styled.div`
  font-size: 35px;
  font-weight: bold;
  line-height: 47px;
  color: #000000;
  padding-top: 42px;
`;

const OrderCodeNote = styled.div`
  font-size: 16px;
  color: #939393;
  line-height: 21px;
  padding-top: 36px;
`;

const OrderLink = styled.div`
  margin: 54px 48px 54px 54px;
`;

const OrderLinkCopy = styled.div`
  font-size: 21px;
  line-height: 34px;
  color: #939393;
`;

const OrderLinkStore = styled.div`
  font-size: 21px;
  line-height: 28px;
  color: #939393;
  padding-top: 9px;
`;

const OrderLinkURL = styled.div`
  font-size: 21px;
  color: #000000;
  padding-top: 42px;
  width: 100%;
  word-break: break-all;
`;

const LinkURL = styled.a`
  color: #000000;
  font-weight: bold;
`;

const OrderLinkCopyContainer = styled.div`
  padding-top: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OrderLinkCopyButton = styled(Button)`
  && {
    font-size: 16px;
    padding: 4px 16px;
    background: #0cbaef;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #0cbaef;
    // width: 120px;
    &:hover {
      color: #0cbaef;
    }
  }
`;

const CopyIconContainer = styled.div`
  margin-left: 12px;
  height: 100%;
  display: flex;
`;

const CopyIcon = styled(CheckCircleOutlineIcon)`
  && {
    font-size: 30px;
    color: #4caf50;
  }
`;

const ContentNoticeContainer = styled.div`
  display: flex;
  flex-direction: row;
  // height: 227px;
  background: #ffffff;
  width: 940px;
`;

const ContentNotice = styled.div`
  margin: 16px 60px 40px;
  width: 80%;
`;

const ContentNoticeTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  color: #939393;
`;

const ContentNoticeContent = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #939393;
  white-space: pre-line;
  padding-top: 9px;
`;

const ContentOk = styled.div`
  margin: 16px 60px 40px;
  width: 20%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const OrderOkButton = styled.div`
  && {
    font-size: 17px;
    padding: 4px 16px;
    background: #d1d1d1;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #d1d1d1;
    width: 52px;
    text-align: center;
    &:hover {
      color: #d1d1d1;
      background: #ffffff;
      cursor: pointer;
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 32px 0;
`;

// const styles = (theme) => ({
//   root: {
//     margin: 0,
//     padding: theme.spacing(0),
//   },
//   closeButton: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
// });

// const DialogTitle = withStyles(styles)((props) => {
//   const { children, classes, onClose, ...other } = props;
//   return (
//     <MuiDialogTitle disableTypography className={classes.root} {...other}>
//       {onClose ? (
//         <IconButton
//           aria-label="close"
//           className={classes.closeButton}
//           onClick={onClose}
//         >
//           <CloseIcon />
//         </IconButton>
//       ) : null}
//     </MuiDialogTitle>
//   );
// });

const CustomDialog = withStyles({
  paper: {
    borderRadius: '10px',
    // width: 940,
    overflowX: 'hidden',
  },
})(Dialog);

const CVSDialog = (props) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState({
    orderDate: '',
    fee: 0,
    items: [],
  });
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');

  const [loading, setLoading] = useState(true);
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getOrderDetail({
          params: { lng: i18n.language, id: props.orderId },
          headers: {
            Authorization: `Bearer ${user.token.accessToken}`,
          },
        });
        setData(result.data);

        setLoading(false);
      } catch (e) {
        setData({
          orderDate: '',
          fee: 0,
          items: [],
        });
        setLoading(false);
        // setOrderStatus(false);
        if (e && e.response) {
          switch (e.response.status) {
            case 401:
              dispatch(Modal('', true));
              break;
            default:
              console.log(e);
          }
        }
      }
    };
    if (props.open) {
      if (user.token === null && ticket === null) {
        dispatch(Modal('', true));
      } else {
        fetchData();
      }
    }
  }, [i18n, i18n.language, props, ticket, user, dispatch]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(1100));

  return (
    <CustomDialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="responsive-dialog-title"
      maxWidth="lg"
      onExit={() => {
        setData({
          orderDate: '',
          fee: 0,
          items: [],
        });
        setLoading(true);
        setCopy(false);
      }}
    >
      {/* <DialogTitle id="customized-dialog-title" onClose={props.close} /> */}
      <Wrapper>
        <ContentTitle>
          <ContentTitleLeft>{t('orderCVS.dialog.title.code')}</ContentTitleLeft>
          <ContentTitleRight>
            {t('orderCVS.dialog.title.link')}
          </ContentTitleRight>
        </ContentTitle>
        <ContentWrapper>
          {loading ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : (
            <OrderContentContainer>
              <OrderLeftContainer>
                <OrderCode>
                  <OrderCodeUntil>
                    {`${t('orderCVS.dialog.content.until1')} `}
                    <OrderCodeExpire>{data.expire}</OrderCodeExpire>
                    {` ${t('orderCVS.dialog.content.until2')}`}
                  </OrderCodeUntil>
                  <OrderCodePrint>
                    {t('orderCVS.dialog.content.print')}
                  </OrderCodePrint>
                  <OrderCodeStore>
                    {t('orderCVS.dialog.content.store')}
                  </OrderCodeStore>
                  <OrderCodeCode>{CodeSplit(data.cvs)}</OrderCodeCode>
                  <OrderCodeNote>
                    {t('orderCVS.dialog.content.note')}
                  </OrderCodeNote>
                </OrderCode>
              </OrderLeftContainer>
              <OrderRightContainer>
                <OrderLink>
                  <OrderLinkCopy>
                    {t('orderCVS.dialog.content.copy')}
                  </OrderLinkCopy>
                  <OrderLinkStore>
                    {t('orderCVS.dialog.content.store')}
                  </OrderLinkStore>
                  <OrderLinkURL>
                    <LinkURL href={data.cvsUrl}>{data.cvsUrl}</LinkURL>
                  </OrderLinkURL>
                  <OrderLinkCopyContainer>
                    <OrderLinkCopyButton
                      onClick={() => {
                        navigator.clipboard.writeText(data.cvsUrl);
                        setCopy(true);
                        if (copy) {
                          setCopy(false);
                          var cc = setInterval(() => {
                            setCopy(true);
                            clearInterval(cc);
                          }, 100);
                        }
                      }}
                    >
                      {t('orderCVS.dialog.button.copy')}
                    </OrderLinkCopyButton>
                    <CopyIconContainer>
                      <Fade in={copy}>
                        <CopyIcon />
                      </Fade>
                    </CopyIconContainer>
                  </OrderLinkCopyContainer>
                </OrderLink>
              </OrderRightContainer>
            </OrderContentContainer>
          )}
        </ContentWrapper>
        <ContentNoticeContainer>
          <ContentNotice>
            <ContentNoticeTitle>
              {t('orderCVS.dialog.title.notice')}
            </ContentNoticeTitle>
            <ContentNoticeContent>
              {t('orderCVS.dialog.content.notice')}
            </ContentNoticeContent>
          </ContentNotice>
          <ContentOk>
            <OrderOkButton
              onClick={() => {
                props.close();
              }}
            >
              {t('orderCVS.dialog.button.ok')}
            </OrderOkButton>
          </ContentOk>
        </ContentNoticeContainer>
      </Wrapper>
    </CustomDialog>
  );
};

export default CVSDialog;

function CodeSplit(code) {
  var c = [];

  if (code.length && code.length > 5) {
    c.push(code.substring(0, 5));
  }

  for (let i = 0; i <= code.length - i; i++) {
    if (i % 3 === 0) {
      c.push(' ');
      c.push(code.substring(5 + i, 5 + i + 3));
    }
  }

  return c.join('');
}
