import axios from 'axios';
import { store } from '../index';
import { LOGOUT_SUCCESS, refreshSuccess } from '../actions/user';

const baseURL = process.env.REACT_APP_API_URL;

let apiClient;
let kneronApiClient;
const handleRequestSent = (config) => {
  console.log(`request to url: ${config.url}`);
  if (
    store.getState().user.token !== null &&
    store.getState().user.token !== undefined
  ) {
    const expiry = store.getState().user.token.expiry;
    const expiryTime = new Date(expiry).getTime();
    const refreshThreshold = new Date().getTime() + 600000;
    // console.log("expiryTime", expiryTime)
    // console.log("refreshThreshold", refreshThreshold)
    if (refreshThreshold > expiryTime * 1000) {
      console.log(`request to url: /users/refresh-tokens`);
      const apiFetch = async () => {
        try {
          const result = await axios.post(
            baseURL + '/users/refresh-tokens',
            {
              scope: 'user',
              refreshToken: store.getState().user.token.refreshToken,
            },
            {
              headers: {
                Authorization: `Bearer ${
                  store.getState().user.token.accessToken
                }`,
              },
            }
          );
          if (result.data.status === 'ok') {
            return store.dispatch(refreshSuccess(result.data));
          }
        } catch (e) {
          store.dispatch({ type: LOGOUT_SUCCESS });
          if (e && e.response) {
            switch (e.response.status) {
              case 401:
                console.log('refresh token 401');
                // store.dispatch(modal.actions.pop({ message: '', open: true }))
                // store.dispatch(message.actions.alert({ open: true, type: 'warning', message: 'clear' }));
                break;
              default:
                console.log(e);
            }
          }
        }
      };
      apiFetch();
    }
  }
  return config;
};

const handleRequestError = (err) => {
  console.log(err);
  return Promise.reject(err);
};

const handleResponseSuccess = (response) => {
  console.log('response ok');
  return response;
};

const handleResponseError = (err) => {
  console.log(err);
  return Promise.reject(err);
};

export const createApiClient = (config) => {
  if (!apiClient) {
    apiClient = axios.create({
      baseURL,
      headers: {
        Version: process.env.REACT_APP_VERSION,
      },
      ...config,
    });

    apiClient.interceptors.request.use(handleRequestSent, handleRequestError);
    apiClient.interceptors.response.use(
      handleResponseSuccess,
      handleResponseError
    );
  }

  return apiClient;
};

export const createKneronApiClient = (config) => {
  if (!kneronApiClient) {
    kneronApiClient = axios.create({
      ...config,
    });

    kneronApiClient.interceptors.request.use(handleRequestSent, handleRequestError);
    kneronApiClient.interceptors.response.use(
      handleResponseSuccess,
      handleResponseError
    );
  }

  return kneronApiClient;
};
