import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton } from '@material-ui/core';
import moment from 'moment';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { addToCart } from '../../actions/cart';
import {
  postUserAddWishList,
  postUserDropWishList,
} from '../../services/authentication';
import { notify } from '../../actions/notification';
import AddIcon from '@material-ui/icons/Add';
import { store } from '../../index';
import { getWishCount } from '../../services/productPage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 64px 0px 16px;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;

  ${({ dialog }) =>
    dialog &&
    `
    display: flex;
    flex-direction: column;

    padding: 64px 0px 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const InfoGrid = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 44px;
  justify-content: space-between;
  ${({ dialog }) =>
    dialog &&
    `
    margin-left: 0;
  `}
`;

const DividerGrid = styled.div`
  display: flex;
  width: 340px;
  flex-direction: column;
  justify-content: flex-end;
  ${({ dialog }) =>
    dialog &&
    `
    width: 260px;
  `}
`;

const ImageGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 84px;
  ${({ dialog }) =>
    dialog &&
    `
    margin-right: 16px;
  `}
`;

const Image = styled.img`
  height: 270px;
  width: 270px;
`;

const ImageDefaultContainer = styled.div`
  height: 270px;
  width: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d1d1d1;
`;

const ImageDefault = styled.p`
  color: #3e3a39;
`;

const Title = styled.div`
  font-size: 33px;
  font-weight: bold;
  color: #3e3a39;
  margin-bottom: 16px;
`;

const Description = styled.div`
  font-size: 15px;
  color: #595757;
  line-height: 25px;
  white-space: pre-line;
  ${({ withCoupon }) =>
    withCoupon &&
    `word-wrap: break-word;
     word-break: normal;
  `}
`;

const WishButton = styled(IconButton)`
  padding: 0;
  margin-right: 10px;
`;

const WishBorderIcon = styled(FavoriteBorderIcon)`
  color: #d1d1d1;
`;

const WishIcon = styled(FavoriteIcon)`
  color: #d1d1d1;
`;

const WishCount = styled.div`
  font-size: 18px;
  line-height: 18px;
  color: #595757;
  margin-right: 16px;
`;

const BuyButton = styled(Button)`
  && {
    padding: 0;
    min-width: 124px;
    border-radius: 100px;
    background: #0cbaef;
    color: #ffffff;
    margin-right: 12px;
    &:hover {
      color: #3e3a39;
    }
  }
`;

const AddButton = styled(Button)`
  && {
    padding: 0;
    min-width: 24px;
    border-radius: 100px;
    background: #ef980c;
    color: #ffffff;
    &:hover {
      color: #3e3a39;
    }
  }
`;

const ItemAddIcon = styled(AddIcon)`
  && {
    font-size: 24px;
  }
`;

const Buttons = styled.div`
  // width: 258px;
  margin-top: 40px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const EmptyButtons = styled.div`
  height: 94px;
`;

const ComingSoon = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #0cbaef;
  // margin-left: 10px;
`;

const CouponGrid = styled.div`
  width: 314px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const CouponContainer = styled.div`
  width: 100%;
  // height: 94px;
  margin-bottom: 24px;
  flex-direction: column;
`;

const CouponUpper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const CouponTitleContainer = styled.div`
  // height: 100%;
  // width: 100%;
  background: #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CouponTitle = styled.div`
  color: #ffffff;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: 1px;
  font-weight: bold;
  text-align: center;
  margin: 4px;
  width: 88px;
  word-wrap: break-word;
  // word-break: break-all;
  overflow-wrap: break-word;
  hyphens: auto;
`;

const CouponDateContainer = styled.div`
  height: auto;
  width: 100%;
  background: #ef980c;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CouponDate = styled.div`
  color: #ffffff;
  font-size: 18px;
  line-height: 38px;
  font-weight: bold;
  text-align: center;
  margin: 0 4px;
`;

const CouponLower = styled.div`
  // height: 58px;
  height: calc(100% - 36px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #ef980c;
  border-right: 1px solid #ef980c;
  border-bottom: 1px solid #ef980c;
`;

const CouponMessage = styled.div`
  padding: 16px 8px;
  font-size: 18px;
  // line-height: 18px;
  color: #ef980c;
  font-weight: bold;
  text-align: center;
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
`;

const CouponNotice = styled.div`
  margin: 4px 0;
  color: #ef980c;
`;

const Information = (props) => {
  const { i18n, t } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { data, dialog, coupon } = props;
  const [wish, setWish] = useState(false);
  const [wishCount, setWishCount] = useState('');
  const [couponDate, SetCouponDate] = useState({
    start: '',
    end: '',
  });
  const timezoneOffset = new Date().getTimezoneOffset() / -60;

  useEffect(() => {
    setWish(data.wish);
  }, [data]);

  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getWishCount(data.id);
        setWishCount(result.data.wishCount);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [data.id, wish]);

  useEffect(() => {
    if (user.info) {
      SetCouponDate({
        start: moment(new Date(data.coupon.start * 1000))
          .add(-timezoneOffset + data.coupon.timezone, 'hours')
          .format('YYYY-MM-DD'),
        end: moment(new Date(data.coupon.end * 1000))
          .add(-timezoneOffset + data.coupon.timezone, 'hours')
          .format('YYYY-MM-DD'),
      });
    } else {
      SetCouponDate({
        start: moment(new Date(data.coupon.start * 1000)).format('YYYY-MM-DD'),
        end: moment(new Date(data.coupon.end * 1000)).format('YYYY-MM-DD'),
      });
    }
  }, [user, timezoneOffset, data.coupon.start, data.coupon.end, data]);

  return (
    <Wrapper dialog={dialog}>
      <InfoGrid dialog={dialog}>
        <ImageGrid dialog={dialog}>
          {data.image ? (
            <Image src={data.image} alt="" />
          ) : (
            <ImageDefaultContainer>
              <ImageDefault>KNEO</ImageDefault>
            </ImageDefaultContainer>
          )}
        </ImageGrid>
        <DividerGrid dialog={dialog}>
          <Title>{data.name}</Title>
          <Description withCoupon={coupon}>{data.description}</Description>
          {data.preview ? (
            <Buttons>
              {!data.review && (
                <>
                  <WishButton
                    onClick={() => {
                      if (user.token !== null) {
                        const apiAddWishList = async () => {
                          try {
                            const result = await postUserAddWishList(
                              {
                                data: [data.id],
                              },
                              user.token.accessToken
                            );
                            if (result.status === 'ok') {
                              setWish(true);
                            }
                          } catch (e) {
                            if (e && e.response) {
                              switch (e.response.status) {
                                case 400:
                                  dispatch(
                                    notify('error', i18n.t('message.400'), true)
                                  );
                                  break;
                                case 401:
                                  dispatch(
                                    notify(
                                      'info',
                                      t('product.hardware.message.wish'),
                                      true
                                    )
                                  );
                                  break;
                                case 500:
                                  dispatch(
                                    notify('error', i18n.t('message.500'), true)
                                  );
                                  break;
                                default:
                                  dispatch(
                                    notify(
                                      'error',
                                      i18n.t('message.error'),
                                      true
                                    )
                                  );
                              }
                            }
                          }
                        };
                        const apiDropWishList = async () => {
                          try {
                            const result = await postUserDropWishList(
                              {
                                data: {
                                  data: [data.id],
                                  query: 'n',
                                },
                              },
                              user.token.accessToken
                            );
                            if (result.status === 'ok') {
                              setWish(false);
                            }
                          } catch (e) {
                            if (e && e.response) {
                              switch (e.response.status) {
                                case 400:
                                  dispatch(
                                    notify('error', i18n.t('message.400'), true)
                                  );
                                  break;
                                case 401:
                                  dispatch(
                                    notify(
                                      'info',
                                      t('product.hardware.message.wish'),
                                      true
                                    )
                                  );
                                  break;
                                case 500:
                                  dispatch(
                                    notify('error', i18n.t('message.500'), true)
                                  );
                                  break;
                                default:
                                  dispatch(
                                    notify(
                                      'error',
                                      i18n.t('message.error'),
                                      true
                                    )
                                  );
                              }
                            }
                          }
                        };
                        if (wish) {
                          apiDropWishList();
                        } else {
                          apiAddWishList();
                        }
                      } else {
                        dispatch(
                          notify(
                            'info',
                            t('product.hardware.message.wish'),
                            true
                          )
                        );
                      }
                    }}
                  >
                    {wish ? (
                      <WishIcon style={{ fontSize: 30, color: '#FF0000' }} />
                    ) : (
                      <WishBorderIcon style={{ fontSize: 30 }} />
                    )}
                  </WishButton>
                  <WishCount>{numberFormatter(wishCount)}</WishCount>
                </>
              )}
              {data.display || data.review ? (
                <>
                  <BuyButton
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      dispatch(
                        addToCart(
                          data.id,
                          data.image,
                          data.name,
                          data.description,
                          data.product,
                          data.category,
                          data.provider,
                          data.providerId,
                          data.version,
                          data.versionIndex,
                          data.price,
                          1
                        )
                      );
                      var timeout = setInterval(() => {
                        if (
                          store
                            .getState()
                            .cart.addedProducts.some(function (
                              cartItem,
                              index,
                              array
                            ) {
                              return data.id === cartItem.id;
                            })
                        ) {
                          history.push('/checkout/cart');
                          clearInterval(timeout);
                        }
                      }, 100);
                    }}
                  >
                    {`NT $${data.price}`}
                  </BuyButton>
                  <AddButton
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      dispatch(
                        addToCart(
                          data.id,
                          data.image,
                          data.name,
                          data.description,
                          data.product,
                          data.category,
                          data.provider,
                          data.providerId,
                          data.version,
                          data.versionIndex,
                          data.price,
                          1
                        )
                      );
                    }}
                  >
                    <ItemAddIcon />
                  </AddButton>
                </>
              ) : (
                <ComingSoon>Coming Soon</ComingSoon>
              )}
            </Buttons>
          ) : (
            <EmptyButtons></EmptyButtons>
          )}
        </DividerGrid>
        <CouponGrid>
          {coupon && (
            <CouponContainer>
              <CouponUpper>
                <CouponTitleContainer>
                  <CouponTitle>{data.coupon.title}</CouponTitle>
                </CouponTitleContainer>
                <CouponDateContainer>
                  <CouponDate>{`${couponDate.start}~${couponDate.end}`}</CouponDate>
                </CouponDateContainer>
              </CouponUpper>
              <CouponLower>
                <CouponMessage>{data.coupon.description}</CouponMessage>
              </CouponLower>
              <CouponNotice>{`* ${t(
                'product.hardware.message.coupon'
              )}`}</CouponNotice>
            </CouponContainer>
          )}
        </CouponGrid>
      </InfoGrid>
    </Wrapper>
  );
};

export default Information;

function numberFormatter(num) {
  if (num && num > 0 && num <= 99999) {
    return num;
  } else if (num && num > 99999 && num <= 999949) {
    var k = num / 1000;
    return k.toFixed(1).toString() + 'K';
  } else if (num && num > 99949) {
    var m = num / 1000000;
    return (
      m
        .toFixed(3)
        .toString()
        .replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,') + 'M'
    );
  } else if (num === 0) {
    return 0;
  } else {
    return '';
  }
}
