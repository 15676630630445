import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  Typography,
  IconButton,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { postUserAppDownload } from '../../services/authentication';
import { notify } from '../../actions/notification';
import { Modal } from '../../actions/modal';
import {
  addToDownloadQueue,
  dropFromDownloadQueue,
} from '../../actions/download';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 36px;
`;

const VersionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const styles = (theme) => ({
  root: {
    // margin: 0,
    padding: theme.spacing(1.5),
    marginLeft: 4,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginLeft: 16,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DownloadDialog = (props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const [version, setVersion] = useState('');
  const handleChange = (event) => {
    setVersion(event.target.value);
  };

  useEffect(() => {
    if (version === '' && props.version && props.version.length !== 0) {
      setVersion(props.version[props.version.length - 1].versionIndex);
    }
  }, [version, props]);
  console.log(version);
  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="responsive-dialog-title"
      maxWidth={'xs'}
      fullWidth={true}
      onExit={() => {
        props.closing();
        props.clearDownloadInfo();
        setVersion('');
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={props.close}>
        {t('dialog.title.kneo')}
      </DialogTitle>
      <DialogContent dividers>
        {props.version && props.version.length !== 0 && (
          <VersionContainer>
            <Typography>Version</Typography>
            <FormControl
              variant="outlined"
              size="small"
              className={classes.formControl}
            >
              <Select value={version} onChange={handleChange}>
                {props.version &&
                  props.version.map((e, index) => (
                    <MenuItem
                      key={index}
                      value={e.versionIndex}
                    >{`${e.versionIndex} (${e.version})`}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </VersionContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} color="primary">
          {t('dialog.button.no')}
        </Button>
        {loading ? (
          <LoadingContainer>
            <CircularProgress size={24} />
          </LoadingContainer>
        ) : (
          <Button
            onClick={() => {
              setLoading(false);
              props.close();
              props.refresh();
              dispatch(
                addToDownloadQueue(props.downloadInfo.downloadQueueId, 0)
              );
              const apiFetch = async () => {
                try {
                  const result = await postUserAppDownload(
                    {
                      device_uid: props.downloadInfo.device_uid,
                      product_uid: props.downloadInfo.product_uid,
                      filename: props.downloadInfo.filename,
                      versionIndex: version,
                      version: true,
                    },
                    user.token.accessToken
                  );
                  let a = document.createElement('a');
                  a.href =
                    process.env.REACT_APP_API_URL +
                    '/apps/download?&user=' +
                    user.info.email +
                    '&timestamp=' +
                    result.data.time +
                    '&filename=' +
                    result.data.filename;
                  a.click();
                  dispatch(
                    dropFromDownloadQueue(props.downloadInfo.downloadQueueId)
                  );
                } catch (e) {
                  dispatch(
                    dropFromDownloadQueue(props.downloadInfo.downloadQueueId)
                  );
                  if (e && e.response) {
                    switch (e.response.status) {
                      case 400:
                        dispatch(notify('error', i18n.t('message.400'), true));
                        break;
                      case 401:
                        dispatch(Modal('', true));
                        break;
                      case 500:
                        dispatch(notify('error', i18n.t('message.500'), true));
                        break;
                      default:
                        dispatch(
                          notify('error', i18n.t('message.error'), true)
                        );
                    }
                  }
                }
              };
              apiFetch();
            }}
            color="primary"
            autoFocus
          >
            {t('dialog.button.yes')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DownloadDialog;
