import {
  ADD_TO_CART,
  DROP_FROM_CART,
  QUANTITY_INCREMENT,
  QUANTITY_DECREMENT,
  FETCH_CART_SUCCESS,
  LOCK_CART_ITEM,
  UNLOCK_CART_ITEM,
  SYNC_CART_ITEM,
  CLEAR_CART,
  CART_LOADING,
  DROP_ITEMS,
  CART_SYNC_LOADING,
  FETCH_COUPONS_SUCCESS,
  UPDATE_COUPON_ITEM,
} from '../actions/cart';
import { LOGOUT_SUCCESS } from '../actions/user';

const KneoCart = 'KneoCart';
const initialCart = localStorage.getItem(KneoCart)
  ? JSON.parse(localStorage.getItem(KneoCart))
  : {
      addedProducts: [],
      disabledStateList: [],
      loading: false,
      syncLoading: false,
      coupon: {},
      discount: {},
    };

export const cart = (state = initialCart, action) => {
  switch (action.type) {
    case FETCH_CART_SUCCESS:
      var cart = action.payload.map((item, index) => {
        return {
          id: item.id,
          image: item.image,
          name: item.name,
          description: item.description,
          product: item.type,
          category: item.category,
          provider: item.provider,
          providerId: item.providerId,
          version: item.version,
          versionIndex: item.versionIndex,
          price: item.sale_price,
          refPrice: item.ref_price,
          quantity: item.quantity,
          oldQuantity: item.quantity,
          review: item.review,
        };
      });
      var stateList = action.payload.map(() => {
        return false;
      });
      localStorage.setItem(
        KneoCart,
        JSON.stringify({
          // ...state,
          addedProducts: cart,
          disabledStateList: stateList,
          loading: false,
        })
      );
      return {
        ...state,
        addedProducts: cart,
        disabledStateList: stateList,
        loading: false,
      };

    case LOGOUT_SUCCESS:
      localStorage.removeItem(KneoCart);
      return {
        addedProducts: [],
        disabledStateList: [],
        loading: false,
        syncLoading: false,
        coupon: {},
        discount: {},
      };

    case ADD_TO_CART:
      localStorage.setItem(
        KneoCart,
        JSON.stringify({
          ...state,
          disabledStateList: [...state.disabledStateList, false],
          addedProducts: [
            ...state.addedProducts,
            {
              id: action.payload.id,
              image: action.payload.image,
              name: action.payload.name,
              description: action.payload.description,
              product: action.payload.product,
              category: action.payload.category,
              provider: action.payload.provider,
              providerId: action.payload.providerId,
              version: action.payload.version,
              versionIndex: action.payload.versionIndex,
              price: action.payload.price,
              refPrice: action.payload.price,
              quantity: action.payload.quantity,
              oldQuantity: action.payload.quantity,
            },
          ],
        })
      );
      return {
        ...state,
        disabledStateList: [...state.disabledStateList, false],
        addedProducts: [
          ...state.addedProducts,
          {
            id: action.payload.id,
            image: action.payload.image,
            name: action.payload.name,
            description: action.payload.description,
            product: action.payload.product,
            category: action.payload.category,
            provider: action.payload.provider,
            providerId: action.payload.providerId,
            version: action.payload.version,
            versionIndex: action.payload.versionIndex,
            price: action.payload.price,
            refPrice: action.payload.price,
            quantity: action.payload.quantity,
            oldQuantity: action.payload.quantity,
          },
        ],
      };

    case DROP_FROM_CART:
      var del = state.addedProducts.findIndex(function (item, index, array) {
        return item.id === action.payload.id;
      });
      localStorage.setItem(
        KneoCart,
        JSON.stringify({
          ...state,
          addedProducts: [
            ...state.addedProducts.slice(0, del),
            ...state.addedProducts.slice(del + 1),
          ],
          disabledStateList: [
            ...state.disabledStateList.slice(0, del),
            ...state.disabledStateList.slice(del + 1),
          ],
        })
      );
      return {
        ...state,
        addedProducts: [
          ...state.addedProducts.slice(0, del),
          ...state.addedProducts.slice(del + 1),
        ],
        disabledStateList: [
          ...state.disabledStateList.slice(0, del),
          ...state.disabledStateList.slice(del + 1),
        ],
      };

    case DROP_ITEMS:
      var newAddedProducts = state.addedProducts;
      var newDisabledStateList = state.disabledStateList;
      var ditem;
      action.payload.forEach((i) => {
        ditem = newAddedProducts.findIndex(function (item, index, array) {
          return item.id === i;
        });
        if (ditem === -1) {
          return;
        }
        newAddedProducts = [
          ...newAddedProducts.slice(0, ditem),
          ...newAddedProducts.slice(ditem + 1),
        ];
        newDisabledStateList = [
          ...newDisabledStateList.slice(0, ditem),
          ...newDisabledStateList.slice(ditem + 1),
        ];
      });

      localStorage.setItem(
        KneoCart,
        JSON.stringify({
          ...state,
          addedProducts: newAddedProducts,
          disabledStateList: newDisabledStateList,
        })
      );
      return {
        ...state,
        addedProducts: newAddedProducts,
        disabledStateList: newDisabledStateList,
      };

    case QUANTITY_INCREMENT:
      var i = state.addedProducts.findIndex(function (item, index, array) {
        return item.id === action.payload.id;
      });
      localStorage.setItem(
        KneoCart,
        JSON.stringify({
          ...state,
          addedProducts: state.addedProducts.map((item, index) => {
            if (index !== i) {
              return item;
            }
            return {
              ...item,
              quantity: state.addedProducts[i].quantity + 1,
            };
          }),
        })
      );
      return {
        ...state,
        addedProducts: state.addedProducts.map((item, index) => {
          if (index !== i) {
            return item;
          }
          return {
            ...item,
            quantity: state.addedProducts[i].quantity + 1,
          };
        }),
      };

    case QUANTITY_DECREMENT:
      var j = state.addedProducts.findIndex(function (item, index, array) {
        return item.id === action.payload.id;
      });
      if (state.addedProducts[j].quantity === 1) {
        return state;
      }
      localStorage.setItem(
        KneoCart,
        JSON.stringify({
          ...state,
          addedProducts: state.addedProducts.map((item, index) => {
            if (index !== j) {
              return item;
            }
            return {
              ...item,
              quantity: state.addedProducts[j].quantity - 1,
            };
          }),
        })
      );
      return {
        ...state,
        addedProducts: state.addedProducts.map((item, index) => {
          if (index !== j) {
            return item;
          }
          return {
            ...item,
            quantity: state.addedProducts[j].quantity - 1,
          };
        }),
      };

    case LOCK_CART_ITEM:
      return {
        ...state,
        disabledStateList: state.disabledStateList.map((item, index) => {
          if (action.payload.find((e) => e === state.addedProducts[index].id)) {
            return true;
          }
          return false;
        }),
      };

    case UNLOCK_CART_ITEM:
      return {
        ...state,
        disabledStateList: state.disabledStateList.map((item, index) => {
          if (action.payload.find((e) => e === state.addedProducts[index].id)) {
            return false;
          }
          return false;
        }),
      };

    case SYNC_CART_ITEM:
      return {
        ...state,
        addedProducts: state.addedProducts.map((item, index) => {
          return {
            ...item,
            oldQuantity: item.quantity,
          };
        }),
      };

    case CLEAR_CART:
      localStorage.setItem(
        KneoCart,
        JSON.stringify({
          addedProducts: [],
          disabledStateList: [],
          loading: false,
          syncLoading: false,
          coupon: {},
          discount: {},
        })
      );
      return {
        addedProducts: [],
        disabledStateList: [],
        loading: false,
        syncLoading: false,
        coupon: {},
        discount: {},
      };

    case CART_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case CART_SYNC_LOADING:
      return {
        ...state,
        syncLoading: action.payload.loading,
      };

    case FETCH_COUPONS_SUCCESS:
      var coupons = action.payload;
      var discounts = {};

      Object.keys(action.payload).forEach((i) => {
        if (coupons[i]) {
          var items = {};
          var total = 0;
          var validItems = [];
          coupons[i].items.forEach((v) => {
            if (v.id) {
              validItems.push(v.id);
            }
          });

          coupons[i].items.forEach((e) => {
            if (
              state.coupon &&
              state.coupon[i] &&
              e.id &&
              state.discount[i][e.id] &&
              validItems.findIndex(function (s, index, array) {
                return s === e.id;
              }) !== -1
            ) {
              items[e.id] = state.discount[i][e.id];
              total += state.discount[i][e.id];
            } else if (e.id) {
              items[e.id] = 0;
            }
          });
          items['total'] = total;
          discounts[i] = items;
        }

        if (coupons[i] && coupons[i].items.length < 5) {
          var ss = [];
          for (let j = 0; j < 5 - coupons[i].items.length; j++) {
            ss.push({
              name: '',
            });
          }
          coupons[i].items.push(...ss);
        }
      });

      localStorage.setItem(
        KneoCart,
        JSON.stringify({
          ...state,
          coupon: coupons,
          loading: false,
          discount: discounts,
        })
      );
      return {
        ...state,
        coupon: coupons,
        loading: false,
        discount: discounts,
      };

    case UPDATE_COUPON_ITEM:
      var discountss = state.discount;
      discountss[action.payload.coupon][action.payload.item] +=
        action.payload.count;
      discountss[action.payload.coupon].total += action.payload.count;

      localStorage.setItem(
        KneoCart,
        JSON.stringify({
          ...state,
          discount: discountss,
        })
      );
      return {
        ...state,
        discount: discountss,
      };

    default:
      return state;
  }
};
