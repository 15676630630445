import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { getPrivacyPage } from '../services/landingPage';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px;
  width: 1100px;
  margin-left: auto;
  margin-right: auto;
`;

const TitleContainer = styled.h2`
  font-size: 28px;
  color: #023859;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 28px;
`;

const ForewordContainer = styled.div`
  font-size: 20px;
  color: #707070;
  white-space: pre-line;
  line-height: 35px;
  margin: 16px 0;
`;

const ContentContainer = styled.div`
  width: 895px;
  background: #f8f7f7;
  color: #707070;
  white-space: pre-line;
  margin-top: 32px;
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 35px;
  padding: 54px 84px 88px;
  margin-left: auto;
  margin-right: auto;
`;

const Privacy = () => {
  const { i18n } = useTranslation();
  const [data, setData] = useState({ title: '', foreword: '', content: '' });
  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getPrivacyPage({
          params: { lng: i18n.language },
        });
        setData(result.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [i18n, i18n.language]);

  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <TitleContainer>{data.title}</TitleContainer>
          <ForewordContainer>{data.foreword}</ForewordContainer>
          <ContentContainer>{data.content}</ContentContainer>
        </ContentWrapper>
      </Wrapper>
    </Fragment>
  );
};

export default Privacy;
