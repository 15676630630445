import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player/lazy';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentFirstContainer = styled.div`
  width: 1184px;
  padding: 70px 48px;
  margin-left: auto;
  margin-right: auto;
`;

const ContentFirstTitle = styled.h2`
  font-size: 28px;
  line-height: 38px;
  color: #023859;
  margin-top: 12px;
  margin-bottom: 36px;
`;

const ContentFirstContent = styled.div`
  color: #595757;
  font-size: 22px;
  line-height: 32px;
`;

const ContentFirstContentSpan = styled.span`
  color: #023859;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
`;

const ContentSecondContainer = styled.div`
  // width: 1920px;
  padding: 62px 0 12px;
  position: relative;
  margin-bottom: 57px;
`;

const ContentSecondMarkContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 391px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

const ContentSecondMarkLeftContainer = styled.div`
  width: 108px;
  background: #dbdbdb;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ContentSecondMarkLeftBackground = styled.div`
  background: #dbdbdb;
  left: 0;
  top: 62px;
  height: 391px;
  width: 960px;
  position: absolute;
`;

const ContentSecondMarkRightContainer = styled.div`
  width: 1172px;
  background: #f8f7f7;
`;

const ContentSecondMarkRightBackground = styled.div`
  background: #f8f7f7;
  right: 0;
  top: 62px;
  height: 391px;
  width: 960px;
  position: absolute;
`;

const ContentSecondMarkK = styled.div`
  font-size: 45px;
  color: #414040;
  line-height: 61px;
  font-weight: bold;
  font-family: sans-serif;
  position: absolute;
  top: 102px;
  left: 74px;
`;

const ContentSecondMarkN = styled.div`
  font-size: 45px;
  color: #414040;
  line-height: 61px;
  font-weight: bold;
  font-family: sans-serif;
  position: absolute;
  top: 180px;
  left: 75px;
`;

const ContentSecondMarkE = styled.div`
  font-size: 45px;
  color: #414040;
  line-height: 61px;
  font-weight: bold;
  font-family: sans-serif;
  position: absolute;
  top: 258px;
  left: 75px;
`;

const ContentSecondMarkO = styled.div`
  font-size: 45px;
  color: #414040;
  line-height: 61px;
  font-weight: bold;
  font-family: sans-serif;
  position: absolute;
  top: 335px;
  left: 73px;
`;

const ContentSecondMarkSpan = styled.span`
  font-size: 24px;
  color: #707070;
  line-height: 61px;
  font-weight: 300;
  font-family: sans-serif;
`;

const ReactPlayerContainer = styled.div`
  position: absolute;
  top: -62px;
  left: 315px;
`;

const ContentSecondBottomContainer = styled.div`
  margin-top: 93px;
`;

const ContentSecondBottomGrid = styled.div`
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const ContentSecondBottomLine = styled.div`
  margin: 22px 0;
  margin-left: 315px;
  font-size: 22px;
  color: #595757;
  line-height: 32px;
`;

const ContentSecondBottomLineSpan = styled.span`
  font-size: 22px;
  color: #023859;
  line-height: 32px;
  font-weight: 600;
`;

const ContentThirdContainer = styled.div`
  width: 1184px;
  padding: 0 48px 22px;
  margin-left: auto;
  margin-right: auto;
`;

const ContentThirdTitle = styled.h2`
  font-size: 28px;
  line-height: 38px;
  color: #023859;
  margin-top: 12px;
  margin-bottom: 36px;
`;

const ContentThirdContent = styled.div`
  color: #595757;
  font-size: 22px;
  line-height: 32px;
`;

const ContentThirdContentSpan = styled.span`
  color: #023859;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
`;

const ContentThirdBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  justify-content: space-between;
`;

const ContentThirdBottomLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 627px;
`;

const ContentThirdBottomLeftSection = styled.div`
  color: #595757;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 40px;
`;

const ContentThirdBottomRightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentThirdBottomImage = styled.img`
  height: 317px;
  width: 505px;
`;

const ContentForthContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const ContentForthTopContainer = styled.div`
  background: #f5f5f5;
`;

const ContentForthTopContentContainer = styled.div`
  width: 1184px;
  padding: 30px 48px 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ContentForthImage = styled.img`
  width: 521px;
  height: 327px;
`;

const ContentForthTitle = styled.h2`
  font-size: 28px;
  line-height: 38px;
  color: #023859;
  margin-top: 12px;
  margin-bottom: 36px;
`;

const ContentForthTopContentFirst = styled.div`
  font-size: 22px;
  line-height: 27px;
  color: #707070;
  margin-bottom: 36px;
`;

const ContentForthTopContentSpan = styled.span`
  font-size: 22px;
  line-height: 27px;
  color: #023859;
  font-weight: 600;
`;

const ContentForthBottomContainer = styled.div`
  width: 1184px;
  padding: 30px 48px 30px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 31px;
  display: flex;
  flex-direction: row;
`;

const ContentForthBottomRightContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 34px;
`;

const ContentForthBottomContentFirst = styled.div`
  font-size: 22px;
  line-height: 32px;
  color: #707070;
  margin-bottom: 24px;
`;

const ContentForthBottomContent = styled.div`
  font-size: 22px;
  line-height: 32px;
  color: #707070;
  // border-right: 2px solid #023859;
  padding-left: 16px;
  margin-bottom: 6px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 0;
    height: 44px;
    width: 2px;
    background: #023859;
  }
`;

const ContentForthBottomContentSpan = styled.span`
  font-size: 22px;
  line-height: 32px;
  color: #023859;
  font-weight: 600;
`;

const ContentFifthContainer = styled.div`
  width: 1184px;
  padding: 0 48px 22px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
`;

const ContentFifthTitle = styled.h2`
  font-size: 28px;
  line-height: 38px;
  color: #023859;
  margin-top: 12px;
  margin-bottom: 36px;
`;

const ContentFifthContent = styled.div`
  color: #595757;
  font-size: 22px;
  line-height: 32px;
`;

const ContentFifthContentSpan = styled.span`
  color: #023859;
  font-size: 22px;
  line-height: 32px;
  font-weight: 600;
`;

const ContentFifthBottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 70px;
  justify-content: space-between;
`;

const ContentFifthBottomLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 627px;
`;

const ContentFifthBottomLeftSection = styled.div`
  color: #595757;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 40px;
`;

const ContentFifthBottomRightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentFifthBottomImage = styled.img`
  height: 317px;
  width: 505px;
`;

const About = () => {
  const { i18n } = useTranslation();
  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <ContentFirstContainer>
            {i18n.language === 'en-US' && (
              <>
                <ContentFirstTitle>INTRODUCING KNEO</ContentFirstTitle>
                <ContentFirstContent>
                  {`KNEO is a private mesh intelligence network made up of Kneron powered devices that will `}
                  <ContentFirstContentSpan>
                    {`personalize mobile AI for everyone`}
                  </ContentFirstContentSpan>
                  {`, just as smartphones and mobile apps personalized mobile computing for all. It will create an open platform for AI application `}
                  <ContentFirstContentSpan>
                    {`developers to build AI apps`}
                  </ContentFirstContentSpan>
                  {` based on mainstream AI frameworks and models for consumers and businesses who use KNEO`}
                </ContentFirstContent>
              </>
            )}
            {i18n.language === 'zh-TW' && (
              <>
                <ContentFirstTitle>KNEO是什麽？</ContentFirstTitle>
                <ContentFirstContent>
                  {`KNEO是一個由耐能驅動的設備組成的私有網格智慧網絡，它可以為每個人提供個性化的移動AI，就如現有的智慧手機和APPs為所有人提供了個性化的移動計算一樣。KNEO為AI開發者提供了一個開放的平臺，基於主流AI框架和模型為使用KNEO的消費者和企業構建AI Apps。`}
                </ContentFirstContent>
              </>
            )}
            {i18n.language === 'zh-CN' && (
              <>
                <ContentFirstTitle>KNEO是什么？</ContentFirstTitle>
                <ContentFirstContent>
                  {`KNEO是一个由耐能驱动的设备组成的私有网格智能网络，它可以为每个人提供个性化的移动AI，就如现有的智能手机和APPs为所有人提供了个性化的移动计算一样。KNEO为AI开发者提供了一个开放的平台，基于主流AI框架和模型为使用KNEO的消费者和企业构建AI Apps。`}
                </ContentFirstContent>
              </>
            )}
          </ContentFirstContainer>
          <ContentSecondContainer>
            <ContentSecondMarkLeftBackground />
            <ContentSecondMarkRightBackground />
            <ContentSecondMarkContainer>
              <ContentSecondMarkLeftContainer>
                <ContentSecondMarkK>
                  K<ContentSecondMarkSpan>neron</ContentSecondMarkSpan>
                </ContentSecondMarkK>
                <ContentSecondMarkN>
                  N<ContentSecondMarkSpan>eural network</ContentSecondMarkSpan>
                </ContentSecondMarkN>
                <ContentSecondMarkE>
                  E<ContentSecondMarkSpan>dge AI</ContentSecondMarkSpan>
                </ContentSecondMarkE>
                <ContentSecondMarkO>
                  O<ContentSecondMarkSpan>pen platform</ContentSecondMarkSpan>
                </ContentSecondMarkO>
              </ContentSecondMarkLeftContainer>
              {i18n.language === 'en-US' && (
                <ReactPlayerContainer>
                  <ReactPlayer
                    url={
                      'https://www.youtube.com/watch?v=1_k3-yoX0ms&feature=emb_logo'
                    }
                    height={500}
                    width={890}
                  />
                </ReactPlayerContainer>
              )}
              {i18n.language === 'zh-TW' && (
                <ReactPlayerContainer>
                  <ReactPlayer
                    url={
                      'https://www.youtube.com/watch?time_continue=1&v=adMe_lkMQuU&feature=emb_logo'
                    }
                    height={500}
                    width={890}
                  />
                </ReactPlayerContainer>
              )}
              {i18n.language === 'zh-CN' && (
                <ReactPlayerContainer>
                  <ReactPlayer
                    url={
                      'https://www.youtube.com/watch?time_continue=1&v=adMe_lkMQuU&feature=emb_logo'
                    }
                    height={500}
                    width={890}
                  />
                </ReactPlayerContainer>
              )}
              <ContentSecondMarkRightContainer></ContentSecondMarkRightContainer>
            </ContentSecondMarkContainer>
            <ContentSecondBottomContainer>
              <ContentSecondBottomGrid>
                {i18n.language === 'en-US' && (
                  <>
                    <ContentSecondBottomLine>
                      {`KNEO builds the `}
                      <ContentSecondBottomLineSpan>{`security`}</ContentSecondBottomLineSpan>
                      {` of blockchain technology on top of the `}
                      <ContentSecondBottomLineSpan>{`privacy`}</ContentSecondBottomLineSpan>
                      {` advantages of edge AI.`}
                    </ContentSecondBottomLine>
                    <ContentSecondBottomLine>
                      {`We invite all `}
                      <ContentSecondBottomLineSpan>{`AI app developers`}</ContentSecondBottomLineSpan>
                      {` and `}
                      <ContentSecondBottomLineSpan>{`device makers`}</ContentSecondBottomLineSpan>
                      {` to join us in democratizing and transforming the future of AI.`}
                    </ContentSecondBottomLine>
                  </>
                )}
                {i18n.language === 'zh-TW' && (
                  <>
                    <ContentSecondBottomLine>
                      {`KNEO結合了區塊鏈的安全性和邊緣AI隱私性的各種優勢`}
                    </ContentSecondBottomLine>
                    <ContentSecondBottomLine>
                      {`我們歡迎AI開發者和邊緣AI設備企業加入我們一起推動AI民主化和改變AI的未來。`}
                    </ContentSecondBottomLine>
                  </>
                )}
                {i18n.language === 'zh-CN' && (
                  <>
                    <ContentSecondBottomLine>
                      {`KNEO结合了区块链的安全性和边缘AI隐私性的各种优势。`}
                    </ContentSecondBottomLine>
                    <ContentSecondBottomLine>
                      {`我们欢迎AI开发者和边缘AI设备的企业加入我们一起改变AI的未来。`}
                    </ContentSecondBottomLine>
                  </>
                )}
              </ContentSecondBottomGrid>
            </ContentSecondBottomContainer>
          </ContentSecondContainer>
          <ContentThirdContainer>
            {i18n.language === 'en-US' && (
              <>
                <ContentThirdTitle>
                  SENSOR FUSION FOR MESH INTELLIGENCE
                </ContentThirdTitle>
                <ContentThirdContent>
                  <ContentThirdContentSpan>{`KNEO Stems`}</ContentThirdContentSpan>
                  {` are AI modules or sensors (cameras, mics, thermal sensors, etc) that connect together to build an edge mesh intelligence that doesn't need access to the cloud to process AI models and applications. Just two or more KNEO Stems connected together can work in concert to be `}
                  <ContentThirdContentSpan>{`your very own private AI network`}</ContentThirdContentSpan>
                  {` that works for your benefit.`}
                </ContentThirdContent>
              </>
            )}
            {i18n.language === 'zh-TW' && (
              <>
                <ContentThirdTitle>網格智能中的傳感器融合</ContentThirdTitle>
                <ContentThirdContent>
                  {`KNEO Stems是AI模塊或傳感器(攝像頭、麥克風、熱傳感器等)連接在一起構建的一個邊緣網格智慧，它無需訪問雲端來處理AI模型和應用。只需要兩個或多個KNEO Stems連接就可以協同工作，從而成為你自己的私人AI網絡，保護你的利益。`}
                </ContentThirdContent>
              </>
            )}
            {i18n.language === 'zh-CN' && (
              <>
                <ContentThirdTitle>网格智能中的传感器融合</ContentThirdTitle>
                <ContentThirdContent>
                  {`KNEO Stems是AI模块或传感器(摄像头、麦克风、热传感器等)连接在一起构建的一个边缘网格智能，它无需访问云端来处理AI模型和应用。只需要两个或多个KNEO Stems连接就可以协同工作，从而成为你自己的私人AI网络，保护你的利益。`}
                </ContentThirdContent>
              </>
            )}
            <ContentThirdBottomContainer>
              <ContentThirdBottomLeftContainer>
                {i18n.language === 'en-US' && (
                  <>
                    <ContentThirdBottomLeftSection>
                      {`Like our eyes and ears, multiple sensors provide a more powerful and accurate understanding of the world than individual ones.`}
                    </ContentThirdBottomLeftSection>
                    <ContentThirdBottomLeftSection>
                      {`KNEO connects multiple sensors in what we call `}
                      <ContentThirdContentSpan>
                        {`sensor fusion`}
                      </ContentThirdContentSpan>
                      {` to create a mesh intelligence that is private, powerful, and mobile. `}
                      <ContentThirdContentSpan>
                        {`In the home`}
                      </ContentThirdContentSpan>
                      {`, you can manage your security or energy usage or even monitor the stock and freshness of your food in the fridge.`}
                    </ContentThirdBottomLeftSection>
                    <ContentThirdBottomLeftSection>
                      <ContentThirdContentSpan>
                        {`Bring KNEO outside`}
                      </ContentThirdContentSpan>
                      {` with you and you can monitor your car in the parking lot, learn about your driving and shopping habits through your KNEO Stems, and even run prescribed AI apps from your doctor to manage your post-visit health and fitness regimen.`}
                    </ContentThirdBottomLeftSection>
                  </>
                )}
                {i18n.language === 'zh-TW' && (
                  <>
                    <ContentThirdBottomLeftSection>
                      {`就像我們的眼睛和耳朵一樣，多個傳感器結合比單個的傳感器對外界的反應更強大和準確。KNEO將多個傳感器連接在一起，我們稱之為“傳感器融合”，以創建更私有、功能強大且可移動的網格智慧。在家裏，你可以做到安全管理和能源消耗，甚至可以監控你冰箱裏的食物存儲和新鮮度。把KNEO帶到外面，你可以監控你停車場裏的汽車，通過KNEO Stems了解你的駕駛和購物習慣，甚至可以運行從醫生那裏開具的AI APP來管理你的出門後健康和健身方案。`}
                    </ContentThirdBottomLeftSection>
                  </>
                )}
                {i18n.language === 'zh-CN' && (
                  <>
                    <ContentThirdBottomLeftSection>
                      {`就像我们的眼睛和耳朵一样，多个传感器结合比单个的传感器对外界的反应更强大和准确。KNEO将多个传感器连接在一起，我们称之为“传感器融合”，以创建更私有、功能强大且可移动的网格智能。在家里，你可以做到安全管理和能源消耗，甚至可以监控你冰箱里的食物存储和新鲜度。把KNEO带到外面，你可以监控你停车场里的汽车，通过KNEO Stems了解你的驾驶和购物习惯，甚至可以运行从医生那里开具的AI APP来管理您的出门后健康和健身方案。`}
                    </ContentThirdBottomLeftSection>
                  </>
                )}
              </ContentThirdBottomLeftContainer>
              <ContentThirdBottomRightContainer>
                <ContentThirdBottomImage
                  src={
                    'https://www.kneron.com/en/_upload/image/technology/large/9515f0fbdea106c3.png'
                  }
                  alt=""
                />
              </ContentThirdBottomRightContainer>
            </ContentThirdBottomContainer>
          </ContentThirdContainer>
          <ContentForthContainer>
            <ContentForthTopContainer>
              {i18n.language === 'en-US' && (
                <ContentForthTopContentContainer>
                  <ContentForthTitle>KNEO AI APP STORE</ContentForthTitle>
                  <ContentForthTopContentFirst>
                    {`KNEO's personal mobile AI platform will `}
                    <ContentForthTopContentSpan>{`democratize AI`}</ContentForthTopContentSpan>
                    {` to realize our vision of making AI accessible for everyone. The KNEO platform would give rise to `}
                    <ContentForthTopContentSpan>{`AI apps being as common as mobile apps`}</ContentForthTopContentSpan>
                    {` are today, and start a new world for AI app developers. `}
                  </ContentForthTopContentFirst>
                </ContentForthTopContentContainer>
              )}
              {i18n.language === 'zh-TW' && (
                <ContentForthTopContentContainer>
                  <ContentForthTitle>KNEO AI App 商城</ContentForthTitle>
                  <ContentForthTopContentFirst>
                    {`KNEO的個人移動AI平臺將使AI民主化，以實現我們讓人人都能使用AI的願景。KNEO平臺將使AI Apps像今天的移動App一樣普遍，並為AI App開發者開啟一個新世界。`}
                  </ContentForthTopContentFirst>
                </ContentForthTopContentContainer>
              )}
              {i18n.language === 'zh-CN' && (
                <ContentForthTopContentContainer>
                  <ContentForthTitle>KNEO AI App 商城</ContentForthTitle>
                  <ContentForthTopContentFirst>
                    {`KNEO的个人移动AI平台将使AI大众化，以实现我们让人人都能使用AI的愿景。KNEO平台将使AI Apps像今天的移动App一样普遍，并为AI App开发者开启一个新世界。`}
                  </ContentForthTopContentFirst>
                </ContentForthTopContentContainer>
              )}
            </ContentForthTopContainer>
            <ContentForthBottomContainer>
              {i18n.language === 'en-US' && (
                <>
                  <ContentForthImage
                    src={
                      'https://www.kneron.com/en/_upload/image/technology/large/4815f0fbfec62774.png'
                    }
                    alt=""
                  />
                  <ContentForthBottomRightContent>
                    <ContentForthBottomContentFirst>
                      {`KNEO's AI app store will be as common as mobile app stores are today by benefitting everyone in the ecosystem.`}
                    </ContentForthBottomContentFirst>
                    <ContentForthBottomContent>
                      <ContentForthBottomContentSpan>{`Developers`}</ContentForthBottomContentSpan>
                      {` build and upload AI apps onto the KNEO platform so they can accumulate users and monetize`}
                    </ContentForthBottomContent>
                    <ContentForthBottomContent>
                      <ContentForthBottomContentSpan>{`Consumers`}</ContentForthBottomContentSpan>
                      {` download AI apps and install them on their KNEO device ecosystem for private and personal AI assistance`}
                    </ContentForthBottomContent>
                    <ContentForthBottomContent>
                      <ContentForthBottomContentSpan>{`Service providers`}</ContentForthBottomContentSpan>
                      {` prescribe AI apps for patient health management, customer car maintenance, or client fitness regimens`}
                    </ContentForthBottomContent>
                    <ContentForthBottomContent>
                      <ContentForthBottomContentSpan>{`Device makers`}</ContentForthBottomContentSpan>
                      {` sell more KNEO powered devices as the power of edge AI grows and becomes more useful in consumers' lives`}
                    </ContentForthBottomContent>
                  </ContentForthBottomRightContent>
                </>
              )}
              {i18n.language === 'zh-TW' && (
                <>
                  <ContentForthImage
                    src={
                      'https://www.kneron.com/tw/_upload/image/technology/large/4815f0fbfec62774.png'
                    }
                    alt=""
                  />
                  <ContentForthBottomRightContent>
                    <ContentForthBottomContentFirst>
                      {`KNEO AI app商城將會惠及到每個人，未來會如同今天的移動App商城一樣普遍`}
                    </ContentForthBottomContentFirst>
                    <ContentForthBottomContent>
                      <ContentForthBottomContentSpan>{`開發者`}</ContentForthBottomContentSpan>
                      {`開發和上傳AI apps到KNEO平臺，從而可以積累用戶並從中獲利；`}
                    </ContentForthBottomContent>
                    <ContentForthBottomContent>
                      <ContentForthBottomContentSpan>{`消費者`}</ContentForthBottomContentSpan>
                      {`下載AI App，並將它們安裝到自己的KNEO設備生態系統中，從而可為其提供私人和個人的AI幫助；`}
                    </ContentForthBottomContent>
                    <ContentForthBottomContent>
                      <ContentForthBottomContentSpan>{`服務商`}</ContentForthBottomContentSpan>
                      {`可開發其相應的AI App如: 患者健康管理、汽車售後維護或客戶健身方案等；`}
                    </ContentForthBottomContent>
                    <ContentForthBottomContent>
                      <ContentForthBottomContentSpan>{`設備商`}</ContentForthBottomContentSpan>
                      {`隨著邊緣AI的快速發展，它將不斷的進入人們的生活，隨之銷售的 KNEO驅動設備也將越來越多。 `}
                    </ContentForthBottomContent>
                  </ContentForthBottomRightContent>
                </>
              )}
              {i18n.language === 'zh-CN' && (
                <>
                  <ContentForthImage
                    src={
                      'https://www.kneron.com/cn/_upload/image/technology/large/4815f0fbfec62774.png'
                    }
                    alt=""
                  />
                  <ContentForthBottomRightContent>
                    <ContentForthBottomContentFirst>
                      {`KNEO AI app商城将会惠及到每个人，未来会如同今天的移动App商城一样普遍`}
                    </ContentForthBottomContentFirst>
                    <ContentForthBottomContent>
                      <ContentForthBottomContentSpan>{`开发者`}</ContentForthBottomContentSpan>
                      {`开发和上传AI apps到KNEO平台，从而可以积累用户并从中获利;`}
                    </ContentForthBottomContent>
                    <ContentForthBottomContent>
                      <ContentForthBottomContentSpan>{`消费者`}</ContentForthBottomContentSpan>
                      {`下载AI App，并将它们安装到自己的KNEO设备生态系统中，从而可为其提供私人和个人的AI帮助;`}
                    </ContentForthBottomContent>
                    <ContentForthBottomContent>
                      <ContentForthBottomContentSpan>{`服务商`}</ContentForthBottomContentSpan>
                      {`可开发其相应的AI App如: 患者健康管理、汽车售后维护或客户健身方案等；`}
                    </ContentForthBottomContent>
                    <ContentForthBottomContent>
                      <ContentForthBottomContentSpan>{`设备商`}</ContentForthBottomContentSpan>
                      {`随着边缘AI的快速发展，它将不断的进入人们的生活，随之销售的 KNEO驱动设备也将越来越多。`}
                    </ContentForthBottomContent>
                  </ContentForthBottomRightContent>
                </>
              )}
            </ContentForthBottomContainer>
          </ContentForthContainer>
          <ContentFifthContainer>
            {i18n.language === 'en-US' && (
              <>
                <ContentFifthTitle>
                  KNEO DIGITAL ASSET MARKETPLACE
                </ContentFifthTitle>
                <ContentFifthContent>
                  {`KNEO uses `}
                  <ContentFifthContentSpan>{`blockchain`}</ContentFifthContentSpan>
                  {` technology to `}
                  <ContentFifthContentSpan>{`secure your private data and convert them into digital assets`}</ContentFifthContentSpan>
                  {` you can manage. Exchange it for discounts on services or sell it to corporations, your data back in your hands.`}
                </ContentFifthContent>
              </>
            )}
            {i18n.language === 'zh-TW' && (
              <>
                <ContentFifthTitle>KNEO數字資產市場</ContentFifthTitle>
                <ContentFifthContent>
                  {`KNEO使用區塊鏈技術保護您的私有數據，並將它們轉換為你可以管理的數字資產。你可用它來與公司交換服務上的折扣或者出售，讓你的數據回到你的掌控之下。 `}
                </ContentFifthContent>
              </>
            )}
            {i18n.language === 'zh-CN' && (
              <>
                <ContentFifthTitle>KNEO数字资产市场</ContentFifthTitle>
                <ContentFifthContent>
                  {`KNEO使用区块链技术保护您的私有数据，并将它们转换为你可以管理的数字资产。你可用它来与公司交换服务上的折扣或者出售，让你的数据回到你的掌控之下。`}
                </ContentFifthContent>
              </>
            )}
            <ContentFifthBottomContainer>
              <ContentFifthBottomLeftContainer>
                {i18n.language === 'en-US' && (
                  <>
                    <ContentFifthBottomLeftSection>
                      {`KNEO's digital asset marketplace lets consumers `}
                      <ContentFifthContentSpan>{`secure, exchange, or sell their data`}</ContentFifthContentSpan>
                      {` to interested buyers. Your data, your choice, your marketplace.`}
                    </ContentFifthBottomLeftSection>
                    <ContentFifthBottomLeftSection>
                      {`As your KNEO Stems go with you when you shop in supermarkets and shopping centers, your shopping habits accumulate into data that become more valuable over time. The blockchain technology built into `}
                      <ContentFifthContentSpan>{`KNEO converts this data into digital assets`}</ContentFifthContentSpan>
                      {` that you manage. You can delete, keep it private, or choose to exchange to retail brands for discounts or sell it to advertisers for market value on the KNEO digital asset marketplace. The choice, the control, and your data are yours.`}
                    </ContentFifthBottomLeftSection>
                  </>
                )}
                {i18n.language === 'zh-TW' && (
                  <>
                    <ContentFifthBottomLeftSection>
                      {`KNEO的數字資產市場可以讓消費者自行保護、交換或出售他們的數據給感興趣的買家。你的數據，由你選擇市場。`}
                    </ContentFifthBottomLeftSection>
                    <ContentFifthBottomLeftSection>
                      {`當你的KNEO Stems隨著和你一起在超市或購物中心購物時，你的購物習慣會隨著時間的流逝而變得越來越有價值。建立在KNEO邊緣 AI 網格智慧之上的區塊鏈技術將這些數據轉換為你管理的數字資產。你可以選擇刪除它，或私下保留，或選擇與品牌商換取折扣，或在KNEO數字資產市場上以市場價值出售給廣告商。`}
                    </ContentFifthBottomLeftSection>
                    <ContentFifthBottomLeftSection>
                      {`你的數據你做主，把你的數據還給你自己。`}
                    </ContentFifthBottomLeftSection>
                  </>
                )}
                {i18n.language === 'zh-CN' && (
                  <>
                    <ContentFifthBottomLeftSection>
                      {`KNEO的数字资产市场可以让消费者自行保护、交换或出售他们的数据给感兴趣的买家。你的数据，由你选择市场。`}
                    </ContentFifthBottomLeftSection>
                    <ContentFifthBottomLeftSection>
                      {`当你的KNEO Steams随着和你一起在超市或购物中心购物时，您的购物习惯会随着时间的流逝而变得越来越有价值。建立在KNEO边缘 AI 网格智能之上的区块链技术将这些数据转换为你管理的数字资产。你可以选择删除它，或私下保留，或选择与品牌商换取折扣，或在KNEO数字资产市场上以市场价值出售给广告商。`}
                    </ContentFifthBottomLeftSection>
                    <ContentFifthBottomLeftSection>
                      {`你的数据你做主，把你的数据还给你自己。`}
                    </ContentFifthBottomLeftSection>
                  </>
                )}
              </ContentFifthBottomLeftContainer>
              <ContentFifthBottomRightContainer>
                <ContentFifthBottomImage
                  src={
                    'https://www.kneron.com/en/_upload/image/technology/large/2445f0fc04078a34.png'
                  }
                  alt=""
                />
              </ContentFifthBottomRightContainer>
            </ContentFifthBottomContainer>
          </ContentFifthContainer>
        </ContentWrapper>
      </Wrapper>
    </Fragment>
  );
};

export default About;
