import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { 
  Grid,
  Button,
  makeStyles,
 } from '@material-ui/core';
import Slider from 'react-slick';
import NextArrow from '../../resources/images/next-arrow.png';
import PrevArrow from '../../resources/images/prev-arrow.png';
import DemoDefaultImage from '../../resources/images/demo-default-image.png';
// import useRWD from '../../helpers/useRWD';
import ReactPlayer from 'react-player/lazy';
import DemoImage_enable from '../../resources/images/demo-image-enable.png';
import DemoImage_disable from '../../resources/images/demo-image-disable.png';
import DemoVideo_enable from '../../resources/images/demo-video-enable.png';
import DemoVideo_disable from '../../resources/images/demo-video-disable.png';
import DemoCamera_enable from '../../resources/images/demo-camera-enable.png';
import DemoCamera_disable from '../../resources/images/demo-camera-disable.png';
import { getDemoFileResult } from '../../services/productPage';
import { useDispatch } from 'react-redux';
import { Modal } from '../../actions/modal';
import { notify } from '../../actions/notification';
import { 
  CircularProgress,
  Collapse
 } from '@material-ui/core';
 import EnlargeView from './EnlargeView';

const useStyles = makeStyles((theme) => ({
  browseBtn : {
    width: "95.9px",
    height: "27.6px",
    padding: "5.9px 24.6px 6.7px 21.3px",
    background: "#0cbaef",
    color: "white",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: "bold",
    textTransform: "capitalize",
    '&.Mui-disabled': {
      background: "#f8f7f7",
    },
    '&:hover': {
      background: "#033158",
    }
  },
}));

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0px 16px;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;
`;

const Arrow = styled.img`
  color: #898989;
  && {
    width: 16px;
    height: 32px;
  }
  &:hover {
    color: #999999;
    background: #f8f9fa;
    border-radius: 100px;
  }
`;

const DemoArrow = styled.img`
  color: #898989;
  margin: 0 auto;
  && {
    width: 14.4px;
    height: 28.9px;
  }
  &:hover {
    color: #999999;
    background: #f8f9fa;
    border-radius: 100px;
  }
`;

const Title = styled.h2`
  margin: 4px 0 16px;
  font-size: 23px;
  color: #3e3a39;
  padding-left: 4px;
`;

const SlickContainer = styled.div`
  margin: 0 0 16px;
`;

const SlickImage = styled.img`
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
`;

const Description = styled.div`
  font-size: 15px;
  color: #3e3a39;
  white-space: pre-line;
  margin-bottom: 28px;
`;

const SpecGrid = styled(Grid)`
  && {
    // border-right: 1px solid #d1d1d1;
  }
`;

const DemoLink = styled.a`
  margin-top: 16px;
  display: block;
  padding-left: 4px;
  white-space: nowrap;
  color: #0cbaef;
`;

const CustomTabs = styled.div`
  width: 968px;
  margin-left: 45.5px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d1d1d1;
  justify-content: flex-start;
`;

const CustomTab = styled.div`
  height: 50px;
  padding: 8px;
  position: relative;
`;

const DemoTestArea = styled.div`
  width: 965.9px;
  height: 371.7px;
  margin-left: 45.5px;
  margin-top: 48.4px;
  display: flex;
  flex-direction: row;
`;

const DemoOperateArea = styled.div`
  width: 388px;
  height: 371.7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const DemoIsLoading = styled.div`
  margin-top: 140px;
  margin-left: 150px;
  display: ${props => props.show ? "" : "none"};
`;

const DemoOperateSpacing = styled.div`
  width: 160px;
  height: 306px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Image = styled.img`
  width: 388px;
  height: 306px;
  object-fit: scale-down;
  display: ${props => props.show ? "" : "none"};
`;

const DemoTabImage = styled.img`
  font-size: 18px;
  width: 39.5px;
  height: 31.6px;
  box-sizing: border-box;
`;

const Preview = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { data } = props;
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const initTabClickState = {
    image: false,
    video: false,
    camera: false,
  };
  const [tabClickState, setTabClickState] = React.useState({
    ...initTabClickState,
    image: true
  });
  const [largeView, setLargeView] = React.useState({
    open: false,
    data: ""
  });
  const [demoServiceInfo, setDemoServiceInfo] = React.useState("");
  const [demoFile, setDemoFile] = React.useState("");
  const [demoResult, setDemoResult] = React.useState({
    type: "",
    data: {}
  });
  

  const slickSettings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <Arrow src={NextArrow} alt="" />,
    prevArrow: <Arrow src={PrevArrow} alt="" />,
  };
  var preview = [];
  if (data.preview) {
    data.preview.forEach((item) => {
      preview.push(item);
    });
    if (data.preview.length < 3) {
      for (let i = 0; i < 3 - data.preview.length; i++) {
        preview.push({
          video: '',
          image: '',
        });
      }
    }
  }

  function getDemoServiceURL() {
    const findCurrentType = Object.keys(tabClickState).filter(type=> tabClickState[type] === true);
    return demoServiceInfo[findCurrentType].api;
  }

  async function uploadDemoFile(event) {
    if (event.target.files.length > 0) {
      try {
        setDemoFile(event.target.files[0]);
        setLoading(true);
        let formData = new FormData();
        formData.append("file", event.target.files[0]);
        formData.append("platform", "alg");
        const result = await getDemoFileResult(formData, getDemoServiceURL());
        setDemoResult({
          type: "image",
          data: {
            ...result
          }
        });
        setLoading(false);
      } catch (e) {
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(notify('error', i18n.t('message.401'), true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            case 502:
              dispatch(notify('error', i18n.t('message.502'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
        setLoading(false);
        setDemoResult({
          type: "",
          data: {}
        });
      }
    }
  }

  function getObjectURL(file) {
    return URL.createObjectURL(file);
  }

  function handleTabClickState(prop) {
    if (!demoServiceInfo[prop] || demoServiceInfo[prop].enabled !== "true") return;
    setTabClickState({
      ...initTabClickState,
      [prop]: true
    });
  }

  const handlDemosite = (demoInfo) => (event) => {
    event.preventDefault();
    setDemoServiceInfo(demoServiceInfo!== "" ? "" : demoInfo);
  }

  const showLargeView = () => {
    setLargeView({
      open: true,
      data: `data:image/png;base64,${demoResult.data.dat}`
    })
  }

  return (
    <Wrapper>
      <EnlargeView
        data={largeView.data}
        open={largeView.open}
        close={() => {
          setLargeView({
            open: false,
            data: ""
          })
        }}
      />
      <Title>{t('product.software.titles.preview')}</Title>
      <SlickContainer>
        <Slider {...slickSettings}>
          {data.preview &&
            preview.map((item, index) => (
              <div key={index}>
                {item.image === '' ? (
                  <div className="software-player-wrapper">
                    <ReactPlayer
                      className="react-player"
                      url={item.video}
                      height={252}
                      width={362}
                      style={{
                        // marginLeft: 'auto',
                        // marginRight: 'auto',
                        borderRadius: '15px',
                      }}
                    />
                  </div>
                ) : (
                  <SlickImage
                    src={item.image}
                    alt={item.image}
                    style={{ width: 362, height: 'auto' }}
                  />
                )}
              </div>
            ))}
        </Slider>
      </SlickContainer>
      <Grid container spacing={0}>
        <SpecGrid item xs={12}>
          <Title>{t('product.software.titles.product')}</Title>
          <Description>{data.description}</Description>
        </SpecGrid>
        {
          (data.demo !== null && data.demo.enabled === "true") && (
            <Grid item xs={0.8}>
            <Title>{t('product.software.titles.demo')}</Title>
            {
              data.demo.data.map((item, index) => {
                return (
                  <DemoLink key={index} href={"#"} onClick={handlDemosite(item)}>
                    {`${t("product.software.button.demoSite")} ${index+1}`}
                  </DemoLink>
                )
              })
            }
            </Grid>
          )
        }
        <Collapse in={demoServiceInfo !== ""}>
          <Grid item xs={9.2}>
            <CustomTabs>
              <CustomTab>
                <DemoTabImage src={tabClickState.image ? DemoImage_enable : DemoImage_disable} onClick={() => handleTabClickState("image")}></DemoTabImage>
                {tabClickState["image"] && <div className="triangle active" />}
              </CustomTab>
              {/* <CustomTab>
                <DemoTabImage src={tabClickState.video ? DemoVideo_enable : DemoVideo_disable} onClick={() => handleTabClickState("video")}></DemoTabImage>
                {tabClickState["video"] && <div className="triangle active" />}
              </CustomTab>
              <CustomTab>
                <DemoTabImage style={{  width: "24.5px", height: "29.9px"}} src={tabClickState.camera ? DemoCamera_enable : DemoCamera_disable} onClick={() => handleTabClickState("camera")}></DemoTabImage>
                {tabClickState["camera"] && <div className="triangle active" />}
              </CustomTab> */}
            </CustomTabs>
            <DemoTestArea>
              <DemoOperateArea>
                <Image src={demoFile === "" ? DemoDefaultImage : getObjectURL(demoFile)} show={true}></Image>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="upload-test-image"
                  name="upload-test-image"
                  type="file"
                  onChange={uploadDemoFile}
                />
                <label htmlFor={false ? "" : "upload-test-image"} style={{width: "95.9px", height: "27.6px", marginLeft: "146px"}}>
                  <Button
                    variant="contained"
                    // color="default"
                    component="span"
                    disabled={false}
                    className={classes.browseBtn}
                  >
                    {t("product.software.button.browse")}
                  </Button>
                </label>
              </DemoOperateArea>
              <DemoOperateSpacing>
                <DemoArrow src={NextArrow} alt="" />
              </DemoOperateSpacing>
              <DemoOperateArea>
                <Image src={demoResult.data.dat ?  `data:image/png;base64,${demoResult.data.dat}` : DemoDefaultImage} show={!loading}></Image>
                <DemoIsLoading show={loading}>
                  <CircularProgress></CircularProgress>
                </DemoIsLoading>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="upload-test-image"
                  name="upload-test-image"
                  type="file"
                  onChange={t=>t}
                />
                <label htmlFor={false ? "" : ""} style={{width: "95.9px", height: "27.6px", marginLeft: "146px"}}>
                  <Button
                    variant="contained"
                    // color="default"
                    component="span"
                    disabled={!demoResult.data.dat}
                    className={classes.browseBtn}
                    onClick={showLargeView}
                  >
                    {t("product.software.button.enlarge")}
                  </Button>
                </label>
              </DemoOperateArea>
              {/* <DemoOperateDetail>
                <DetaildIcon>i</DetaildIcon>
              </DemoOperateDetail> */}
            </DemoTestArea>
          </Grid>
        </Collapse>
      </Grid>
    </Wrapper>
  );
};

export default Preview;
