import {
  OAUTH_SUCCESS,
  OAUTH_FAIL,
  OAUTH_RESET,
  OAUTH_LOADING,
  LOGOUT_SUCCESS,
  REFRESH_SUCCESS,
  // REFRESH_LOGOUT,
} from '../actions/user';

const KneoUser = 'KneoUser';
const initialInfo = localStorage.getItem(KneoUser)
  ? JSON.parse(localStorage.getItem(KneoUser)).info
  : null;

const initialToken = localStorage.getItem(KneoUser)
  ? JSON.parse(localStorage.getItem(KneoUser)).token
  : null;

export const user = (
  state = {
    info: initialInfo,
    token: initialToken,
    loading: false,
    status: false,
  },
  action
) => {
  switch (action.type) {
    case OAUTH_SUCCESS:
      localStorage.setItem(
        KneoUser,
        JSON.stringify({
          ...state,
          info: {
            email: action.payload.uid,
            username: action.payload.username,
          },
          token: {
            accessToken: action.payload.accessToken,
            refreshToken: action.payload.refreshToken,
            tokenType: action.payload.tokenType,
            expiry: action.payload.expiry,
          },
          loading: false,
        })
      );
      return {
        ...state,
        info: {
          email: action.payload.uid,
          username: action.payload.username,
        },
        token: {
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
          tokenType: action.payload.tokenType,
          expiry: action.payload.expiry,
        },
        loading: false,
      };

    case OAUTH_FAIL:
      return {
        ...state,
        status: true,
      };

    case OAUTH_RESET:
      return {
        ...state,
        status: false,
      };

    case OAUTH_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case LOGOUT_SUCCESS:
      localStorage.removeItem(KneoUser);
      return {
        ...state,
        info: null,
        token: null,
      };

    case REFRESH_SUCCESS:
      localStorage.setItem(
        KneoUser,
        JSON.stringify({
          ...state,
          token: {
            accessToken: action.payload.accessToken,
            refreshToken: state.token.refreshToken,
            tokenType: state.token.tokenType,
            expiry: action.payload.expiry,
          },
        })
      );
      return {
        ...state,
        token: {
          accessToken: action.payload.accessToken,
          refreshToken: state.token.refreshToken,
          tokenType: state.token.tokenType,
          expiry: action.payload.expiry,
        },
      };

    // case REFRESH_LOGOUT:
    //   localStorage.setItem(
    //     KneoUser,
    //     JSON.stringify({
    //       ...state,
    //       token: null,
    //     })
    //   );
    //   return {
    //     ...state,
    //     token: null,
    //   };

    default:
      return state;
  }
};
