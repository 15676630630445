import { ofType } from 'redux-observable';
import { mergeMap, startWith, catchError } from 'rxjs/operators';
import { empty, from, of } from 'rxjs';
import i18n from '../i18n';
import { postUserSignOut, postLoginProxy } from '../services/authentication';
import {
  OAUTH_USER,
  LOGOUT_USER,
  oauthReset,
  oauthLoadingStart,
  oauthLoadingEnd,
  oauthSuccess,
  oauthFail,
  oauthError,
  logoutSuccess,
  logoutError,
  fetchSyncUserCart,
} from '../actions/user';
import { cartSyncLoadingStart } from '../actions/cart';
import { notify } from '../actions/notification';

export const oauthEpic = (action$) =>
  action$.pipe(
    ofType(OAUTH_USER),
    mergeMap((action) =>
      from(postLoginProxy({ ticket: action.payload })).pipe(
        mergeMap((result) =>
          of(
            oauthSuccess(result),
            notify('success', i18n.t('message.200'), true),
            fetchSyncUserCart(i18n.language)
          )
        ),
        catchError((e) => of(oauthError(e), oauthFail(), oauthLoadingEnd())),
        startWith(oauthReset(), oauthLoadingStart(), cartSyncLoadingStart(true))
      )
    )
  );

export const logoutEpic = (action$) =>
  action$.pipe(
    ofType(LOGOUT_USER),
    mergeMap((action) =>
      from(postUserSignOut({ scope: 'user' }, action.payload)).pipe(
        mergeMap((result) => {
          console.log('logout', result.status);
          return empty();
        }),
        catchError(() => logoutError()),
        startWith(logoutSuccess())
      )
    )
  );
