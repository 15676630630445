import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { store } from '../../index';
import { Button, FormLabel, LinearProgress, Grid } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import { Formik, Form, Field } from 'formik';
import { fieldToTextField } from 'formik-material-ui';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';

import CollapseAlert from '../Alerts/CollapseAlert';
import {
  postUserGetReview,
  postUserEditReview,
  getUserProfileTimezone,
  getUserApps,
} from '../../services/authentication';
import { Modal } from '../../actions/modal';
import { notify } from '../../actions/notification';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px 16px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const StyleForm = styled.div`
  // width: 736px;
  margin-left: auto;
  margin-right: auto;
`;

const FullwidthContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  width: 904.9px;
  // margin-left: -100px;
`;

const ButtonContainer = styled.div`
  margin-bottom: 8px;
  flex-direction: column;
  width: 904.9px;
  text-align: right;
`;

const StyledFormLabel = styled(FormLabel)`
  && {
    font-size: 20px;
    color: #707070;
    padding: 16px 0 0;
  }
`;

const StyleLinearProgress = styled(LinearProgress)`
  width: 100%;
  margin: 8px 0 0;
`;

const ResponseMessage = styled.div`
  margin: 0px 0px 12px;
`;

const InfoGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-left: 220px;
  margin-top: 100px;
`;

const InfoUpperContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DividerGrid = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-left: 38px;
`;

const ImageGrid = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Image = styled.img`
  height: 92.5px;
  width: 92.5px;
`;

const ImageDefaultContainer = styled.div`
  height: 92.5px;
  width: 92.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d1d1d1;
`;

const ImageDefault = styled.p`
  color: #3e3a39;
`;

const Title = styled.div`
  font-size: 23px;
  font-weight: bold;
  color: #3e3a39;
  margin-top: 32px;
  margin-bottom: 4px;
  width: 100%;
`;

const RatingGrid = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledRating = withStyles({
  iconFilled: {
    color: '#0cbaef',
  },
  iconHover: {
    color: '#0cbaef',
  },
  root: {
    marginLeft: 8,
  },
})(Rating);

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    // width: '100%', // Fix IE 11 issue.
    // margin: theme.spacing(2, 0, 2),
    // paddingLeft: theme.spacing(6),
    // paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(2),
    flexDirection: 'column',
    // display: 'flex',
    // width: '904.9px',
    // height: '384px'
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    background: '#29a9e0',
    textTransform: 'none',
    fontSize: '1rem',
    '&:hover': {
      background: '#0372ba',
    },
    // alignItems: 'right',
    // justifyContent: 'right',
    borderRadius: '100px',
    minWidth: '101.1px',
    height: '28.8px',
  },
  cancel: {
    margin: theme.spacing(2, 2, 2),
    background: '#d1d1d1',
    color: '#fff',
    textTransform: 'none',
    fontSize: '1rem',
    // alignItems: 'right',
    // justifyContent: 'right',
    '&:hover': {
      background: '#0372ba',
    },
    borderRadius: '100px',
    minWidth: '101.1px',
    height: '28.8px',
  },
}));

function stringBytes(c) {
  var n = c.length,
    s;
  var len = 0;
  for (var i = 0; i < n; i++) {
    s = c.charCodeAt(i);
    while (s > 0) {
      len++;
      s = s >> 8;
    }
  }
  return len;
}

const ReviewEdit = () => {
  const classes = useStyles();
  const { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const messageInitState = {
    type: 'error',
    message: '',
    open: false,
  };
  const [message, setMessage] = useState(messageInitState);
  const [data, setData] = useState({
    app: { license: { available: 0, bind: 0 } },
    devices: [],
    recommend: [],
  });
  const [dataProfile, setDataProfile] = useState({
    profile: {
      fullname: '',
      email: '',
      phone: '',
      address: '',
    },
  });
  const [rating, setRating] = useState(1);
  const [reviewDetail, setReviewDetail] = useState('');

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');

  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getUserApps(
          id,
          {
            params: { lng: i18n.language },
          },
          user.token.accessToken
        );
        setData(result.data);
      } catch (e) {
        setData({
          app: { license: { available: 0, bind: 0 } },
          devices: [],
          recommend: [],
        });
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };

    var fetchDataReview = async () => {
      try {
        const result = await postUserGetReview(
          {
            data: {
              product_uid: id
            },
          },
          user.token.accessToken
        );

        setRating(result.data.data.rating)
        setReviewDetail(result.data.data.review_detail)
      } catch (e) {
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };

    if (user.token === null) {
      if (ticket === null) {
        dispatch(Modal('', true));
      }
    }

    fetchData();
    fetchDataReview();
  }, [user, i18n, message, dispatch, ticket, id]);

  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getUserProfileTimezone(
          {
            params: {
              lng: i18n.language,
            },
          },
          user.token.accessToken
        );
      } catch (e) {
        console.log(e);
      }
    };
    if (user.token !== null) {
      fetchData();
    }
  }, [i18n.language, user]);

  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <InfoGrid>
            <InfoContainer>
              <InfoUpperContainer>
                <ImageGrid item xs={2}>
                  {data.app.image ? (
                    <Image src={data.app.image} alt={data.app.name} />
                  ) : (
                    <ImageDefaultContainer>
                      <ImageDefault>KNEO</ImageDefault>
                    </ImageDefaultContainer>
                  )}
                </ImageGrid>
                <DividerGrid item xs={10}>
                  <Title>{data.app.name}</Title>
                </DividerGrid>
              </InfoUpperContainer>
            </InfoContainer>
            <RatingGrid item xs={4}>
              <RatingContainer>
                <StyledRating
                  name="read-only"
                  value={rating}
                  onChange={(event, newValue) => {
                    if (newValue === null) return; // 0 star is not allow
                    setRating(newValue);
                  }}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />
              </RatingContainer>
            </RatingGrid>
          </InfoGrid>
          <StyleForm>
            <Formik
              initialValues={{
                reviewDetail: reviewDetail,
              }}
              enableReinitialize
              validate={(values) => {
                const errors = {};
                let strBytes = stringBytes(values.reviewDetail)
                let strLines = values.reviewDetail.split(/\r\n|\r|\n/).length
                if (
                  strBytes > 250
                ) {
                  errors.reviewDetail = 'Must be 250 characters or less (' + strBytes + '/250)';
                } else if (
                  strLines > 8
                ) {
                  errors.reviewDetail = 'Must be less than or equal to 8 lines(' + strLines + '/8)';
                }
                // console.log(errors);
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                let reviewDetail = values.reviewDetail.replace(
                  /(?=[^ -~]+)(?=[^\p{L} 0-9])/g, ''
                );

                const apiFetch = async () => {
                  try {
                    const result = await postUserEditReview(
                      {
                        data: {
                          product_uid: id,
                          rating: rating,
                          review_detail: reviewDetail
                        },
                      },
                      user.token.accessToken
                    );
                    if (result.status === 'ok') {
                      setSubmitting(false);
                      setMessage({
                        type: 'success',
                        open: true,
                        message: 'message.200',
                      });
                    }
                  } catch (e) {
                    setSubmitting(false);
                    if (e && e.response) {
                      switch (e.response.status) {
                        case 400:
                          setMessage({
                            type: 'error',
                            open: true,
                            message: 'message.400',
                          });
                          break;
                        case 401:
                          setMessage({
                            type: 'error',
                            open: true,
                            message: 'message.401',
                          });
                          dispatch(Modal('', true));
                          break;
                        case 500:
                          setMessage({
                            type: 'error',
                            open: true,
                            message: 'message.500',
                          });
                          break;
                        default:
                          setMessage({
                            type: 'error',
                            open: true,
                            message: 'message.error',
                          });
                          break;
                      }
                    }
                  }
                };
                if (id.startsWith('SW')) {
                  apiFetch().then(history.push(`/users/appList`));
                } else {
                  apiFetch().then(history.push(`/users/deviceList`));
                }
              }}
            >
              {({
                isSubmitting,
                touched,
                errors,
                values,
                setFieldValue,
                setFieldTouched,
                handleBlur,
              }) => (
                <Form className={classes.form}>
                  <FullwidthContainer>
                    <StyledFormLabel>
                      {/* {t('profile.label.email')} */}
                    </StyledFormLabel>
                    <Field
                      component={StyleTextField}
                      multiline
                      rows={9}
                      rowsMax={9}
                      variant="outlined"
                      margin="normal"
                      // fullWidth
                      name="reviewDetail"
                      id="reviewDetail"
                      autoComplete="reviewDetail"
                    />
                  </FullwidthContainer>
                  {isSubmitting && <StyleLinearProgress />}
                  <ButtonContainer>
                    <Button
                      type="button"
                      variant="contained"
                      color="default"
                      className={classes.cancel}
                      onClick={() => {
                        history.push(id.startsWith('SW') ? `/users/appList` : `/users/deviceList`);
                      }}
                    >
                      {t('review.button.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      // fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={isSubmitting}
                    >
                      {t('review.button.submit')}
                    </Button>
                  </ButtonContainer>

                  <ResponseMessage>
                    <CollapseAlert
                      type={message.type}
                      message={message.message}
                      open={message.open}
                      close={() => {
                        if (message.type === 'success') {
                          setMessage({
                            type: 'success',
                            message: '',
                            open: false,
                          });
                        } else {
                          setMessage(messageInitState);
                        }
                      }}
                    />
                  </ResponseMessage>
                </Form>
              )}
            </Formik>
          </StyleForm>
        </ContentWrapper>
      </Wrapper>
    </Fragment>
  );
};

function StyleTextField(props) {
  const { t } = useTranslation();
  return (
    <MuiTextField
      {...fieldToTextField(props)}
      InputProps={{
        style: {
          marginBottom: 8,
        },
      }}
      helperText={
        props.form.touched[props.field.name] &&
        t(props.form.errors[props.field.name])
      }
    />
  );
}

export default ReviewEdit;
