import { createApiClient } from './apiClient';

const apiClient = createApiClient();

export const getLandingPage = async (props) => {
  const response = await apiClient.get('/landing-page', props);
  return response.data;
};

export const getLegalPage = async (props) => {
  const response = await apiClient.get('/legal-page', props);
  return response.data;
};

export const getPrivacyPage = async (props) => {
  const response = await apiClient.get('/privacy-page', props);
  return response.data;
};
