import { createApiClient } from './apiClient';

const apiClient = createApiClient();

export const postBilling = async (props, headers) => {
  const response = await apiClient.post('/users/payment', props, headers);
  return response.data;
};

export const postBillingFinish = async (props, headers) => {
  const response = await apiClient.post('/users/paymentFinish', props, headers);
  return response.data;
};

export const postBillingApprove = async (props, headers) => {
  const response = await apiClient.post(
    '/users/paymentApprove',
    props,
    headers
  );
  return response.data;
};

export const getBillingRegion = async (props) => {
  const response = await apiClient.get('/bill/region', props);
  return response.data;
};

export const getBillingRegionCity = async (props) => {
  const response = await apiClient.get('/bill/region/city', props);
  return response.data;
};

export const getBillingRegionCityDistrict = async (props) => {
  const response = await apiClient.get('/bill/region/city/district', props);
  return response.data;
};

export const getOrderResult = async (props) => {
  const response = await apiClient.get('/users/orderResult', props);
  return response.data;
};

export const getOrderInternational = async (props) => {
  const response = await apiClient.get('/users/orderInternational', props);
  return response.data;
};

export const getOrderDetail = async (props) => {
  const response = await apiClient.get('/users/orderDetail', props);
  return response.data;
};

export const getSubOrders = async (props) => {
  const response = await apiClient.get('/users/suborderDetail', props);
  return response.data;
};

export const getCurrency = async (props) => {
  const response = await apiClient.post('/bill/currency', props);
  return response.data;
};

export const postCoupons = async (props, language) => {
  const response = await apiClient.post('/coupons/info', props, {
    params: {
      lng: language,
    },
  });
  return response.data;
};
