import { createApiClient, createKneronApiClient } from './apiClient';

const apiClient = createApiClient();
const kneronApiClient = createKneronApiClient();

export const getProductSoftwareCategory = async (props) => {
  const response = await apiClient.get('/products/software-category', props);
  return response.data;
};

export const getProductHardwareCategory = async (props) => {
  const response = await apiClient.get('/products/hardware-category', props);
  return response.data;
};

export const getProductSoftware = async (id, versionIndex, props) => {
  const response = await apiClient.get(
    `/products/software/${id}/${versionIndex}`,
    props
  );
  return response.data;
};

export const getProductHardware = async (id, versionIndex, props) => {
  const response = await apiClient.get(
    `/products/hardware/${id}/${versionIndex}`,
    props
  );
  return response.data;
};

export const getProductReview = async (id, props) => {
  const response = await apiClient.get(`/products/review/${id}`, {
    params: props.params,
  });
  return response.data;
};

export const getCartRecommend = async (props) => {
  const response = await apiClient.get('/carts/recommend', props);
  return response.data;
};

export const getWishCount = async (id) => {
  const response = await apiClient.get(`/wish/count/${id}`);
  return response.data;
};

export const getDemoFileResult = async (props, fullURL) => {
  const response = await kneronApiClient.post(fullURL, props);
  return response.data;
};

export const getSearchProducts = async (props) => {
  const response = await apiClient.get(`/products/search`, {
    params: props.params,
  });
  return response.data;
};
