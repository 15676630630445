import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { store } from '../../index';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getUserAppList } from '../../services/authentication';
import Purchase from '../../resources/images/purchase.png';
import { ButtonBase } from '@material-ui/core';
import DownloadButton from '../../resources/images/download-available.png';
import ReviewButton from '../../resources/images/review.png';
import { notify } from '../../actions/notification';
import { Modal } from '../../actions/modal';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px 16px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

const CustomTabs = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d1d1d1;
  justify-content: space-between;
`;

const CustomTab = styled.div`
  height: 50px;
  padding: 8px;
  position: relative;
`;

const TabTitle = styled.div`
  color: #d1d1d1;
  font-size: 18px;
  padding: 2px 16px;
  ${({ active }) =>
    active &&
    `
    background: #0cbaef;
    color: #ffffff;
    border-radius: 20px;
    padding: 2px 16px;
  `}
`;

const TitleLink = styled(Link)`
  text-decoration: none;
`;

const DeviceWrapper = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const DeviceTitleIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0;
`;

const DeviceIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 20px;
`;

const TitleIconLink = styled(Link)`
  width: 65px;
  height: 65px;
  background: #1dbaef;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleIconImage = styled.img`
  width: 32px;
`;

const TitleIconTitle = styled.div`
  color: #9fa0a0;
  font-size: 16px;
  margin-top: 11px;
`;

const TitleContainer = styled.div`
  margin: 0 4px;
  padding: 0 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #29a9e0;
  border-radius: 20px;
  height: 36px;
`;

const TitleModel = styled.div`
  width: 54%;
  text-indent: 30%;
  font-size: 18px;
  color: #ffffff;
`;

const TitleDownload = styled.div`
  width: 22%;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
`;

const TitleReview = styled.div`
  width: 22%;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 8px;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #d1d1d1;
`;

const ItemModel = styled.div`
  width: 54%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 60px;
  width: 96px;
  height: 96px;
  ${({ image }) =>
    image === '' &&
    `
    background: #d1d1d1;
  `}
`;

const ItemImage = styled.img`
  width: 96px;
  height: 100%;
  object-fit: scale-down;
  ${({ isPhaseout }) =>
  isPhaseout &&
    `
    -webkit-filter:grayscale(1);
  `}
`;

const ImageDefaultContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 96px;
`;

const ImageDefault = styled.p`
  color: #3e3a39;
`;

const ItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 40px;
`;

const ItemInfoName = styled.div`
  font-size: 17px;
  color: #3e3a39;
  font-weight: bold;
  ${({ isPhaseout }) =>
  isPhaseout &&
    `
    text-decoration:line-through 2px;
  `}
`;

const AppItemAvailableLicense = styled.div`
  font-size: 16px;
  color: #9fa0a0;
  margin: 4px 0;
  ${({ license }) =>
    license > 0 &&
    `
    color: #0cbaef;
  `}
`;

const AppItemBindingLicense = styled.div`
  font-size: 16px;
  color: #9fa0a0;
`;

const ItemDownload = styled.div`
  width: 22%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EmptyDeviceContainer = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

const EmptyTitleContainer = styled.div`
  text-align: center;
  font-size: 28px;
  color: #9fa0a0;
  font-weight: bold;
  white-space: pre-line;
  margin-top: 132px;
  margin-bottom: 45px;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 144px;
`;

const IconContainer = styled.div`
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconLink = styled(Link)`
  width: 197px;
  border-radius: 200px;
  text-decoration: none;
`;

const Icon = styled.div`
  width: 197px;
  height: 197px;
  display: flex;
  flex-direction: column;
  background: #1dbaef;
  border-radius: 200px;
  align-items: center;
  justify-content: center;
`;

const IconImage = styled.img`
  width: 90px;
`;

const IconTitle = styled.div`
  font-size: 25px;
  color: #ffffff;
  margin-top: 18px;
`;

const IconDescription = styled.div`
  margin-top: 60px;
  color: #9fa0a0;
  font-size: 22px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  margin: calc(50vh - 45px) auto 0;
`;

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#9fa0a0',
    fontSize: '15px',
    fontWeight: '700'
  },
  arrow: {
    color: '#9fa0a0',
  },
})(Tooltip);

const AppList = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  let history = useHistory();
  const [data, setData] = useState({
    applist: [],
  });
  const [loading, setLoading] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState({});

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');

  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getUserAppList(
          {
            params: { lng: i18n.language },
          },
          user.token.accessToken
        );
        setData(result.data);
        setLoading(false);
      } catch (e) {
        setData({
          applist: [],
        });
        setLoading(false);
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };
    if (user.token === null) {
      if (ticket === null) {
        dispatch(Modal('', true));
      } else {
        var reLogin = setInterval(() => {
          if (store.getState().user.token) {
            fetchData();
            clearInterval(reLogin);
          }
        }, 100);
      }
    } else {
      fetchData();
    }
  }, [i18n, i18n.language, user, dispatch, ticket]);

  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <CustomTabs>
            <CustomTab>
              <TitleLink className="customTab" to="/users/profile">
                <TabTitle>{t('user.tab.title.profile')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/order">
                <TabTitle>{t('user.tab.title.order')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/wishList">
                <TabTitle>{t('user.tab.title.wishList')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/deviceList">
                <TabTitle>{t('user.tab.title.device')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/appList">
                <TabTitle active={true}>{t('user.tab.title.app')}</TabTitle>
              </TitleLink>
              <div className="triangle active" />
            </CustomTab>
          </CustomTabs>
          {data.applist.length === 0 ? (
            loading ? (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            ) : (
              <EmptyDeviceContainer>
                <EmptyTitleContainer>
                  {t('app.title.emptyTitle')}
                </EmptyTitleContainer>
                <IconsContainer>
                  <IconContainer>
                    <IconLink to="/products/software">
                      <Icon>
                        <IconImage src={Purchase} alt="" />
                        <IconTitle>{t('app.title.purchase')}</IconTitle>
                      </Icon>
                    </IconLink>
                    <IconDescription>
                      {t('app.description.purchase')}
                    </IconDescription>
                  </IconContainer>
                </IconsContainer>
              </EmptyDeviceContainer>
            )
          ) : (
            <DeviceWrapper>
              <DeviceTitleIconContainer>
                <DeviceIconContainer>
                  <TitleIconLink to="/products/software">
                    <TitleIconImage src={Purchase} alt="" />
                  </TitleIconLink>
                  <TitleIconTitle>{t('app.title.purchase')}</TitleIconTitle>
                </DeviceIconContainer>
              </DeviceTitleIconContainer>
              <TitleContainer>
                <TitleModel>{t('app.title.app')}</TitleModel>
                <TitleDownload>{t('app.title.download')}</TitleDownload>
                <TitleReview>{t('app.title.review')}</TitleReview>
              </TitleContainer>
              <ItemsContainer>
                {data.applist.map((item, index) => (
                  <ItemContainer key={index}>
                    <ItemModel>
                      <ItemImageContainer image={item.image}>
                        {item.image ? (
                          <ItemImage src={item.image} alt={item.name} isPhaseout={!item.purchasable}/>
                        ) : (
                          <ImageDefaultContainer>
                            <ImageDefault>KNEO</ImageDefault>
                          </ImageDefaultContainer>
                        )}
                      </ItemImageContainer>
                      <ItemInfoContainer>
                        <CustomTooltip
                            title={tooltipOpen[item.id] ? t("order.message.tip-phaseout") : ''}
                            placement="bottom"
                            arrow
                            open={true}
                            onOpen={() => {
                              setTooltipOpen({
                                ...tooltipOpen,
                                [item.id]: !item.purchasable,
                              });
                            }}
                            onClose={() => {
                              setTooltipOpen({
                                ...tooltipOpen,
                                [item.id]: false,
                              });
                            }}
                        >
                          <ItemInfoName isPhaseout={!item.purchasable}>{item.name}</ItemInfoName>
                        </CustomTooltip>
                        <AppItemAvailableLicense
                          license={item.license.available}
                        >
                          {`${t('app.title.availableLicense')}: ${
                            item.license.available
                          }`}
                        </AppItemAvailableLicense>
                        <AppItemBindingLicense>
                          {`${t('app.title.bindingLicense')}: ${
                            item.license.bind
                          }`}
                        </AppItemBindingLicense>
                      </ItemInfoContainer>
                    </ItemModel>
                    <ItemDownload>
                      <ButtonBase
                        onClick={() => {
                          history.push(`/users/apps/${item.id}`);
                        }}
                      >
                        <img src={DownloadButton} alt="" />
                      </ButtonBase>
                    </ItemDownload>
                    <ItemDownload>
                      <ButtonBase
                        onClick={() => {
                          if (item.commented) {
                            history.push(`/users/reviewEdit/${item.id}`);
                          } else {
                            history.push(`/users/review/${item.id}`);
                          }
                        }}
                      >
                        <img src={ReviewButton} alt="" />
                      </ButtonBase>
                    </ItemDownload>
                  </ItemContainer>
                ))}
              </ItemsContainer>
            </DeviceWrapper>
          )}
        </ContentWrapper>
      </Wrapper>
    </Fragment>
  );
};

export default AppList;
