import React, { useState } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import VideoDialog from './LandingPageVideoDialog';
import NextArrow from '../../resources/images/next-arrow.png';
import PrevArrow from '../../resources/images/prev-arrow.png';
import VideoPlay from '../../resources/images/videoPlay.png'
import {
  Button
} from '@material-ui/core';

const StyleCarousel = styled.div`
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

const SlickImg = styled.img`
  width: 1279px;
  height: 381px;
  object-fit: scale-down;
  border-bottom-left-radius: 20px 20px;
  border-bottom-right-radius: 20px 20px;
`;

const Arrow = styled.img`
  color: #898989;
  && {
    width: 16px;
    height: 32px;
  }
  &:hover {
    color: #999999;
    background: #f8f9fa;
    border-radius: 100px;
  }
`;

const PlayImg = styled.img`
  width: 110px;
  height: 110px;
`;

const LandingPageCarousel = (props) => {
  const { data } = props;
  const [nextSlideIndex, setNextSlide] = useState(0);
  const slickSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: false,
    swipe: false,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <Arrow src={NextArrow} alt="" />,
    prevArrow: <Arrow src={PrevArrow} alt="" />,
    beforeChange: (current, next) => {
      setNextSlide(next);
    },
  };
  const [videoDialogOpen, setVideoDialogOpen] = useState({
    play: false,
    src: ""
  });
  return (
    <>
      <VideoDialog
        open={videoDialogOpen}
        close={() => {
          setVideoDialogOpen({
            ...videoDialogOpen,
            play: false
          });
        }}
      />
      <StyleCarousel>
        <Slider {...slickSettings}>
          {data.map((item, index) => (
            <div key={index}>
              <SlickImg
                src={item.image}
                alt=""
                onClick={() => {
                  if (item.annex === "") return;
                  setVideoDialogOpen({
                    ...videoDialogOpen,
                    play: true,
                    src: item.annex
                  });
                }}
              >
              </SlickImg>
            </div>
          ))}
        </Slider>
        <Button
          style={{
            position: "absolute",
            top: "120px",
            left: "550px",
            width: "200px",
            borderRadius: "20px",
            display: data[nextSlideIndex].annex === "" ? "none" : ""
          }}
          onClick={() => {
            if (data[nextSlideIndex].annex === "") return;
            setVideoDialogOpen({
              ...videoDialogOpen,
              play: true,
              src: data[nextSlideIndex].annex
            });
          }}
        >
          <PlayImg src={VideoPlay}></PlayImg>
        </Button>
      </StyleCarousel>
    </>
  );
};

export default LandingPageCarousel;
