import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Button, LinearProgress, CircularProgress, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiTextField from '@material-ui/core/TextField';
import { fieldToTextField } from 'formik-material-ui';
import {
  postOrderQA,
  getOrderQAList,
  getUserProfile
} from '../../services/authentication';
import { Modal } from '../../actions/modal';
import { notify } from '../../actions/notification';
import { Formik, Form, Field } from 'formik';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { store } from '../../index';
import CollapseAlert from '../Alerts/CollapseAlert';
import {
  getDateString
} from '../../helpers/timezone'

const Wrapper = styled.div`
  display: block;
  margin: auto;
  width: 858px;
  margin-top: 90px;
  margin-bottom: auto;
`;

const HeaderTitle = styled.div`
  display: inline;
  width: 72px;
  height: 25px;
  font-size: 18px;
  margin-right: 20.3px;
  line-height: 1.32;
`;

const HeaderContent = styled.div`
  display: inline;
  width: 90px;
  height: 23px;
  font-size: 20px;
  line-height: 1.32;
  margin-right: 41.8px;
  color: #9fa0a0;
`;

const HeaderDiagramWrap = styled.div`
  display: flex;
  width: 100%;
  height: 74px;
  margin-bottom: 29.7px;
  margin-top: 43.3px;
`;

const ImageDiagram = styled.img`
  width: 74px;
  height: 74px;
  margin-left: 34.6px;
  object-fit: cover;
`;

const DescriptionWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
  margin-left: 34.6px;
`;

const DescriptionTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.13;
`;

const DescriptionContentType = styled.div`
  line-height: 1.33;
  text-align: left;
  font-size: 15px;
  color: #9fa0a0;
  margin-top: 6.1px;
`;

const DescriptionContent = styled.div`
  line-height: 1.0;
  text-align: left;
  font-size: 13px;
  height: 30px;
  color: #9fa0a0;
  margin-top: 4.7px;
  width: 300px;
  overflow: hidden;
  word-break: normal;
	word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行數
  -webkit-box-orient: vertical;
`

const BodyWrapper = styled.div`
  width: 100%
  height: 638px;
  margin-top: 31px;
  margin-bottom: 200px;
`

const MessageWrapper = styled.div`
  display:flex;
  align-items: center;
  width: 100%;
  border-top: solid 1px #e6e6e6;
  background-color: #ffffff;
  ${({ reply, isReply }) => 
    (reply && !isReply) &&
    `
      display: none;
    ` 
  }
  ${({ reply }) =>
    reply &&
    `
      background-color: #f8f7f7;
      border-top: solid 1px #ffffff
    `
  }
  ${({ index, reply  }) =>
    (!reply) &&
    `margin-top: 50px;`
  }
  ${({ index, reply }) =>
    (index === 3 && reply) &&
    `
      margin-bottom: 10px;
      border-top: solid 1px #ffffff
    `    
  }
`

const UserName = styled.div`
  font-size: 25px;
  display: inline;
  width: 40px;
  height: 40px;
  padding: auto 11px auto 11px;
  margin-right: 15.7px;
  margin-top: 20px;
  background-color: #0cbaef;
  border-radius: 50%;
  box-sizing: border-box;
  text-align: center;
  line-height: 40px;
  color: #ffffff;
  align-self: flex-start;
  overflow: hidden;
  ${({ reply }) =>
    reply &&
    `
      margin-left: 93.7px;
      background-color: #ef980c;
    `
  }
`

const MessageContent = styled.div`
  display: inline;
  padding: 20px 0;
  width: 478px;
  font-size: 18px;
  line-height: 1.22;
  color: #707070;
  word-break: normal;
	word-wrap: break-word;
`

const MessageTime = styled.div`
  font-size: 18px;
  margin: 0 28px 0 auto;
  color: #d1d1d1;
`

const ButtonContainer = styled.div`
  margin-bottom: 8px;
  flex-direction: column;
  width: 100%;
  text-align: right;
`;

const StyleForm = styled.div`
  position: fixed;
  bottom:0px;
  left: 0px;
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center; 
  align-items: center;
  z-index: 11;
`;

const FullwidthContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  width: 858px;
`;

const StyleLinearProgress = styled(LinearProgress)`
  width: 100%;
  margin: 8px 0 0;
`;

const PagingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 858px;
  margin-left: auto;
  margin-right: auto;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PagingIconButton = styled(IconButton)`
  && {
    padding: 0;
  }
`;

const CustomNavigateBeforeIcon = styled(NavigateBeforeIcon)`
  height: 16px;
  color: #939393;
  && {
    padding: 8px;
  }
`;

const PagingNumber = styled(Button)`
  && {
    margin: 0 8px;
    color: #939393;
    font-size: 20px;
    min-width: 20px;
    padding: 0;
    ${({ current }) =>
      current === 'true' &&
      `
      color: #0cbaef;
    `}
  }
`;

const CustomNavigateNextIcon = styled(NavigateNextIcon)`
  height: 16px;
  color: #939393;
  && {
    padding: 8px;
  }
`;

const PagingInstruction = styled.div`
  font-size: 16px;
  color: #9fa0a0;
  margin-right: 6px;
  margin-left: 12px;
`;

const PagingInput = styled.input`
  width: 58px;
  height: 22px;
  border-radius: 20px;
  border: 1px solid #cacaca;
  text-align: center;
  color: #d1d1d1;
  outline: none;
  font-size: 16px;
  line-height: 25px;
  margin-right: 6px;
  margin-left: 10px;
`;

const PagingButton = styled(Button)`
  && {
    margin-left: 6px;
    margin-right: 10px;
    height: 26px;
    font-size: 16px;
    color: #ffffff;
    background: #cacaca;
    border-radius: 20px;
    border: 1px solid #cacaca;
    line-height: 23px;
    &:hover {
      color: #cacaca;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  margin: calc(50vh - 45px) auto 0;
`;

const ResponseMessage = styled.div`
  margin: 0px 0px 12px;
`;

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(2, 0, 2),
    background: '#29a9e0',
    textTransform: 'none',
    fontSize: '1rem',
    '&:hover': {
      background: '#0372ba',
    },
    borderRadius: '100px',
    minWidth: '101.1px',
    height: '28.8px',
  },
  cancel: {
    margin: theme.spacing(2, 2, 2),
    background: '#d1d1d1',
    color: '#fff',
    textTransform: 'none',
    fontSize: '1rem',
    '&:hover': {
      background: '#0372ba',
    },
    borderRadius: '100px',
    minWidth: '101.1px',
    height: '28.8px',
  },
  form: {
    // width: '100%', // Fix IE 11 issue.
    // margin: theme.spacing(2, 0, 2),
    // paddingLeft: theme.spacing(6),
    // paddingRight: theme.spacing(6),
    // paddingTop: theme.spacing(2),
    flexDirection: 'column',
    // display: 'flex',
    // width: '904.9px',
    // height: '384px'
  },
}));

const OrderQA = () => {
  const Length = 5;
  const classes = useStyles();
  const { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const [data, setData] = useState({
    items: [],
    questions: [],
    orderDate: ""
  });
  const [page, setPage] = useState(1);
  const [pageIndex, setPageIndex] = useState([]);
  const [number, setNumber] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataProfile, setDataProfile] = useState({
    profile: {
      fullname: '',
      email: '',
      phone: '',
      address: '',
      timezone: 0
    },
  });
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');
  const messageInitState = {
    type: 'error',
    message: '',
    open: false,
  };
  const [message, setMessage] = useState(messageInitState);

  useEffect(() => {
    var fetchData = async () => {
      try {
        setLoading(true);
        const result = await getOrderQAList(
          {
            params: { 
              id,
              index: (page - 1) * Length,
              length: Length,
              sorting: "desc"
             },
          },
          user.token.accessToken
        );
        var index = Math.ceil(result.data.length / Length);
        var indexArray = [];
        for (var i = 0; i < index; i++) {
          indexArray.push(i + 1);
        }
        setPageIndex(indexArray);
        setLoading(false);
        setData({
          items: result.data.order.items,
          questions: result.data.questions === null ? [] : result.data.questions,
          orderDate: result.data.order.date
        });
      } catch (e) {
        setData({
          items: [],
          questions: [],
          orderDate: ""
        });
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };

    var fetchDataProfile = async () => {
      try {
        const result = await getUserProfile(user.token.accessToken);
        setDataProfile(result.data);
      } catch (e) {
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };

    if (user.token === null) {
      if (ticket === null) {
        dispatch(Modal('', true));
      } else {
        var reLogin = setInterval(() => {
          if (store.getState().user.token) {
            fetchDataProfile();
            clearInterval(reLogin);
          }
        }, 100);
      }
    } else {
      fetchDataProfile();
    }
    fetchData();
  }, [user, i18n, dispatch, ticket, id, page, message]);

  function stringBytes(c) {
    var n = c.length,
      s;
    var len = 0;
    for (var i = 0; i < n; i++) {
      s = c.charCodeAt(i);
      while (s > 0) {
        len++;
        s = s >> 8;
      }
    }
    return len;
  }

  return (
    <Fragment>
      <Wrapper>
        <HeaderTitle>{t("orderResult.label.id")}</HeaderTitle>
        <HeaderContent>{id}</HeaderContent>
        <HeaderTitle>{t("orderResult.label.date")}</HeaderTitle>
        <HeaderContent>{data.orderDate}</HeaderContent>
        {
          data.items.map(item => (
            <HeaderDiagramWrap>
              <ImageDiagram src={item.image} alt="no image"></ImageDiagram>
              <DescriptionWrap>
                <DescriptionTitle>{item.name}</DescriptionTitle>
                <DescriptionContentType>{item.product}</DescriptionContentType>
                <DescriptionContent>{item.description}</DescriptionContent>
              </DescriptionWrap>
            </HeaderDiagramWrap>
          ))
        }
        {
          loading ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : (
            <BodyWrapper>
              {
                data.questions.map((q,i) => (
                  <div key={i}>
                    <MessageWrapper index={i} key={`buyer-wrap-${i}`}>
                      <UserName key={`buyer-name-${i}`}>Q</UserName>
                      <MessageContent key={`buyer-content-${i}`}>
                        {q.question}
                      </MessageContent>
                      <MessageTime key={`buyer-time-${i}`}>
                        {getDateString(q.questionTime, dataProfile.profile.timezone)}
                      </MessageTime>
                    </MessageWrapper>
                    <MessageWrapper reply={true} isReply={q.answerer !== ""} index={i} key={`seller-wrap${i}`}>
                      <UserName reply={true} key={`seller-name-${i}`}>A</UserName>
                      <MessageContent reply={true} key={`seller-content-${i}`}>
                        {q.answer}
                      </MessageContent>
                      <MessageTime key={`seller-time-${i}`}>
                        {getDateString(q.answerTime, dataProfile.profile.timezone)}
                      </MessageTime>
                    </MessageWrapper>
                  </div>
                ))
              }
              <PagingContainer>
                <PageContainer>
                  {page === 1 ? (
                    <></>
                  ) : (
                    <PagingIconButton
                      onClick={() => {
                        setPage(page - 1);
                      }}
                    >
                      <CustomNavigateBeforeIcon />
                    </PagingIconButton>
                  )}
                  {pageIndex.length !== 0 &&
                    pageIndex.map((item, index) => {
                      var pages;
                      if (pageIndex.length < 6) {
                        if (index < 5) {
                          pages = (
                            <PagingNumber
                              key={index}
                              current={index === page - 1 ? 'true' : 'false'}
                              onClick={() => {
                                setPage(item);
                              }}
                            >
                              {item}
                            </PagingNumber>
                          );
                        }
                      } else {
                        if (page === pageIndex[pageIndex.length - 1]) {
                          if (index >= pageIndex.length - 5) {
                            pages = (
                              <PagingNumber
                                key={index}
                                current={item === page ? 'true' : 'false'}
                                onClick={() => {
                                  setPage(item);
                                }}
                              >
                                {item}
                              </PagingNumber>
                            );
                          }
                        } else if (page === pageIndex[pageIndex.length - 2]) {
                          if (index >= pageIndex.length - 5) {
                            pages = (
                              <PagingNumber
                                key={index}
                                current={item === page ? 'true' : 'false'}
                                onClick={() => {
                                  setPage(item);
                                }}
                              >
                                {item}
                              </PagingNumber>
                            );
                          }
                        } else if (page <= 3) {
                          if (index < 5) {
                            pages = (
                              <PagingNumber
                                key={index}
                                current={item === page ? 'true' : 'false'}
                                onClick={() => {
                                  setPage(item);
                                }}
                              >
                                {item}
                              </PagingNumber>
                            );
                          }
                        } else {
                          if (item >= page - 2 && item <= page + 2) {
                            pages = (
                              <PagingNumber
                                key={index}
                                current={item === page ? 'true' : 'false'}
                                onClick={() => {
                                  setPage(item);
                                }}
                              >
                                {item}
                              </PagingNumber>
                            );
                          }
                        }
                      }
                      return pages;
                    })}
                  {page === pageIndex[pageIndex.length - 1] ? (
                    <></>
                  ) : (
                    <PagingIconButton
                      onClick={() => {
                        setPage(page + 1);
                      }}
                    >
                      <CustomNavigateNextIcon />
                    </PagingIconButton>
                  )}
                  <PagingInstruction>{t('order.label.to')}</PagingInstruction>
                  <PagingInput
                    onChange={(e) => {
                      setNumber(e.target.value);
                    }}
                  />
                  <PagingButton
                    onClick={() => {
                      console.log(number);
                      if (number === 0 || number === '0') {
                        setPage(1);
                      } else if (
                        /^[0-9]*$/i.test(number) &&
                        number > pageIndex[pageIndex.length - 1]
                      ) {
                        setNumber(pageIndex[pageIndex.length - 1]);
                      } else if (number === '') {
                      } else {
                        setPage(parseInt(number, 10));
                      }
                    }}
                  >
                    {t('order.button.go')}
                  </PagingButton>
                </PageContainer>
              </PagingContainer>
            </BodyWrapper>
          )
        }
        <StyleForm>
          <Formik
            initialValues={{
              question: '',
            }}
            enableReinitialize
            validate={(values) => {
              const errors = {};
              let strBytes = stringBytes(values.question)
              let strLines = values.question.split(/\r\n|\r|\n/).length
              if (
                strBytes > 250
              ) {
                errors.question = `${t("orderResult.message.qaInput.charLimit")}(${strBytes}/250).`;
              } else if (
                strLines > 8
              ) {
                errors.question = `${t("orderResult.message.qaInput.linesLimit")}(${strLines}/8).`;
              }
              // console.log(errors);
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              let question = values.question.replace(
                /(?=[^ -~]+)(?=[^\p{L} 0-9])/g, ''
              );

              const apiFetch = async () => {
                try {
                  const result = await postOrderQA(
                    {
                      data: {
                        id,
                        message: question
                      },
                    },
                    user.token.accessToken
                  );
                  if (result.status === 'ok') {
                    setSubmitting(false);
                    setMessage({
                      type: 'success',
                      open: true,
                      message: 'message.200',
                    });
                  }
                } catch (e) {
                  setSubmitting(false);
                  if (e && e.response) {
                    switch (e.response.status) {
                      case 400:
                        setMessage({
                          type: 'error',
                          open: true,
                          message: 'message.400',
                        });
                        break;
                      case 401:
                        setMessage({
                          type: 'error',
                          open: true,
                          message: 'message.401',
                        });
                        dispatch(Modal('', true));
                        break;
                      case 500:
                        setMessage({
                          type: 'error',
                          open: true,
                          message: 'message.500',
                        });
                        break;
                      default:
                        setMessage({
                          type: 'error',
                          open: true,
                          message: 'message.error',
                        });
                        break;
                    }
                  }
                }
              };
              apiFetch();
            }}
          >
            {({
              isSubmitting,
              values
            }) => (
              <Form className={classes.form}>
                <FullwidthContainer>
                  <Field
                    component={StyleTextField}
                    multiline
                    rows={3}
                    rowsMax={3}
                    variant="outlined"
                    // margin="normal"
                    // fullWidth
                    name="question"
                    id="question"
                    autoComplete="question"
                  />
                </FullwidthContainer>
                {isSubmitting && <StyleLinearProgress />}
                <ButtonContainer>
                  <Button
                    type="button"
                    variant="contained"
                    color="default"
                    className={classes.cancel}
                    onClick={() => {
                      history.push(`/users/order`);
                    }}
                  >
                    {t('review.button.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    // fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isSubmitting || values.question === ""}
                  >
                    {t('review.button.submit')}
                  </Button>
                </ButtonContainer>
                <ResponseMessage>
                  <CollapseAlert
                    type={message.type}
                    message={message.message}
                    open={message.open}
                    close={() => {
                      if (message.type === 'success') {
                        setMessage({
                          type: 'success',
                          message: '',
                          open: false,
                        });
                      } else {
                        setMessage(messageInitState);
                      }
                    }}
                  />
                </ResponseMessage>
              </Form>
            )}
          </Formik>
        </StyleForm>
      </Wrapper>
    </Fragment>
  );
};

function StyleTextField(props) {
  const { t } = useTranslation();
  return (
    <MuiTextField
      {...fieldToTextField(props)}
      InputProps={{
        style: {
          marginBottom: 8,
        },
      }}
      helperText={
        props.form.touched[props.field.name] &&
        t(props.form.errors[props.field.name])
      }
    />
  );
}

export default OrderQA;
