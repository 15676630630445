import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, IconButton, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { addToCart } from '../../actions/cart';
import {
  postUserAddWishList,
  postUserDropWishList,
} from '../../services/authentication';
import { notify } from '../../actions/notification';
import AddIcon from '@material-ui/icons/Add';
import { store } from '../../index';
import { getWishCount } from '../../services/productPage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0 16px;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;
`;

const InfoGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
`;

const InfoUpperContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DividerGrid = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-left: 38px;
`;

const ImageGrid = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Image = styled.img`
  height: 125px;
  width: 125px;
`;

const ImageDefaultContainer = styled.div`
  height: 125px;
  width: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d1d1d1;
`;

const ImageDefault = styled.p`
  color: #3e3a39;
`;

const Title = styled.div`
  font-size: 23px;
  font-weight: bold;
  color: #3e3a39;
  margin-top: 32px;
  margin-bottom: 4px;
  width: 100%;
`;

const Product = styled.div`
  font-size: 19px;
  color: #9fa0a0;
  margin-top: 4px;
  margin-bottom: 8px;
  width: 100%;
`;

const InfoLowerContainer = styled.div`
  margin-top: 24px;
`;

const Description = styled.div`
  font-size: 15px;
  color: #595757;
  line-height: 2;
  padding-right: 32px;
  white-space: pre-line;
  width: 338px;
`;

const RatingGrid = styled(Grid)`
  && {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;

const RatingContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const WishButton = styled(IconButton)`
  padding: 0;
`;

const WishBorderIcon = styled(FavoriteBorderIcon)`
  color: #d1d1d1;
`;

const WishIcon = styled(FavoriteIcon)`
  color: #d1d1d1;
`;

const WishCount = styled.div`
  font-size: 18px;
  line-height: 18px;
  color: #595757;
  margin-right: 16px;
  margin-left: 8px;
`;

const BuyButton = styled(Button)`
  && {
    padding: 0;
    min-width: 124px;
    border-radius: 100px;
    background: #0cbaef;
    color: #ffffff;
    margin-right: 12px;
    margin-left: 8px;
    &:hover {
      color: #3e3a39;
    }
  }
`;

const AddButton = styled(Button)`
  && {
    padding: 0;
    min-width: 24px;
    border-radius: 100px;
    background: #ef980c;
    color: #ffffff;
    &:hover {
      color: #3e3a39;
    }
  }
`;

const ItemAddIcon = styled(AddIcon)`
  && {
    font-size: 24px;
  }
`;

const Buttons = styled.div`
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const ButtonsGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

const ComingSoon = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #0cbaef;
  // margin-left: 10px;
`;

const CouponContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 313px;
  justify-content: flex-end;
`;

const CouponGrid = styled.div`
  width: 100%;
  height: 94px;
  margin-bottom: 48px;
  flex-direction: column;
`;

const CouponUpper = styled.div`
  height: 36px;
  display: flex;
  flex-direction: row;
`;

const CouponTitleContainer = styled.div`
  height: 100%;
  width: 119px;
  background: #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CouponTitle = styled.div`
  color: #ffffff;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 1px;
  font-weight: bold;
  text-align: center;
`;

const CouponDateContainer = styled.div`
  height: 100%;
  width: 194px;
  background: #ef980c;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CouponDate = styled.div`
  color: #ffffff;
  font-size: 18px;
  line-height: 38px;
  font-weight: bold;
  text-align: center;
`;

const CouponLower = styled.div`
  // height: 58px;
  height: calc(100% - 36px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #ef980c;
  border-right: 1px solid #ef980c;
  border-bottom: 1px solid #ef980c;
`;

const CouponMessage = styled.div`
  font-size: 18px;
  // line-height: 38px;
  color: #ef980c;
  font-weight: bold;
  text-align: center;
`;

const StyledRating = withStyles({
  iconFilled: {
    color: '#0cbaef',
  },
  iconHover: {
    color: '#0cbaef',
  },
  root: {
    marginLeft: 8,
  },
})(Rating);

const Information = (props) => {
  const { i18n, t } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { data, coupon } = props;
  const [wish, setWish] = useState(false);
  const [wishCount, setWishCount] = useState('');
  useEffect(() => {
    setWish(data.wish);
  }, [data]);

  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getWishCount(data.id);
        setWishCount(result.data.wishCount);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, [data.id, wish]);
  return (
    <Wrapper>
      <InfoGrid>
        <InfoContainer>
          <InfoUpperContainer>
            <ImageGrid item xs={2}>
              {data.image ? (
                <Image src={data.image} alt={data.name} />
              ) : (
                <ImageDefaultContainer>
                  <ImageDefault>KNEO</ImageDefault>
                </ImageDefaultContainer>
              )}
            </ImageGrid>
            <DividerGrid item xs={10}>
              <Title>{data.name}</Title>
              <Product>{data.product}</Product>
            </DividerGrid>
          </InfoUpperContainer>
          <InfoLowerContainer>
            <Description item xs={4}>
              {data.description}
            </Description>
          </InfoLowerContainer>
        </InfoContainer>
        {/* <RatingGrid item xs={12}>
          <RatingContainer>
            <StyledRating
              name="read-only"
              value={data.rating === undefined ? 0 : data.rating}
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
            />
          </RatingContainer>
        </RatingGrid> */}
        <CouponContainer>
          {coupon && (
            <CouponGrid>
              <CouponUpper>
                <CouponTitleContainer>
                  <CouponTitle>{data.coupon.title}</CouponTitle>
                </CouponTitleContainer>
                <CouponDateContainer>
                  <CouponDate>{`${data.coupon.start}~${data.coupon.end}`}</CouponDate>
                </CouponDateContainer>
              </CouponUpper>
              <CouponLower>
                <CouponMessage>{data.coupon.description}</CouponMessage>
              </CouponLower>
            </CouponGrid>
          )}
          {data.preview && (
            <ButtonsGrid>
              <Buttons>
                {!data.review && (
                  <>
                    <WishButton
                      onClick={() => {
                        if (user.token !== null) {
                          const apiAddWishList = async () => {
                            try {
                              const result = await postUserAddWishList(
                                {
                                  data: [data.id],
                                },
                                user.token.accessToken
                              );
                              if (result.status === 'ok') {
                                setWish(true);
                              }
                            } catch (e) {
                              if (e && e.response) {
                                switch (e.response.status) {
                                  case 400:
                                    dispatch(
                                      notify(
                                        'error',
                                        i18n.t('message.400'),
                                        true
                                      )
                                    );
                                    break;
                                  case 401:
                                    dispatch(
                                      notify(
                                        'info',
                                        t('product.software.message.wish'),
                                        true
                                      )
                                    );
                                    break;
                                  case 500:
                                    dispatch(
                                      notify(
                                        'error',
                                        i18n.t('message.500'),
                                        true
                                      )
                                    );
                                    break;
                                  default:
                                    dispatch(
                                      notify(
                                        'error',
                                        i18n.t('message.error'),
                                        true
                                      )
                                    );
                                }
                              }
                            }
                          };
                          const apiDropWishList = async () => {
                            try {
                              await postUserDropWishList(
                                {
                                  data: {
                                    data: [data.id],
                                    query: 'n',
                                  },
                                },
                                user.token.accessToken
                              );
                              setWish(false);
                            } catch (e) {
                              if (e && e.response) {
                                switch (e.response.status) {
                                  case 400:
                                    dispatch(
                                      notify(
                                        'error',
                                        i18n.t('message.400'),
                                        true
                                      )
                                    );
                                    break;
                                  case 401:
                                    dispatch(
                                      notify(
                                        'info',
                                        t('product.software.message.wish'),
                                        true
                                      )
                                    );
                                    break;
                                  case 500:
                                    dispatch(
                                      notify(
                                        'error',
                                        i18n.t('message.500'),
                                        true
                                      )
                                    );
                                    break;
                                  default:
                                    dispatch(
                                      notify(
                                        'error',
                                        i18n.t('message.error'),
                                        true
                                      )
                                    );
                                }
                              }
                            }
                          };
                          if (wish) {
                            apiDropWishList();
                          } else {
                            apiAddWishList();
                          }
                        } else {
                          dispatch(
                            notify(
                              'info',
                              t('product.software.message.wish'),
                              true
                            )
                          );
                        }
                      }}
                    >
                      {wish ? (
                        <WishIcon style={{ fontSize: 30, color: '#FF0000' }} />
                      ) : (
                        <WishBorderIcon style={{ fontSize: 30 }} />
                      )}
                    </WishButton>
                    <WishCount>{numberFormatter(wishCount)}</WishCount>
                  </>
                )}
                {data.display || data.review ? (
                  <>
                    <BuyButton
                      variant="contained"
                      disableElevation
                      onClick={() => {
                        dispatch(
                          addToCart(
                            data.id,
                            data.image,
                            data.name,
                            data.description,
                            data.product,
                            data.category,
                            data.provider,
                            data.providerId,
                            data.version,
                            data.versionIndex,
                            data.price,
                            1
                          )
                        );
                        var timeout = setInterval(() => {
                          if (
                            store
                              .getState()
                              .cart.addedProducts.some(function (
                                cartItem,
                                index,
                                array
                              ) {
                                return data.id === cartItem.id;
                              })
                          ) {
                            history.push('/checkout/cart');
                            clearInterval(timeout);
                          }
                        }, 100);
                      }}
                    >
                      {`NT $${data.price}`}
                    </BuyButton>
                    <AddButton
                      variant="contained"
                      disableElevation
                      onClick={() => {
                        dispatch(
                          addToCart(
                            data.id,
                            data.image,
                            data.name,
                            data.description,
                            data.product,
                            data.category,
                            data.provider,
                            data.providerId,
                            data.version,
                            data.versionIndex,
                            data.price,
                            1
                          )
                        );
                      }}
                    >
                      <ItemAddIcon />
                    </AddButton>
                  </>
                ) : (
                  <ComingSoon>Coming Soon</ComingSoon>
                )}
              </Buttons>
            </ButtonsGrid>
          )}
        </CouponContainer>
      </InfoGrid>
    </Wrapper>
  );
};

export default Information;

function numberFormatter(num) {
  if (num && num > 0 && num <= 99999) {
    return num;
  } else if (num && num > 99999 && num <= 999949) {
    var k = num / 1000;
    return k.toFixed(1).toString() + 'K';
  } else if (num && num > 99949) {
    var m = num / 1000000;
    return (
      m
        .toFixed(3)
        .toString()
        .replace(/(\d{1, 3})(?=(\d{3})+(?:$|\.))/g, '$1,') + 'M'
    );
  } else if (num === 0) {
    return 0;
  } else {
    return '';
  }
}
