import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { addToCart } from '../../actions/cart';
import { store } from '../../index';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  padding: 24px 0;
`;

const ItemGrid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
  position: relative;
`;

const ItemImgGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  flex-direction: column;
`;

const ItemImg = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 80px;
  height: 80px;
  border-radius: 15px;
  object-fit: scale-down;
`;

const ItemImgDefaultContainer = styled.div`
  width: 80px;
  height: 80px;
  background: #d1d1d1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemImgDefault = styled.p`
  color: #3e3a39;
`;

const DividerGrid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 8px;
  justify-content: center;
  flex-direction: column;
`;

const ItemSubTitle = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: #3e3a39;
  height: 45%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  width: 128px;
  ${({ discount }) =>
    discount &&
    `
    margin-right: 32px;
  `}
`;

const LowerContainer = styled.div`
  height: 55%;
  width: 100%;
`;

const ItemSubDescription = styled.div`
  font-size: 15px;
  color: #9fa0a0;
  margin-top: 6px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;
  width: 128px;
`;

const ItemButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ItemBuyButton = styled(Button)`
  && {
    padding: 0;
    min-width: 105px;
    border-radius: 100px;
    background: #0cbaef;
    color: #ffffff;
    font-size: 11px;
    line-height: 16px;
    &:hover {
      color: #3e3a39;
    }
    ${({ discount }) =>
      discount === `true` &&
      `
      background: #ff0000;
    `}
  }
`;

const ItemAddButton = styled(Button)`
  && {
    padding: 0;
    min-width: 16px;
    border-radius: 100px;
    background: #ef980c;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
    &:hover {
      color: #3e3a39;
    }
  }
`;

const ItemAddIcon = styled(AddIcon)`
  && {
    font-size: 16px;
  }
`;

const ComingSoon = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #0cbaef;
  line-height: 15px;
`;

const ItemLink = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: #3e3a39;
`;

const NameLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  height: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行數
  -webkit-box-orient: vertical;
`;

const ProviderLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  height: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;
`;

const ItemBadge = styled.div`
  position: absolute;
  width: 35px;
  height: 35px;
  top: 0;
  right: 8px;
  background: linear-gradient(
    0deg,
    rgba(179, 20, 14, 1) 0%,
    rgba(223, 48, 38, 1) 50%,
    rgba(253, 91, 71, 1) 100%
  );
  -webkit-clip-path: polygon(
    100% 0,
    92% 18%,
    92% 100%,
    50% 80%,
    8% 100%,
    8% 18%,
    0 0
  );
  clip-path: polygon(100% 0, 92% 18%, 92% 100%, 50% 80%, 8% 100%, 8% 18%, 0 0);
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const BadgeMessage = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 4px;
  font-weight: bold;
  line-height: 12px;
  height: 28px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SeriesItems = (props) => {
  let history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data } = props;
  return (
    <Wrapper>
      {data.map((item, index) =>
        item.image !== undefined ? (
          <ItemGrid key={index}>
            <ItemImgGrid>
              <ItemLink
                to={`/products/softwares/${item.id}/${item.versionIndex}`}
              >
                {item.image ? (
                  <ItemImg src={item.image} alt={item.name} />
                ) : (
                  <ItemImgDefaultContainer>
                    <ItemImgDefault>KNEO</ItemImgDefault>
                  </ItemImgDefaultContainer>
                )}
              </ItemLink>
              {item.review && (
                <div>{`${item.versionIndex} (${item.version})`}</div>
              )}
            </ItemImgGrid>
            <DividerGrid>
              <ItemSubTitle discount={item.discount}>
                <NameLink
                  to={`/products/softwares/${item.id}/${item.versionIndex}`}
                >
                  {titleTrimmer(item.name)}
                </NameLink>
              </ItemSubTitle>
              <LowerContainer>
                <ItemSubDescription>
                  <ProviderLink
                    to={`/products/softwares/${item.id}/${item.versionIndex}`}
                  >
                    {item.provider}
                  </ProviderLink>
                </ItemSubDescription>
                {item.display || item.review ? (
                  <ItemButtonContainer>
                    <ItemBuyButton
                      discount={item.discount.toString()}
                      variant="contained"
                      disableElevation
                      onClick={() => {
                        dispatch(
                          addToCart(
                            item.id,
                            item.image,
                            item.name,
                            item.description,
                            item.product,
                            item.category,
                            item.provider,
                            item.providerId,
                            item.version,
                            item.versionIndex,
                            item.price,
                            1
                          )
                        );
                        var timeout = setInterval(() => {
                          if (
                            store
                              .getState()
                              .cart.addedProducts.some(function (
                                cartItem,
                                index,
                                array
                              ) {
                                return item.id === cartItem.id;
                              })
                          ) {
                            history.push('/checkout/cart');
                            clearInterval(timeout);
                          }
                        }, 100);
                      }}
                    >
                      {`NT $${item.price}`}
                    </ItemBuyButton>
                    <ItemAddButton
                      variant="contained"
                      disableElevation
                      onClick={() => {
                        dispatch(
                          addToCart(
                            item.id,
                            item.image,
                            item.name,
                            item.description,
                            item.product,
                            item.category,
                            item.provider,
                            item.providerId,
                            item.version,
                            item.versionIndex,
                            item.price,
                            1
                          )
                        );
                      }}
                    >
                      <ItemAddIcon />
                    </ItemAddButton>
                  </ItemButtonContainer>
                ) : (
                  <ComingSoon>{t(`product.status.${item.status}`)}</ComingSoon>
                )}
              </LowerContainer>
            </DividerGrid>
            {item.discount && (
              <ItemBadge>
                <BadgeMessage>
                  <div>{t(`product.status.${"on_sale"}`)}</div>
                </BadgeMessage>
              </ItemBadge>
            )}
          </ItemGrid>
        ) : (
          <ItemGrid key={index}> </ItemGrid>
        )
      )}
    </Wrapper>
  );
};

export default SeriesItems;

function titleTrimmer(title) {
  const titleLenMax = 12;
  if (title) {
    var titles = title.split(' ');
    var trimmed = [];
    titles.forEach((w, i) => {
      if (w.length > titleLenMax) {
        trimmed.push(w.substring(0, titleLenMax) + '...');
      } else {
        trimmed.push(w);
      }
    });
    return trimmed.join(' ');
  } else {
    return '';
  }
}
