import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { store } from '../../index';
import { Button, FormLabel, LinearProgress, MenuItem } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import { Formik, Form, Field } from 'formik';
import { fieldToTextField, Select } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import CollapseAlert from '../Alerts/CollapseAlert';
import {
  getUserProfile,
  postUserProfile,
  getUserProfileTimezone,
} from '../../services/authentication';
import { Modal } from '../../actions/modal';
import { notify } from '../../actions/notification';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px 16px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const CustomTabs = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d1d1d1;
  justify-content: space-between;
`;

const CustomTab = styled.div`
  height: 50px;
  padding: 8px;
  position: relative;
`;

const TabTitle = styled.div`
  color: #d1d1d1;
  font-size: 18px;
  padding: 2px 16px;
  ${({ active }) =>
    active &&
    `
    background: #0cbaef;
    color: #ffffff;
    border-radius: 20px;
    padding: 2px 16px;
  `}
`;

const TitleLink = styled(Link)`
  text-decoration: none;
`;

const StyleForm = styled.div`
  width: 736px;
  margin-left: auto;
  margin-right: auto;
`;

const FullwidthContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const StyledFormLabel = styled(FormLabel)`
  && {
    font-size: 20px;
    color: #707070;
    padding: 16px 0 0;
  }
`;

const StyledSelectField = styled(Field)`
  margin: 12px 0 4px;
`;

const SelectErrorMessageContainer = styled.div`
  color: #f44336;
  font-size: 0.75rem;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  margin-left: 14px;
  margin-top: 1px;
`;

const StyleLinearProgress = styled(LinearProgress)`
  width: 100%;
  margin: 8px 0 0;
`;

const ResponseMessage = styled.div`
  margin: 0px 0px 12px;
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    // width: '100%', // Fix IE 11 issue.
    margin: theme.spacing(2, 0, 2),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(2),
    flexDirection: 'column',
    display: 'flex',
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
    background: '#29a9e0',
    textTransform: 'none',
    fontSize: '1rem',
    '&:hover': {
      background: '#0372ba',
    },
  },
}));

const Profile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const messageInitState = {
    type: 'error',
    message: '',
    open: false,
  };
  const [message, setMessage] = useState(messageInitState);
  const [data, setData] = useState({
    profile: {
      fullname: '',
      email: '',
      phone: '',
      address: '',
    },
  });
  const [timezoneData, setTimezoneData] = useState([]);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');

  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getUserProfile(user.token.accessToken);
        setData(result.data);
      } catch (e) {
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };
    if (user.token === null) {
      if (ticket === null) {
        dispatch(Modal('', true));
      } else {
        var reLogin = setInterval(() => {
          if (store.getState().user.token) {
            fetchData();
            clearInterval(reLogin);
          }
        }, 100);
      }
    } else {
      fetchData();
    }
  }, [user, i18n, message, dispatch, ticket]);

  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getUserProfileTimezone(
          {
            params: {
              lng: i18n.language,
            },
          },
          user.token.accessToken
        );
        setTimezoneData(result.data);
      } catch (e) {
        console.log(e);
      }
    };
    if (user.token !== null) {
      fetchData();
    }
  }, [i18n.language, user]);

  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <CustomTabs>
            <CustomTab>
              <TitleLink className="customTab" to="/users/profile">
                <TabTitle active={true}>{t('user.tab.title.profile')}</TabTitle>
              </TitleLink>
              <div className="triangle active" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/order">
                <TabTitle>{t('user.tab.title.order')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/wishList">
                <TabTitle>{t('user.tab.title.wishList')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/deviceList">
                <TabTitle>{t('user.tab.title.device')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/appList">
                <TabTitle>{t('user.tab.title.app')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
          </CustomTabs>
          <StyleForm>
            <Formik
              initialValues={{
                fullname: data.profile.fullname || '',
                email: data.profile.email || '',
                phone: data.profile.phone || '',
                address: data.profile.address || '',
                timezone: data.profile.timezone || '',
              }}
              enableReinitialize
              validate={(values) => {
                const errors = {};
                // if (!values.email) {
                //   errors.email = t('signin.emailRequired');
                // } else if (
                //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                //     values.email
                //   )
                // ) {
                //   errors.email = t('signin.emailValid');
                // }
                // if (!values.password) {
                //   errors.password = t('signin.passwordRequired');
                // }
                if (!values.timezone) {
                  errors.timezone = 'profile.validation.invalid';
                }
                if (!values.address) {
                  errors.address = 'profile.require.textField';
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                const apiFetch = async () => {
                  try {
                    const result = await postUserProfile(
                      {
                        data: {
                          address: values.address,
                          timezone: values.timezone,
                        },
                      },
                      user.token.accessToken
                    );
                    if (result.status === 'ok') {
                      setSubmitting(false);
                      setMessage({
                        type: 'success',
                        open: true,
                        message: 'message.200',
                      });
                    }
                  } catch (e) {
                    setSubmitting(false);
                    if (e && e.response) {
                      switch (e.response.status) {
                        case 400:
                          setMessage({
                            type: 'error',
                            open: true,
                            message: 'message.400',
                          });
                          break;
                        case 401:
                          setMessage({
                            type: 'error',
                            open: true,
                            message: 'message.401',
                          });
                          dispatch(Modal('', true));
                          break;
                        case 500:
                          setMessage({
                            type: 'error',
                            open: true,
                            message: 'message.500',
                          });
                          break;
                        default:
                          setMessage({
                            type: 'error',
                            open: true,
                            message: 'message.error',
                          });
                          break;
                      }
                    }
                  }
                };
                apiFetch();
              }}
            >
              {({
                isSubmitting,
                touched,
                errors,
                values,
                setFieldValue,
                setFieldTouched,
                handleBlur,
              }) => (
                <Form className={classes.form}>
                  <FullwidthContainer>
                    <StyledFormLabel>
                      {t('profile.label.fullname')}
                    </StyledFormLabel>
                    <Field
                      component={StyleTextField}
                      variant="outlined"
                      margin="normal"
                      // fullWidth
                      id="fullname"
                      name="fullname"
                      autoComplete="fullname"
                      disabled
                    />
                  </FullwidthContainer>
                  <FullwidthContainer>
                    <StyledFormLabel>
                      {t('profile.label.email')}
                    </StyledFormLabel>
                    <Field
                      component={StyleTextField}
                      variant="outlined"
                      margin="normal"
                      // fullWidth
                      name="email"
                      id="email"
                      autoComplete="email"
                      disabled
                    />
                  </FullwidthContainer>
                  <FullwidthContainer>
                    <StyledFormLabel>
                      {t('profile.label.phone')}
                    </StyledFormLabel>
                    <Field
                      component={StyleTextField}
                      variant="outlined"
                      margin="normal"
                      // fullWidth
                      name="phone"
                      id="phone"
                      autoComplete="phone"
                      disabled
                    />
                  </FullwidthContainer>
                  <FullwidthContainer>
                    <StyledFormLabel>
                      {t('profile.label.timezone')}
                    </StyledFormLabel>
                    <StyledSelectField
                      component={Select}
                      variant="outlined"
                      fullWidth
                      name="timezone"
                      id="timezone"
                      error={touched.timezone && errors.timezone !== undefined}
                      onBlur={handleBlur}
                      disabled={values.timezone === '' ? true : false}
                    >
                      {timezoneData.map((item) => (
                        <MenuItem key={item.label} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </StyledSelectField>
                    <SelectErrorMessageContainer>
                      {touched.invoice && t(errors.invoice)}
                    </SelectErrorMessageContainer>
                  </FullwidthContainer>
                  <FullwidthContainer>
                    <StyledFormLabel>
                      {t('profile.label.address')}
                    </StyledFormLabel>
                    <Field
                      component={StyleTextField}
                      variant="outlined"
                      margin="normal"
                      // fullWidth
                      name="address"
                      id="address"
                      autoComplete="address"
                    />
                  </FullwidthContainer>
                  {isSubmitting && <StyleLinearProgress />}
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isSubmitting}
                  >
                    {t('profile.button.save')}
                  </Button>
                  <ResponseMessage>
                    <CollapseAlert
                      type={message.type}
                      message={message.message}
                      open={message.open}
                      close={() => {
                        if (message.type === 'success') {
                          setMessage({
                            type: 'success',
                            message: '',
                            open: false,
                          });
                        } else {
                          setMessage(messageInitState);
                        }
                      }}
                    />
                  </ResponseMessage>
                </Form>
              )}
            </Formik>
          </StyleForm>
        </ContentWrapper>
      </Wrapper>
    </Fragment>
  );
};

function StyleTextField(props) {
  const { t } = useTranslation();
  return (
    <MuiTextField
      {...fieldToTextField(props)}
      InputProps={{
        style: {
          marginBottom: 8,
        },
      }}
      helperText={
        props.form.touched[props.field.name] &&
        t(props.form.errors[props.field.name])
      }
    />
  );
}

export default Profile;
