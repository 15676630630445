import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { store } from '../index';

const KneoUser = 'KneoUser';

const PrivateRoute = ({ children, ...rest }) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');

  return (
    <Route
      {...rest}
      render={() =>
        localStorage.getItem(KneoUser) || ticket || store.getState().user ? (
          children
        ) : (
          <Redirect to={'/'} />
        )
      }
    />
  );
};

export default PrivateRoute;
