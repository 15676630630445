import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@material-ui/core';
import Slider from 'react-slick';
import NextArrow from '../../resources/images/next-arrow.png';
import PrevArrow from '../../resources/images/prev-arrow.png';
import { addToCart } from '../../actions/cart';

const RecommendWrapper = styled.div`
  width: 100%;
  background: #ebebeb;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 12px 0px 48px;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;
`;

const Arrow = styled.img`
  color: #898989;
  && {
    width: 16px;
    height: 32px;
  }
  &:hover {
    color: #999999;
    background: #f8f9fa;
    border-radius: 100px;
  }
`;

const Title = styled.h2`
  margin: 16px 0 16px;
  font-size: 23px;
  color: #3e3a39;
`;

const SlickContainer = styled.div`
  margin: 8px 0 32px;
`;

const ItemGrid = styled(Grid)`
  && {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ItemImgGrid = styled(Grid)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ItemImg = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-radius: 15px;
  // height: 120px;
`;

const ItemLink = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
`;

const TextLink = styled(Link)`
  text-decoration: none;
`;

const DividerGrid = styled(Grid)`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ItemSubTitle = styled.div`
  font-size: 17px;
  color: #3e3a39;
`;

const ItemSubDescription = styled.div`
  font-size: 14px;
  color: #9fa0a0;
  margin-top: 4px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;
`;

const SlickButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ItemBuyButton = styled(Button)`
  && {
    padding: 0;
    min-width: 48px;
    border-radius: 100px;
    background: #0cbaef;
    color: #ffffff;
    margin-bottom: 2px;
    font-size: 11px;
    line-height: 16px;
    &:hover {
      color: #3e3a39;
    }
  }
`;

const Recommend = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const { data } = props;
  const slickSettings = {
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    nextArrow: <Arrow src={NextArrow} alt="" />,
    prevArrow: <Arrow src={PrevArrow} alt="" />,
  };
  if (data && data.length && data.length > slickSettings.slidesToShow) {
    slickSettings.infinite = true
  }
  return (
    <RecommendWrapper>
      <Wrapper>
        <Title>{t('product.software.titles.recommend')}</Title>
        <SlickContainer>
          <Slider {...slickSettings}>
            {data.map((item, index) => (
              <div key={index}>
                <ItemGrid key={index} container spacing={2}>
                  {item.category === 'HW' && (
                    <ItemImgGrid item xs={5}>
                      <ItemLink
                        to={`/products/hardwares/${item.id}/${item.versionIndex}`}
                      >
                        <ItemImg src={item.image} alt="" />
                      </ItemLink>
                    </ItemImgGrid>
                  )}
                  {item.category !== 'HW' && (
                    <ItemImgGrid item xs={5}>
                      <ItemLink
                        to={`/products/softwares/${item.id}/${item.versionIndex}`}
                      >
                        <ItemImg src={item.image} alt="" />
                      </ItemLink>
                    </ItemImgGrid>
                  )}
                  <DividerGrid item xs={7}>
                    <Grid>
                      {item.category === 'HW' && (
                        <TextLink
                          to={`/products/hardwares/${item.id}/${item.versionIndex}`}
                        >
                          <ItemSubTitle>{item.name}</ItemSubTitle>
                        </TextLink>
                      )}
                      {item.category !== 'HW' && (
                        <TextLink
                          to={`/products/softwares/${item.id}/${item.versionIndex}`}
                        >
                          <ItemSubTitle>{item.name}</ItemSubTitle>
                        </TextLink>
                      )}
                      {item.category === 'HW' && (
                        <TextLink
                          to={`/products/hardwares/${item.id}/${item.versionIndex}`}
                        >
                          <ItemSubDescription>
                            {item.description}
                          </ItemSubDescription>
                        </TextLink>
                      )}
                      {item.category !== 'HW' && (
                        <TextLink
                          to={`/products/softwares/${item.id}/${item.versionIndex}`}
                        >
                          <ItemSubDescription>
                            {item.description}
                          </ItemSubDescription>
                        </TextLink>
                      )}
                      <SlickButtonContainer>
                        <ItemBuyButton
                          variant="contained"
                          disableElevation
                          onClick={() => {
                            dispatch(
                              addToCart(
                                item.id,
                                item.image,
                                item.name,
                                item.description,
                                item.product,
                                item.category,
                                item.provider,
                                item.providerId,
                                item.version,
                                item.versionIndex,
                                item.price,
                                1
                              )
                            );
                            history.push('/checkout/cart');
                          }}
                        >
                          {t('product.softwareCategory.button.buy')}
                        </ItemBuyButton>
                      </SlickButtonContainer>
                    </Grid>
                  </DividerGrid>
                </ItemGrid>
              </div>
            ))}
          </Slider>
        </SlickContainer>
      </Wrapper>
    </RecommendWrapper>
  );
};

export default Recommend;
