import {
  ADD_TO_DOWNLOAD_QUEUE,
  DROP_FROM_DOWNLOAD_QUEUE,
  UPDATE_DOWNLOAD_PROGRESS,
} from '../actions/download';

export const download = (state = [], action) => {
  switch (action.type) {
    case ADD_TO_DOWNLOAD_QUEUE:
      return [
        ...state,
        {
          Id: action.payload.Id,
          progress: action.payload.progress,
        },
      ];

    case DROP_FROM_DOWNLOAD_QUEUE:
      var del = state.findIndex(function (item, index, array) {
        return item.Id === action.payload.Id;
      });
      return [...state.slice(0, del), ...state.slice(del + 1)];

    case UPDATE_DOWNLOAD_PROGRESS:
      var update = state.findIndex(function (item, index, array) {
        return item.Id === action.payload.Id;
      });
      return state.map((item, index) => {
        if (index !== update) {
          return item;
        }

        return {
          ...item,
          progress: action.payload.progress,
        };
      });

    default:
      return state;
  }
};
