import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  min-height: 50px;
  background: linear-gradient(
    0deg,
    rgba(1, 24, 38, 1) 0%,
    rgba(1, 39, 62, 1) 50%,
    rgba(2, 56, 89, 1) 100%
  );
`;

const HomeFooter = styled.div`
  position: relative;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  min-height: 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffffff;
`;

const FooterLinkContainer = styled.div`
  display: flex;
  position: absolute;
  right: 48px;
`;

const FooterLink = styled(Link)`
  width: 70px;
  text-align: center;
  color: #ffffff;
  padding: 0 10px;
  white-space: nowrap;
  border-right: 1px solid #ffffff;
  display: table;
  z-index: 10;
  text-decoration: none;
  &:hover {
    color: gray;
    cursor: pointer;
  }
`;

const FooterLinkEnd = styled(Link)`
  width: 70px;
  text-align: center;
  color: #ffffff;
  padding: 0 10px;
  white-space: nowrap;
  display: table;
  z-index: 10;
  text-decoration: none;
  &:hover {
    color: gray;
    cursor: pointer;
  }
`;

const Footer = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <HomeFooter maxWidth={false} component="footer">
        <div>{`${t('footer.copyright1')}${new Date().getFullYear()}${t(
          'footer.copyright2'
        )}`}</div>
        <FooterLinkContainer>
          <FooterLink to="/legal">{t('footer.legal')}</FooterLink>
          <FooterLinkEnd to="/privacy">{t('footer.privacy')}</FooterLinkEnd>
        </FooterLinkContainer>
      </HomeFooter>
    </Wrapper>
  );
};

export default Footer;
