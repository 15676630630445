import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px 16px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const CustomTabs = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d1d1d1;
  justify-content: space-between;
`;

const CustomTab = styled.div`
  height: 50px;
  padding: 8px;
  position: relative;
`;

const TabTitle = styled.div`
  color: #d1d1d1;
  font-size: 18px;
  padding: 2px 16px;
  ${({ active }) =>
    active &&
    `
    background: #0cbaef;
    color: #ffffff;
    border-radius: 20px;
    padding: 2px 16px;
  `}
`;

const TitleLink = styled(Link)`
  text-decoration: none;
`;

const DeviceInstructionContainer = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  padding-top: 160px;
`;

const InstructionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 157px;
  margin-top: 32px;
`;

const InstructionLabel = styled.div`
  font-size: 33px;
  color: #0cbaef;
  font-weight: bold;
  margin-right: 12px;
`;

const InstructionValue = styled.div`
  font-size: 28px;
  color: #9fa0a0;
  margin-left: 12px;
`;

const StepButtonContainer = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 194px;
`;

const StepButton = styled(Button)`
  && {
    background: #0cbaef;
    font-size: 18px;
    color: #ffffff;
    width: 128px;
    height: 36px;
    border-radius: 20px;
    text-transform: none;
    margin: 0 32px;
    &:hover {
      border: 1px solid #0cbaef;
      color: #0cbaef;
    }
  }
`;

const DeviceInstruction = () => {
  const { t } = useTranslation();
  let history = useHistory();

  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <CustomTabs>
            <CustomTab>
              <TitleLink className="customTab" to="/users/profile">
                <TabTitle>{t('user.tab.title.profile')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/order">
                <TabTitle>{t('user.tab.title.order')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/wishList">
                <TabTitle>{t('user.tab.title.wishList')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/deviceList">
                <TabTitle active={true}>{t('user.tab.title.device')}</TabTitle>
              </TitleLink>
              <div className="triangle active" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/appList">
                <TabTitle>{t('user.tab.title.app')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
          </CustomTabs>
          <DeviceInstructionContainer>
            <InstructionContainer>
              <InstructionLabel>{t('device.title.step1')}</InstructionLabel>
              <InstructionValue>
                {t('device.description.step1')}
              </InstructionValue>
            </InstructionContainer>
            <InstructionContainer>
              <InstructionLabel>{t('device.title.step2')}</InstructionLabel>
              <InstructionValue>
                {t('device.description.step2')}
              </InstructionValue>
            </InstructionContainer>
            <InstructionContainer>
              <InstructionLabel>{t('device.title.step3')}</InstructionLabel>
              <InstructionValue>
                {t('device.description.step3')}
              </InstructionValue>
            </InstructionContainer>
            <StepButtonContainer>
              <StepButton
                onClick={() => {
                  history.push('/users/deviceUtility');
                }}
              >
                {t('device.button.prevStep')}
              </StepButton>
              <StepButton
                onClick={() => {
                  history.push('/users/deviceList');
                }}
              >
                {t('device.button.finish')}
              </StepButton>
            </StepButtonContainer>
          </DeviceInstructionContainer>
        </ContentWrapper>
      </Wrapper>
    </Fragment>
  );
};

export default DeviceInstruction;
