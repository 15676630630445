export const NOTIFICATION = 'NOTIFICATION';

export const notify = (type, message, open) => ({
  type: NOTIFICATION,
  payload: { type: type, message: message, open: open },
});

// export const Notification = (type, message, open) => (dispatch) => {
//   dispatch({
//     type: NOTIFICATION,
//     payload: { type: type, message: message, open: open },
//   });
// };
