import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { store } from '../../index';
import {
  getUserApps,
  postUserAppDownload,
} from '../../services/authentication';
import {
  Button,
  ButtonBase,
  // Grid,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DownloadAvailable from '../../resources/images/download-available.png';
import DownloadUnavailable from '../../resources/images/download-unavailable.png';
import BindingAvailable from '../../resources/images/binding-available.png';
import BindingUnavailable from '../../resources/images/binding-unavailable.png';
import PurchaseAvailable from '../../resources/images/purchase-available.png';
// import PurchaseUnavailable from '../../resources/images/purchase-unavailable.png';
import { addToCart } from '../../actions/cart';
import { notify } from '../../actions/notification';
import { Modal } from '../../actions/modal';
import {
  addToDownloadQueue,
  dropFromDownloadQueue,
} from '../../actions/download';
import BindingDialog from '../../components/Alerts/BindingDialog';
import DownloadDialog from '../../components/Alerts/DownloadDialog';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px 16px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const CustomTabs = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d1d1d1;
  justify-content: space-between;
`;

const CustomTab = styled.div`
  height: 50px;
  padding: 8px;
  position: relative;
`;

const TabTitle = styled.div`
  color: #d1d1d1;
  font-size: 18px;
  padding: 2px 16px;
  ${({ active }) =>
    active &&
    `
    background: #0cbaef;
    color: #ffffff;
    border-radius: 20px;
    padding: 2px 16px;
  `}
`;

const TitleLink = styled(Link)`
  text-decoration: none;
`;

const AppWrapper = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const AppBindingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 48px;
`;

const AppBindingImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
`;

const AppBindingImage = styled.img`
  width: 96px;
  height: 96px;
`;

const AppBindingImageDefaultContainer = styled.div`
  width: 96px;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d1d1d1;
`;

const AppBindingImageDefault = styled.p`
  color: #3e3a39;
`;

const AppBindingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

const AppBindingInfoName = styled.div`
  font-size: 29px;
  color: #595757;
  font-weight: bold;
`;

const AppLicenseContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

const AppLicenseTotalContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 1px;
`;

const AppLicenseTotalLabel = styled.div`
  font-size: 21px;
  color: #9fa0a0;
  font-weight: bold;
  margin-right: 10px;
  padding-bottom: 6px;
`;

const AppLicenseTotalValue = styled.div`
  font-size: 39px;
  line-height: 53px;
  color: #0cbaef;
`;

const AppLicenseSubContainer = styled.div`
  display: flex;
  // justify-content: space-between;
`;

const AppLicenseBindContainer = styled.div`
  font-size: 16px;
  color: #9fa0a0;
  margin-right: 48px;
`;

const AppLicenseAvailableContainer = styled.div`
  font-size: 16px;
  color: #9fa0a0;
`;

const AppBindingPurchaseButtonContainer = styled.div`
  width: 20%;
  padding: 0 8px;
`;

const AppBindingPurchaseButton = styled(Button)`
  && {
    width: 100%;
    border-radius: 20px;
    height: 28px;
    font-size: 16px;
    color: #ffffff;
    background: #0cbaef;
    text-transform: none;
  }
`;

const DevicesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  margin: 0 4px;
  padding: 0 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #29a9e0;
  border-radius: 20px;
  height: 36px;
`;

const TitleDevice = styled.div`
  width: 32%;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
`;

const TitleCode = styled.div`
  width: 17%;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
`;

const TitleBinding = styled.div`
  width: 17%;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
`;

const TitlePurchase = styled.div`
  width: 17%;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
`;

const TitleDownload = styled.div`
  width: 17%;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
`;

const DeviceItemsContainer = styled.div`
  margin: 0 8px;
  display: flex;
  flex-direction: column;
`;

const DeviceItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #d1d1d1;
`;

const DeviceItemInfoContainer = styled.div`
  width: 32%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DeviceItemInfoImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 14%;
  width: 96px;
  height: 96px;
  ${({ image }) =>
    image === '' &&
    `
    background: #d1d1d1;
  `}
`;

const DeviceItemInfoImage = styled.img`
  width: 96px;
  height: 100%;
  object-fit: scale-down;
`;

const DeviceImageDefault = styled.p`
  color: #3e3a39;
  width: 96px;
  text-align: center;
`;

const DeviceItemInfoLicenseContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8%;
  justify-content: center;
`;

const DeviceItemName = styled.div`
  font-size: 20px;
  color: #595757;
  font-weight: bold;
`;

const DeviceItemCategoryContainer = styled.div`
  font-size: 16px;
  color: #9fa0a0;
  margin: 4px 0;
`;

const AppItemCodeContainer = styled.div`
  width: 17%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: #9fa0a0;
`;

const DeviceItemBindingContainer = styled.div`
  width: 17%;
  display: flex;
  justify-content: center;
`;

const DeviceItemPurchaseContainer = styled.div`
  width: 17%;
  display: flex;
  justify-content: center;
`;

const DeviceItemDownloadContainer = styled.div`
  width: 17%;
  display: flex;
  justify-content: center;
`;

const DeviceEmptyContainer = styled.div`
  height: 234px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 68px;
  padding-bottom: 16px;
`;

const DeviceEmptyTitle = styled.div`
  width: 574px;
  color: #707070;
  font-size: 23px;
  line-height: 45px;
  text-align: center;
  margin-bottom: 30px;
`;

const DeviceEmptyTitleSpan = styled.span`
  color: #707070;
  font-size: 23px;
  font-weight: bold;
  line-height: 45px;
`;

const DeviceEmptyButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BuyOneButton = styled(Button)`
  && {
    font-size: 14px;
    padding: 4px 20px;
    background: #0cbaef;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #0cbaef;
    width: 246px;
    margin-right: 18px;
    &:hover {
      color: #0cbaef;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const RegisterButton = styled(Button)`
  && {
    font-size: 14px;
    padding: 4px 20px;
    background: #ef980c;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #ef980c;
    min-width: 246px;
    margin-left: 18px;
    &:hover {
      color: #ef980c;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const FinishContainer = styled.div`
  height: 120px;
  padding: 0 8px;
`;

const FinishButtonContainer = styled.div`
  margin-left: 83%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const FinishButton = styled(Button)`
  && {
    width: 100px;
    height: 28px;
    background: #0cbaef;
    color: #ffffff;
    font-size: 16px;
    border-radius: 20px;
    text-transform: none;
  }
`;

// const RecommendWrapper = styled.div`
//   display: block;
//   flex-direction: column;

//   width: 100%;
//   margin-left: auto;
//   margin-right: auto;
//   background: #ebebeb;
//   bottom: 0;
//   height: 168px;
// `;

// const Recommend = styled.div`
//   display: flex;
//   flex-direction: column;

//   width: 1280px;
//   margin-left: auto;
//   margin-right: auto;
// `;

// const RecommendTitleContainer = styled.div`
//   margin-top: 28px;
//   width: 946px;
//   margin-left: auto;
//   margin-right: auto;
// `;

// const RecommendTitle = styled.h2`
//   color: #3e3a39;
//   font-size: 19px;
//   margin-top: 0;
//   margin-bottom: 8px;
// `;

// const RecommendItemContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
//   margin-top: 8px;
//   margin-bottom: 32px;
//   width: 946px;
//   margin-left: auto;
//   margin-right: auto;
// `;

// const ItemGrid = styled(Grid)`
//   && {
//     width: 90%;
//     margin-left: auto;
//     margin-right: auto;
//   }
// `;

// const ItemImgGrid = styled(Grid)`
//   && {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// `;

// const ItemImg = styled.img`
//   margin-left: auto;
//   margin-right: auto;
//   width: 64px;
//   height: 64px;
//   border-radius: 28px;
//   object-fit: scale-down;
// `;

// const ItemLink = styled(Link)`
//   display: flex;
//   justify-content: center;
//   text-decoration: none;
//   border-radius: 28px;
// `;

// const TextLink = styled(Link)`
//   text-decoration: none;
// `;

// const DividerGrid = styled(Grid)`
//   display: flex;
//   align-items: center;
//   width: 100%;
// `;

// const ItemSubTitle = styled.div`
//   font-size: 17px;
//   color: #3e3a39;
//   margin-bottom: 4px;
// `;

// const ItemSubDescription = styled.div`
//   font-size: 14px;
//   color: #9fa0a0;
//   margin-top: 4px;
//   margin-bottom: 4px;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: normal;
//   display: -webkit-box;
//   -webkit-line-clamp: 1; //行數
//   -webkit-box-orient: vertical;
// `;

// const ItemBuyButton = styled(Button)`
//   && {
//     padding: 0;
//     min-width: 44px;
//     border-radius: 100px;
//     background: #0cbaef;
//     color: #ffffff;
//     font-size: 7px;
//     line-height: 14px;
//     margin-bottom: 2px;
//     &:hover {
//       color: #3e3a39;
//     }
//   }
// `;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  margin: calc(50vh - 45px) auto 0;
`;

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#9fa0a0',
  },
  arrow: {
    color: '#9fa0a0',
  },
})(Tooltip);

const AppBinding = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const download = useSelector((state) => state.download);
  const [data, setData] = useState({
    app: { license: { available: 0, bind: 0 } },
    devices: [],
    recommend: [],
  });
  const [loading, setLoading] = useState(true);
  const [bindingOpen, setBindingOpen] = useState(false);
  const [bindingInfo, setBindingInfo] = useState({
    app: '',
    device: '',
  });
  const [refresh, setRefresh] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [bindingVersion, setBindingVersion] = useState([]);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [downloadInfo, setDownloadInfo] = useState({});

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');

  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getUserApps(
          id,
          {
            params: { lng: i18n.language },
          },
          user.token.accessToken
        );
        setData(result.data);
        setLoading(false);
        var tooltip = {};
        if (result.data.devices) {
          result.data.devices.forEach((element) => {
            tooltip[result.data.app.id + element.deviceId] = false;
          });
        }
        setTooltipOpen(tooltip);
      } catch (e) {
        setData({
          app: { license: { available: 0, bind: 0 } },
          devices: [],
          recommend: [],
        });
        setLoading(false);
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };
    if (user.token === null) {
      if (ticket === null) {
        dispatch(Modal('', true));
      } else {
        var reLogin = setInterval(() => {
          if (store.getState().user.token) {
            fetchData();
            clearInterval(reLogin);
          }
        }, 100);
      }
    } else {
      fetchData();
    }
  }, [i18n, i18n.language, user, dispatch, id, refresh, ticket]);

  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <CustomTabs>
            <CustomTab>
              <TitleLink className="customTab" to="/users/profile">
                <TabTitle>{t('user.tab.title.profile')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/order">
                <TabTitle>{t('user.tab.title.order')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/wishList">
                <TabTitle>{t('user.tab.title.wishList')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/deviceList">
                <TabTitle>{t('user.tab.title.device')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/appList">
                <TabTitle active={true}>{t('user.tab.title.app')}</TabTitle>
              </TitleLink>
              <div className="triangle active" />
            </CustomTab>
          </CustomTabs>
          {loading ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : (
            <AppWrapper>
              <BindingDialog
                open={bindingOpen}
                close={() => setBindingOpen(false)}
                info={bindingInfo}
                refresh={() => setRefresh(refresh + 1)}
              />
              <DownloadDialog
                open={downloadOpen}
                close={() => setDownloadOpen(false)}
                info={bindingInfo}
                refresh={() => setRefresh(refresh + 1)}
                version={bindingVersion}
                closing={() => setBindingVersion()}
                downloadInfo={downloadInfo}
                clearDownloadInfo={() => setDownloadInfo({})}
              />
              <AppBindingContainer>
                <AppBindingImageContainer>
                  {data.app.image ? (
                    <AppBindingImage src={data.app.image} alt={data.app.name} />
                  ) : (
                    <AppBindingImageDefaultContainer>
                      <AppBindingImageDefault>KNEO</AppBindingImageDefault>
                    </AppBindingImageDefaultContainer>
                  )}
                </AppBindingImageContainer>
                <AppBindingInfoContainer>
                  <AppBindingInfoName>{data.app.name}</AppBindingInfoName>
                </AppBindingInfoContainer>
                <AppLicenseContainer>
                  <AppLicenseTotalContainer>
                    <AppLicenseTotalLabel>
                      {`${t('app.title.totalLicense')}: `}
                    </AppLicenseTotalLabel>
                    <AppLicenseTotalValue>
                      {data.app.license.available + data.app.license.bind}
                    </AppLicenseTotalValue>
                  </AppLicenseTotalContainer>
                  <AppLicenseSubContainer>
                    <AppLicenseBindContainer>
                      {`${t('app.title.bind')}: ${data.app.license.bind}`}
                    </AppLicenseBindContainer>
                    <AppLicenseAvailableContainer>
                      {`${t('app.title.available')}: ${data.app.license.available
                        }`}
                    </AppLicenseAvailableContainer>
                  </AppLicenseSubContainer>
                </AppLicenseContainer>
                <AppBindingPurchaseButtonContainer>
                  <AppBindingPurchaseButton
                    onClick={() => {
                      dispatch(
                        addToCart(
                          data.app.id,
                          data.app.image,
                          data.app.name,
                          data.app.description,
                          data.app.product,
                          data.app.category,
                          data.app.provider,
                          data.app.providerId,
                          data.app.version,
                          data.app.versionIndex,
                          data.app.price,
                          1
                        )
                      );
                      var timeout = setInterval(() => {
                        if (
                          store
                            .getState()
                            .cart.addedProducts.some(function (
                              cartItem,
                              index,
                              array
                            ) {
                              return data.app.id === cartItem.id;
                            })
                        ) {
                          history.push('/checkout/cart');
                          clearInterval(timeout);
                        }
                      }, 100);
                    }}
                  >
                    {t('app.title.purchaseMore')}
                  </AppBindingPurchaseButton>
                </AppBindingPurchaseButtonContainer>
              </AppBindingContainer>
              <DevicesContainer>
                <TitleContainer>
                  <TitleDevice>{t('app.title.app')}</TitleDevice>
                  <TitleCode>{t('app.title.code')}</TitleCode>
                  <TitleBinding>{t('app.title.binding')}</TitleBinding>
                  <TitlePurchase>{t('app.title.purchaseMore')}</TitlePurchase>
                  <TitleDownload>{t('app.title.download')}</TitleDownload>
                </TitleContainer>
                {data.devices && data.devices.length !== 0 ? (
                  <DeviceItemsContainer>
                    {data.devices.map((item, index) => (
                      <DeviceItemContainer key={index}>
                        <DeviceItemInfoContainer>
                          <DeviceItemInfoImageContainer image={item.image}>
                            {item.image ? (
                              <DeviceItemInfoImage
                                src={item.image}
                                alt={item.name}
                              />
                            ) : (
                              <DeviceImageDefault>KNEO</DeviceImageDefault>
                            )}
                          </DeviceItemInfoImageContainer>
                          <DeviceItemInfoLicenseContainer>
                            <DeviceItemName>{item.name}</DeviceItemName>
                            <DeviceItemCategoryContainer>
                              {item.category}
                            </DeviceItemCategoryContainer>
                          </DeviceItemInfoLicenseContainer>
                        </DeviceItemInfoContainer>
                        <AppItemCodeContainer>{item.code}</AppItemCodeContainer>
                        <DeviceItemBindingContainer>
                          <CustomTooltip
                            title={item.binding ? '' : t('app.message.bind')}
                            placement="top"
                            arrow
                            open={
                              tooltipOpen[data.app.id + item.deviceId] ===
                                undefined
                                ? false
                                : tooltipOpen[data.app.id + item.deviceId]
                            }
                          >
                            <ButtonBase
                              disabled={item.binding}
                              onClick={() => {
                                if (data.app.license.available > 0) {
                                  setBindingInfo({
                                    app: data.app.id,
                                    device: item.deviceId,
                                  });
                                  setBindingOpen(true);
                                } else {
                                  dispatch(
                                    notify('info', t('app.message.less'), true)
                                  );
                                }
                              }}
                            >
                              <img
                                src={
                                  item.binding
                                    ? BindingUnavailable
                                    : BindingAvailable
                                }
                                alt=""
                              />
                            </ButtonBase>
                          </CustomTooltip>
                        </DeviceItemBindingContainer>
                        <DeviceItemPurchaseContainer>
                          <ButtonBase
                            onClick={() => {
                              dispatch(
                                addToCart(
                                  item.id,
                                  item.image,
                                  item.name,
                                  item.description,
                                  item.product,
                                  item.category,
                                  item.provider,
                                  item.providerId,
                                  item.version,
                                  item.versionIndex,
                                  item.price,
                                  1
                                )
                              );
                              var timeout = setInterval(() => {
                                if (
                                  store
                                    .getState()
                                    .cart.addedProducts.some(function (
                                      cartItem,
                                      index,
                                      array
                                    ) {
                                      return item.id === cartItem.id;
                                    })
                                ) {
                                  history.push('/checkout/cart');
                                  clearInterval(timeout);
                                }
                              }, 100);
                            }}
                          >
                            <img src={PurchaseAvailable} alt="" />
                          </ButtonBase>
                        </DeviceItemPurchaseContainer>
                        <DeviceItemDownloadContainer>
                          {download.some(function (loading, index, array) {
                            return data.app.id + item.deviceId === loading.Id;
                          }) ? (
                            <CircularProgress style={{ color: '#0cbaef' }} />
                          ) : (
                            <CustomTooltip
                              title={item.binding ? `` : t('app.message.tip')}
                              placement="top"
                              arrow
                              onOpen={() => {
                                setTooltipOpen({
                                  ...tooltipOpen,
                                  [data.app.id + item.deviceId]: true,
                                });
                              }}
                              onClose={() => {
                                setTooltipOpen({
                                  ...tooltipOpen,
                                  [data.app.id + item.deviceId]: false,
                                });
                              }}
                            >
                              <div>
                                <ButtonBase
                                  disabled={!item.binding}
                                  onClick={() => {
                                    if (data.app.review) {
                                      setBindingVersion(data.app.versions);
                                      setDownloadInfo({
                                        downloadQueueId:
                                          data.app.id + item.deviceId,
                                        device_uid: item.deviceId,
                                        product_uid: data.app.id,
                                        filename: data.app.name,
                                      });
                                      setDownloadOpen(true);
                                    } else {
                                      dispatch(
                                        addToDownloadQueue(
                                          data.app.id + item.deviceId,
                                          0
                                        )
                                      );
                                      const apiFetch = async () => {
                                        try {
                                          const result = await postUserAppDownload(
                                            {
                                              device_uid: item.deviceId,
                                              product_uid: data.app.id,
                                              filename: data.app.name,
                                              version: false,
                                            },
                                            user.token.accessToken
                                          );

                                          let a = document.createElement('a');
                                          a.href =
                                            process.env.REACT_APP_API_URL +
                                            '/apps/download?&user=' +
                                            user.info.email +
                                            '&timestamp=' +
                                            result.data.time +
                                            '&filename=' +
                                            result.data.filename;
                                          a.click();
                                          dispatch(
                                            dropFromDownloadQueue(
                                              data.app.id + item.deviceId
                                            )
                                          );
                                        } catch (e) {
                                          dispatch(
                                            dropFromDownloadQueue(
                                              data.app.id + item.deviceId
                                            )
                                          );
                                          if (e && e.response) {
                                            switch (e.response.status) {
                                              case 400:
                                                dispatch(
                                                  notify(
                                                    'error',
                                                    i18n.t('message.400'),
                                                    true
                                                  )
                                                );
                                                break;
                                              case 401:
                                                dispatch(Modal('', true));
                                                break;
                                              case 500:
                                                dispatch(
                                                  notify(
                                                    'error',
                                                    i18n.t('message.500'),
                                                    true
                                                  )
                                                );
                                                break;
                                              default:
                                                dispatch(
                                                  notify(
                                                    'error',
                                                    i18n.t('message.error'),
                                                    true
                                                  )
                                                );
                                            }
                                          }
                                        }
                                      };
                                      apiFetch();
                                    }
                                  }}
                                >
                                  <img
                                    src={
                                      item.binding
                                        ? DownloadAvailable
                                        : DownloadUnavailable
                                    }
                                    alt=""
                                  />
                                </ButtonBase>
                              </div>
                            </CustomTooltip>
                          )}
                        </DeviceItemDownloadContainer>
                      </DeviceItemContainer>
                    ))}
                  </DeviceItemsContainer>
                ) : (
                  <DeviceEmptyContainer>
                    <DeviceEmptyTitle>
                      {t('app.device.emptyTitle1')}
                      <DeviceEmptyTitleSpan>KNEO</DeviceEmptyTitleSpan>
                      {t('app.device.emptyTitle2')}
                    </DeviceEmptyTitle>
                    <DeviceEmptyButtonContainer>
                      <BuyOneButton
                        onClick={() => {
                          history.push('/products/hardware');
                        }}
                      >
                        {t('app.button.buyOne')}
                      </BuyOneButton>
                      <RegisterButton
                        onClick={() => {
                          history.push('/users/deviceUtility');
                        }}
                      >
                        {t('app.button.register')}
                      </RegisterButton>
                    </DeviceEmptyButtonContainer>
                  </DeviceEmptyContainer>
                )}
              </DevicesContainer>
              {data.devices && data.devices.length !== 0 && (
                <FinishContainer>
                  <FinishButtonContainer>
                    <FinishButton
                      onClick={() => {
                        history.push('/users/appList');
                      }}
                    >
                      {t('app.button.finish')}
                    </FinishButton>
                  </FinishButtonContainer>
                </FinishContainer>
              )}
            </AppWrapper>
          )}
        </ContentWrapper>
      </Wrapper>
      {/* <RecommendWrapper>
        <Recommend>
          <RecommendTitleContainer>
            <RecommendTitle>{t('app.title.recommend')}</RecommendTitle>
          </RecommendTitleContainer>
          <RecommendItemContainer>
            {data.recommend.map((item, index) => (
              <ItemGrid key={index} container spacing={2}>
                <ItemImgGrid item xs={5}>
                  <ItemLink to={`/products/softwares/${item.id}`}>
                    <ItemImg src={item.image} alt="" />
                  </ItemLink>
                </ItemImgGrid>
                <DividerGrid item xs={7}>
                  <Grid>
                    <TextLink to={`/products/softwares/${item.id}`}>
                      <ItemSubTitle>{item.name}</ItemSubTitle>
                    </TextLink>
                    <TextLink to={`/products/softwares/${item.id}`}>
                      <ItemSubDescription>
                        {item.description}
                      </ItemSubDescription>
                    </TextLink>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <ItemBuyButton
                          variant="contained"
                          disableElevation
                          onClick={() => {
                            dispatch(
                              addToCart(
                                item.id,
                                item.image,
                                item.name,
                                item.description,
                                item.product,
                                item.category,
                                item.price,
                                1
                              )
                            );
                            history.push('/checkout/cart');
                          }}
                        >
                          {t('app.button.buy')}
                        </ItemBuyButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </DividerGrid>
              </ItemGrid>
            ))}
          </RecommendItemContainer>
        </Recommend>
      </RecommendWrapper> */}
    </Fragment>
  );
};

export default AppBinding;
