import i18n from '../i18n';
import { notify } from './notification';

export const OAUTH_SUCCESS = 'OAUTH_SUCCESS';
export const OAUTH_FAIL = 'OAUTH_FAIL';
export const OAUTH_RESET = 'OAUTH_RESET';
export const OAUTH_LOADING = 'OAUTH_LOADING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REFRESH_SUCCESS = 'REFRESH_SUCCESS';
// export const REFRESH_LOGOUT = 'REFRESH_LOGOUT';

export const OAUTH_USER = 'OAUTH_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FETCH_USER_CART = 'FETCH_USER_CART';
export const FETCH_VIEW_CART = 'FETCH_VIEW_CART';
export const FETCH_SYNC_USER_CART = 'FETCH_SYNC_USER_CART';

export const oauthUser = (ticket) => ({ type: OAUTH_USER, payload: ticket });
export const oauthReset = () => ({ type: OAUTH_RESET });
export const oauthLoadingStart = () => ({
  type: OAUTH_LOADING,
  payload: { loading: true },
});
export const oauthLoadingEnd = () => ({
  type: OAUTH_LOADING,
  payload: { loading: false },
});
export const oauthFail = () => ({ type: OAUTH_FAIL });
export const oauthSuccess = (result) => ({
  type: OAUTH_SUCCESS,
  payload: result.data,
});
export const oauthError = (e) => {
  if (e && e.response) {
    switch (e.response.status) {
      case 400:
        return notify('error', i18n.t('message.401'), true);
      case 401:
        return notify('error', i18n.t('message.401'), true);
      case 500:
        return notify('error', i18n.t('message.500'), true);
      case 502:
        return notify('error', i18n.t('message.502'), true);
      default:
        if (Array.isArray(e.response.data.reasons)) {
          console.log(e);
          return notify('error', e.response.data.reasons[0], true);
        } else {
          return notify('error', e.response.data.reasons, true);
        }
    }
  }
};

export const refreshSuccess = (result) => ({
  type: REFRESH_SUCCESS,
  payload: result.data,
});

export const logoutUser = (token) => ({ type: LOGOUT_USER, payload: token });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = (e) => {
  if (e && e.response) {
    console.log(e);
    return { type: LOGOUT_SUCCESS };
  }
};

export const fetchUserCart = (language) => ({
  type: FETCH_USER_CART,
  payload: language,
});
export const fetchSyncUserCart = (language) => ({
  type: FETCH_SYNC_USER_CART,
  payload: language,
});
export const fetchViewCart = (language) => ({
  type: FETCH_VIEW_CART,
  payload: language,
});
