import React from 'react';
import styled from 'styled-components';
import SeriesItems from './SoftwareSeriesItems';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px 0px 32px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const SeriesWrapper = styled.div`
  margin-bottom: 16px;
`;

const SeriesHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6px;
`;

const SeriesHeader = styled.h2`
  color: #595757;
  font-size: 21px;
  white-space: nowrap;
  padding-left: 8px;
  margin: 0px;
  padding-right: 96px;
`;

const HeaderDivider = styled.hr`
  border: 1px solid #ffffff;
  background: #898989;
  border-style: inset;
`;

const DividerContainer = styled.div`
  width: 100%;
  margin: auto;
`;

const SoftwareSeries = (props) => {
  const { data } = props;
  return (
    <Wrapper>
      {data.map((item, index) => (
        <SeriesWrapper key={index} id={`${item.id}`}>
          <SeriesHeaderContainer>
            <SeriesHeader>{item.title}</SeriesHeader>
            <DividerContainer>
              <HeaderDivider />
            </DividerContainer>
          </SeriesHeaderContainer>
          {item.items.map((e, i) => (
            <SeriesItems key={i} data={e} />
          ))}
        </SeriesWrapper>
      ))}
    </Wrapper>
  );
};

export default SoftwareSeries;
