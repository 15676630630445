import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 0px 32px;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.h2`
  margin: 8px 0 24px;
  font-size: 23px;
  color: #3e3a39;
  padding-left: 4px;
`;

const Description = styled.div`
  font-size: 15px;
  color: #3e3a39;
  white-space: pre-line;
`;

const SupportedPlatfrom = (props) => {
  const { t } = useTranslation();
  const { data } = props;

  return (
    <Wrapper>
      <div>
        <Title>{t('product.software.titles.platform')}</Title>
        <Description>{data}</Description>
      </div>
    </Wrapper>
  );
};

export default SupportedPlatfrom;
