import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { store } from '../../index';
import { getUserDeviceUtility } from '../../services/authentication';
import WindowsIcon from '../../resources/images/windows.png';
import LinuxIcon from '../../resources/images/linux.png';
import { Button } from '@material-ui/core';
import { notify } from '../../actions/notification';
import { Modal } from '../../actions/modal';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px 16px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  // @media (max-width: 1440px) {
  //   padding: 48px 0px 16px;
  // }
`;

const CustomTabs = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d1d1d1;
  justify-content: space-between;
`;

const CustomTab = styled.div`
  height: 50px;
  padding: 8px;
  position: relative;
`;

const TabTitle = styled.div`
  color: #d1d1d1;
  font-size: 18px;
  padding: 2px 16px;
  ${({ active }) =>
    active &&
    `
    background: #0cbaef;
    color: #ffffff;
    border-radius: 20px;
    padding: 2px 16px;
  `}
`;

const TitleLink = styled(Link)`
  text-decoration: none;
`;

const DeviceUtilityContainer = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`;

const UtilityTitleContainer = styled.div`
  text-align: center;
  font-size: 28px;
  color: #9fa0a0;
  font-weight: bold;
  white-space: pre-line;
  margin-top: 132px;
  margin-bottom: 45px;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 48px;
`;

const IconContainer = styled.div`
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.div`
  width: 197px;
  height: 197px;
  display: flex;
  flex-direction: column;
  background: #1dbaef;
  border-radius: 200px;
  align-items: center;
  justify-content: center;
`;

const IconImage = styled.img`
  width: 90px;
`;

const IconTitle = styled.div`
  font-size: 25px;
  color: #ffffff;
  margin-top: 12px;
`;

const IconDescriptionContainer = styled.div`
  margin-top: 48px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const DownloadLink = styled.a`
  font-size: 22px;
  color: #9fa0a0;
  text-decoration: underline;
  cursor: pointer;
`;

const StepButtonContainer = styled.div`
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepButton = styled(Button)`
  && {
    background: #0cbaef;
    font-size: 18px;
    color: #ffffff;
    width: 128px;
    height: 36px;
    border-radius: 20px;
    text-transform: none;
    &:hover {
      border: 1px solid #0cbaef;
      color: #0cbaef;
    }
  }
`;

const DeviceUtility = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  let history = useHistory();
  const [data, setData] = useState({
    devicelist: [],
  });

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');

  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getUserDeviceUtility(
          {
            params: { lng: i18n.language },
          },
          user.token.accessToken
        );
        setData(result.data);
      } catch (e) {
        if (e && e.response) {
          switch (e.response.status) {
            case 401:
              dispatch(Modal('', true));
              break;
            default:
              if (Array.isArray(e.response.data.reasons)) {
                dispatch(notify('error', e.response.data.reasons[0], true));
                console.log(e);
              } else {
                dispatch(notify('error', e.response.data.reasons, true));
              }
          }
        }
      }
    };
    if (user.token === null) {
      if (ticket === null) {
        dispatch(Modal('', true));
      } else {
        var reLogin = setInterval(() => {
          if (store.getState().user.token) {
            fetchData();
            clearInterval(reLogin);
          }
        }, 100);
      }
    } else {
      fetchData();
    }
  }, [i18n, user, dispatch, ticket]);

  console.log(data);
  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <CustomTabs>
            <CustomTab>
              <TitleLink className="customTab" to="/users/profile">
                <TabTitle>{t('user.tab.title.profile')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/order">
                <TabTitle>{t('user.tab.title.order')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/wishList">
                <TabTitle>{t('user.tab.title.wishList')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/deviceList">
                <TabTitle active={true}>{t('user.tab.title.device')}</TabTitle>
              </TitleLink>
              <div className="triangle active" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/appList">
                <TabTitle>{t('user.tab.title.app')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
          </CustomTabs>
          <DeviceUtilityContainer>
            <UtilityTitleContainer>
              {t('device.title.utilityTitle')}
            </UtilityTitleContainer>
            <IconsContainer>
              <IconContainer>
                <Icon>
                  <IconImage src={WindowsIcon} alt="" />
                  <IconTitle>{`Windows`}</IconTitle>
                </Icon>
                <IconDescriptionContainer>
                  {/* <DownloadLink
                    onClick={() => {
                      console.log('Windows');
                    }}
                  >
                    32-Bit
                  </DownloadLink> */}
                  <DownloadLink
                    onClick={() => {
                      window.location = data.windows.bit64;
                    }}
                  >
                    {t('device.download.64bit')}
                  </DownloadLink>
                </IconDescriptionContainer>
              </IconContainer>
              <IconContainer>
                <Icon>
                  <IconImage src={LinuxIcon} alt="" />
                  <IconTitle>{`Linux`}</IconTitle>
                </Icon>
                <IconDescriptionContainer>
                  {/* <DownloadLink
                    onClick={() => {
                      console.log('Linux');
                    }}
                  >
                    32-Bit
                  </DownloadLink> */}
                  <DownloadLink
                    onClick={() => {
                      window.location = data.linux.bit64;
                    }}
                  >
                    {t('device.download.64bit')}
                  </DownloadLink>
                </IconDescriptionContainer>
              </IconContainer>
            </IconsContainer>
            <StepButtonContainer>
              <StepButton
                onClick={() => {
                  history.push('/users/deviceInstruction');
                }}
              >
                {t('device.button.nextStep')}
              </StepButton>
            </StepButtonContainer>
          </DeviceUtilityContainer>
        </ContentWrapper>
      </Wrapper>
    </Fragment>
  );
};

export default DeviceUtility;
