import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import NextArrow from '../../resources/images/next-arrow.png';
import PrevArrow from '../../resources/images/prev-arrow.png';
import ReactPlayer from 'react-player/lazy';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 0px 16px;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;

  ${({ dialog }) =>
    dialog &&
    `
    display: flex;
    flex-direction: column;

    padding: 8px 0px 16px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const Arrow = styled.img`
  color: #898989;
  && {
    width: 16px;
    height: 32px;
  }
  &:hover {
    color: #999999;
    background: #f8f9fa;
    border-radius: 100px;
  }
`;

const Title = styled.h2`
  margin: 8px 0 16px;
  font-size: 23px;
  color: #3e3a39;
  padding-left: 4px;
`;

const SlickContainer = styled.div`
  margin: 8px 0 16px;
`;

const SlickImage = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 93%;
  border-radius: 15px;
  height: auto;
`;

const Preview = (props) => {
  const { t } = useTranslation();
  const { data, dialog } = props;
  const slickSettings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: <Arrow src={NextArrow} alt="" />,
    prevArrow: <Arrow src={PrevArrow} alt="" />,
  };
  var preview = [];
  if (data) {
    data.forEach((item) => {
      preview.push(item);
    });
    if (data.length < 3) {
      for (let i = 0; i < 3 - data.length; i++) {
        preview.push({
          video: '',
          image: '',
        });
      }
    }
  }
  return (
    <Wrapper dialog={dialog}>
      <Title>{t('product.hardware.titles.preview')}</Title>
      <SlickContainer>
        <Slider {...slickSettings}>
          {/* {data &&
            data.map((item, index) => (
              <div key={index}>
                <SlickImage src={item.image} alt={item.image} />
              </div>
            ))} */}
          {data &&
            preview.map((item, index) => (
              <div key={index}>
                {item.image === '' ? (
                  <div className="hardware-player-wrapper">
                    <ReactPlayer
                      className="react-player"
                      url={item.video}
                      height={353}
                      width={353}
                      style={{
                        // marginLeft: 'auto',
                        // marginRight: 'auto',
                        borderRadius: '15px',
                      }}
                    />
                  </div>
                ) : (
                  <SlickImage src={item.image} alt={item.image} />
                )}
              </div>
            ))}
        </Slider>
      </SlickContainer>
    </Wrapper>
  );
};

export default Preview;
