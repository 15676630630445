import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  Grid,
  LinearProgress,
  IconButton,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { getProductReview } from '../../services/productPage';
import { notify } from '../../actions/notification';
import { Modal } from '../../actions/modal';
import ReviewDialog from './ReviewDialog';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 0px 0px;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;
`;

const RatingBoardContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;

const CommentContainer = styled.div`
  margin: 27px 15px 8px 39px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const CardTitle = styled.h2`
  font-size: 28px;
  color: #3e3a39;
  margin-top: 8px;
  margin-left: 16px;
  margin-bottom: 8px;
`;

const CardContent = styled.div`
  font-size: 15px;
  color: #3e3a39;
  margin-top: 32px;
  margin-left: 16px;
  height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
`;

const CardContentReply = styled.div`
  font-size: 15px;
  color: #0cbaef;
  margin-top: 45px;
  margin-left: 0px;
  height: 60px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
`;

const CardRightGrid = styled(Grid)`
  && {
    position: relative;
  }
`;

const CardBottomGrid = styled(Grid)`
  && {
    position: relative;
  }
`;

const CardIconContainer = styled.div`
  position: absolute;
  top: 48.2px;
  right: 35.3px;
`;

const CardEditRemind = styled.div`
  position: absolute;
  font-size: 16px;
  font-weight: 300;
  color: #3e3a39;
  text-align: left;
  font-weight: bold;
  ${({ side }) => `
    ${side}: ${side==="right"?"42px":"39.5px"};
    bottom: ${side==="right"?"23px":"33px"};
  `}
`;

const CardDate = styled.div`
  position: absolute;
  bottom: 0px;
  right: 42px;
  font-size: 16px;
  font-weight: 300;
  color: #3e3a39;
  text-align: left;
  top: 117px;
`;

const CardDateReply = styled.div`
  position: absolute;
  font-size: 16px;
  font-weight: 300;
  color: #3e3a39;
  text-align: left;
  left: 39.5px;
  top: 85px;
`;

const SeeAllContainer = styled.div`
  position: relative;
  margin-left: 400px;
  margin-top: 5px;
`;

const SeeAllLink = styled(Link)`
  width: 28px;
  height: 10px;
  color: #0cbaef;
  text-align: left;
  display: table;
  font-size: 15px;
  font-weight: normal;
  text-decoration: none;
  &:hover {
    color: gray;
    cursor: pointer;
  }
`;

const PagingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 940px;
  margin-left: auto;
  margin-right: 15px;
  margin-bottom: 100px;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PagingInstruction = styled.div`
  font-size: 16px;
  color: #9fa0a0;
  margin-right: 6px;
  margin-left: 12px;
`;

const PagingIconButton = styled(IconButton)`
  && {
    padding: 0;
  }
`;

const PagingNumber = styled(Button)`
  && {
    margin: 0 8px;
    color: #939393;
    font-size: 20px;
    min-width: 20px;
    padding: 0;
    ${({ current }) =>
      current === 'true' &&
      `
      color: #0cbaef;
    `}
  }
`;

const CustomNavigateBeforeIcon = styled(NavigateBeforeIcon)`
  height: 16px;
  color: #939393;
  && {
    padding: 8px;
  }
`;

const CustomNavigateNextIcon = styled(NavigateNextIcon)`
  height: 16px;
  color: #939393;
  && {
    padding: 8px;
  }
`;

const PagingInput = styled.input`
  width: 58px;
  height: 22px;
  border-radius: 20px;
  border: 1px solid #cacaca;
  text-align: center;
  color: #d1d1d1;
  outline: none;
  font-size: 16px;
  line-height: 25px;
  margin-right: 6px;
  margin-left: 10px;
`;

const PagingButton = styled(Button)`
  && {
    margin-left: 6px;
    margin-right: 10px;
    height: 26px;
    font-size: 16px;
    color: #ffffff;
    background: #cacaca;
    border-radius: 20px;
    border: 1px solid #cacaca;
    line-height: 23px;
    &:hover {
      color: #cacaca;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const InfoGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 100px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  margin-left: 14px;
`;

const Title = styled.div`
  font-size: 23px;
  font-weight: bold;
  color: #0cbaef;
  margin-top: 32px;
  margin-bottom: 4px;
  width: 100%;
  height: 28px;
`;

const Product = styled.div`
  font-size: 19px;
  color: #9fa0a0;
  margin-top: 4px;
  margin-bottom: 8px;
  width: 100%;
  font-weight: 500;
  height: 23px;
`;

const DividerGrid = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-left: 38px;
`;

const StyledRating = withStyles({
  iconFilled: {
    color: '#ffffff',
  },
  iconHover: {
    color: '#ffffff',
  },
})(Rating);

const StyledCardRating = withStyles({
  iconFilled: {
    color: '#0cbaef',
  },
  iconHover: {
    color: '#0cbaef',
  },
})(Rating);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 5,
    top: 9,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#0cbaef',
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  paperLight: {
    width: '485px',
    height: '280px',
    background: '#f5f5f5',
    padding: 16,
    borderRadius: 20,
    margin: '8px 0px 26px 8px',
    // [theme.breakpoints.down(1440)]: {
    //   margin: '8px 4px',
    // },
  },
  paperDark: {
    width: '485px',
    height: '280px',
    background: '#ebebeb',
    padding: 16,
    borderRadius: 20,
    margin: '8px 0px 26px 8px',
    // [theme.breakpoints.down(1440)]: {
    //   margin: '8px 4px',
    // },
  },
}));

const WrapperDivide = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0;
  width: 450px;
  margin-left: 18px;
  margin-right: 20px;
  margin-top: 18px;

  ${({ dialog }) =>
    dialog &&
    `
    display: flex;
    flex-direction: column;

    padding: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const Divide = styled.hr`
  border: 1px solid #ffffff;
  background: #898989;
  border-style: inset;
  // border-width: 1px;
`;

const DividerCard = (props) => {
  const { dialog } = props;
  return (
    <WrapperDivide dialog={dialog}>
      <div>
        <Divide />
      </div>
    </WrapperDivide>
  );
};

const WrapperDivideTitle = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0;
  width: 1063px;
  margin-left: 52px;
  margin-right: 20px;
  margin-top: 8px;

  ${({ dialog }) =>
    dialog &&
    `
    display: flex;
    flex-direction: column;

    padding: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const DivideTitle = styled.hr`
  border: 1px solid #ffffff;
  background: #d1d1d1;
  border-style: inset;
  // border-width: 1px;
`;

const DividerTitle = (props) => {
  const { dialog } = props;
  return (
    <WrapperDivideTitle dialog={dialog}>
      <div>
        <DivideTitle />
      </div>
    </WrapperDivideTitle>
  );
};

const HardwareReviewBoard = (props) => {
  const Length = 10;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id, versionIndex } = useParams();
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [data, setData] = useState({
    info: {},
    view: { preview: [], description: '', demo: [] },
    devices: [],
    rating: { comment: [] },
    feature: {},
    softwareInfo: {},
    recommend: [],
    platform: '',
  });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageIndex, setPageIndex] = useState([]);
  const [number, setNumber] = useState(0);
  const [reviewDetail, setReviewDetail] = useState([]);

  const [reviewDialogOpen, setReviewDialogOpen] = useState(false);

  useEffect(() => {
    var fetchData = async () => {
      try {
        var token = '';
        if (user.token) {
          token = user.token.accessToken;
        }
        const result = await getProductReview(id, {
          params: {
            lng: i18n.language,
            index: page - 1,
            length: Length,
          },
        });
        setData(result.data);

        var index = Math.ceil(result.data.length / Length);
        var indexArray = [];
        for (var i = 0; i < index; i++) {
          indexArray.push(i + 1);
        }
        setPageIndex(indexArray);

        setLoading(false);
      } catch (e) {
        setData({
          info: {},
          view: { preview: [], description: '', demo: [] },
          devices: [],
          rating: { comment: [] },
          feature: {},
          softwareInfo: {},
          recommend: [],
          platform: '',
        });
        setLoading(false);
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };
    fetchData();
  }, [id, versionIndex, i18n, i18n.language, user.token, dispatch, page]);

  console.log(data.rating.comment);

  return (
    <Wrapper>
      <ReviewDialog
        data={reviewDetail}
        open={reviewDialogOpen}
        close={() => {
          setReviewDialogOpen(false);
        }}
      />
      <InfoGrid>
        <InfoContainer>
          <DividerGrid item xs={10}>
            <Title>{data.rating.displayName}</Title>
            <Product>{data.rating.product}</Product>
          </DividerGrid>
        </InfoContainer>
      </InfoGrid>
      <DividerTitle />
      <RatingBoardContainer>
        {/* <RatingGridContainer>
          <Title>{t('product.software.titles.rating')}</Title>
          <ScoreGridContainer>
            <ScoreGrid>{data.average}</ScoreGrid>
            <IconGrid>
              <IconContainer>
                <StyledRating
                  name="read-only"
                  value={data.average === undefined ? 0 : data.average}
                  emptyIcon={<StyleStarBorderIcon fontSize="inherit" />}
                  size="small"
                  readOnly
                />
              </IconContainer>
            </IconGrid>
          </ScoreGridContainer>
          <AverageGridContainer container spacing={0}>
            <PointGrid item xs={1}>
              5
            </PointGrid>
            <AverageGrid item xs={11}>
              <BorderLinearProgress
                variant="determinate"
                value={data.five === undefined ? 0 : data.five}
              />
            </AverageGrid>
          </AverageGridContainer>
          <AverageGridContainer container spacing={0}>
            <PointGrid item xs={1}>
              4
            </PointGrid>
            <AverageGrid item xs={11}>
              <BorderLinearProgress
                variant="determinate"
                value={data.four === undefined ? 0 : data.four}
              />
            </AverageGrid>
          </AverageGridContainer>
          <AverageGridContainer container spacing={0}>
            <PointGrid item xs={1}>
              3
            </PointGrid>
            <AverageGrid item xs={11}>
              <BorderLinearProgress
                variant="determinate"
                value={data.three === undefined ? 0 : data.three}
              />
            </AverageGrid>
          </AverageGridContainer>
          <AverageGridContainer container spacing={0}>
            <PointGrid item xs={1}>
              2
            </PointGrid>
            <AverageGrid item xs={11}>
              <BorderLinearProgress
                variant="determinate"
                value={data.two === undefined ? 0 : data.two}
              />
            </AverageGrid>
          </AverageGridContainer>
          <AverageGridContainer container spacing={0}>
            <PointGrid item xs={1}>
              1
            </PointGrid>
            <AverageGrid item xs={11}>
              <BorderLinearProgress
                variant="determinate"
                value={data.one === undefined ? 0 : data.one}
              />
            </AverageGrid>
          </AverageGridContainer>
        </RatingGridContainer> */}
        <CommentContainer>
          {data.rating.comment.map((item, index) => (
            <Card
              className={clsx(
                classes.paperLight,
                (index === 1 ||
                  index === 2 ||
                  index === 5 ||
                  index === 6 ||
                  index === 9) &&
                  classes.paperDark
              )}
              key={index}
            >
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <CardTitle>{item.author}</CardTitle>
                  <CardContent>{item.content}</CardContent>
                </Grid>
                <CardRightGrid item xs={4}>
                  <CardIconContainer>
                    <StyledCardRating
                      name="read-only"
                      value={item.score === undefined ? 0 : item.score}
                      emptyIcon={<StarBorderIcon fontSize="inherit" />}
                      size="medium"
                      readOnly
                    />
                  </CardIconContainer>
                  { item.modified && <CardEditRemind side={"right"}>{t("product.software.other.edited")}</CardEditRemind> }                  
                  <CardDate>{item.date}</CardDate>
                </CardRightGrid>
                <CardBottomGrid item xs={4}>
                  {item.review_reply !== undefined && <DividerCard />}
                  { item.reply_modified && <CardEditRemind side={"left"}>{t("product.software.other.edited")}</CardEditRemind> }
                  <CardDateReply>{item.reply_time}</CardDateReply>
                </CardBottomGrid>
                <CardBottomGrid item xs={7}>
                  <CardContentReply>{item.review_reply}</CardContentReply>
                </CardBottomGrid>

                <Grid item xs={8}>
                  <SeeAllContainer>
                    <SeeAllLink
                      onClick={() => {
                        setReviewDetail([data.rating.comment[index]]);
                        setReviewDialogOpen(true);
                      }}
                    >
                      {t('product.software.button.more')}
                    </SeeAllLink>
                  </SeeAllContainer>
                </Grid>
              </Grid>
            </Card>
          ))}
        </CommentContainer>
      </RatingBoardContainer>
      <PagingContainer>
        <PageContainer>
          {page === 1 ? (
            <></>
          ) : (
            <PagingIconButton
              onClick={() => {
                setPage(page - 1);
              }}
            >
              <CustomNavigateBeforeIcon />
            </PagingIconButton>
          )}
          {pageIndex.length !== 0 &&
            pageIndex.map((item, index) => {
              var pages;
              if (pageIndex.length < 6) {
                if (index < 5) {
                  pages = (
                    <PagingNumber
                      key={index}
                      current={index === page - 1 ? 'true' : 'false'}
                      onClick={() => {
                        setPage(item);
                      }}
                    >
                      {item}
                    </PagingNumber>
                  );
                }
              } else {
                if (page === pageIndex[pageIndex.length - 1]) {
                  if (index >= pageIndex.length - 5) {
                    pages = (
                      <PagingNumber
                        key={index}
                        current={item === page ? 'true' : 'false'}
                        onClick={() => {
                          setPage(item);
                        }}
                      >
                        {item}
                      </PagingNumber>
                    );
                  }
                } else if (page === pageIndex[pageIndex.length - 2]) {
                  if (index >= pageIndex.length - 5) {
                    pages = (
                      <PagingNumber
                        key={index}
                        current={item === page ? 'true' : 'false'}
                        onClick={() => {
                          setPage(item);
                        }}
                      >
                        {item}
                      </PagingNumber>
                    );
                  }
                } else if (page <= 3) {
                  if (index < 5) {
                    pages = (
                      <PagingNumber
                        key={index}
                        current={item === page ? 'true' : 'false'}
                        onClick={() => {
                          setPage(item);
                        }}
                      >
                        {item}
                      </PagingNumber>
                    );
                  }
                } else {
                  if (item >= page - 2 && item <= page + 2) {
                    pages = (
                      <PagingNumber
                        key={index}
                        current={item === page ? 'true' : 'false'}
                        onClick={() => {
                          setPage(item);
                        }}
                      >
                        {item}
                      </PagingNumber>
                    );
                  }
                }
              }
              return pages;
            })}
          {page === pageIndex[pageIndex.length - 1] ? (
            <></>
          ) : (
            <PagingIconButton
              onClick={() => {
                setPage(page + 1);
              }}
            >
              <CustomNavigateNextIcon />
            </PagingIconButton>
          )}
          <PagingInstruction>{t('order.label.to')}</PagingInstruction>
          <PagingInput
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
          <PagingButton
            onClick={() => {
              console.log(number);
              if (number === 0 || number === '0') {
                setPage(1);
              } else if (
                /^[0-9]*$/i.test(number) &&
                number > pageIndex[pageIndex.length - 1]
              ) {
                setNumber(pageIndex[pageIndex.length - 1]);
              } else if (number === '') {
              } else {
                setPage(parseInt(number, 10));
              }
            }}
          >
            {t('order.button.go')}
          </PagingButton>
        </PageContainer>
      </PagingContainer>
    </Wrapper>
  );
};

export default HardwareReviewBoard;
