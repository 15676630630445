import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
// import useRWD from '../../helpers/useRWD';
import Slider from 'react-slick';
import NextArrow from '../../resources/images/next-arrow.png';
import PrevArrow from '../../resources/images/prev-arrow.png';
import HardwareDialog from './HardwareDialog';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2px 0px 16px;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;
`;

const Arrow = styled.img`
  color: #898989;
  && {
    width: 16px;
    height: 32px;
  }
  &:hover {
    color: #999999;
    background: #f8f9fa;
    border-radius: 100px;
  }
`;

const Title = styled.h2`
  margin: 2px 0 8px;
  font-size: 23px;
  color: #3e3a39;
  padding-left: 4px;
`;

const SlickContainer = styled.div`
  margin: 8px 0 16px;
`;

const ImageContainer = styled.button`
  // margin: 0 4%;
  height: 154px;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
  background: #ebebeb;
  border-radius: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 154px;
  border: none;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`;

const SlickImage = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 80%;
  border-radius: 50%;
`;

const Support = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  const slickSettings = {
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 6,
    nextArrow: <Arrow src={NextArrow} alt="" />,
    prevArrow: <Arrow src={PrevArrow} alt="" />,
  };
  // var h = useRWD(154, 1920);

  const [hardwareDialogOpen, setHardwareDialogOpen] = useState(false);
  const [hardware, setHardware] = useState({
    id: '',
    versionIndex: 0,
  });

  var support = [];
  if (data) {
    data.forEach((item) => {
      support.push(item);
    });
    if (data.length < 6) {
      for (let i = 0; i < 6 - data.length; i++) {
        support.push({
          id: '',
          image: '',
        });
      }
    }
  }

  return (
    <Wrapper>
      <HardwareDialog
        id={hardware.id}
        versionIndex={hardware.versionIndex}
        open={hardwareDialogOpen}
        close={() => {
          setHardwareDialogOpen(false);
          setHardware({
            id: '',
            versionIndex: 0,
          });
        }}
      />
      <Title>{t('product.software.titles.support')}</Title>
      <SlickContainer>
        <Slider {...slickSettings}>
          {support.map((item, index) =>
            item.id !== '' ? (
              <div key={index}>
                <ImageContainer
                  onClick={() => {
                    setHardware({
                      id: item.id,
                      versionIndex: item.versionIndex,
                    });
                    setHardwareDialogOpen(true);
                  }}
                >
                  <SlickImage src={item.image} alt="" />
                </ImageContainer>
              </div>
            ) : (
              <div key={index}></div>
            )
          )}
        </Slider>
      </SlickContainer>
    </Wrapper>
  );
};

export default Support;
