import { POP } from '../actions/modal';

export const modal = (state = { message: '', open: false }, action) => {
  switch (action.type) {
    case POP:
      return {
        message: action.payload.message,
        open: action.payload.open,
      };

    default:
      return state;
  }
};
