import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 0px 32px;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.h2`
  margin: 8px 0 24px;
  font-size: 23px;
  color: #3e3a39;
  padding-left: 4px;
`;

const Description = styled.div`
  font-size: 15px;
  color: #3e3a39;
  white-space: pre-line;
`;

const RecordGrid = styled(Grid)`
  display: flex;
  // justify-content: flex-end;
  flex-direction: column;
`;

const RecordContainer = styled.div`
  font-size: 15px;
  color: #3e3a39;
  margin: 16px 24px 2px;
  text-align: right;
`;

const DateContainer = styled.div`
  font-size: 15px;
  color: #3e3a39;
  margin: 2px 24px;
  text-align: right;
`;

const Feature = (props) => {
  const { t } = useTranslation();
  const { data } = props;

  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid item xs={9}>
          <Title>{t('product.software.titles.feature')}</Title>
          <Description>{data.content}</Description>
        </Grid>
        <RecordGrid item xs={3}>
          <RecordContainer>
            {t('product.software.titles.version') + ' ' + data.version}
          </RecordContainer>
          <DateContainer>{data.date}</DateContainer>
        </RecordGrid>
      </Grid>
    </Wrapper>
  );
};

export default Feature;
