import React from 'react';
import styled from 'styled-components';
import HardwareItems from './HardwareItems';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px 0px 32px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const SeriesWrapper = styled.div`
  margin-bottom: 16px;
`;

const SeriesHeader = styled.h2`
  color: #595757;
  font-size: 21px;
  white-space: nowrap;
  padding-left: 8px;
  margin: 0px;
  padding-right: 96px;
`;

const HeaderDivider = styled.hr`
  border: 1px solid #ffffff;
  background: #898989;
  border-style: inset;
`;

const DividerContainer = styled.div`
  width: 100%;
  margin: auto;
`;

const EmptyContainer = styled.div`
  padding: 32px 0;
  text-align: center;
  font-size: 18px;
  background: #f5f5f5;
`;

const HardwareReview = (props) => {
  const { data, special, count } = props;
  return (
    special && (
      <Wrapper>
        <SeriesHeader>Review</SeriesHeader>
        <DividerContainer>
          <HeaderDivider />
        </DividerContainer>
        {count > 0 ? (
          data.map((e, i) => (
            <SeriesWrapper key={i}>
              <HardwareItems data={e} />
            </SeriesWrapper>
          ))
        ) : (
          <EmptyContainer>No review items</EmptyContainer>
        )}
      </Wrapper>
    )
  );
};

export default HardwareReview;
