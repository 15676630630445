import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { getProductHardwareCategory } from '../../services/productPage';
import { Modal } from '../../actions/modal';
import { notify } from '../../actions/notification';
import HardwareCarousel from './HardwareCarousel';
import HardwareTop from './HardwareTop';
import HardwareRecommendation from './HardwareRecommendation';
import HardwareReview from './HardwareReview';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  margin: calc(50vh + 10px) auto 0;
`;

const HardwareCategory = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState({
    banner: [],
    image: [],
    top: [],
    choice: [],
    review: [],
    special: false,
  });
  const [reviewCount, setReviewCount] = useState(0);
  const { hash } = useLocation();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    var fetchData = async () => {
      try {
        var token = '';
        if (user.token) {
          token = user.token.accessToken;
        }
        const result = await getProductHardwareCategory({
          params: { lng: i18n.language },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        var review = [];
        if (result.data.review) {
          setReviewCount(result.data.review.length);
          var block = [];
          result.data.review.forEach((i, index) => {
            if (index !== 0 && index % 4 === 0) {
              review.push(block);
              block = [];
            }
            block.push(i);
          });
          if (block.length < 4) {
            var t = 4 - (block.length % 5);
            for (let j = 0; j < t; j++) {
              block.push({});
            }
          }
          review.push(block);

          result.data.review = review;
        }

        setData(result.data);
        setLoading(false);
      } catch (e) {
        setData({ banner: [], image: [], top: [], choice: [], review: [], special: false });
        setLoading(false);
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };
    fetchData().then(() => {
      if (hash !== '') {
        setTimeout(() => {
          const id = hash.replace('#', '');
          const element = document.getElementById(id);
          if (element) {
            window.scrollTo({
              top: element.getBoundingClientRect().top - 80,
              behavior: 'smooth',
            });
          }
        }, 100);
      }
    });
  }, [i18n, hash, i18n.language, user.token, dispatch]);
  return (
    <Fragment>
      <Wrapper>
        {loading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          <>
            <HardwareCarousel data={data.banner} />
            <HardwareTop data={data.top} />
            <HardwareRecommendation data={data.choice} />
            <HardwareReview
              data={data.review}
              special={data.special}
              count={reviewCount}
            />
          </>
        )}
      </Wrapper>
    </Fragment>
  );
};

export default HardwareCategory;
