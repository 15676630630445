import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 16px 0px 24px;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;

  ${({ dialog }) =>
    dialog &&
    `
    display: flex;
    flex-direction: column;

    padding: 16px 0px 24px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const Title = styled.h2`
  margin: 8px 0 16px;
  font-size: 23px;
  color: #3e3a39;
  padding-left: 4px;
`;

const Description = styled.div`
  font-size: 15px;
  color: #3e3a39;
  margin: 8px 0;
  white-space: pre-line;
`;

const Specification = (props) => {
  const { t } = useTranslation();
  const { data, dialog } = props;
  return (
    <Wrapper dialog={dialog}>
      <Title>{t('product.hardware.titles.product')}</Title>
      <Description>{data.description}</Description>
    </Wrapper>
  );
};

export default Specification;
