import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 0px 64px;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;

  ${({ dialog }) =>
    dialog &&
    `
    display: flex;
    flex-direction: column;

    padding: 8px 0px 64px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const Title = styled.h2`
  margin: 8px 0 24px;
  font-size: 23px;
  color: #3e3a39;
  padding-left: 4px;
`;

const ContainerTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: grey;
`;

const ContainerContent = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: black;
  margin-top: 4px;
`;

const InfoGrid = styled(Grid)`
  && {
    margin-bottom: 16px;
  }
`;

const HardwareInfo = (props) => {
  const { t } = useTranslation();
  const { data, dialog } = props;

  return (
    <Wrapper dialog={dialog}>
      <Title>{t('product.hardware.titles.information')}</Title>
      <InfoGrid container spacing={0}>
        <Grid item xs={4}>
          <ContainerTitle>
            {t('product.hardware.titles.supplier')}
          </ContainerTitle>
          <ContainerContent>{data.supplier}</ContainerContent>
        </Grid>
        <Grid item xs={4}>
          <ContainerTitle>{t('product.hardware.titles.size')}</ContainerTitle>
          <ContainerContent>{data.size}</ContainerContent>
        </Grid>
        <Grid item xs={4}>
          <ContainerTitle>
            {t('product.software.titles.platform')}
          </ContainerTitle>
          <ContainerContent>
            {data.os && data.os.replace(/,/g, "/")}
          </ContainerContent>
        </Grid>
      </InfoGrid>
    </Wrapper>
  );
};

export default HardwareInfo;
