import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import NextArrow from '../../resources/images/next-arrow.png';
import PrevArrow from '../../resources/images/prev-arrow.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 14px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const SlickHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
`;

const SlickHeader = styled.h2`
  color: #595757;
  font-size: 26px;
  white-space: nowrap;
  padding-left: 8px;
  margin: 0px;
  padding-right: 96px;
`;

const SlickHeaderLink = styled(Link)`
  text-decoration: none;
  color: #595757;
`;

const HeaderDivider = styled.hr`
  border: 1px solid #ffffff;
  background: #898989;
  border-style: inset;
`;

const DividerContainer = styled.div`
  width: 100%;
  margin: auto;
`;

const Arrow = styled.img`
  color: #898989;
  && {
    width: 16px;
    height: 32px;
  }
  &:hover {
    color: #999999;
    background: #f8f9fa;
    border-radius: 100px;
  }
`;

const SlickWrapper = styled.div`
  padding: 0 26px;
`;

const SlickTitle = styled.div`
  font-size: 20px;
  position: absolute;
  bottom: 18px;
  left: 24px;
  color: #ffffff;
`;

const SlickImgContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 380px;
  position: relative;
  overflow: hidden;
  // &:hover ${SlickTitle} {
  //   bottom: 50%;
  //   z-index: 100;
  // }
`;

const SlickImg = styled.img`
  border-radius: 15px;
  object-fit: scale-down;
  width: 380px;
  height: 178px;
  // &:hover {
  //   filter: blur(5px);
  //   border-radius: 15px;
  // }
`;

const LandingPageSoftware = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  const slickSettings = {
    infinite: true,
    slidesToShow: data.length < 3 ? data.length : 3,
    slidesToScroll: 3,
    nextArrow: <Arrow src={NextArrow} alt="" />,
    prevArrow: <Arrow src={PrevArrow} alt="" />,
  };
  return (
    <Wrapper>
      <SlickHeaderContainer>
        <SlickHeader>
          <SlickHeaderLink to="/products/software">
            {t('landingPage.software.header')}
          </SlickHeaderLink>
        </SlickHeader>
        <DividerContainer>
          <HeaderDivider />
        </DividerContainer>
      </SlickHeaderContainer>
      <SlickWrapper>
        <Slider {...slickSettings}>
          {data.map((item, index) => (
            <div key={index}>
              <Link to={`/products/software/#${item.id}`}>
                <SlickImgContainer>
                  <SlickTitle>{item.title}</SlickTitle>
                  <SlickImg src={item.image} alt="no image" />
                </SlickImgContainer>
              </Link>
            </div>
          ))}
        </Slider>
      </SlickWrapper>
    </Wrapper>
  );
};

export default LandingPageSoftware;
