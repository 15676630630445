import { createApiClient } from './apiClient';
import { store } from '../index';
import { updateDownloadProgress } from '../actions/download';

const apiClient = createApiClient();

export const postUserSignOut = async (props, token) => {
  const response = await apiClient.delete('/users/signout', {
    data: props,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const postLoginProxy = async (props) => {
  const response = await apiClient.post('/users/loginProxy', props);
  return response.data;
};

export const getUserCart = async (props, language) => {
  const response = await apiClient.get('/users/cart', {
    params: {
      lng: language,
    },
    headers: {
      Authorization: `Bearer ${props.token}`,
    },
  });
  return response.data;
};

export const postViewCart = async (props, language) => {
  const response = await apiClient.post('/view/cart', props.data, {
    params: {
      lng: language,
    },
  });
  return response.data;
};

export const postUserAddCart = async (props, token) => {
  const response = await apiClient.post('/users/add/cart', props.data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const postUserDropCart = async (props, token) => {
  const response = await apiClient.post('/users/delete/cart', props.data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const postECPay = async (props) => {
  const response = await apiClient.post('/users/ecpay/test', props);
  return response.data;
};

export const getUserProfile = async (token) => {
  const response = await apiClient.get('/users/profile', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUserProfileTimezone = async (props, token) => {
  const response = await apiClient.get('/users/profile/timezone', {
    params: props.params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const postUserProfile = async (props, token) => {
  const response = await apiClient.post('/users/profile', props.data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const postUserCreateReview = async (props, token) => {
  const response = await apiClient.post('/product/review', props.data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const postUserEditReview = async (props, token) => {
  const response = await apiClient.post('/product/update/review', props.data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const postUserGetReview = async (props, token) => {
  const response = await apiClient.post('/product/get/review', props.data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const postUserCartRecommend = async (props, token) => {
  const response = await apiClient.post('/users/carts/recommend', props.data, {
    headers: {},
  });
  return response.data;
};

export const getUserOrder = async (props, token) => {
  const response = await apiClient.get('/users/order', {
    params: props.params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getOrderQAList = async (props, token) => {
  const response = await apiClient.get('/users/order/qna', {
    params: props.params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const postOrderQA = async (props, token) => {
  const response = await apiClient.post('/users/order/qna', props.data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUserWishList = async (props, token) => {
  const response = await apiClient.get('/users/wishList', {
    params: props.params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const postUserAddWishList = async (props, token) => {
  const response = await apiClient.post('/users/add/wishList', props.data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const postUserDropWishList = async (props, token) => {
  const response = await apiClient.post('/users/delete/wishList', props.data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUserDeviceList = async (props, token) => {
  const response = await apiClient.get('/users/deviceList', {
    params: props.params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUserDeviceUtility = async (props, token) => {
  const response = await apiClient.get('/users/deviceUtility', {
    params: props.params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUserDevices = async (devid, props, token) => {
  const response = await apiClient.get(`/users/devices/${devid}`, {
    params: props.params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUserAppList = async (props, token) => {
  const response = await apiClient.get('/users/appList', {
    params: props.params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const getUserApps = async (id, props, token) => {
  const response = await apiClient.get(`/users/apps/${id}`, {
    params: props.params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const postUserAppDeviceBinding = async (data, token) => {
  const response = await apiClient.post('/users/devices/apps/binding', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const postUserAppDownload = async (data, token) => {
  const response = await apiClient.post('/users/apps/download', data, {
    headers: {
      Authorization: `Bearer ${token}`,
      responseType: 'blob',
    },
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      // console.log(progressEvent.lengthComputable)
      // console.log(percentCompleted);
      if (
        store.getState().download[
          store.getState().download.findIndex(function (item, index, array) {
            return item.Id === data.product_uid + data.device_uid;
          })
        ].progress !== percentCompleted
      ) {
        store.dispatch(
          updateDownloadProgress(
            data.product_uid + data.device_uid,
            percentCompleted
          )
        );
      }
    },
  });
  return response.data;
};
