import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  Typography,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { postUserAppDeviceBinding } from '../../services/authentication';
import { notify } from '../../actions/notification';
import { Modal } from '../../actions/modal';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 36px;
`;

const styles = (theme) => ({
  root: {
    // margin: 0,
    padding: theme.spacing(1.5),
    marginLeft: 4,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const BindingDialog = (props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);

  return (
    <Dialog
      open={props.open}
      onClose={props.close}
      aria-labelledby="responsive-dialog-title"
      maxWidth={'xs'}
      fullWidth={true}
    >
      <DialogTitle id="customized-dialog-title" onClose={props.close}>
        {t('dialog.title.kneo')}
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>{t('dialog.description.bind')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close} color="primary">
          {t('dialog.button.no')}
        </Button>
        {loading ? (
          <LoadingContainer>
            <CircularProgress size={24} />
          </LoadingContainer>
        ) : (
          <Button
            onClick={() => {
              setLoading(true);
              const apiPost = async () => {
                try {
                  const result = await postUserAppDeviceBinding(
                    props.info,
                    user.token.accessToken
                  );
                  if (result.status === 'ok') {
                    props.refresh();
                    props.close();
                    dispatch(notify('success', i18n.t('message.200'), true));
                    setLoading(false);
                  }
                } catch (e) {
                  setLoading(false);
                  props.refresh();
                  props.close();
                  if (e && e.response) {
                    switch (e.response.status) {
                      case 400:
                        dispatch(notify('error', i18n.t('message.400'), true));
                        break;
                      case 401:
                        dispatch(Modal('', true));
                        break;
                      case 500:
                        dispatch(notify('error', i18n.t('message.500'), true));
                        break;
                      default:
                        dispatch(
                          notify('error', i18n.t('message.error'), true)
                        );
                        break;
                    }
                  }
                }
              };
              apiPost();
            }}
            color="primary"
            autoFocus
          >
            {t('dialog.button.yes')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default BindingDialog;
