import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { store } from '../../index';
import {
  getUserDevices,
  postUserAppDownload,
} from '../../services/authentication';
import {
  Button,
  ButtonBase,
  // Grid,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DownloadAvailable from '../../resources/images/download-available.png';
import DownloadUnavailable from '../../resources/images/download-unavailable.png';
import BindingAvailable from '../../resources/images/binding-available.png';
import BindingUnavailable from '../../resources/images/binding-unavailable.png';
import PurchaseAvailable from '../../resources/images/purchase-available.png';
import { addToCart } from '../../actions/cart';
import { notify } from '../../actions/notification';
import { Modal } from '../../actions/modal';
import {
  addToDownloadQueue,
  dropFromDownloadQueue,
} from '../../actions/download';
import BindingDialog from '../../components/Alerts/BindingDialog';
import DownloadDialog from '../../components/Alerts/DownloadDialog';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px 16px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const CustomTabs = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d1d1d1;
  justify-content: space-between;
`;

const CustomTab = styled.div`
  height: 50px;
  padding: 8px;
  position: relative;
`;

const TabTitle = styled.div`
  color: #d1d1d1;
  font-size: 18px;
  padding: 2px 16px;
  ${({ active }) =>
    active &&
    `
    background: #0cbaef;
    color: #ffffff;
    border-radius: 20px;
    padding: 2px 16px;
  `}
`;

const TitleLink = styled(Link)`
  text-decoration: none;
`;

const DeviceWrapper = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const DeviceBindingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 16px;
`;

const DeviceBindingImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
`;

const DeviceBindingImage = styled.img`
  width: 158px;
  height: 158px;
  object-fit: scale-down;
`;

const DeviceBindingImageDefaultContainer = styled.div`
  width: 158px;
  height: 158px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d1d1d1;
`;

const DeviceBindingImageDefault = styled.p`
  color: #3e3a39;
`;

const DeviceBindingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
`;

const DeviceBindingInfoName = styled.div`
  font-size: 29px;
  color: #595757;
  font-weight: bold;
`;

const DeviceBindingInfoDescription = styled.div`
  font-size: 11px;
  color: #9fa0a0;
  margin-top: 12px;
  margin-bottom: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行數
  -webkit-box-orient: vertical;
`;

const DeviceBindingInfoCodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DeviceBindingInfoCodeLabel = styled.div`
  font-size: 16px;
  color: #9fa0a0;
  font-weight: bold;
  margin-right: 8px;
`;

const DeviceBindingInfoCode = styled.div`
  font-size: 13px;
  color: #9fa0a0;
`;

const DeviceBindingPurchaseButtonContainer = styled.div`
  width: 20%;
  padding: 0 8px;
`;

const DeviceBindingPurchaseButton = styled(Button)`
  && {
    width: 100%;
    border-radius: 20px;
    height: 28px;
    font-size: 16px;
    color: #ffffff;
    background: #0cbaef;
    text-transform: none;
  }
`;

const ReviewProductButton = styled(Button)`
  && {
    width: 100%;
    border-radius: 20px;
    height: 28px;
    font-size: 16px;
    color: #0cbaef;
    background: #ffffff;
    text-transform: none;
    font-weight: 500;
  }
`;

const AppsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  margin: 0 4px;
  padding: 0 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #29a9e0;
  border-radius: 20px;
  height: 36px;
`;

const TitleApp = styled.div`
  width: 40%;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
`;

const TitleBinding = styled.div`
  width: 20%;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
`;

const TitlePurchase = styled.div`
  width: 20%;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
`;

const TitleDownload = styled.div`
  width: 20%;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
`;

const AppItemsContainer = styled.div`
  margin: 0 8px;
  display: flex;
  flex-direction: column;
`;

const AppItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #d1d1d1;
`;

const AppItemInfoContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const AppItemInfoImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 58px;
  width: 64px;
  height: 64px;
  ${({ image }) =>
    image === '' &&
    `
    background: #d1d1d1;
  `}
`;

const AppItemInfoImage = styled.img`
  width: 64px;
  height: 100%;
  object-fit: scale-down;
`;

const AppImageDefault = styled.p`
  color: #3e3a39;
`;

const AppItemInfoLicenseContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  width: 220px;
`;

const AppItemName = styled.div`
  font-size: 20px;
  color: #595757;
  font-weight: bold;
`;

const AppItemAvailableLicense = styled.div`
  font-size: 16px;
  color: #9fa0a0;
  margin: 4px 0;
  width: 100%;
  ${({ license }) =>
    license > 0 &&
    `
    color: #0cbaef;
  `}
`;

const AppItemBindingLicense = styled.div`
  font-size: 16px;
  color: #9fa0a0;
`;

const AppItemBindingContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;

const AppItemPurchaseContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;

const AppItemDownloadContainer = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
`;

const AppEmptyContainer = styled.div`
  height: 234px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 68px;
  padding-bottom: 16px;
`;

const AppEmptyTitle = styled.div`
  width: 574px;
  color: #707070;
  font-size: 23px;
  line-height: 45px;
  text-align: center;
  margin-bottom: 30px;
`;

const AppEmptyTitleSpan = styled.span`
  color: #707070;
  font-size: 23px;
  font-weight: bold;
  line-height: 45px;
`;

const AppEmptyButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BuyOneButton = styled(Button)`
  && {
    font-size: 14px;
    padding: 4px 20px;
    background: #0cbaef;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #0cbaef;
    width: 246px;
    &:hover {
      color: #0cbaef;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const FinishContainer = styled.div`
  height: 120px;
  padding: 0 8px;
`;

const FinishButtonContainer = styled.div`
  margin-left: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const FinishButton = styled(Button)`
  && {
    width: 100px;
    height: 28px;
    background: #0cbaef;
    color: #ffffff;
    font-size: 16px;
    border-radius: 20px;
    text-transform: none;
  }
`;

// const RecommendWrapper = styled.div`
//   display: block;
//   flex-direction: column;

//   width: 100%;
//   margin-left: auto;
//   margin-right: auto;
//   background: #ebebeb;
//   bottom: 0;
//   height: 168px;
// `;

// const Recommend = styled.div`
//   display: flex;
//   flex-direction: column;

//   width: 1280px;
//   margin-left: auto;
//   margin-right: auto;
// `;

// const RecommendTitleContainer = styled.div`
//   margin-top: 28px;
//   width: 946px;
//   margin-left: auto;
//   margin-right: auto;
// `;

// const RecommendTitle = styled.h2`
//   color: #3e3a39;
//   font-size: 19px;
//   margin-top: 0;
//   margin-bottom: 8px;
// `;

// const RecommendItemContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: space-between;
//   margin-top: 8px;
//   margin-bottom: 32px;
//   width: 946px;
//   margin-left: auto;
//   margin-right: auto;
// `;

// const ItemGrid = styled(Grid)`
//   && {
//     width: 90%;
//     margin-left: auto;
//     margin-right: auto;
//   }
// `;

// const ItemImgGrid = styled(Grid)`
//   && {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// `;

// const ItemImg = styled.img`
//   margin-left: auto;
//   margin-right: auto;
//   width: 64px;
//   height: 64px;
//   object-fit: scale-down;
//   border-radius: 28px;
// `;

// const ItemLink = styled(Link)`
//   display: flex;
//   justify-content: center;
//   text-decoration: none;
//   border-radius: 28px;
// `;

// const TextLink = styled(Link)`
//   text-decoration: none;
// `;

// const DividerGrid = styled(Grid)`
//   display: flex;
//   align-items: center;
//   width: 100%;
// `;

// const ItemSubTitle = styled.div`
//   font-size: 17px;
//   color: #3e3a39;
//   margin-bottom: 4px;
// `;

// const ItemSubDescription = styled.div`
//   font-size: 14px;
//   color: #9fa0a0;
//   margin-top: 4px;
//   margin-bottom: 4px;
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: normal;
//   display: -webkit-box;
//   -webkit-line-clamp: 1; //行數
//   -webkit-box-orient: vertical;
// `;

// const ItemBuyButton = styled(Button)`
//   && {
//     padding: 0;
//     min-width: 44px;
//     border-radius: 100px;
//     background: #0cbaef;
//     color: #ffffff;
//     font-size: 7px;
//     line-height: 14px;
//     margin-bottom: 2px;
//     &:hover {
//       color: #3e3a39;
//     }
//   }
// `;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  margin: calc(50vh - 45px) auto 0;
`;

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#9fa0a0',
  },
  arrow: {
    color: '#9fa0a0',
  },
})(Tooltip);

const DeviceBinding = () => {
  const { t, i18n } = useTranslation();
  const { devid } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const download = useSelector((state) => state.download);
  const [data, setData] = useState({
    device: {},
    apps: [],
    recommend: [],
  });
  const [loading, setLoading] = useState(true);
  const [bindingOpen, setBindingOpen] = useState(false);
  const [bindingInfo, setBindingInfo] = useState({
    app: '',
    device: '',
  });
  const [refresh, setRefresh] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [bindingVersion, setBindingVersion] = useState([]);
  const [downloadOpen, setDownloadOpen] = useState(false);
  const [downloadInfo, setDownloadInfo] = useState({});

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');

  useEffect(() => {
    var fetchData = async () => {
      try {
        const result = await getUserDevices(
          devid,
          {
            params: { lng: i18n.language },
          },
          user.token.accessToken
        );
        setData(result.data);
        setLoading(false);
        var tooltip = {};
        if (result.data.apps) {
          result.data.apps.forEach((element) => {
            tooltip[element.id + result.data.device.deviceId] = false;
          });
          setTooltipOpen(tooltip);
        }
      } catch (e) {
        setData({
          device: {},
          apps: [],
          recommend: [],
        });
        setLoading(false);
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };
    if (user.token === null) {
      if (ticket === null) {
        dispatch(Modal('', true));
      } else {
        var reLogin = setInterval(() => {
          if (store.getState().user.token) {
            fetchData();
            clearInterval(reLogin);
          }
        }, 100);
      }
    } else {
      fetchData();
    }
  }, [i18n, i18n.language, user, dispatch, devid, refresh, ticket]);

  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <CustomTabs>
            <CustomTab>
              <TitleLink className="customTab" to="/users/profile">
                <TabTitle>{t('user.tab.title.profile')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/order">
                <TabTitle>{t('user.tab.title.order')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/wishList">
                <TabTitle>{t('user.tab.title.wishList')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/deviceList">
                <TabTitle active={true}>{t('user.tab.title.device')}</TabTitle>
              </TitleLink>
              <div className="triangle active" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/appList">
                <TabTitle>{t('user.tab.title.app')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
          </CustomTabs>
          {loading ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : (
            <DeviceWrapper>
              <BindingDialog
                open={bindingOpen}
                close={() => setBindingOpen(false)}
                info={bindingInfo}
                refresh={() => setRefresh(refresh + 1)}
                version={bindingVersion}
                closing={(d) => setBindingVersion(d)}
              />
              <DownloadDialog
                open={downloadOpen}
                close={() => setDownloadOpen(false)}
                info={bindingInfo}
                refresh={() => setRefresh(refresh + 1)}
                version={bindingVersion}
                closing={() => setBindingVersion([])}
                downloadInfo={downloadInfo}
                clearDownloadInfo={() => setDownloadInfo({})}
              />
              <DeviceBindingContainer>
                <DeviceBindingImageContainer>
                  {data.device.image ? (
                    <DeviceBindingImage
                      src={data.device.image}
                      alt={data.device.name}
                    />
                  ) : (
                    <DeviceBindingImageDefaultContainer>
                      <DeviceBindingImageDefault>
                        KNEO
                      </DeviceBindingImageDefault>
                    </DeviceBindingImageDefaultContainer>
                  )}
                </DeviceBindingImageContainer>
                <DeviceBindingInfoContainer>
                  <DeviceBindingInfoName>
                    {data.device.name}
                  </DeviceBindingInfoName>
                  <DeviceBindingInfoDescription>
                    {data.device.description}
                  </DeviceBindingInfoDescription>
                  <DeviceBindingInfoCodeContainer>
                    <DeviceBindingInfoCodeLabel>
                      {t('device.title.code')}
                    </DeviceBindingInfoCodeLabel>
                    <DeviceBindingInfoCode>
                      {data.device.code}
                    </DeviceBindingInfoCode>
                  </DeviceBindingInfoCodeContainer>
                </DeviceBindingInfoContainer>
                <DeviceBindingPurchaseButtonContainer>
                  <DeviceBindingPurchaseButton
                    onClick={() => {
                      dispatch(
                        addToCart(
                          data.device.id,
                          data.device.image,
                          data.device.name,
                          data.device.description,
                          data.device.product,
                          data.device.category,
                          data.device.provider,
                          data.device.providerId,
                          data.device.version,
                          data.device.versionIndex,
                          data.device.price,
                          1
                        )
                      );
                      var timeout = setInterval(() => {
                        if (
                          store
                            .getState()
                            .cart.addedProducts.some(function (
                              cartItem,
                              index,
                              array
                            ) {
                              return data.device.id === cartItem.id;
                            })
                        ) {
                          history.push('/checkout/cart');
                          clearInterval(timeout);
                        }
                      }, 100);
                    }}
                  >
                    {t('device.title.purchaseMore')}
                  </DeviceBindingPurchaseButton>
                </DeviceBindingPurchaseButtonContainer>
              </DeviceBindingContainer>
              <AppsContainer>
                <TitleContainer>
                  <TitleApp>{t('device.title.app')}</TitleApp>
                  <TitleBinding>{t('device.title.binding')}</TitleBinding>
                  <TitlePurchase>
                    {t('device.title.purchaseMore')}
                  </TitlePurchase>
                  <TitleDownload>{t('device.title.download')}</TitleDownload>
                </TitleContainer>
                {data.apps && data.apps.length !== 0 ? (
                  <AppItemsContainer>
                    {data.apps &&
                      data.apps.map((item, index) => (
                        <AppItemContainer key={index}>
                          <AppItemInfoContainer>
                            <AppItemInfoImageContainer image={item.image}>
                              {item.image ? (
                                <AppItemInfoImage
                                  src={item.image}
                                  alt={item.name}
                                />
                              ) : (
                                <AppImageDefault>KNEO</AppImageDefault>
                              )}
                            </AppItemInfoImageContainer>
                            <AppItemInfoLicenseContainer>
                              <AppItemName>{item.name}</AppItemName>
                              <AppItemAvailableLicense
                                license={item.license.available}
                              >
                                {`${t('device.title.availableLicense')}: ${item.license.available
                                  }`}
                              </AppItemAvailableLicense>
                              <AppItemBindingLicense>
                                {`${t('device.title.bindingLicense')}: ${item.license.bind
                                  }`}
                              </AppItemBindingLicense>
                            </AppItemInfoLicenseContainer>
                          </AppItemInfoContainer>
                          <AppItemBindingContainer>
                            <CustomTooltip
                              title={item.binding ? '' : t('app.message.bind')}
                              placement="top"
                              arrow
                              open={
                                tooltipOpen[item.id + data.device.deviceId] ===
                                  undefined
                                  ? false
                                  : tooltipOpen[item.id + data.device.deviceId]
                              }
                            >
                              <ButtonBase
                                disabled={item.binding}
                                onClick={() => {
                                  if (item.license.available > 0) {
                                    setBindingInfo({
                                      app: item.id,
                                      device: data.device.deviceId,
                                    });
                                    setBindingOpen(true);
                                  } else {
                                    dispatch(
                                      notify(
                                        'info',
                                        t('app.message.less'),
                                        true
                                      )
                                    );
                                  }
                                }}
                              >
                                <img
                                  src={
                                    item.binding
                                      ? BindingUnavailable
                                      : BindingAvailable
                                  }
                                  alt=""
                                />
                              </ButtonBase>
                            </CustomTooltip>
                          </AppItemBindingContainer>
                          <AppItemPurchaseContainer>
                            <ButtonBase
                              onClick={() => {
                                dispatch(
                                  addToCart(
                                    item.id,
                                    item.image,
                                    item.name,
                                    item.description,
                                    item.product,
                                    item.category,
                                    item.provider,
                                    item.providerId,
                                    item.version,
                                    item.versionIndex,
                                    item.price,
                                    1
                                  )
                                );
                                var timeout = setInterval(() => {
                                  if (
                                    store
                                      .getState()
                                      .cart.addedProducts.some(function (
                                        cartItem,
                                        index,
                                        array
                                      ) {
                                        return item.id === cartItem.id;
                                      })
                                  ) {
                                    history.push('/checkout/cart');
                                    clearInterval(timeout);
                                  }
                                }, 100);
                              }}
                            >
                              <img src={PurchaseAvailable} alt="" />
                            </ButtonBase>
                          </AppItemPurchaseContainer>
                          <AppItemDownloadContainer>
                            {download.some(function (loading, index, array) {
                              return (
                                item.id + data.device.deviceId === loading.Id
                              );
                            }) ? (
                              <CircularProgress style={{ color: '#0cbaef' }} />
                            ) : (
                              <CustomTooltip
                                title={item.binding ? `` : t('app.message.tip')}
                                placement="top"
                                arrow
                                onOpen={() => {
                                  setTooltipOpen({
                                    ...tooltipOpen,
                                    [item.id + data.device.deviceId]: true,
                                  });
                                }}
                                onClose={() => {
                                  setTooltipOpen({
                                    ...tooltipOpen,
                                    [item.id + data.device.deviceId]: false,
                                  });
                                }}
                              >
                                <div>
                                  <ButtonBase
                                    disabled={!item.binding}
                                    onClick={() => {
                                      if (item.review) {
                                        setBindingVersion(item.versions);
                                        setDownloadInfo({
                                          downloadQueueId:
                                            item.id + data.device.deviceId,
                                          device_uid: data.device.deviceId,
                                          product_uid: item.id,
                                          filename: item.name,
                                        });
                                        setDownloadOpen(true);
                                      } else {
                                        dispatch(
                                          addToDownloadQueue(
                                            item.id + data.device.deviceId,
                                            0
                                          )
                                        );
                                        const apiFetch = async () => {
                                          try {
                                            const result = await postUserAppDownload(
                                              {
                                                device_uid:
                                                  data.device.deviceId,
                                                product_uid: item.id,
                                                filename: item.name,
                                                version: false,
                                              },
                                              user.token.accessToken
                                            );

                                            let a = document.createElement('a');
                                            a.href =
                                              process.env.REACT_APP_API_URL +
                                              '/apps/download?&user=' +
                                              user.info.email +
                                              '&timestamp=' +
                                              result.data.time +
                                              '&filename=' +
                                              result.data.filename;
                                            a.click();
                                            dispatch(
                                              dropFromDownloadQueue(
                                                item.id + data.device.deviceId
                                              )
                                            );
                                          } catch (e) {
                                            dispatch(
                                              dropFromDownloadQueue(
                                                item.id + data.device.deviceId
                                              )
                                            );
                                            if (e && e.response) {
                                              switch (e.response.status) {
                                                case 400:
                                                  dispatch(
                                                    notify(
                                                      'error',
                                                      i18n.t('message.400'),
                                                      true
                                                    )
                                                  );
                                                  break;
                                                case 401:
                                                  dispatch(Modal('', true));
                                                  break;
                                                case 500:
                                                  dispatch(
                                                    notify(
                                                      'error',
                                                      i18n.t('message.500'),
                                                      true
                                                    )
                                                  );
                                                  break;
                                                default:
                                                  dispatch(
                                                    notify(
                                                      'error',
                                                      i18n.t('message.error'),
                                                      true
                                                    )
                                                  );
                                              }
                                            }
                                          }
                                        };
                                        apiFetch();
                                      }
                                    }}
                                  >
                                    <img
                                      src={
                                        item.binding
                                          ? DownloadAvailable
                                          : DownloadUnavailable
                                      }
                                      alt=""
                                    />
                                  </ButtonBase>
                                </div>
                              </CustomTooltip>
                            )}
                          </AppItemDownloadContainer>
                        </AppItemContainer>
                      ))}
                  </AppItemsContainer>
                ) : (
                  <AppEmptyContainer>
                    <AppEmptyTitle>
                      {t('device.app.emptyTitle1')}
                      <AppEmptyTitleSpan>KNEO</AppEmptyTitleSpan>
                      {t('device.app.emptyTitle2')}
                    </AppEmptyTitle>
                    <AppEmptyButtonContainer>
                      <BuyOneButton
                        onClick={() => {
                          history.push('/products/software');
                        }}
                      >
                        {t('device.button.buyOne')}
                      </BuyOneButton>
                    </AppEmptyButtonContainer>
                  </AppEmptyContainer>
                )}
              </AppsContainer>
              {data.apps && data.apps.length !== 0 && (
                <FinishContainer>
                  <FinishButtonContainer>
                    <FinishButton
                      onClick={() => {
                        history.push('/users/deviceList');
                      }}
                    >
                      {t('device.button.finish')}
                    </FinishButton>
                  </FinishButtonContainer>
                </FinishContainer>
              )}
            </DeviceWrapper>
          )}
        </ContentWrapper>
      </Wrapper>
      {/* <RecommendWrapper>
        <Recommend>
          <RecommendTitleContainer>
            <RecommendTitle>{t('device.title.recommend')}</RecommendTitle>
          </RecommendTitleContainer>
          <RecommendItemContainer>
            {data.recommend.map((item, index) => (
              <ItemGrid key={index} container spacing={2}>
                <ItemImgGrid item xs={5}>
                  <ItemLink to={`/products/softwares/${item.id}`}>
                    <ItemImg src={item.image} alt="" />
                  </ItemLink>
                </ItemImgGrid>
                <DividerGrid item xs={7}>
                  <Grid>
                    <TextLink to={`/products/softwares/${item.id}`}>
                      <ItemSubTitle>{item.name}</ItemSubTitle>
                    </TextLink>
                    <TextLink to={`/products/softwares/${item.id}`}>
                      <ItemSubDescription>
                        {item.description}
                      </ItemSubDescription>
                    </TextLink>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <ItemBuyButton
                          variant="contained"
                          disableElevation
                          onClick={() => {
                            dispatch(
                              addToCart(
                                item.id,
                                item.image,
                                item.name,
                                item.description,
                                item.product,
                                item.category,
                                item.price,
                                1
                              )
                            );
                            history.push('/checkout/cart');
                          }}
                        >
                          {t('device.button.buy')}
                        </ItemBuyButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </DividerGrid>
              </ItemGrid>
            ))}
          </RecommendItemContainer>
        </Recommend>
      </RecommendWrapper> */}
    </Fragment>
  );
};

export default DeviceBinding;
