import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { Button } from '@material-ui/core';
import NextArrow from '../../resources/images/next-arrow.png';
import PrevArrow from '../../resources/images/prev-arrow.png';
import { addToCart } from '../../actions/cart';
import AddIcon from '@material-ui/icons/Add';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 14px 0px 32px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const SlickHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
`;

const SlickHeader = styled.h2`
  color: #595757;
  font-size: 26px;
  white-space: nowrap;
  padding-left: 8px;
  margin: 0px;
  padding-right: 96px;
`;

const SlickHeaderLink = styled(Link)`
  text-decoration: none;
  color: #595757;
`;

const HeaderDivider = styled.hr`
  border: 1px solid #ffffff;
  background: #898989;
  border-style: inset;
`;

const DividerGrid = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;

const DividerContainer = styled.div`
  width: 100%;
  margin: auto;
`;

const NameLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  height: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3; //行數
  -webkit-box-orient: vertical;
`;

const ProviderLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  height: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 3; //行數
  -webkit-box-orient: vertical;
`;

const Arrow = styled.img`
  color: #898989;
  && {
    width: 16px;
    height: 32px;
  }
  &:hover {
    color: #999999;
    background: #f8f9fa;
    border-radius: 100px;
  }
`;

const SlickWrapper = styled.div`
  padding: 0 26px;
`;

const SlickGrid = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const ImgGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

const SlickImg = styled.img`
  // margin-left: auto;
  // margin-right: auto;
  width: 140px;
  // border-radius: 15px;
  object-fit: scale-down;
  height: 140px;
`;

const SlickLink = styled(Link)`
  text-decoration: none;
`;

const SlickImgDefaultContainer = styled.div`
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d1d1d1;
`;

const SlickImgDefault = styled.p`
  color: #3e3a39;
`;

const SlickSubTitle = styled.div`
  font-size: 17px;
  font-weight: bold;
  color: #3e3a39;
  line-height: 20px;
  height: 45%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  ${({ discount }) =>
    discount &&
    `
    margin-right: 36px;
  `}
`;

const LowerContainer = styled.div`
  height: 55%;
  width: 100%;
`;

const SlickSubDescription = styled.div`
  font-size: 15px;
  line-height: 20px;
  color: #9fa0a0;
  margin-top: 6px;
  margin-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;
  @media (max-width: 1440px) {
    font-size: 16px;
  }
`;

const SlickButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // margin-top: 4px;
`;

const SlickBuyButton = styled(Button)`
  && {
    padding: 0;
    min-width: 100px;
    border-radius: 100px;
    background: #0cbaef;
    color: #ffffff;
    font-size: 11px;
    line-height: 16px;
    &:hover {
      color: #3e3a39;
    }
    ${({ discount }) =>
      discount === `true` &&
      `
      background: #ff0000;
    `}
  }
`;

const SlickAddButton = styled(Button)`
  && {
    padding: 0;
    min-width: 16px;
    border-radius: 100px;
    background: #ef980c;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    &:hover {
      color: #3e3a39;
    }
  }
`;

const ItemAddIcon = styled(AddIcon)`
  && {
    font-size: 16px;
  }
`;

const SlickComingSoon = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #0cbaef;
  line-height: 15px;
`;

const ItemBadge = styled.div`
  position: absolute;
  width: 35px;
  height: 35px;
  top: 0;
  right: 8px;
  background: linear-gradient(
    0deg,
    rgba(179, 20, 14, 1) 0%,
    rgba(223, 48, 38, 1) 50%,
    rgba(253, 91, 71, 1) 100%
  );
  -webkit-clip-path: polygon(
    100% 0,
    92% 18%,
    92% 100%,
    50% 80%,
    8% 100%,
    8% 18%,
    0 0
  );
  clip-path: polygon(100% 0, 92% 18%, 92% 100%, 50% 80%, 8% 100%, 8% 18%, 0 0);
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const BadgeMessage = styled.div`
  color: #ffffff;
  text-align: center;
  font-size: 4px;
  font-weight: bold;
  line-height: 12px;
  height: 28px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LandingPageHardware = (props) => {
  const { t } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const { data } = props;
  const slickSettings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <Arrow src={NextArrow} alt="" />,
    prevArrow: <Arrow src={PrevArrow} alt="" />,
  };

  var hardwares = [];
  data.forEach((item) => {
    hardwares.push(item);
  });
  if (data.length < 4) {
    for (let i = 0; i < 4 - data.length; i++) {
      hardwares.push({
        id: '',
        image: '',
      });
    }
  }
  return (
    <Wrapper>
      <SlickHeaderContainer>
        <SlickHeader>
          <SlickHeaderLink to="/products/hardware">
            {t('landingPage.hardware.header')}
          </SlickHeaderLink>
        </SlickHeader>
        <DividerContainer>
          <HeaderDivider />
        </DividerContainer>
      </SlickHeaderContainer>
      <SlickWrapper>
        <Slider {...slickSettings}>
          {hardwares.map((item, index) =>
            item.id !== '' ? (
              <div key={index}>
                <SlickGrid>
                  <ImgGrid>
                    <SlickLink
                      to={`/products/hardwares/${item.id}/${item.versionIndex}`}
                    >
                      {item.image ? (
                        <SlickImg src={item.image} alt={item.name} />
                      ) : (
                        <SlickImgDefaultContainer>
                          <SlickImgDefault>KNEO</SlickImgDefault>
                        </SlickImgDefaultContainer>
                      )}
                    </SlickLink>
                  </ImgGrid>
                  <DividerGrid>
                    <SlickSubTitle discount={item.discount}>
                      <NameLink
                        to={`/products/hardwares/${item.id}/${item.versionIndex}`}
                      >
                        {item.name}
                      </NameLink>
                    </SlickSubTitle>
                    <LowerContainer>
                      <SlickSubDescription>
                        <ProviderLink
                          to={`/products/hardwares/${item.id}/${item.versionIndex}`}
                        >
                          {item.provider}
                        </ProviderLink>
                      </SlickSubDescription>
                      <SlickButtonContainer>
                        {item.display ? (
                          <>
                            <SlickBuyButton
                              discount={item.discount.toString()}
                              variant="contained"
                              disableElevation
                              onClick={() => {
                                dispatch(
                                  addToCart(
                                    item.id,
                                    item.image,
                                    item.name,
                                    item.description,
                                    item.product,
                                    item.category,
                                    item.provider,
                                    item.providerId,
                                    item.version,
                                    item.versionIndex,
                                    item.price,
                                    1
                                  )
                                );
                                history.push('/checkout/cart');
                              }}
                            >
                              {`NT $${item.price}`}
                            </SlickBuyButton>
                            <SlickAddButton
                              variant="contained"
                              disableElevation
                              onClick={() => {
                                dispatch(
                                  addToCart(
                                    item.id,
                                    item.image,
                                    item.name,
                                    item.description,
                                    item.product,
                                    item.category,
                                    item.provider,
                                    item.providerId,
                                    item.version,
                                    item.versionIndex,
                                    item.price,
                                    1
                                  )
                                );
                              }}
                            >
                              <ItemAddIcon />
                            </SlickAddButton>
                          </>
                        ) : (
                          <SlickComingSoon>Coming Soon</SlickComingSoon>
                        )}
                      </SlickButtonContainer>
                    </LowerContainer>
                  </DividerGrid>
                  {item.discount && (
                    <ItemBadge>
                      <BadgeMessage>
                        <div>On Sales</div>
                      </BadgeMessage>
                    </ItemBadge>
                  )}
                </SlickGrid>
              </div>
            ) : (
              <div key={index}></div>
            )
          )}
        </Slider>
      </SlickWrapper>
    </Wrapper>
  );
};

export default LandingPageHardware;
