import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dialog, IconButton, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { getProductHardware } from '../../services/productPage';
import Information from '../Hardwares/Information';
import Divider from '../Divider';
import Preview from '../Hardwares/Preview';
import Specification from '../Hardwares/Specification';
// import Rating from '../Hardwares/Rating';
import HardwareInfo from '../Hardwares/HardwareInfo';

const Wrapper = styled.div`
  padding: 0 88px;
  width: 893px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: calc(50vh + 10px) auto;
`;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const HardwareDialog = (props) => {
  const { i18n } = useTranslation();
  const { id, versionIndex } = props;
  const user = useSelector((state) => state.user);
  const [data, setData] = useState({
    info: {},
    preview: [],
    spec: {},
    rating: { comment: [] },
    hardwareInfo: {},
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    var fetchData = async () => {
      try {
        var token = '';
        if (user.token) {
          token = user.token.accessToken;
        }
        const result = await getProductHardware(id, versionIndex, {
          params: { lng: i18n.language },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(result.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    if (id !== '') {
      fetchData();
    }
  }, [id, versionIndex, i18n, i18n.language, user.token, props.open]);
  return (
    <Dialog
      // fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="responsive-dialog-title"
      maxWidth={false}
      onExit={() => {
        setData({
          info: {},
          preview: [],
          spec: {},
          rating: { comment: [] },
          hardwareInfo: {},
        });
        setLoading(true);
      }}
    >
      <DialogTitle id="customized-dialog-title" onClose={props.close} />
      <Wrapper>
        {loading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          <>
            <Information data={data.info} dialog={true} />
            <Divider dialog={true} />
            <Preview data={data.preview} dialog={true} />
            <Divider dialog={true} />
            <Specification data={data.spec} dialog={true} />
            <Divider dialog={true} />
            {/* <Rating data={data.rating} dialog={true} />
        <Divider dialog={true} /> */}
            <HardwareInfo data={data.hardwareInfo} dialog={true} />
          </>
        )}
      </Wrapper>
    </Dialog>
  );
};

export default HardwareDialog;
