import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  IconButton,
  useMediaQuery,
  Card,
  Grid,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { getLegalPage } from '../../services/landingPage';
import clsx from 'clsx';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const CardTitle = styled.h2`
  font-size: 28px;
  color: #3e3a39;
  margin-top: 31px;
  margin-left: 16px;
  margin-bottom: 8px;
`;

const CardContent = styled.div`
  font-size: 15px;
  color: #3e3a39;
  margin-top: 40px;
  margin-left: 16px;
  height: 164px;
  width: 304px;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

const CardContentReply = styled.div`
  font-size: 15px;
  color: #0cbaef;
  margin-top: 45px;
  margin-left: 0px;
  height: 177px;
  width: 304px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const CardRightGrid = styled(Grid)`
  && {
    position: relative;
  }
`;

const CardBottomGrid = styled(Grid)`
  && {
    position: relative;
  }
`;

const CardIconContainer = styled.div`
  position: absolute;
  top: 48.2px;
  right: 55px;
`;

const CardEditRemind = styled.div`
  position: absolute;
  font-size: 16px;
  font-weight: 300;
  color: #3e3a39;
  text-align: left;
  font-weight: bold;
  ${({ side }) => `
    ${side}: ${side==="right"?"42px":"39.5px"};
    bottom: ${side==="right"?"23px":"33px"};
  `}
`;

const CardDate = styled.div`
  position: absolute;
  bottom: 0px;
  right: 42px;
  font-size: 16px;
  font-weight: 300;
  color: #3e3a39;
  text-align: left;
`;

const CardDateReply = styled.div`
  position: absolute;
  font-size: 16px;
  font-weight: 300;
  color: #3e3a39;
  text-align: left;
  left: 39.5px;
  top: 200px;
`;

const WrapperDivide = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0;
  width: 450px;
  margin-left: 18px;
  margin-right: 20px;
  margin-top: 18px;

  ${({ dialog }) =>
    dialog &&
    `
    display: flex;
    flex-direction: column;

    padding: 0;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const Divide = styled.hr`
  border: 1px solid #ffffff;
  background: #898989;
  border-style: inset;
  // border-width: 1px;
`;

const DividerCard = (props) => {
  const { dialog } = props;
  return (
    <WrapperDivide dialog={dialog}>
      <div>
        <Divide />
      </div>
    </WrapperDivide>
  );
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const useStyles = makeStyles((theme) => ({
  backDrop: {
    backdropFilter: 'blur(3px)',
    backgroundColor: 'rgba(0,0,30,0.4)',
  },
  paperLight: {
    width: '507px',
    height: '509px',
    background: '#f5f5f5',
    padding: 16,
    borderRadius: 20,
    // margin: '8px 0px 26px 8px',
    // [theme.breakpoints.down(1440)]: {
    //   margin: '8px 4px',
    // },
  },
}));

const StyledCardRating = withStyles({
  iconFilled: {
    color: '#0cbaef',
  },
  iconHover: {
    color: '#0cbaef',
  },
})(Rating);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ReviewDialog = (props) => {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [data, setData] = useState({
    title: '',
    foreword: '',
    content: '',
    contact: '',
    notice: '',
  });
  useEffect(() => {
    if (props.open) {
      var fetchData = async () => {
        try {
          const result = await getLegalPage({
            params: { lng: i18n.language },
          });
          setData(result.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, [i18n, i18n.language, props]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(1440));
  return (
    <Dialog
      // fullScreen={fullScreen}
      open={props.open}
      onClose={props.close}
      aria-labelledby="responsive-dialog-title"
      maxWidth="lg"
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" />
      {/* <Wrapper> */}
      {/* <ContentWrapper> */}
      {/* <TitleContainer>{data.title}</TitleContainer>
          <ForewordContainer>{data.foreword}</ForewordContainer> */}
      {/* <ContentContainer>{data.content}</ContentContainer>
          <ContactContainer>{data.contact}</ContactContainer>
          <NoticeContainer>{data.notice}</NoticeContainer> */}

      {props.data.map((item, index) => (
        <Card className={clsx(classes.paperLight)} key={index}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <CardTitle>{item.author}</CardTitle>
              <CardContent>{item.content}</CardContent>
            </Grid>
            <CardRightGrid item xs={4}>
              <CardIconContainer>
                <StyledCardRating
                  name="read-only"
                  value={item.score === undefined ? 0 : item.score}
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                  size="medium"
                  readOnly
                />
              </CardIconContainer>
              { item.modified && <CardEditRemind side={"right"}>{t("product.software.other.edited")}</CardEditRemind> }                  
              <CardDate>{item.date}</CardDate>
            </CardRightGrid>
            <CardBottomGrid item xs={4}>
              {item.review_reply !== undefined && <DividerCard />}
              { item.reply_modified && <CardEditRemind side={"left"}>{t("product.software.other.edited")}</CardEditRemind> }
              <CardDateReply>{item.reply_time}</CardDateReply>
            </CardBottomGrid>
            <CardBottomGrid item xs={7}>
              <CardContentReply>{item.review_reply}</CardContentReply>
            </CardBottomGrid>
          </Grid>
        </Card>
      ))}
      {/* </ContentWrapper> */}
      {/* </Wrapper> */}
    </Dialog>
  );
};

export default ReviewDialog;
