import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { store } from '../../index';
import { Button, CircularProgress, IconButton, Tooltip, } from '@material-ui/core';
import { getUserOrder } from '../../services/authentication';
import { Modal } from '../../actions/modal';
import { notify } from '../../actions/notification';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import OrderInternationalDialog from './OrderInternationalDialog';
import OrderRefundDialog from './OrderRefundDialog';
import CVSDialog from '../CVSDialog';
import { withStyles } from '@material-ui/core/styles';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px 16px;
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
`;

const CustomTabs = styled.div`
  width: 946px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #d1d1d1;
  justify-content: space-between;
`;

const CustomTab = styled.div`
  height: 50px;
  padding: 8px;
  position: relative;
`;

const TabTitle = styled.div`
  color: #d1d1d1;
  font-size: 18px;
  padding: 2px 16px;
  ${({ active }) =>
    active &&
    `
    background: #0cbaef;
    color: #ffffff;
    border-radius: 20px;
    padding: 2px 16px;
  `}
`;

const TitleLink = styled(Link)`
  text-decoration: none;
`;

const OrderContentContainer = styled.div`
  margin: 0 0 8px;
  width: 940px;
  margin-left: auto;
  margin-right: auto;
`;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0;
  ${({ active }) =>
    active !== 0 &&
    `
  border-top: 1px dashed #707070;
  padding-top: 32px;
`}
`;

const OrderTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OrderTitleLabel = styled.div`
  margin-left: 32px;
  font-size: 18px;
  color: #000000;
`;

const OrderTitleValue = styled.div`
  margin-left: 16px;
  font-size: 22px;
  color: #9fa0a0;
  // line-height: 1.5;
`;

const OrderListContainer = styled.div`
  border: 1px solid #d1d1d1;
  border-radius: 20px;
  margin-top: 16px;
`;

const OrderListTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 64px;
  background: #ebebeb;
  border-top-left-radius: 19px 19px;
  border-top-right-radius: 19px 19px;
  align-items: center;
  padding: 0 32px;
`;

const OrderListTitleItem = styled.div`
  font-size: 18px;
  color: #000000;
  width: 49%;
`;

const OrderListQuantity = styled.div`
  font-size: 18px;
  color: #000000;
  width: 17%;
  text-align: center;
`;

const OrderListQA = styled.div`
  font-size: 18px;
  color: #000000;
  width: 17%;
  text-align: center;
`;

const OrderListDelivery = styled.div`
  font-size: 18px;
  color: #000000;
  width: 17%;
  text-align: center;
`;

const OrderListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-right: 32px;
  position: relative;
  ${({ active }) =>
    active !== 0 &&
    `
    &:after {
    content: '';
    position: absolute;
    right: 32px;
    left: 32px;
    top: 0;
    height: 0.5px;
    background: #d1d1d1;
    `}
  }
`;

const OrderItemInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 49%;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
`;

const ImageLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #3e3a39;
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
  `}
`;

const Image = styled.img`
  width: 64px;
  height: 64px;
  object-fit: scale-down;
  ${({ isPhaseout }) =>
  isPhaseout &&
    `
    -webkit-filter:grayscale(2);
  `}
`;

const ImageDefaultContainer = styled.div`
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d1d1d1;
`;

const ImageDefault = styled.p`
  color: #3e3a39;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const NameContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const NameLink = styled(Link)`
  text-decoration: none;
  color: #3e3a39;
  ${({ isPhaseout }) =>
  isPhaseout &&
    `
    text-decoration:line-through 2px;
    cursor: default;
  `}
`;

const CategoryContainer = styled.div`
  font-size: 13px;
  margin: 4px 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;
  width: 100%;
`;

const CategoryLink = styled(Link)`
  text-decoration: none;
  color: #9fa0a0;
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
  `}
`;

const DescrtptionContainer = styled.div`
  font-size: 13px;
  color: #9fa0a0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;
`;

const DescrtptionLink = styled(Link)`
  text-decoration: none;
  color: #9fa0a0;
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
  `}
`;

const OrderItemQuantityContainer = styled.div`
  width: 17%;
  text-align: center;
  color: #9fa0a0;
  font-size: 24px;
`;

const OrderItemQAContainer = styled.div`
  width: 17%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OrderItemQAContainerButton = styled(Button)`
  && {
    color: #9fa0a0;
    font-size: 16px;
    text-transform: none;
  }
`;

const OrderItemDeliveryContainer = styled.div`
  width: 17%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OrderItemDelivery = styled.div`
  font-size: 16px;
  color: #9fa0a0;
  padding: 0 12px 2px;
  line-height: 26px;
  border: 1px solid transparent;
  text-align: center;
`;

const OrderItemDeliveryComplete = styled.div`
  font-size: 13px;
  color: #ffffff;
  border-radius: 20px;
  padding: 0 12px;
  line-height: 26px;
  border: 1px solid transparent;
  background: #9fa0a0;
`;

const OrderItemDeliveryFareEstimation = styled.div`
  font-size: 16px;
  color: #9fa0a0;
  padding: 0 12px 4px;
  line-height: 26px;
  border: 1px solid transparent;
`;

const OrderItemDeliveryFareConfirmationButton = styled.button`
  font-size: 13px;
  color: #ffffff;
  border-radius: 20px;
  padding: 0 12px;
  line-height: 26px;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  background: #ef980c;
`;

const OrderItemDeliveryReturnButton = styled.button`
  font-size: 13px;
  color: #ffffff;
  border-radius: 20px;
  padding: 0 12px;
  line-height: 26px;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  background: #0cbaef;
`;

const OrderItemDeliveryCompleteButton = styled.button`
  font-size: 13px;
  color: #ffffff;
  border-radius: 20px;
  padding: 0 12px;
  line-height: 26px;
  border: 1px solid transparent;
  cursor: pointer;
  outline: none;
  background: #9fa0a0;
`;

const EmptyOrderContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 360px;
  background: #f9f9f9;
  flex-direction: column;
  margin-top: 72px;
`;

const EmptyOrderTitleContainer = styled.div`
  text-align: center;
  font-size: 39px;
  color: #707070;
  font-weight: bold;
`;

const EmptyOrderDescriptionContainer = styled.div`
  text-align: center;
  font-size: 29px;
  color: #707070;
`;

const EmptyOrderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SoftwareButton = styled(Button)`
  && {
    font-size: 14px;
    padding: 4px 20px;
    background: #0cbaef;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #0cbaef;
    width: 106px;
    margin-right: 16px;
    &:hover {
      color: #0cbaef;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const HardwareButton = styled(Button)`
  && {
    font-size: 14px;
    padding: 4px 20px;
    background: #ef980c;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #ef980c;
    width: 106px;
    margin-left: 16px;
    &:hover {
      color: #ef980c;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  margin: calc(50vh - 45px) auto 0;
`;

const PagingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 940px;
  margin-left: auto;
  margin-right: auto;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PagingIconButton = styled(IconButton)`
  && {
    padding: 0;
  }
`;

const CustomNavigateBeforeIcon = styled(NavigateBeforeIcon)`
  height: 16px;
  color: #939393;
  && {
    padding: 8px;
  }
`;

const CustomNavigateNextIcon = styled(NavigateNextIcon)`
  height: 16px;
  color: #939393;
  && {
    padding: 8px;
  }
`;

const PagingInput = styled.input`
  width: 58px;
  height: 22px;
  border-radius: 20px;
  border: 1px solid #cacaca;
  text-align: center;
  color: #d1d1d1;
  outline: none;
  font-size: 16px;
  line-height: 25px;
  margin-right: 6px;
  margin-left: 10px;
`;

const PagingButton = styled(Button)`
  && {
    margin-left: 6px;
    margin-right: 10px;
    height: 26px;
    font-size: 16px;
    color: #ffffff;
    background: #cacaca;
    border-radius: 20px;
    border: 1px solid #cacaca;
    line-height: 23px;
    &:hover {
      color: #cacaca;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const PagingInstruction = styled.div`
  font-size: 16px;
  color: #9fa0a0;
  margin-right: 6px;
  margin-left: 12px;
`;

const PagingNumber = styled(Button)`
  && {
    margin: 0 8px;
    color: #939393;
    font-size: 20px;
    min-width: 20px;
    padding: 0;
    ${({ current }) =>
      current === 'true' &&
      `
      color: #0cbaef;
    `}
  }
`;

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#9fa0a0',
    fontSize: '15px',
    fontWeight: '700'
  },
  arrow: {
    color: '#9fa0a0',
  },
})(Tooltip);

const Order = () => {
  const Length = 10;
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [data, setData] = useState({
    order: [],
  });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [number, setNumber] = useState(0);
  const [pageIndex, setPageIndex] = useState([]);
  const [dialogInternationalOpen, setDialogInternationalOpen] = useState(false);
  const [dialogInternationalOrderId, setdialogInternationalOrderId] = useState(
    ''
  );
  const [dialogRefundOpen, setDialogRefundOpen] = useState(false);
  const [dialogRefundOrderId, setdialogRefundOrderId] = useState('');
  const [dialogCVSOpen, setDialogCVSOpen] = useState(false);
  const [dialogCVSOrderId, setdialogCVSOrderId] = useState('');
  const [tooltipOpen, setTooltipOpen] = useState({});

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    var fetchData = async () => {
      try {
        const result = await getUserOrder(
          {
            params: {
              lng: i18n.language,
              index: page - 1,
              length: Length,
            },
          },
          user.token.accessToken
        );
        setData(result.data);
        var index = Math.ceil(result.data.length / Length);
        var indexArray = [];
        for (var i = 0; i < index; i++) {
          indexArray.push(i + 1);
        }
        setPageIndex(indexArray);
        setLoading(false);
      } catch (e) {
        setData({
          order: [],
        });
        setLoading(false);
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };
    if (user.token === null) {
      if (ticket === null) {
        dispatch(Modal('', true));
      } else {
        var reLogin = setInterval(() => {
          if (store.getState().user.token) {
            fetchData();
            clearInterval(reLogin);
          }
        }, 100);
      }
    } else {
      fetchData();
    }
  }, [i18n, user, dispatch, page, ticket, dialogInternationalOpen]);

  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          <OrderInternationalDialog
            open={dialogInternationalOpen}
            close={() => {
              setDialogInternationalOpen(false);
            }}
            orderId={dialogInternationalOrderId}
            setDialogInternationalOpen={setDialogInternationalOpen}
            dialogInternationalOpen={dialogInternationalOpen}
          />
          <OrderRefundDialog
            open={dialogRefundOpen}
            close={() => {
              setDialogRefundOpen(false);
            }}
            orderId={dialogRefundOrderId}
          />
          <CVSDialog
            open={dialogCVSOpen}
            close={() => {
              setDialogCVSOpen(false);
            }}
            orderId={dialogCVSOrderId}
          />
          <CustomTabs>
            <CustomTab>
              <TitleLink className="customTab" to="/users/profile">
                <TabTitle>{t('user.tab.title.profile')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/order">
                <TabTitle active={true}>{t('user.tab.title.order')}</TabTitle>
              </TitleLink>
              <div className="triangle active" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/wishList">
                <TabTitle>{t('user.tab.title.wishList')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/deviceList">
                <TabTitle>{t('user.tab.title.device')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
            <CustomTab>
              <TitleLink className="customTab" to="/users/appList">
                <TabTitle>{t('user.tab.title.app')}</TabTitle>
              </TitleLink>
              <div className="triangle" />
            </CustomTab>
          </CustomTabs>
          {data.order.length === 0 ? (
            loading ? (
              <LoadingContainer>
                <CircularProgress />
              </LoadingContainer>
            ) : (
              <EmptyOrderContainer>
                <EmptyOrderTitleContainer>
                  {t('order.label.emptyTitle')}
                </EmptyOrderTitleContainer>
                <EmptyOrderDescriptionContainer>
                  {t('order.label.emptyDescription')}
                </EmptyOrderDescriptionContainer>
                <EmptyOrderButtonContainer>
                  <SoftwareButton
                    onClick={() => {
                      history.push('/products/software');
                    }}
                  >
                    {t('order.button.software')}
                  </SoftwareButton>
                  <HardwareButton
                    onClick={() => {
                      history.push('/products/hardware');
                    }}
                  >
                    {t('order.button.hardware')}
                  </HardwareButton>
                </EmptyOrderButtonContainer>
              </EmptyOrderContainer>
            )
          ) : loading ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : (
            <>
              <OrderContentContainer>
                {data.order.map((item, index) => (
                  <OrderContainer key={index} active={index}>
                    <OrderTitleContainer>
                      <OrderTitleLabel>
                        {t('order.label.orderNum')}
                      </OrderTitleLabel>
                      <OrderTitleValue>{item.id}</OrderTitleValue>
                      <OrderTitleLabel>
                        {t('order.label.orderDate')}
                      </OrderTitleLabel>
                      <OrderTitleValue>{item.date}</OrderTitleValue>
                    </OrderTitleContainer>
                    <OrderListContainer>
                      <OrderListTitleContainer>
                        <OrderListTitleItem>
                          {t('order.label.orderItem')}
                        </OrderListTitleItem>
                        <OrderListQuantity>
                          {t('order.label.orderQuantity')}
                        </OrderListQuantity>
                        <OrderListQA>{t('order.label.orderQA')}</OrderListQA>
                        <OrderListDelivery>
                          {t('order.label.orderDelivery')}
                        </OrderListDelivery>
                      </OrderListTitleContainer>
                      <OrderListItemContainer>
                        {item.items &&
                          item.items.map((k, v) => (
                            <OrderItemContainer key={v} active={v}>
                              <OrderItemInfoContainer>
                                <ImageContainer>
                                  <ImageLink
                                    to={`/products/${k.product.toLowerCase()}s/${
                                      k.id
                                    }/${k.versionIndex}`}
                                    disabled={!k.purchasable}
                                  >
                                    {k.image ? (
                                      <Image src={k.image} alt={k.name} isPhaseout={!k.purchasable}/>
                                    ) : (
                                      <ImageDefaultContainer>
                                        <ImageDefault>KNEO</ImageDefault>
                                      </ImageDefaultContainer>
                                    )}
                                  </ImageLink>
                                </ImageContainer>
                                <InfoContainer>
                                  <NameContainer>
                                    <CustomTooltip
                                      title={tooltipOpen[k.id] ? t("order.message.tip-phaseout") : ''}
                                      placement="bottom"
                                      arrow
                                      open={true}
                                      onOpen={() => {
                                        setTooltipOpen({
                                          ...tooltipOpen,
                                          [k.id]: !k.purchasable,
                                        });
                                      }}
                                      onClose={() => {
                                        setTooltipOpen({
                                          ...tooltipOpen,
                                          [k.id]: false,
                                        });
                                      }}
                                    >
                                      <NameLink
                                        to={`/products/${k.product.toLowerCase()}s/${
                                          k.id
                                        }/${k.versionIndex}`}
                                        disabled={!k.purchasable}
                                        isPhaseout={!k.purchasable}
                                        onClick={e => e.preventDefault()}
                                      >
                                        {k.name}
                                      </NameLink>
                                    </CustomTooltip>
                                  </NameContainer>
                                  <CategoryContainer>
                                    <CategoryLink
                                      to={`/products/${k.product.toLowerCase()}s/${
                                        k.id
                                      }/${k.versionIndex}`}
                                      disabled={!k.purchasable}
                                    >
                                      {`${k.product}`}
                                      {k.product === 'software' &&
                                        `, Category: ${k.category}`}
                                    </CategoryLink>
                                  </CategoryContainer>
                                  <DescrtptionContainer>
                                    <DescrtptionLink
                                      to={`/products/${k.product.toLowerCase()}s/${
                                        k.id
                                      }/${k.versionIndex}`}
                                      disabled={!k.purchasable}
                                    >
                                      {k.description}
                                    </DescrtptionLink>
                                  </DescrtptionContainer>
                                </InfoContainer>
                              </OrderItemInfoContainer>
                              <OrderItemQuantityContainer>
                                {k.quantity}
                              </OrderItemQuantityContainer>
                              <OrderItemQAContainer>
                                <OrderItemQAContainerButton
                                  disableRipple
                                  onClick={() => {
                                    history.push(`/users/order/QA/${item.id}`);
                                  }}
                                >
                                  {t('order.button.QA')}
                                </OrderItemQAContainerButton>
                              </OrderItemQAContainer>
                              <OrderItemDeliveryContainer>
                                {k.delivery === 0 &&
                                  (item.refunded && item.returns[k.id] ? (
                                    item.returns[k.id].delivery === 0 ? (
                                      <OrderItemDeliveryCompleteButton
                                        onClick={() => {
                                          setDialogRefundOpen(true);
                                          setdialogRefundOrderId(item.id);
                                        }}
                                      >
                                        {item.returns[k.id].delivery === 0 &&
                                          t('order.button.finish')}
                                      </OrderItemDeliveryCompleteButton>
                                    ) : (
                                      <OrderItemDeliveryReturnButton
                                        onClick={() => {
                                          setDialogRefundOpen(true);
                                          setdialogRefundOrderId(item.id);
                                        }}
                                      >
                                        {item.returns[k.id].delivery === -4 &&
                                          t('order.button.completeReturn')}
                                        {item.returns[k.id].delivery === -3 &&
                                          t('order.button.completeRefund')}
                                        {item.returns[k.id].delivery === -2 &&
                                          t('order.button.completeCancel')}
                                        {item.returns[k.id].delivery === -1 &&
                                          t(
                                            'order.button.completeCancelReturn'
                                          )}
                                        {item.returns[k.id].delivery === 1 &&
                                          t('order.button.refund')}
                                        {item.returns[k.id].delivery === 2 &&
                                          t('order.button.return')}
                                      </OrderItemDeliveryReturnButton>
                                    )
                                  ) : (
                                    <OrderItemDeliveryComplete>
                                      {t('order.button.finish')}
                                    </OrderItemDeliveryComplete>
                                  ))}
                                {k.delivery === 1 && (
                                  <OrderItemDelivery>
                                    {t('order.button.refund')}
                                  </OrderItemDelivery>
                                )}
                                {k.delivery === 2 && (
                                  <OrderItemDelivery>
                                    {t('order.button.return')}
                                  </OrderItemDelivery>
                                )}
                                {k.delivery === 3 &&
                                  (item.refunded && item.returns[k.id] ? (
                                    item.returns[k.id].delivery === 0 ? (
                                      <OrderItemDeliveryCompleteButton
                                        onClick={() => {
                                          setDialogRefundOpen(true);
                                          setdialogRefundOrderId(item.id);
                                        }}
                                      >
                                        {item.returns[k.id].delivery === 0 &&
                                          t('order.button.finish')}
                                      </OrderItemDeliveryCompleteButton>
                                    ) : (
                                      <OrderItemDeliveryReturnButton
                                        onClick={() => {
                                          setDialogRefundOpen(true);
                                          setdialogRefundOrderId(item.id);
                                        }}
                                      >
                                        {item.returns[k.id].delivery === -4 &&
                                          t('order.button.completeReturn')}
                                        {item.returns[k.id].delivery === -3 &&
                                          t('order.button.completeRefund')}
                                        {item.returns[k.id].delivery === -2 &&
                                          t('order.button.completeCancel')}
                                        {item.returns[k.id].delivery === -1 &&
                                          t(
                                            'order.button.completeCancelReturn'
                                          )}
                                        {item.returns[k.id].delivery === 1 &&
                                          t('order.button.refund')}
                                        {item.returns[k.id].delivery === 2 &&
                                          t('order.button.return')}
                                      </OrderItemDeliveryReturnButton>
                                    )
                                  ) : (
                                    <OrderItemDelivery>
                                      {t('order.button.arrived')}
                                    </OrderItemDelivery>
                                  ))}
                                {k.delivery === 4 && (
                                  <OrderItemDelivery>
                                    {t('order.button.delivering')}
                                  </OrderItemDelivery>
                                )}
                                {k.delivery === 5 && (
                                  <OrderItemDelivery>
                                    {t('order.button.preparing')}
                                  </OrderItemDelivery>
                                )}
                                {k.delivery === 6 && (
                                  <OrderItemDelivery>
                                    {t('order.button.cancelOrder')}
                                  </OrderItemDelivery>
                                )}
                                {k.delivery === 7 && (
                                  <OrderItemDelivery>
                                    {t('order.button.verifying')}
                                  </OrderItemDelivery>
                                )}
                                {k.delivery === 8 && (
                                  <OrderItemDeliveryFareConfirmationButton
                                    onClick={() => {
                                      setDialogInternationalOpen(true);
                                      setdialogInternationalOrderId(item.id);
                                    }}
                                  >
                                    {t('order.button.fareConfirmation')}
                                  </OrderItemDeliveryFareConfirmationButton>
                                )}
                                {k.delivery === 9 && (
                                  <OrderItemDeliveryFareEstimation>
                                    {t('order.button.fareEstimation')}
                                  </OrderItemDeliveryFareEstimation>
                                )}
                                {k.delivery === 10 && (
                                  <OrderItemDelivery>
                                    {t('order.button.licensed')}
                                  </OrderItemDelivery>
                                )}
                                {k.delivery === -2 && (
                                  <OrderItemDelivery>
                                    {t('order.button.completeCancel')}
                                  </OrderItemDelivery>
                                )}
                                {k.delivery === 11 && (
                                  <OrderItemDeliveryFareConfirmationButton
                                    onClick={() => {
                                      setDialogCVSOpen(true);
                                      setdialogCVSOrderId(item.id);
                                    }}
                                  >
                                    {t('order.button.waitingPay')}
                                  </OrderItemDeliveryFareConfirmationButton>
                                )}
                              </OrderItemDeliveryContainer>
                            </OrderItemContainer>
                          ))}
                      </OrderListItemContainer>
                    </OrderListContainer>
                  </OrderContainer>
                ))}
              </OrderContentContainer>
              <PagingContainer>
                <PageContainer>
                  {page === 1 ? (
                    <></>
                  ) : (
                    <PagingIconButton
                      onClick={() => {
                        setPage(page - 1);
                      }}
                    >
                      <CustomNavigateBeforeIcon />
                    </PagingIconButton>
                  )}
                  {pageIndex.length !== 0 &&
                    pageIndex.map((item, index) => {
                      var pages;
                      if (pageIndex.length < 6) {
                        if (index < 5) {
                          pages = (
                            <PagingNumber
                              key={index}
                              current={index === page - 1 ? 'true' : 'false'}
                              onClick={() => {
                                setPage(item);
                              }}
                            >
                              {item}
                            </PagingNumber>
                          );
                        }
                      } else {
                        if (page === pageIndex[pageIndex.length - 1]) {
                          if (index >= pageIndex.length - 5) {
                            pages = (
                              <PagingNumber
                                key={index}
                                current={item === page ? 'true' : 'false'}
                                onClick={() => {
                                  setPage(item);
                                }}
                              >
                                {item}
                              </PagingNumber>
                            );
                          }
                        } else if (page === pageIndex[pageIndex.length - 2]) {
                          if (index >= pageIndex.length - 5) {
                            pages = (
                              <PagingNumber
                                key={index}
                                current={item === page ? 'true' : 'false'}
                                onClick={() => {
                                  setPage(item);
                                }}
                              >
                                {item}
                              </PagingNumber>
                            );
                          }
                        } else if (page <= 3) {
                          if (index < 5) {
                            pages = (
                              <PagingNumber
                                key={index}
                                current={item === page ? 'true' : 'false'}
                                onClick={() => {
                                  setPage(item);
                                }}
                              >
                                {item}
                              </PagingNumber>
                            );
                          }
                        } else {
                          if (item >= page - 2 && item <= page + 2) {
                            pages = (
                              <PagingNumber
                                key={index}
                                current={item === page ? 'true' : 'false'}
                                onClick={() => {
                                  setPage(item);
                                }}
                              >
                                {item}
                              </PagingNumber>
                            );
                          }
                        }
                      }
                      return pages;
                    })}
                  {page === pageIndex[pageIndex.length - 1] ? (
                    <></>
                  ) : (
                    <PagingIconButton
                      onClick={() => {
                        setPage(page + 1);
                      }}
                    >
                      <CustomNavigateNextIcon />
                    </PagingIconButton>
                  )}
                  <PagingInstruction>{t('order.label.to')}</PagingInstruction>
                  <PagingInput
                    onChange={(e) => {
                      setNumber(e.target.value);
                    }}
                  />
                  <PagingButton
                    onClick={() => {
                      console.log(number);
                      if (number === 0 || number === '0') {
                        setPage(1);
                      } else if (
                        /^[0-9]*$/i.test(number) &&
                        number > pageIndex[pageIndex.length - 1]
                      ) {
                        setNumber(pageIndex[pageIndex.length - 1]);
                      } else if (number === '') {
                      } else {
                        setPage(parseInt(number, 10));
                      }
                    }}
                  >
                    {t('order.button.go')}
                  </PagingButton>
                </PageContainer>
              </PagingContainer>
            </>
          )}
        </ContentWrapper>
      </Wrapper>
    </Fragment>
  );
};

export default Order;
