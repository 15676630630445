import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CircularProgress, Button, IconButton } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { withStyles } from '@material-ui/core/styles';
import { getSearchProducts } from '../services/productPage';
import { notify } from '../actions/notification';
import { Modal } from '../actions/modal';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const Wrapper = styled.div`
  display: block;
  margin: auto;
  width: 872px;
  margin-top: 100px;
  margin-bottom: auto;
`;

const ProductWrap = styled.div`
  display: flex;
  width: 100%;
`;

const ProductDivider = styled.div`
  width: 772px;
  margin-bottom: 31.4px;
  margin-left: auto;
  border-bottom: solid 1px #cacaca;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  object-fit: cover;
`;

const ItemLink = styled(Link)`
  text-decoration: none;
`;

const NameLink = styled(Link)`
  line-height: 1.21;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  color: #3e3a39;
  height: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行數
  -webkit-box-orient: vertical;
`;

const ItemTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
  margin-left: 19.6px;
`;

const ItemSubTitle = styled.div`  
  display: inline-block;
  align-content: flex-end;
  width: 120px;
`;

const ItemSubDescription = styled.div`
  width: 120px;
  display: inline-block;
  align-content: flex-start;
`;

const ItemDescription = styled.div`
  width: 285px;
  height: 38px;
  display: inline-block;
  margin-left: 32px;
  line-height: 1.69;
  color: #0cbaef;
  text-align: left;
  font-size: 13px;
  margin-top: auto;
  margin-bottom: auto;
  display: -webkit-box;
  -webkit-line-clamp: 2; //行數
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ProviderLink = styled(Link)`
  line-height: 1.21;
  text-decoration: none;
  font-size: 14px;
  color: #9fa0a0;
  height: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  margin-left: 8.3px;
  -webkit-line-clamp: 1; //行數
  -webkit-box-orient: vertical;
`;

const CardIconContainer = styled.div`
  margin: auto 15.4px 15.4px auto;
  transform: scale(1);
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  margin: calc(50vh - 45px) auto 0;
`;

const NotFoundTitle = styled.div`
  width: 610px;
  line-height: 1.52;
  font-size: 33px;
  font-weight: bold;
  text-align: left;
  color: #7b7979;
  word-break: break-all;
`;

const NotFoundContent = styled.div`
  line-height: 1.75;
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  color: #939393;
`;

const Divider = styled.div`
  width: 100%;
  margin-top: 22.5px;
  margin-bottom: 35.6px;
  border-bottom: solid 1px #707070;
`;

const PagingContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 858px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PagingIconButton = styled(IconButton)`
  && {
    padding: 0;
  }
`;

const CustomNavigateBeforeIcon = styled(NavigateBeforeIcon)`
  height: 16px;
  color: #939393;
  && {
    padding: 8px;
  }
`;

const PagingNumber = styled(Button)`
  && {
    margin: 0 8px;
    color: #939393;
    font-size: 20px;
    min-width: 20px;
    padding: 0;
    ${({ current }) =>
      current === 'true' &&
      `
      color: #0cbaef;
    `}
  }
`;

const PagingInstruction = styled.div`
  font-size: 16px;
  color: #9fa0a0;
  margin-right: 6px;
  margin-left: 12px;
`;

const PagingInput = styled.input`
  width: 58px;
  height: 22px;
  border-radius: 20px;
  border: 1px solid #cacaca;
  text-align: center;
  color: #d1d1d1;
  outline: none;
  font-size: 16px;
  line-height: 25px;
  margin-right: 6px;
  margin-left: 10px;
`;

const PagingButton = styled(Button)`
  && {
    margin-left: 6px;
    margin-right: 10px;
    height: 26px;
    font-size: 16px;
    color: #ffffff;
    background: #cacaca;
    border-radius: 20px;
    border: 1px solid #cacaca;
    line-height: 23px;
    &:hover {
      color: #cacaca;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const CustomNavigateNextIcon = styled(NavigateNextIcon)`
  height: 16px;
  color: #939393;
  && {
    padding: 8px;
  }
`;

const StyledCardRating = withStyles({
  iconFilled: {
    color: '#0cbaef',
  },
  iconHover: {
    color: '#0cbaef',
  },
})(Rating);


const ProductSearch = () => {
  const Length = 7;
  const { keyword } = useParams();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  // for pagenation:
  const [page, setPage] = useState(1);
  const [pageIndex, setPageIndex] = useState([]);
  const [number, setNumber] = useState(0);

  useEffect(() => {
    var fetchData = async () => {
      try {
        setLoading(true);
        const result = await getSearchProducts(
          {
            params: {
              keyword,
              index: (page - 1) * Length,
              length: Length,
              lng: i18n.language
             },
          }
        );
        var index = Math.ceil(result.data.length / Length);
        var indexArray = [];
        for (var i = 0; i < index; i++) {
          indexArray.push(i + 1);
        }
        setPageIndex(indexArray);
        setData(result.data.result);
        setLoading(false);
      } catch (e) {
        if (e && e.response) {
          switch (e.response.status) {
            case 400:
              dispatch(notify('error', i18n.t('message.400'), true));
              break;
            case 401:
              dispatch(Modal('', true));
              break;
            case 500:
              dispatch(notify('error', i18n.t('message.500'), true));
              break;
            default:
              dispatch(notify('error', i18n.t('message.error'), true));
          }
        }
      }
    };
    fetchData();
  }, [ i18n, dispatch, keyword, page]);

  return (
    <Fragment>
      {
        loading ? (
          <LoadingContainer>
            <CircularProgress />
          </LoadingContainer>
        ) : (
          <Wrapper>
            {
              data.length !== 0 ? (
                <>
                  {
                    data.map((product, index) =>
                      <div key={product.id}>
                        <ProductWrap>
                          <ItemLink to={`/products/${product.product}s/${product.id}/${product.versionIndex}`}>
                            <Image src={product.image}></Image>
                          </ItemLink>
                          <ItemTitleWrap>
                            <ItemSubTitle>
                              <NameLink  to={`/products/${product.product}s/${product.id}/${product.versionIndex}`}>
                                {product.name}
                              </NameLink>
                            </ItemSubTitle>
                            <ItemSubDescription>
                              <ProviderLink to={`/products/${product.product}s/${product.id}/${product.versionIndex}`}>
                                {product.provider}
                              </ProviderLink>
                            </ItemSubDescription>
                          </ItemTitleWrap>
                          <ItemDescription>
                            {product.description}
                          </ItemDescription>
                          <CardIconContainer>
                            <StyledCardRating
                              name="read-only"
                              value={product.average}
                              emptyIcon={<StarBorderIcon fontSize="inherit" />}
                              size="small"
                              readOnly
                              precision={0.5}
                            />
                          </CardIconContainer>
                        </ProductWrap>
                        <ProductDivider></ProductDivider>
                      </div>
                    )
                  }
                  <PagingContainer>
                    <PageContainer>
                      {page === 1 ? (
                        <></>
                      ) : (
                        <PagingIconButton
                          onClick={() => {
                            setPage(page - 1);
                          }}
                        >
                          <CustomNavigateBeforeIcon />
                        </PagingIconButton>
                      )}
                      {pageIndex.length !== 0 &&
                        pageIndex.map((item, index) => {
                          var pages;
                          if (pageIndex.length < 6) {
                            if (index < 5) {
                              pages = (
                                <PagingNumber
                                  key={index}
                                  current={index === page - 1 ? 'true' : 'false'}
                                  onClick={() => {
                                    setPage(item);
                                  }}
                                >
                                  {item}
                                </PagingNumber>
                              );
                            }
                          } else {
                            if (page === pageIndex[pageIndex.length - 1]) {
                              if (index >= pageIndex.length - 5) {
                                pages = (
                                  <PagingNumber
                                    key={index}
                                    current={item === page ? 'true' : 'false'}
                                    onClick={() => {
                                      setPage(item);
                                    }}
                                  >
                                    {item}
                                  </PagingNumber>
                                );
                              }
                            } else if (page === pageIndex[pageIndex.length - 2]) {
                              if (index >= pageIndex.length - 5) {
                                pages = (
                                  <PagingNumber
                                    key={index}
                                    current={item === page ? 'true' : 'false'}
                                    onClick={() => {
                                      setPage(item);
                                    }}
                                  >
                                    {item}
                                  </PagingNumber>
                                );
                              }
                            } else if (page <= 3) {
                              if (index < 5) {
                                pages = (
                                  <PagingNumber
                                    key={index}
                                    current={item === page ? 'true' : 'false'}
                                    onClick={() => {
                                      setPage(item);
                                    }}
                                  >
                                    {item}
                                  </PagingNumber>
                                );
                              }
                            } else {
                              if (item >= page - 2 && item <= page + 2) {
                                pages = (
                                  <PagingNumber
                                    key={index}
                                    current={item === page ? 'true' : 'false'}
                                    onClick={() => {
                                      setPage(item);
                                    }}
                                  >
                                    {item}
                                  </PagingNumber>
                                );
                              }
                            }
                          }
                          return pages;
                        })}
                      {page === pageIndex[pageIndex.length - 1] ? (
                        <></>
                      ) : (
                        <PagingIconButton
                          onClick={() => {
                            setPage(page + 1);
                          }}
                        >
                          <CustomNavigateNextIcon />
                        </PagingIconButton>
                      )}
                      <PagingInstruction>{t('order.label.to')}</PagingInstruction>
                      <PagingInput
                        onChange={(e) => {
                          setNumber(e.target.value);
                        }}
                      />
                      <PagingButton
                        onClick={() => {
                          console.log(number);
                          if (number === 0 || number === '0') {
                            setPage(1);
                          } else if (
                            /^[0-9]*$/i.test(number) &&
                            number > pageIndex[pageIndex.length - 1]
                          ) {
                            setNumber(pageIndex[pageIndex.length - 1]);
                          } else if (number === '') {
                          } else {
                            setPage(parseInt(number, 10));
                          }
                        }}
                      >
                        {t('order.button.go')}
                      </PagingButton>
                    </PageContainer>
                  </PagingContainer>
                </>
              ) : (
                <>
                  <NotFoundTitle>{`${i18n.t('product.search.notFound-title1')}『 ${keyword} 』${i18n.t("product.search.notFound-title2")}`}</NotFoundTitle>
                  <Divider></Divider>
                  <NotFoundContent>
                    ■ {`${t("product.search.notFound-content1")}`} <br></br>
                    ■ {`${t("product.search.notFound-content2")}`} <br></br>
                    ■ {`${t("product.search.notFound-content3")}`}
                  </NotFoundContent>
                </>
              )
            }
          </Wrapper>
        )
      }
    </Fragment>
  );
};

export default ProductSearch;
