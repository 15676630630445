import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import NextArrow from '../../resources/images/next-arrow.png';
import PrevArrow from '../../resources/images/prev-arrow.png';

const StyleCarousel = styled.div`
  width: 1280px;
  margin-left: auto;
  margin-right: auto;
`;

const SlickImg = styled.img`
  margin-left: auto;
  margin-right: auto;
  width: 1279px;
  height: 355px;
  object-fit: scale-down;
  border-bottom-left-radius: 20px 20px;
  border-bottom-right-radius: 20px 20px;
`;

const Arrow = styled.img`
  color: #898989;
  && {
    width: 16px;
    height: 32px;
  }
  &:hover {
    color: #999999;
    background: #f8f9fa;
    border-radius: 100px;
  }
`;

const HardwareCarousel = (props) => {
  const { data } = props;
  const slickSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // arrows: true,
    swipe: false,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <Arrow src={NextArrow} alt="" />,
    prevArrow: <Arrow src={PrevArrow} alt="" />,
  };
  return (
    <StyleCarousel>
      <Slider {...slickSettings}>
        {data.map((item, index) => (
          <div key={index}>
            <SlickImg src={item.image} alt="" />
          </div>
        ))}
      </Slider>
    </StyleCarousel>
  );
};

export default HardwareCarousel;
