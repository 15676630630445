import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import PrivateRoute from './helpers/PrivateRoute';
import styled from 'styled-components';
import './App.css';
import ScrollToTop from './helpers/ScrollToTop';

import Header from './components/Header';
import LandingPage from './components/LandingPage';
import Footer from './components/Footer';
import About from './components/About';
import CategorySoftware from './components/CategorySoftware';
import CategoryHardware from './components/CategoryHardware';
import Softwares from './components/Softwares';
import Hardwares from './components/Hardwares';
import Cart from './components/Cart';
import Bill from './components/Bill';
import Profile from './components/User/Profile';
import Order from './components/User/Order';
import WishList from './components/User/WishList';
import DeviceList from './components/User/DeviceList';
import DeviceUtility from './components/User/DeviceUtility';
import DeviceInstruction from './components/User/DeviceInstruction';
import Devices from './components/User/Devices';
import AppList from './components/User/AppList';
import Apps from './components/User/Apps';
import Legal from './components/Legal';
import Privacy from './components/Privacy';
import OrderResult from './components/OrderResult';
import OrderInternational from './components/OrderInternational';
import CVSResult from './components/CVSResult';
import SoftwareReviewBoard from './components/Softwares/Review';
import HardwareReviewBoard from './components/Hardwares/Review';
import Review from './components/User/Review';
import ReviewEdit from './components/User/ReviewEdit';
import OrderQA from './components/User/OrderQA';
import ProductSearch from './components/ProductSearch';

const Main = styled.div`
  display: flex;
  // justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

function App() {
  return (
    <Router>
      <Header />
      <Main>
        <ScrollToTop />
        <Switch>
          <Route exact path="/">
            <LandingPage />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/legal">
            <Legal />
          </Route>
          <Route exact path="/privacy">
            <Privacy />
          </Route>
          <Route exact path="/orderResult/:paymentId">
            <OrderResult />
          </Route>
          <Route exact path="/cvsResult/:paymentId">
            <CVSResult />
          </Route>
          <Route exact path="/orderInternational">
            <OrderInternational />
          </Route>
          <Route exact path="/products/software">
            <CategorySoftware />
          </Route>
          <Route exact path="/products/hardware">
            <CategoryHardware />
          </Route>
          <Route exact path="/products/softwares/:id/:versionIndex">
            <Softwares />
          </Route>
          <Route exact path="/products/software/:id/:versionIndex/review">
            <SoftwareReviewBoard />
          </Route>
          <Route exact path="/products/hardwares/:id/:versionIndex">
            <Hardwares />
          </Route>
          <Route exact path="/products/hardware/:id/:versionIndex/review">
            <HardwareReviewBoard />
          </Route>
          <Route exact path="/products/search/:keyword">
            <ProductSearch />
          </Route>
          <Route exact path="/checkout/cart">
            <Cart />
          </Route>
          <PrivateRoute exact path="/checkout/bill/:provider/:product">
            <Bill />
          </PrivateRoute>
          <PrivateRoute exact path="/users/profile">
            <Profile />
          </PrivateRoute>
          <PrivateRoute exact path="/users/order">
            <Order />
          </PrivateRoute>
          <PrivateRoute exact path="/users/wishList">
            <WishList />
          </PrivateRoute>
          <PrivateRoute exact path="/users/deviceList">
            <DeviceList />
          </PrivateRoute>
          <PrivateRoute exact path="/users/deviceUtility">
            <DeviceUtility />
          </PrivateRoute>
          <PrivateRoute exact path="/users/deviceInstruction">
            <DeviceInstruction />
          </PrivateRoute>
          <PrivateRoute exact path="/users/devices/:devid">
            <Devices />
          </PrivateRoute>
          <PrivateRoute exact path="/users/appList">
            <AppList />
          </PrivateRoute>
          <PrivateRoute exact path="/users/apps/:id">
            <Apps />
          </PrivateRoute>
          <PrivateRoute exact path="/users/review/:id">
            <Review />
          </PrivateRoute>
          <PrivateRoute exact path="/users/reviewEdit/:id">
            <ReviewEdit />
          </PrivateRoute>
          <PrivateRoute exact path="/users/order/QA/:id">
            <OrderQA/>
          </PrivateRoute>
          <Redirect to="/" />
        </Switch>
        <Footer />
      </Main>
    </Router>
  );
}

export default App;
