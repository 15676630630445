import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, CircularProgress } from '@material-ui/core';
import Divider from './Divider';
import { getOrderResult } from '../services/billingPage';
import { Modal } from '../actions/modal';
import { dropItemsFromCart } from '../actions/cart';

const Wrapper = styled.div`
  display: block;
  width: 100%;
  margin-top: 70px;
  margin-bottom: auto;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 32px 0px 16px;
  width: 631px;
  margin-left: auto;
  margin-right: auto;
`;

const Title = styled.h2`
  font-size: 25px;
  color: #595757;
  margin-top: 8px;
  margin-bottom: 12px;
`;

const OrderInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 62px;
  margin-top: 8px;
  padding-right: 46px;
`;

const OrderInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
`;

const OrderInfoLabel = styled.div`
  font-size: 16px;
  color: #707070;
`;

const OrderInfoValue = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #707070;
`;

const UserGuideContainer = styled.div`
  background: #0cbaef;
  border-radius: 10px;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  line-height: 58px;
  margin-bottom: 60px;
  text-align: center;
`;

const UserGuideLink = styled(Link)`
  color: #ffffff;
`;

const OrderDetailContainer = styled.div`
  width: 200px;
  display: flex;
  justify-content: space-between;
`;

const OrderDetailValue = styled.div`
  width: 80px;
  font-size: 16px;
  font-weight: bold;
  color: #707070;
  text-align: right;
`;

const OrderItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-right: 46px;
`;

const OrderItemInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0;
`;

const OrderLogisticContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-right: 46px;
`;

// const OrderSummaryContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 8px;
//   margin-top: 8px;
//   padding-right: 46px;
// `;

const OkContainer = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  padding-right: ${(props) => props.w * 0.5 + 9}px;
`;

const OkButton = styled(Button)`
  && {
    font-size: 14px;
    font-weight: bold;
    background: #0cbaef;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #0cbaef;
    width: 74px;
    height: 21px;
    &:hover {
      color: #0cbaef;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const TitleFail = styled.h2`
  font-size: 25px;
  color: #595757;
  margin-top: 322px;
  margin-bottom: auto;
  text-align: center;
`;

const ButtonGroupFailContainer = styled.div`
  margin-top: 33px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 238px;
  margin-left: auto;
  margin-right: auto;
`;

const RetryButton = styled(Button)`
  && {
    font-size: 14px;
    background: #0cbaef;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #0cbaef;
    width: 106px;
    &:hover {
      color: #0cbaef;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const CancelButton = styled(Button)`
  && {
    font-size: 14px;
    background: #d1d1d1;
    color: #ffffff;
    border-radius: 100px;
    border: 1px solid #d1d1d1;
    width: 106px;
    &:hover {
      color: #d1d1d1;
      background: rgba(0, 0, 0, 0.04);
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  margin: calc(50vh + 10px) auto 0;
`;

const OrderResult = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [data, setData] = useState({
    orderId: '',
    orderDate: '',
    total: 0,
    type: '',
    items: [],
  });

  const [loading, setLoading] = useState(true);
  const [orderStatus, setOrderStatus] = useState(true);
  const [okButtonWidth, setOkButtonWidth] = useState(0);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const ticket = params.get('ticket');
  const { paymentId } = useParams();

  useEffect(() => {
    var fetchData = async () => {
      try {
        console.log(paymentId);
        const result = await getOrderResult({
          params: { lng: 'zh-TW', id: paymentId },
          headers: {
            Authorization: `Bearer ${user.token.accessToken}`,
          },
        });
        setData(result.data);
        setLoading(false);
        setOrderStatus(true);

        var dropItems = [];
        if (result.data && result.data.items) {
          result.data.items.forEach((element) => {
            if (!element.promo) {
              dropItems.push(element.id);
            }
          });
        }
        dispatch(dropItemsFromCart(dropItems));
      } catch (e) {
        setData({
          orderId: '',
          orderDate: '',
          total: 0,
          type: '',
          items: [],
        });
        setLoading(false);
        setOrderStatus(false);
        if (e && e.response) {
          switch (e.response.status) {
            case 401:
              dispatch(Modal('', true));
              break;
            default:
              console.log(e);
          }
        }
      }
    };
    if (user.token === null && ticket === null) {
      window.location =
        process.env.REACT_APP_KNERON_URL +
        '/login/?redirect=' +
        window.location.origin +
        window.location.pathname;
    } else {
      var reLogin = setInterval(() => {
        if (user.token) {
          fetchData();
          clearInterval(reLogin);
        }
      }, 200);
    }
  }, [paymentId, user, dispatch, ticket]);

  return (
    <Fragment>
      <Wrapper>
        <ContentWrapper>
          {loading ? (
            <LoadingContainer>
              <CircularProgress />
            </LoadingContainer>
          ) : orderStatus ? (
            <>
              <Title>{t('orderResult.title.receive')}</Title>
              <Divider dialog={true} />
              <OrderInfoContainer>
                <OrderInfo>
                  <OrderInfoLabel>{t('orderResult.label.id')}</OrderInfoLabel>
                  <OrderInfoValue>{data.orderId}</OrderInfoValue>
                </OrderInfo>
                <OrderInfo>
                  <OrderInfoLabel>{t('orderResult.label.date')}</OrderInfoLabel>
                  <OrderInfoValue>{data.orderDate}</OrderInfoValue>
                </OrderInfo>
                <OrderInfo>
                  <OrderInfoLabel>
                    {t('orderResult.label.total')}
                  </OrderInfoLabel>
                  <OrderInfoValue>{`NT$ ${data.total}`}</OrderInfoValue>
                </OrderInfo>
                <OrderInfo>
                  <OrderInfoLabel>{t('orderResult.label.pay')}</OrderInfoLabel>
                  <OrderInfoValue>{data.type}</OrderInfoValue>
                </OrderInfo>
              </OrderInfoContainer>
              <UserGuideContainer>
                {t('orderResult.title.guide1')}
                <UserGuideLink to={'/users/deviceUtility'}>
                  {t('orderResult.title.guide2')}
                </UserGuideLink>
                {t('orderResult.title.guide3')}
                <UserGuideLink to={'/users/appList'}>
                  {t('orderResult.title.guide4')}
                </UserGuideLink>
              </UserGuideContainer>
              <Title>{t('orderResult.title.detail')}</Title>
              <Divider dialog={true} />
              <OrderItemContainer>
                {data.items.map((item, index) => (
                  <OrderItemInfo key={index}>
                    <OrderInfoLabel>{item.name}</OrderInfoLabel>
                    <OrderDetailContainer>
                      <OrderDetailValue>{`X${item.count}`}</OrderDetailValue>
                      <OrderDetailValue>{`NT$ ${item.price}`}</OrderDetailValue>
                    </OrderDetailContainer>
                  </OrderItemInfo>
                ))}
              </OrderItemContainer>
              <Divider dialog={true} />
              <OrderLogisticContainer>
                <OrderInfo>
                  <OrderInfoLabel>
                    {t('orderResult.label.total')}
                  </OrderInfoLabel>
                  <OrderInfoValue
                    ref={(el) => {
                      if (el) {
                        setOkButtonWidth(el.getBoundingClientRect().width);
                      }
                    }}
                  >{`NT$ ${data.total}`}</OrderInfoValue>
                </OrderInfo>
                {/* <OrderInfo>
                  <OrderInfoLabel>{t('orderResult.label.logistic')}</OrderInfoLabel>
                  <OrderInfoValue>2020/11/20</OrderInfoValue>
                </OrderInfo>
                <OrderInfo>
                  <OrderInfoLabel>{t('orderResult.label.pay')}</OrderInfoLabel>
                  <OrderInfoValue>{data.type}</OrderInfoValue>
                </OrderInfo> */}
              </OrderLogisticContainer>
              {/* <Divider dialog={true} />
              <OrderSummaryContainer>
                <OrderInfo>
                  <OrderInfoLabel>{t('orderResult.label.total')}</OrderInfoLabel>
                  <OrderInfoValue>NT$ 120</OrderInfoValue>
                </OrderInfo>
              </OrderSummaryContainer> */}
              <OkContainer w={okButtonWidth}>
                <OkButton
                  onClick={() => {
                    history.push('/users/order');
                  }}
                >{`OK`}</OkButton>
              </OkContainer>
            </>
          ) : (
            <>
              <TitleFail>{t('orderResult.title.fail')}</TitleFail>
              <ButtonGroupFailContainer>
                <RetryButton
                  onClick={() => {
                    history.push('/checkout/cart');
                  }}
                >
                  {t('orderResult.button.retry')}
                </RetryButton>
                <CancelButton
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  {t('orderResult.button.cancel')}
                </CancelButton>
              </ButtonGroupFailContainer>
            </>
          )}
        </ContentWrapper>
      </Wrapper>
    </Fragment>
  );
};

export default OrderResult;
