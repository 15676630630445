import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Grid,
  LinearProgress,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 8px 0px 16px;
  width: 1140px;
  margin-left: auto;
  margin-right: auto;

  ${({ dialog }) =>
    dialog &&
    `
    display: flex;
    flex-direction: column;

    padding: 8px 0px 8px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  `}
`;

const RatingBoardContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
`;

const RatingGridContainer = styled.div`
  margin-right: 38px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  margin: 12px 0 4px;
  font-size: 23px;
  color: #3e3a39;
  padding-left: 4px;
`;

const ScoreGrid = styled.div`
  font-size: 91px;
  color: #9fa0a0;
  text-align: center;
  line-height: 96px;
`;

const IconGrid = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: 8px;
`;

const IconContainer = styled.div`
  background: #0cbaef;
  padding: 2px 18px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 10px;
`;

const ScoreGridContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AverageGridContainer = styled(Grid)`
  && {
    flex-wrap: nowrap;
  }
`;

const AverageGrid = styled(Grid)`
  && {
    position: relative;
  }
`;

const PointGrid = styled(Grid)`
  && {
    color: #9fa0a0;
  }
`;

const StyleStarBorderIcon = styled(StarBorderIcon)`
  color: #ffffff;
`;

const CommentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const CardTitle = styled.h2`
  font-size: 20px;
  color: #3e3a39;
  margin-top: 0;
  margin-bottom: 8px;
`;

const CardContent = styled.div`
  font-size: 15px;
  color: #3e3a39;
  margin-top: 8px;
  height: 38px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
`;

const CardRightGrid = styled(Grid)`
  && {
    position: relative;
  }
`;

const CardIconContainer = styled.div`
  padding-top: 16px;
  float: right;
  transform: scale(0.8);
`;

const CardEditRemind = styled.div`
  position: absolute;
  bottom: 19.7px;
  right: 16px;
  font-size: 12px;
  color: #3e3a39;
  text-align: left;
  font-weight: bold;
`;

const CardDate = styled.div`
  position: absolute;
  bottom: 0;
  right: 16px;
  font-size: 12px;
  color: #3e3a39;
  text-align: left;
`;

const SeeAllGrid = styled(Grid)`
  position: relative;
`;

const SeeAllContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: right;
  margin-right: 25px;
  margin-top: 8px;
  margin-bottom: 4px;
  bottom: 0;
  right: 0;
`;

const SeeAllLink = styled(Link)`
  width: 68px;
  height: 24px;
  color: #0cbaef;
  text-align: left;
  display: table;
  font-size: 17px;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    color: gray;
    cursor: pointer;
  }
`;

const StyledRating = withStyles({
  iconFilled: {
    color: '#ffffff',
  },
  iconHover: {
    color: '#ffffff',
  },
})(Rating);

const StyledCardRating = withStyles({
  iconFilled: {
    color: '#0cbaef',
  },
  iconHover: {
    color: '#0cbaef',
  },
})(Rating);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 5,
    top: 9,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#0cbaef',
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  paperLight: {
    width: '375px',
    height: '81px',
    background: '#f5f5f5',
    padding: 16,
    borderRadius: 20,
    margin: '8px 0 8px 8px',
    // [theme.breakpoints.down(1440)]: {
    //   margin: '8px 4px',
    // },
  },
  paperDark: {
    width: '375px',
    height: '81px',
    // width: '28%',
    background: '#ebebeb',
    padding: 16,
    borderRadius: 20,
    margin: '8px 0 8px 8px',
    // [theme.breakpoints.down(1440)]: {
    //   margin: '8px 4px',
    // },
  },
}));

const RatingBoard = (props) => {
  const { t } = useTranslation();
  const { data, dialog } = props;
  const classes = useStyles();
  return (
    <Wrapper dialog={dialog}>
      <Grid container spacing={0}>
        <Grid item xs={9}>
          <Title>{t('product.hardware.titles.rating')}</Title>
        </Grid>
        {data.comment.length > 0 && (
          <SeeAllGrid item xs={3}>
            <SeeAllContainer>
              <SeeAllLink
                to={`/products/hardware/${data.id}/${data.versionIndex}/review`}
              >
                {t('product.hardware.button.seeAll')}
              </SeeAllLink>
            </SeeAllContainer>
          </SeeAllGrid>
        )}
      </Grid>
      <RatingBoardContainer>
        <RatingGridContainer>
          <ScoreGridContainer>
            <ScoreGrid>{data.average}</ScoreGrid>
            <IconGrid>
              <IconContainer>
                <StyledRating
                  name="read-only"
                  value={data.average === undefined ? 0 : data.average}
                  emptyIcon={<StyleStarBorderIcon fontSize="inherit" />}
                  size="small"
                  readOnly
                />
              </IconContainer>
            </IconGrid>
          </ScoreGridContainer>
          <AverageGridContainer container spacing={0}>
            <PointGrid item xs={1}>
              5
            </PointGrid>
            <AverageGrid item xs={11}>
              <BorderLinearProgress
                variant="determinate"
                value={data.five === undefined ? 0 : data.five}
              />
            </AverageGrid>
          </AverageGridContainer>
          <AverageGridContainer container spacing={0}>
            <PointGrid item xs={1}>
              4
            </PointGrid>
            <AverageGrid item xs={11}>
              <BorderLinearProgress
                variant="determinate"
                value={data.four === undefined ? 0 : data.four}
              />
            </AverageGrid>
          </AverageGridContainer>
          <AverageGridContainer container spacing={0}>
            <PointGrid item xs={1}>
              3
            </PointGrid>
            <AverageGrid item xs={11}>
              <BorderLinearProgress
                variant="determinate"
                value={data.three === undefined ? 0 : data.three}
              />
            </AverageGrid>
          </AverageGridContainer>
          <AverageGridContainer container spacing={0}>
            <PointGrid item xs={1}>
              2
            </PointGrid>
            <AverageGrid item xs={11}>
              <BorderLinearProgress
                variant="determinate"
                value={data.two === undefined ? 0 : data.two}
              />
            </AverageGrid>
          </AverageGridContainer>
          <AverageGridContainer container spacing={0}>
            <PointGrid item xs={1}>
              1
            </PointGrid>
            <AverageGrid item xs={11}>
              <BorderLinearProgress
                variant="determinate"
                value={data.one === undefined ? 0 : data.one}
              />
            </AverageGrid>
          </AverageGridContainer>
        </RatingGridContainer>
        <CommentContainer>
          {data.comment.map((item, index) => (
            <Card
              className={clsx(
                classes.paperLight,
                index % 2 === 1 && classes.paperDark
              )}
              key={index}
            >
              <Grid container spacing={1}>
                <Grid item xs={9}>
                  <CardTitle>{item.author}</CardTitle>
                  <CardContent>{item.content}</CardContent>
                </Grid>
                <CardRightGrid item xs={3}>
                  <CardIconContainer>
                    <StyledCardRating
                      name="read-only"
                      value={item.score === undefined ? 0 : item.score}
                      emptyIcon={<StarBorderIcon fontSize="inherit" />}
                      size="small"
                      readOnly
                    />
                  </CardIconContainer>
                  {
                    item.modified && 
                    <CardEditRemind>{t("product.software.other.edited")}</CardEditRemind>
                  }  
                  <CardDate>{item.date}</CardDate>
                </CardRightGrid>
              </Grid>
            </Card>
          ))}
        </CommentContainer>
      </RatingBoardContainer>
    </Wrapper>
  );
};

export default RatingBoard;
