export const ADD_TO_CART = 'ADD_TO_CART';
export const DROP_FROM_CART = 'DROP_FROM_CART';
export const QUANTITY_INCREMENT = 'QUANTITY_INCREMENT';
export const QUANTITY_DECREMENT = 'QUANTITY_DECREMENT';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_SYNC_CART_SUCCESS = 'FETCH_SYNC_CART_SUCCESS';
export const LOCK_CART_ITEM = 'LOCK_CART_ITEM';
export const UNLOCK_CART_ITEM = 'UNLOCK_CART_ITEM';
export const SYNC_CART_ITEM = 'SYNC_CART_ITEM';
export const CART_LOADING = 'CART_LOADING';

export const ADD_CART = 'ADD_CART';
export const DROP_CART = 'DROP_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const DROP_ITEMS = 'DROP_ITEMS';
export const CART_SYNC_LOADING = 'CART_SYNC_LOADING';

export const FETCH_CART_COUPONS = 'FETCH_CART_COUPONS';
export const FETCH_COUPONS_SUCCESS = 'FETCH_COUPONS_SUCCESS';
export const UPDATE_COUPON_ITEM = 'UPDATE_COUPON_ITEM';

export const fetchCartSuccess = (result) => ({
  type: FETCH_CART_SUCCESS,
  payload: result.data,
});

export const cartLoadingStart = () => ({
  type: CART_LOADING,
  payload: { loading: true },
});

export const cartSyncLoadingStart = (status) => ({
  type: CART_SYNC_LOADING,
  payload: { loading: status },
});

export const addToCart = (
  productId,
  image,
  name,
  description,
  product,
  category,
  provider,
  providerId,
  version,
  versionIndex,
  price,
  quantity
) => ({
  type: ADD_CART,
  payload: {
    id: productId,
    image: image,
    name: name,
    description: description,
    product: product,
    category: category,
    provider: provider,
    providerId: providerId,
    version: version,
    versionIndex: versionIndex,
    price: price,
    quantity: quantity,
  },
});

export const dropFromCart = (productId) => ({
  type: DROP_CART,
  payload: {
    id: productId,
  },
});

export const dropItemsFromCart = (productIds) => ({
  type: DROP_ITEMS,
  payload: productIds,
});

export const quantityIncrement = (productId) => ({
  type: QUANTITY_INCREMENT,
  payload: {
    id: productId,
  },
});

export const quantityDecrement = (productId) => ({
  type: QUANTITY_DECREMENT,
  payload: {
    id: productId,
  },
});

export const lockCartItem = (index) => ({
  type: LOCK_CART_ITEM,
  payload: index,
});

export const unlockCartItem = (index) => ({
  type: UNLOCK_CART_ITEM,
  payload: index,
});

export const syncCartItem = () => ({
  type: SYNC_CART_ITEM,
});

export const clearCart = () => ({
  type: CLEAR_CART,
});

export const fetchCoupons = (language) => ({
  type: FETCH_CART_COUPONS,
  payload: language,
});

export const fetchCouponsSuccess = (result) => ({
  type: FETCH_COUPONS_SUCCESS,
  payload: result.data,
});

export const updateCouponItem = (coupon, discount, count) => ({
  type: UPDATE_COUPON_ITEM,
  payload: {
    coupon: coupon,
    item: discount,
    count: count,
  },
});
